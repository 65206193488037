// Getting the specified information from the browser local storage
export function getFromLocalStorage(item, onError) {
  try {
    return window.localStorage.getItem(item);
  } catch (error) {
    onError && onError(error);
  }
  return undefined;
}

// Saving new information to the browser local storage
export function setToLocalStorage(item, value, onError) {
  try {
    window.localStorage.setItem(item, value);
  } catch (error) {
    onError && onError(error);
  }
}

// Getting a specific information from the browser local storage
export function removeFromLocalStorage(item, onError) {
  try {
    window.localStorage.removeItem(item);
  } catch (error) {
    onError && onError(error);
  }
}

// Saving the customer key
export function setCustomerKey(eventId, startNumber, customerKey, onError) {
  const storageKey = `event-${eventId}`;
  try {
    // Check if the 'search' object already exists
    const obj = JSON.parse(window.sessionStorage.getItem(storageKey));
    if (obj) {
      const newObj = { ...obj };
      newObj[startNumber] = customerKey;
      window.sessionStorage.setItem(storageKey, JSON.stringify(newObj));
    } else {
      const newObj = { [startNumber]: customerKey };
      window.sessionStorage.setItem(storageKey, JSON.stringify(newObj));
    }
  } catch (error) {
    onError && onError(error);
  }
}

// Getting the customer key
export function getCustomerKey(eventId, startNumber, onError) {
  const storageKey = `event-${eventId}`;
  try {
    const obj = JSON.parse(window.sessionStorage.getItem(storageKey));
    if (obj) {
      return obj[startNumber];
    }
    return undefined;
  } catch (error) {
    onError && onError(error);
  }
}
