import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation DeleteLineItemFromCartMutation($input: DeleteLineItemFromCartInput!) {
    deleteLineItemFromCart(input: $input) {
      cart {
        additionalProducts {
          id
          price
          type
        }
        lineItems {
          id
          product {
            eventId
            price
            type
          }
          productParams {
            ... on SingleFotoParams {
              foto {
                sgId
              }
            }
            ... on FotoFlatParams {
              startnumber
            }
          }
        }
        totalPrice
      }
    }
  }
`;

function commit(environment, lineItemId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        lineItemId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.deleteLineItemFromCart.cart);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
