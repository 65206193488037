import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartQuickCheckout(props) {
  const {
    stepNumber,
    onGoToCheckout,
    onContinueAsGuest,
    onGoToPaymentStep,
    isFormError,
    isOrder,
    isLoggedIn,
  } = props;
  const [nextStepButton, setNextStepButton] = useState(() => onGoToCheckout);

  useEffect(() => {
    defineNextStepFunction();
  }, []);

  useEffect(() => {
    defineNextStepFunction();
  }, [props.stepNumber]);

  const defineNextStepFunction = () => {
    if (stepNumber === -1) {
      setNextStepButton(() => onGoToCheckout);
    }
    if (stepNumber === 0) {
      setNextStepButton(() => onContinueAsGuest);
    }
    if (stepNumber === 1) {
      setNextStepButton(() => onGoToPaymentStep);
    }
  };

  return (
    <>
      {stepNumber != 2 && (
        <div className={`cart-btn-next-step ${props.isDesktop ? 'desktop' : 'mobile'}`}>
          <button
            className="btn-default primary-new full-width btn-cart-desktop"
            onClick={nextStepButton}
            disabled={stepNumber === 1 && isFormError}
          >
            {stepNumber === -1 && (
              <FormattedMessageWrappedInSpan
                id="cartPage.continueButton"
                defaultMessage="Proceed to checkout"
              />
            )}
            {stepNumber === 0 && (
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingRegisterStep.guestCheckout.continueButton"
                defaultMessage="Continue as guest"
              />
            )}
            {stepNumber === 1 &&
              (isFormError ? (
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingAddressStep.incorrectForm"
                  defaultMessage="Form is incorrect"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingAddressStep.nextButton"
                  defaultMessage="Proceed to payment"
                />
              ))}
          </button>
        </div>
      )}
    </>
  );
}

CartQuickCheckout.propTypes = {
  stepNumber: PropTypes.number,
  onGoToCheckout: PropTypes.func,
  onContinueAsGuest: PropTypes.func,
  onGoToPaymentStep: PropTypes.func,
  onCompleteCheckout: PropTypes.func,
  isFormError: PropTypes.bool,
  isOrder: PropTypes.bool,
};

export { CartQuickCheckout };
export default CartQuickCheckout;
