/**
 * @flow
 * @relayHash 94db2c782f38a09850fa80e93eac7386
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdyenPaymentsDetailsInput = {|
  details: string
|};
export type AdyenPaymentsDetailsMutationVariables = {|
  input: AdyenPaymentsDetailsInput
|};
export type AdyenPaymentsDetailsMutationResponse = {|
  +adyenPaymentsDetails: ?{|
    +paymentsDetails: ?{|
      +merchantReference: ?string,
      +pspReference: ?string,
      +resultCode: ?string,
    |}
  |}
|};
export type AdyenPaymentsDetailsMutation = {|
  variables: AdyenPaymentsDetailsMutationVariables,
  response: AdyenPaymentsDetailsMutationResponse,
|};
*/


/*
mutation AdyenPaymentsDetailsMutation(
  $input: AdyenPaymentsDetailsInput!
) {
  adyenPaymentsDetails(input: $input) {
    paymentsDetails {
      merchantReference
      pspReference
      resultCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AdyenPaymentsDetailsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adyenPaymentsDetails",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdyenPaymentsDetailsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentsDetails",
        "storageKey": null,
        "args": null,
        "concreteType": "AdyenPaymentsDetails",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "merchantReference",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pspReference",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "resultCode",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdyenPaymentsDetailsMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AdyenPaymentsDetailsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdyenPaymentsDetailsMutation",
    "id": null,
    "text": "mutation AdyenPaymentsDetailsMutation(\n  $input: AdyenPaymentsDetailsInput!\n) {\n  adyenPaymentsDetails(input: $input) {\n    paymentsDetails {\n      merchantReference\n      pspReference\n      resultCode\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ea383854d9655e85b71bc82f73c5aa0';

module.exports = node;
