import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';
import { NotificationDialogConsumer } from '../../context/NotificationDialogContext';

const HtmlDialog = () => (
  <NotificationDialogConsumer>
    {({ htmlDialogIsOpen, html, onCloseHtmlDialog }) => (
      <CustomDialog open={htmlDialogIsOpen} onRequesClose={onCloseHtmlDialog}>
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="notification-action">
              <button className="accept" onClick={onCloseHtmlDialog}>
                <FormattedMessageWrappedInSpan id="htmlDialog.acceptButton" defaultMessage="Ok" />
              </button>
            </div>
          </DialogActions>
        </div>
      </CustomDialog>
    )}
  </NotificationDialogConsumer>
);


export default HtmlDialog;
