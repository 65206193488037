import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../misc';

const InvalidGpxFileInformation = () => {
  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="notification-info">
          <h2>
            <FormattedMessageWrappedInSpan
              id="invalidGpxFileInformation.message"
              defaultMessage="We could not find any photos with the gpx file you provided. Please make sure the gpx file corresponds to the correct EVENT."
            />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default InvalidGpxFileInformation;
