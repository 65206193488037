/* global ADYEN_CLIENT_KEY: true */

import { sendError } from '../appsignal';

import isDev from './DevDetect';
import AdyenCheckout from '@adyen/adyen-web';

const renderAdyenComponent = async (element, paymentMethodsResponse, config) => {
  const adyenPaymentMethods = {
    AdyenCard: 'card',
    AdyenVisa: 'card',
    AdyenMastercard: 'card',
    AdyenIDEAL: 'ideal',
    AdyenSofort: 'directEbanking',
    AdyenAlipay: 'alipay',
    AdyenApplePay: 'applepay',
    AdyenBancontact: 'bcmc',
    AdyenGiropay: 'giropay',
  };

  const { address } = config;

  const adyenPaymentMethodsResponse = {
    paymentMethods: JSON.parse(paymentMethodsResponse.paymentMethods),
    storedPaymentMethods: JSON.parse(paymentMethodsResponse.storedPaymentMethods),
  };

  await AdyenCheckout({
    locale: config.locale,
    environment: isDev() ? 'test' : 'live',
    clientKey: ADYEN_CLIENT_KEY, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
    paymentMethodsResponse: adyenPaymentMethodsResponse,
    amount: config.amount,
    showPayButton: true,
    onSubmit: (state, component) => {
      if (state.isValid) {
        config.onSubmit(state.data, component);
      } else {
        config.onError();
      }
    },
    onAdditionalDetails: (state, component) => {
      config.onAdditionalDetails(state, component);
    },
    onError: (error, component) => {
      sendError(error);
      config.onError(error, component);
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        // billingAddressRequired: true, // Set to true to show the billing address input fields.
        data: {
          holderName: address.firstName + ' ' + address.lastName,
          billingAddress: {
            street: address.line1,
            houseNumberOrName: address.line2,
            postalCode: address.postalCode,
            city: address.city,
            country: address.country.alpha2,
          },
        },
      },
      ideal: {
        showImage: true,
      },
      applepay: {
        buttonType: 'buy',
        buttonColor: 'black',
      },
      bcmc: {
        hasHolderName: true,
        holderNameRequired: true,
        data: {
          holderName: address.firstName + ' ' + address.lastName,
        },
      },
    },
  }).then((checkout) => {
    config.onReady(checkout);
    checkout.create(adyenPaymentMethods[config.paymentMethod.name]).mount(element);
  });
};

const initAdyenComponent = (element, session, config) => {
  renderAdyenComponent(element, session, config);
};

export default initAdyenComponent;
