import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

const AccountPageInformations = ({
  email,
  shouldShowCookiesUpdateBanner,
  onCookiesChange,
  socialCookies,
  statsCookies,
}) => {
  return (
    <>
      <div className="account-page-email">
        <FormattedMessageWrappedInSpan
          id="accountPage.userEmail"
          defaultMessage="You are logged in as <span>{email}</span>"
          values={{
            email: email,
            span: (chunks) => <span className="account-page-user-email">{chunks}</span>,
          }}
        />
      </div>
      {shouldShowCookiesUpdateBanner && (
        <div className="account-page-cookie-change">
          <FormattedMessageWrappedInSpan
            id="accountPage.cookieChangedInfo.body"
            defaultMessage="We've added a new cookie type to simplify searching for photos. Allow us to save IDs of face search results and other searching data in your browser to use new features."
          />
          <div className="account-page-cookie-change_actions">
            <button
              className="btn btn-default secondary-new"
              onClick={() =>
                onCookiesChange({
                  stats: statsCookies,
                  social: socialCookies,
                  sportografWeb: 'false',
                })
              }
            >
              <FormattedMessageWrappedInSpan
                id="accountPage.cookieChangedInfoBtns.doNotAcceptButton"
                defaultMessage="Do not allow"
              />
            </button>
            <button
              className="btn btn-default secondary-new"
              onClick={() =>
                onCookiesChange({
                  stats: statsCookies,
                  social: socialCookies,
                  sportografWeb: true,
                })
              }
            >
              <FormattedMessageWrappedInSpan
                id="accountPage.cookieChangedInfoBtns.acceptButton"
                defaultMessage="Allow to save these cookies"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

AccountPageInformations.propTypes = {
  email: PropTypes.string,
  shouldShowCookiesUpdateBanner: PropTypes.bool,
  onCookiesChange: PropTypes.func,
  socialCookies: PropTypes.bool,
  statsCookies: PropTypes.bool,
};

export { AccountPageInformations };

export default AccountPageInformations;
