import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { withRouter } from 'react-router-dom';

import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountDownloadTileVoucher = (props) => {
  const { icon, textInSpan, values, history } = props;

  const [hasOpenedOptions, setHasOpenedOptions] = useState(false);

  const handleHasOpenedOptions = () => {
    if (values?.length > 1) {
      setHasOpenedOptions(true);
    } else {
      goToVoucher(0);
    }
  };

  const goToVoucher = (index) => {
    setHasOpenedOptions(false);
    history.push('/account/orders/invoice/'+values[index].orderid);
  };

  return (
    <>
      <AccountDownloadTileButton
        icon={icon}
        textInSpan={textInSpan}
        hasOpenedOptions={hasOpenedOptions}
        onClick={handleHasOpenedOptions}
        hasOptions={values?.length > 0}
        onCloseOptions={() => setHasOpenedOptions(false)}
      >
        {hasOpenedOptions && (
          <div className="dropdown">
            {values.map((downloadableElement, index) => {
              return (
                <button
                  onClick={() => goToVoucher(index)}
                  className="dropdown-item"
                >
                  <FormattedMessageWrappedInSpan
                    id="accountEventPage.products.orderId"
                    defaultMessage="Order ID"
                    className="dropdown-label"
                  />
                  <span className="dropdown-detail"> {downloadableElement.orderid}</span>
                </button>
              );
            })}
          </div>
        )}
      </AccountDownloadTileButton>
    </>
  );
};

AccountDownloadTileVoucher.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  values: PropTypes.array,
  history: PropTypes.object,
};

export default withRouter(AccountDownloadTileVoucher);
