/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventInfoCard_eventInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventPendingView_pendingEvent$ref: FragmentReference;
declare export opaque type EventPendingView_pendingEvent$fragmentType: EventPendingView_pendingEvent$ref;
export type EventPendingView_pendingEvent = {|
  +id: string,
  +sgId: ?number,
  +name: ?string,
  +sports: ?$ReadOnlyArray<?{|
    +name: ?string
  |}>,
  +$fragmentRefs: EventInfoCard_eventInfo$ref,
  +$refType: EventPendingView_pendingEvent$ref,
|};
export type EventPendingView_pendingEvent$data = EventPendingView_pendingEvent;
export type EventPendingView_pendingEvent$key = {
  +$data?: EventPendingView_pendingEvent$data,
  +$fragmentRefs: EventPendingView_pendingEvent$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventPendingView_pendingEvent",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sports",
      "storageKey": null,
      "args": null,
      "concreteType": "Sport",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCard_eventInfo",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5aa8a8e3dad91fe89f45568c98a4c31b';

module.exports = node;
