import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../misc';

import FaqCard from './FaqCard';

const Faq = ({ onFindMore, eventHasSearchByFace, hasExtendedOptions }) => {
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleExpandClick = (id) => {
    setSelectedCardId((prevState) => (prevState != id ? id : null));
  };

  const linkToHowToMarkArticle =
    'https://helpdesk.sportograf.com/en/support/solutions/articles/77000265399-how-do-i-find-and-mark-more-photos-of-myself-';

  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="faq-container">
          <h2>
            <FormattedMessageWrappedInSpan id="faq.title" defaultMessage="Help" />
          </h2>
          <FaqCard
            expanded={selectedCardId === 0}
            onExpandClick={() => handleExpandClick(0)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.fotoFlatTab.tabTitle"
                defaultMessage="What is a Foto-Flat"
              />
            }
          >
            <p>
              {eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.fotoFlatTab.searchByFace.description"
                  defaultMessage="At Sportograf a Foto-Flat is a bundle of photos for a fixed price. It includes *all* personal photos of one athlete and a selection of impression photos we took at your event. The number of pictures may vary. We do not always find all photos automatically. Further photos showing you can be added for free!"
                />
              )}
              {!eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.fotoFlatTab.description"
                  defaultMessage="At Sportograf a Foto-Flat is a bundle of photos for a fixed price. It includes *all* personal photos of one athlete (bib number) and a selection of impression photos we took at your event. The number of pictures may vary. We do not always find all photos automatically. Further photos showing you can be added for free!"
                />
              )}
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.fotoFlatTab.note"
                defaultMessage="Please note: Our Foto-Flat does {boldText} include photos of your family- or groupmembers, best mates, partners, etc. It is only for one person!"
                values={{
                  boldText: (
                    <b>
                      <FormattedMessageWrappedInSpan
                        id="faq.fotoFlatTab.nodeBoldText"
                        defaultMessage="not"
                      />
                    </b>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.fotoFlatTab.specialNote"
                defaultMessage="(*Spartans, every distance requires a new flatrate! Arroooo!)"
              />
            </p>
          </FaqCard>
          <FaqCard
            expanded={selectedCardId === 1}
            onExpandClick={() => handleExpandClick(1)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.buySinglePhotoTab.tabTitle"
                defaultMessage="Can I buy single photos?"
              />
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.buySinglePhotoTab.description"
                defaultMessage="For most events you can buy single photos. Click on the photo to see the price.{br}Of course, there are exceptions to the rule. For example, on multi-day stage races, we offer only the entire package.{br}Please search for your event and get the options and prices."
                values={{
                  br: <br />,
                }}
              />
            </p>
          </FaqCard>
          <FaqCard
            expanded={selectedCardId === 2}
            onExpandClick={() => handleExpandClick(2)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.morePhotosTab.tabTitle"
                defaultMessage="Do you have more photos of me?"
              />
            }
          >
            <h3>
              <FormattedMessageWrappedInSpan
                id="faq.morePhotosTab.maybe"
                defaultMessage="Maybe!"
              />
            </h3>
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.morePhotosTab.linkToFAQHint"
                defaultMessage="You can use alternative search options to find more photos of you. <a>Here you can find helpful tips</a>."
                values={{
                  a: (chunks) => (
                    <a
                      href={linkToHowToMarkArticle}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-blue"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
            {hasExtendedOptions && (
              <button className="secondary mt-20" onClick={onFindMore}>
                <FormattedMessageWrappedInSpan
                  id="faq.morePhotosTab.button"
                  defaultMessage="Find more of me"
                />
              </button>
            )}
          </FaqCard>
          <FaqCard
            expanded={selectedCardId === 3}
            onExpandClick={() => handleExpandClick(3)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.morePhotosTab.finalQuestion"
                defaultMessage="Can I buy my Foto-Flat now and look for additional photos later?"
              />
            }
          >
            <p>
              {eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.morePhotosTab.searchByFace.answer"
                  defaultMessage="YES YOU CAN! As soon as you have tagged a photo and we have checked it, you will receive an email from us and the photo will automatically be added to your order."
                />
              )}
              {!eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.morePhotosTab.answer"
                  defaultMessage="YES YOU CAN! As soon as you have tagged a photo with your bib number and we have checked it, you will receive an email from us and the photo will automatically be added to your order."
                />
              )}
            </p>
            <p>
              {eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.morePhotosTab.searchByFace.otherWayAround"
                  defaultMessage="By the way, it also works the other way round. You can mark photos, have them checked by us and place your order afterwards."
                />
              )}
              {!eventHasSearchByFace && (
                <FormattedMessageWrappedInSpan
                  id="faq.morePhotosTab.otherWayAround"
                  defaultMessage="By the way, it also works the other way round. You can mark photos with your bib number, have them checked by us and place your order afterwards."
                />
              )}
            </p>
          </FaqCard>

          <FaqCard
            expanded={selectedCardId === 4}
            onExpandClick={() => handleExpandClick(4)}
            title={
              eventHasSearchByFace ? (
                <FormattedMessageWrappedInSpan
                  id="faq.addPhotoToSnTab.searchByFace.tabTitle"
                  defaultMessage="How can I tag photos?"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="faq.addPhotoToSnTab.tabTitle"
                  defaultMessage="How can I add photos to my bib number?"
                />
              )
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.addPhotoToSnTab.descriptionWithLink"
                defaultMessage="If you have found other photos of yourself, you can mark them with the <b>That's me</b> button.{br}If the <b>That's me</b> button does not appear, <a>you can find solutions here</a>."
                values={{
                  br: <br />,
                  b: (chunks) => <b>{chunks}</b>,
                  a: (chunks) => (
                    <a
                      href={linkToHowToMarkArticle}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-blue"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>

            {hasExtendedOptions && (
              <button className="secondary mt-20" onClick={onFindMore}>
                <FormattedMessageWrappedInSpan
                  id="faq.addPhotoToSnTab.button"
                  defaultMessage="Find more of me"
                />
              </button>
            )}
          </FaqCard>
        </div>
      </div>
    </div>
  );
};

Faq.propTypes = {
  onFindMore: PropTypes.func,
  eventHasSearchByFace: PropTypes.bool,
  hasExtendedOptions: PropTypes.bool,
};

export default Faq;
