/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';

import MediaList from './MediaList';

class MediaCollectionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
    };
  }

  handleLoadMore = () => {
    if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ isFetching: true });
    this.props.relay.loadMore(MEDIA_FETCH_COUNT, () =>
      this.setState({ isFetching: false })
    );
  };

  render() {
    const { isFetching } = this.state;
    const {
      eventHasSearchByFace,
      eventHasSecondFactor,
      mediaCollectionMedia,
      relay,
      offer,
      participantMediaCount,
      impressionMediaCount,
      startnumber,
      goBackToMainSearchResults,
      eventHasFotoFlat,
    } = this.props;

    return (
      <MediaList
        eventHasSearchByFace={eventHasSearchByFace}
        eventHasSecondFactor={eventHasSecondFactor}
        media={mediaCollectionMedia.mediaList}
        hasMore={relay.hasMore()}
        isFetching={isFetching}
        onLoadMore={this.handleLoadMore}
        offer={offer}
        participantMediaCount={participantMediaCount}
        impressionMediaCount={impressionMediaCount}
        listType="categories"
        startnumber={startnumber}
        goBackToMainSearchResults={goBackToMainSearchResults}
        eventHasFotoFlat={eventHasFotoFlat}
      />
    );
  }
}

MediaCollectionList.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  startnumber: PropTypes.string,
  mediaCollectionMedia: PropTypes.object,
  offer: PropTypes.object,
  relay: PropTypes.object,
  goBackToMainSearchResults: PropTypes.func,
  eventHasFotoFlat: PropTypes.bool,
};

export { MediaCollectionList };

export default createPaginationContainer(
  MediaCollectionList,
  {
    mediaCollectionMedia: graphql`
      fragment MediaCollectionList_mediaCollectionMedia on ExtendedMediaConnection
      @argumentDefinitions(
        fetchCategory: { type: "Boolean" }
        fetchAllPhotos: { type: "Boolean" }
      ) {
        mediaList(
          first: $count
          after: $cursor
          afterShotDate: $minTime
        ) @connection(key: "MediaCollectionList_mediaList") {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          ...MediaList_media
        }
      }
    `,
    offer: graphql`
      fragment MediaCollectionList_offer on Event {
        ...MediaList_offer
      }
    `,
  },
  {
    direction: 'forward',
    // Function that should indicate which connection to paginate over, given the fragment
    // props.
    getConnectionFromProps(props) {
      return props.mediaCollectionMedia && props.mediaCollectionMedia.mediaList;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        eventId: props.eventId,
        categoryId: props.categoryId,
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        fetchCategory: fragmentVariables.fetchCategory,
        fetchAllPhotos: fragmentVariables.fetchAllPhotos,
        minTime: fragmentVariables.minTime,
        isRecheckPage: false,
      };
    },
    query: graphql`
      query MediaCollectionListQuery(
        $eventId: ID!
        $categoryId: ID!
        $count: Int!
        $cursor: String
        $fetchCategory: Boolean
        $fetchAllPhotos: Boolean
        $minTime: NaiveDatetime
        $isRecheckPage: Boolean!
      ) {
        viewer {
          event(id: $eventId) {
            media @include(if: $fetchAllPhotos) {
              ...MediaCollectionList_mediaCollectionMedia
            }
            mediaCollection(id: $categoryId) @include(if: $fetchCategory) {
              media {
                ...MediaCollectionList_mediaCollectionMedia
              }
            }
          }
        }
      }
    `,
  }
);
