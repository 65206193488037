import React from 'react';

import { FormattedMessageWrappedInSpan } from '../../components/misc';

export default function PrivacyPage() {
  let paragraphNumber = 0;
  return (
    <div className="container-960 container-page">
      <h2>
        <FormattedMessageWrappedInSpan id="privacyPage.title" defaultMessage="Placeholder" />
      </h2>
      <br />
      <div>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.date" defaultMessage="Placeholder" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.intro.pOne" defaultMessage="Placeholder" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.intro.pTwo" defaultMessage="Placeholder" />
        </p>
      </div>
      {/*
        * 1. Controller
        */}
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.dataController.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.dataController.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <center>
          <div>
            <b>Sportograf Digital Solutions GmbH</b>
          </div>
          <div>
            <b>Süsterfeldstr 170</b>
          </div>
          <div>
            <b>52072 Aachen</b>
          </div>
          <div>
            <b>E-Mail: support@sportograf.com</b>
          </div>
        </center>
      </div>
      {/*
        * 2. Data protection officer
        */}
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.dataProtectionOfficer.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.dataProtectionOfficer.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <center>
          <div>
            <b>Aix Privacy GmbH</b>
          </div>
          <div>
            <b>Aachener-und-Münchener-Allee 9</b>
          </div>
          <div>
            <b>52074 Aachen</b>
          </div>
          <div>
            <b>E-Mail: datenschutz.sportograf@aixprivacy.de</b>
          </div>
        </center>
      </div>
      {/*
        * 3. Collection, processing and use of personal data
        */}
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.collectionProcessingUse.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.collectionProcessingUse.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <ul>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.collectionProcessingUse.liOne"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.collectionProcessingUse.liTwo"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.collectionProcessingUse.liThree"
              defaultMessage="Placeholder"
            />
          </li>
        </ul>
      </div>
      {/*
        * 4. Type of data and purpose of collection, processing or use of personal data
        */}
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.typeOfPersonalData.title"
            defaultMessage="Placeholder"
          />
        </b>
        <ul className="no-style-type">
          <li>
            <b>
              A.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
          <ul>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liOne"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liTwo"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liThree"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liFour"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liFive"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liSix"
                defaultMessage="Placeholder"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.informationalUse.liSeven"
                defaultMessage="Placeholder"
              />
            </li>
          </ul>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              B.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.contactByEmail.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.contactByEmail.pOne"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              C.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.pThree"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.pFour"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.onlineShop.pFive"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              D.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <b>
                <FormattedMessageWrappedInSpan
                  id="privacyPage.typeOfPersonalData.newsLetter.pTwo.bold"
                  defaultMessage="Placeholder"
                />&nbsp;
              </b>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pTwo.normal"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <b>
                <FormattedMessageWrappedInSpan
                  id="privacyPage.typeOfPersonalData.newsLetter.pThree.bold"
                  defaultMessage="Placeholder"
                />&nbsp;
              </b>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pThree.normal"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <b>
                <FormattedMessageWrappedInSpan
                  id="privacyPage.typeOfPersonalData.newsLetter.pFour.bold"
                  defaultMessage="Placeholder"
                />&nbsp;
              </b>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pFour.normal"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <b>
                <FormattedMessageWrappedInSpan
                  id="privacyPage.typeOfPersonalData.newsLetter.pFive.bold"
                  defaultMessage="Placeholder"
                />&nbsp;
              </b>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pFive.normal"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <b>
                <FormattedMessageWrappedInSpan
                  id="privacyPage.typeOfPersonalData.newsLetter.pSix.bold"
                  defaultMessage="Placeholder"
                />&nbsp;
              </b>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.newsLetter.pSix.normal"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              E.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.photography.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.photography.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.photography.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.photography.pThree"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              F.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pOne"
                defaultMessage="Placeholder"
                values={{
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pThree"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pFour"
                defaultMessage="Placeholder"
                values={{
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pFive"
                defaultMessage="Placeholder"
                values={{
                  b: chunks => <b>{chunks}</b>,
                  br: <br />
                }}
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.googleAnalytics.pSix"
                defaultMessage="Placeholder"
                values={{
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </p>
          </li>
        </ul>
        <ul className="no-style-type">
          <li>
            <b>
              G.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.search.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.search.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.search.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.typeOfPersonalData.search.pThree"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.purposeOfCollection.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.purposeOfCollection.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.purposeOfCollection.pTwo"
            defaultMessage="Placeholder"
          />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.durationOfStorage.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.durationOfStorage.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.durationOfStorage.pTwo"
            defaultMessage="Placeholder"
          />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.noPassingToThirdParties.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.noPassingToThirdParties.pOne"
            defaultMessage="Placeholder"
          />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.protectionOfPersonalData.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.protectionOfPersonalData.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.protectionOfPersonalData.pTwo"
            defaultMessage="Placeholder"
          />
        </p>
        <ul className="no-style-type">
          <li>
            <b>
              A.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.protectionOfPersonalData.sslDefinition.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.protectionOfPersonalData.sslDefinition.pOne"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
          <li>
            <b>
              B.&nbsp;<FormattedMessageWrappedInSpan
                id="privacyPage.protectionOfPersonalData.sslWorkflow.title"
                defaultMessage="Placeholder"
              />
            </b>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.protectionOfPersonalData.sslWorkflow.pOne"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
        </ul>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan id="privacyPage.cookies.title" defaultMessage="Placeholder" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.cookies.pOne" defaultMessage="Placeholder" />
        </p>
        <p>
          <ul>
            <li>
              <FormattedMessageWrappedInSpan id="privacyPage.cookies.bullet1" defaultMessage="-" />
            </li>
            <li>
              <FormattedMessageWrappedInSpan id="privacyPage.cookies.bullet2" defaultMessage="-" />
            </li>
          </ul>
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.cookies.pTwo" defaultMessage="Placeholder" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.cookies.pThree"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.cookies.pFour" defaultMessage="Placeholder" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="privacyPage.cookies.pFive" defaultMessage="." />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.thirdPartyServicesIntegration.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.thirdPartyServicesIntegration.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.thirdPartyServicesIntegration.pTwo"
            defaultMessage="Placeholder"
          />
        </p>
        <ul>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.thirdPartyServicesIntegration.liOne"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.thirdPartyServicesIntegration.liTwo.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.thirdPartyServicesIntegration.liTwo.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.thirdPartyServicesIntegration.liTwo.pThree"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.thirdPartyServicesIntegration.liThree.pOne"
                defaultMessage="Placeholder"
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="privacyPage.thirdPartyServicesIntegration.liThree.pTwo"
                defaultMessage="Placeholder"
              />
            </p>
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.thirdPartyServicesIntegration.liFour"
              defaultMessage="Placeholder"
            />
          </li>
        </ul>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.personsRights.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.personsRights.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <ul>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.personsRights.liOne"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.personsRights.liTwo"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.personsRights.liThree"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.personsRights.liFour"
              defaultMessage="Placeholder"
            />
          </li>
          <li>
            <FormattedMessageWrappedInSpan
              id="privacyPage.personsRights.liFive"
              defaultMessage="Placeholder"
            />
          </li>
        </ul>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.personsRights.pTwo"
            defaultMessage="Placeholder"
          />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.oppositionOrRevocation.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.oppositionOrRevocation.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <p>
          <b>
            <FormattedMessageWrappedInSpan
              id="privacyPage.oppositionOrRevocation.pTwo"
              defaultMessage="Placeholder"
            />
          </b>
        </p>
        <p>
          <b>
            <FormattedMessageWrappedInSpan
              id="privacyPage.oppositionOrRevocation.pThree"
              defaultMessage="Placeholder"
            />
          </b>
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.declarationChanges.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.declarationChanges.pOne"
            defaultMessage="Placeholder"
          />
        </p>
      </div>
      <div>
        <b>
          {++paragraphNumber}.&nbsp;
          <FormattedMessageWrappedInSpan
            id="privacyPage.questions.title"
            defaultMessage="Placeholder"
          />
        </b>
        <p>
          <FormattedMessageWrappedInSpan
            id="privacyPage.questions.pOne"
            defaultMessage="Placeholder"
          />
        </p>
        <center>
          <div>
            <b>Sportograf Digital Solutions GmbH</b>
          </div>
          <div>
            <b>Süsterfeldstr 170</b>
          </div>
          <div>
            <b>52072 Aachen</b>
          </div>
          <div>* * * * *</div>
        </center>
      </div>
    </div>
  );
}
