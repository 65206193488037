import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation SetCookieSettingsMutation($input: SetCookieSettingsInput!) {
    setCookieSettings(input: $input) {
      cookieSettings {
        optIn
        userId
      }
    }
  }
`;

function commit(environment, optIn, userId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        optIn,
        userId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.setCookieSettings.cookieSettings);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
