import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation ConfirmCheckoutMutation($input: ConfirmCheckoutInput!) {
    confirmCheckout(input: $input) {
      order {
        orderid
        state
      }
    }
  }
`;

function commit(environment, orderId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        orderId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.confirmCheckout.order);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
