import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AddReminderForEventMutation($input: AddReminderForEventInput!) {
    addReminderForEvent(input: $input) {
      status
    }
  }
`;

function commit(environment, email, eventId, language, participant, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        eventId,
        language,
        participant,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.addReminderForEvent.status);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
