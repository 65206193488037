import React from 'react'

const AboutStartnumberSvg = () => (
  <svg viewBox="-4 -2 468 555">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g fill="#1788DA">
        <g>
          <rect
            className="about-startnumber-1"
            style={{mixBlendMode: 'screen'}}
            x={0}
            y={483}
            width={22}
            height={11}
          />
          <rect
            className="about-startnumber-2"
            style={{mixBlendMode: 'screen'}}
            x={100}
            y={573}
            width={30}
            height={11}
          />
          <rect
            className="about-startnumber-3"
            style={{mixBlendMode: 'screen'}}
            x={242}
            y={482}
            width={30}
            height={12}
          />
          <rect
            className="about-startnumber-4"
            style={{mixBlendMode: 'screen'}}
            x={317}
            y={394}
            width={30}
            height={12}
          />
          <rect
            className="about-startnumber-5"
            style={{mixBlendMode: 'screen'}}
            x={400}
            y={388}
            width={30}
            height={12}
          />
          <rect
            className="about-startnumber-6"
            style={{mixBlendMode: 'screen'}}
            x={50}
            y={349}
            width={22}
            height={11}
          />
          <rect
            className="about-startnumber-7"
            style={{mixBlendMode: 'screen'}}
            x={160}
            y={330}
            width={30}
            height={11}
          />
          <rect
            className="about-startnumber-8"
            style={{mixBlendMode: 'screen'}}
            x={291}
            y={331}
            width={30}
            height={12}
          />
          <rect
            className="about-startnumber-9"
            style={{mixBlendMode: 'screen'}}
            x={87}
            y={292}
            width={22}
            height={11}
          />
          <rect
            className="about-startnumber-10"
            style={{mixBlendMode: 'screen'}}
            x={193}
            y={260}
            width={30}
            height={11}
          />
          <rect
            className="about-startnumber-11"
            style={{mixBlendMode: 'screen'}}
            x={328}
            y={277}
            width={22}
            height={11}
          />
          <rect
            className="about-startnumber-12"
            style={{mixBlendMode: 'screen'}}
            x={164}
            y={184}
            width={16}
            height={10}
          />
          <rect
            className="about-startnumber-13"
            style={{mixBlendMode: 'screen'}}
            x={50}
            y={163}
            width={17}
            height={9}
          />
          <rect
            className="about-startnumber-14"
            style={{mixBlendMode: 'screen'}}
            x={113}
            y={144}
            width={17}
            height={9}
          />
          <rect
            className="about-startnumber-15"
            style={{mixBlendMode: 'screen'}}
            x={191}
            y={144}
            width={17}
            height={9}
          />
          <rect
            className="about-startnumber-16"
            style={{mixBlendMode: 'screen'}}
            x={242}
            y={130}
            width={19}
            height={9}
          />
          <rect
            className="about-startnumber-17"
            style={{mixBlendMode: 'screen'}}
            x={314}
            y={139}
            width={17}
            height={9}
          />
          <rect
            className="about-startnumber-18"
            style={{mixBlendMode: 'screen'}}
            x={211}
            y={101}
            width={13}
            height={7}
          />
          <rect
            className="about-startnumber-19"
            style={{mixBlendMode: 'screen'}}
            x={95}
            y={81}
            width={12}
            height={6}
          />
          <rect
            className="about-startnumber-20"
            style={{mixBlendMode: 'screen'}}
            x={181}
            y={64}
            width={12}
            height={6}
          />
          <rect
            className="about-startnumber-21"
            style={{mixBlendMode: 'screen'}}
            x={266}
            y={67}
            width={12}
            height={6}
          />
          <rect
            className="about-startnumber-22"
            style={{mixBlendMode: 'screen'}}
            x={312}
            y={107}
            width={16}
            height={8}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AboutStartnumberSvg
