import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { FormattedMessageWrappedInSpan } from '../components/misc';
import { Redirect, useLocation } from 'react-router-dom';

import EventOnlineView from '../components/content/event/EventOnlineView';
import Faq from '../components/content/Faq';
import InsufficientResultsInformation from '../components/content/informations/InsufficientResultsInformation';
import SecondFactorErrorMsg from '../components/messages/SecondFactorErrorMsg';
import LoadingView from '../components/content/LoadingView';
import MediaList from '../components/content/lists/MediaList';
import ParticipantMediaList from '../components/content/lists/ParticipantMediaList';
import SearchInstructionsDialog from '../components/content/dialogs/SearchInstructionsDialog';
import CustomDialog from '../components/content/dialogs/CustomDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Pricelist from '../components/content/event/Pricelist';
import PaymentMethodView from '../components/content/PaymentMethodView';
import ItemAddedDialog from '../components/content/dialogs/ItemAddedDialog';
import { customScrollToComponent } from '../utils/common';

import {
  hasMedia,
  hasEnoughMedia,
  getFotoFlat,
  getSinglePhoto,
  getVideo,
  getCertificate,
  includesVideo,
  includesCertificate,
} from '../utils/common';
import {
  FACTOR_EMAIL,
  FACTOR_CODE,
  MAIN_SEARCH_FACE,
  MAIN_SEARCH_BIB,
  FOTO_FLAT,
  ALTERNATIVE,
  TAB_DUAL,
  TAB_SMART,
  TAB_CATEGORIES,
  TAB_GPS,
} from '../utils/variables';
import EmbeddedVideo from '../components/misc/EmbeddedVideo';
import withRequestHandler from '../components/hoc/withRequestHandler';
import { setCustomerKey, setToLocalStorage } from '../utils/browser-storage';
import { trackEvent } from '../utils/ga-tracking';
import { UserConsumer, UserContext } from '../components/context/UserContext';
import { HelpdeskWidgetContext } from '../components/content/HelpdeskWidget';
import { AddFotoFlatToCartMutation } from '../mutations';
import { getProduct } from '../utils/common';
import usePrevStateValue from '../utils/usePrevStateValue';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const EventSnSearchPage = (props) => {
  const { event } = props.viewer;
  const {
    viewer,
    snFromUrl,
    customerKey,
    codeSecondFactorForFaceEvent,
    relay,
    onRequestStart,
    onRequestStop,
    onRequestError,
  } = props;
  const { onMarathonFotoChange } = useContext(UserContext);
  const prevSnFromUrl = usePrevStateValue(snFromUrl);
  const prevCustomerKey = usePrevStateValue(customerKey);

  const [isLoading, setIsLoading] = useState(false);
  const [extendedSearchSelectedTab, setExtendedSearchSelectedTab] =
    useState(initialSelectedTab);
  const [extendedSearchExpand, setExtendedSearchExpand] = useState(false);
  const [showSearchInstructionsDialog, setShowSearchInstructionsDialog] = useState(
    Boolean(event.dialogAfterSearch)
  );
  const [shouldLoadImpressions, setShouldLoadImpressions] = useState(false);
  const [formPositionRef, setFormPositionRef] = useState(null);
  const [altSearchPositionRef, setAltSearchPositionRef] = useState(null);
  const [showCertificateInformation, setShowCertificateInformation] = useState(false);
  const [isFaceSearchEvent, setIsFaceSearchEvent] = useState(
    event.mainSearch === MAIN_SEARCH_FACE
  );
  const [isBibSearchEvent, setIsBibSearchEvent] = useState(
    event.mainSearch === MAIN_SEARCH_BIB
  );
  const [updatedCart, setUpdatedCart] = useState(null);
  const [showItemDialog, setShowItemDialog] = useState(false);
  const [fotoFlatPosition, setFotoFlatPosition] = useState(null);
  const [formPosition, setFormPosition] = useState(null);
  const [stillProcessingPosition, setStillProcessingPosition] = useState(null);

  const location = useLocation()

  const shouldShowdialogAfterSearchWarn =
    (event.dialogAfterSearch && !codeSecondFactorForFaceEvent) ||
    (event.dialogAfterSearch && codeSecondFactorForFaceEvent && snFromUrl);

  const impressionRef = useRef();
  const videoRef = useRef();
  const certificateRef = useRef();
  const eventOnlineViewRef = useRef();
  const mediaSectionRef = useRef();
  const eventCardRef = useRef();
  const vouchersRef = useRef(new Map());

  let imagesLoadedFallback;
  let videoUrl = null;
  let certificateImgPreview = null;
  let initialSelectedTab;

  const userLoginEmail = viewer.account && String(viewer.account.profile.email).trim();
  const participantMediaCount = event.participant
    ? event.participant.media.mediaInfo.count
    : 0;
  const { count: impressionMediaCount } = event.impressionMedia.mediaInfo;
  const hasExtendedOptions =
    event.gps ||
    event.searchDual ||
    (event.smartSearch && participantMediaCount !== 0) ||
    event.searchCollections;

  if (includesVideo(event.products)) {
    videoUrl = getVideoUrl(event);
  }

  if (includesCertificate(event.products)) {
    certificateImgPreview = getCertificatePreview(event.products);
  }

  switch (true) {
    case event.searchDual:
      initialSelectedTab = TAB_DUAL;
      break;
    case event.smartSearch && hasMedia(event.participant):
      initialSelectedTab = TAB_SMART;
      break;
    case event.searchCollections:
      initialSelectedTab = TAB_CATEGORIES;
      break;
    case event.gps:
      initialSelectedTab = TAB_GPS;
      break;
    default:
      initialSelectedTab = TAB_CATEGORIES;
  }

  useEffect(() => {
    setExtendedSearchSelectedTab(initialSelectedTab);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isAlt = queryParams.get(ALTERNATIVE);
    if (isAlt) {
      setExtendedSearchExpand(true)
    }
  }, [location.search])

  useEffect(() => {
    onMarathonFotoChange(event.marathonFoto);

    if (Boolean(event.participant) && snFromUrl) {
      scrollToMediaSection();
    }

    if (customerKey && event.participant) {
      setCustomerKey(viewer.event.sgId, snFromUrl, customerKey);
    }

    if (codeSecondFactorForFaceEvent && event.participant) {
      setToLocalStorage(`${viewer.event.sgId}-code`, codeSecondFactorForFaceEvent);
    }

    //in case we don't get the callback from MediaListMasonry
    imagesLoadedFallback = setTimeout(handleImagesLoaded, 5000);

    // track if no media where found
    if (!hasMedia(event.participant)) {
      trackNoMediaFound(snFromUrl);
    }
  }, []);

  useEffect(() => {
    if (snFromUrl !== prevSnFromUrl || customerKey !== prevCustomerKey) {
      refetchMediaForNewStartnumber(snFromUrl, customerKey);
    }
  }, [snFromUrl, customerKey]);

  useEffect(() => {
    return () => {
      clearImagesLoadedFallback();
      vouchersRef.current.clear();
    };
  }, []);

  useEffect(() => {
    if (stillProcessingPosition && stillProcessingPosition.current) {
      scrollToMediaSection();
    }
  }, [stillProcessingPosition]);

  // navigation

  const handleExtendedSearchTabChanged = (event, value) => {
    setExtendedSearchSelectedTab(value);
    setExtendedSearchExpand(true);
  };

  const handleExtendedSearchExpand = () => {
    setExtendedSearchExpand((prevState) => !prevState);
  };

  const handleSearchTriggered = () => {
    setExtendedSearchExpand(false);
  };

  const handleDeepSearchBtnClicked = () => {
    scrollToExtendedSearch(TAB_SMART);
  };

  const handleCategorySearchBtnClicked = () => {
    scrollToExtendedSearch(TAB_CATEGORIES);
  };

  const handleGpsSearchBtnClicked = () => {
    scrollToExtendedSearch(TAB_GPS);
  };

  const handleDualSearchBtnClicked = () => {
    scrollToExtendedSearch(TAB_DUAL);
  };

  // scrolling

  const handleScrollToImpressions = () => {
    impressionRef.current && customScrollToComponent(impressionRef.current);
  };

  const handleScrollToEvent = () => {
    impressionRef.current && customScrollToComponent(impressionRef.current);
  };

  const handleScrollToVoucher = (voucherCampaignId) => {
    vouchersRef.current && customScrollToComponent(vouchersRef.current.get(voucherCampaignId), 'middle');
    trackEvent('Event', 'Voucher', 'Offer Foto-Flat');
  };

  const handleScrollToVideo = () => {
    videoRef.current && customScrollToComponent(videoRef.current);
  };

  const handleScrollToCertificate = () => {
    if (certificateRef.current) {
      customScrollToComponent(certificateRef.current);
    } else {
      setShowCertificateInformation(true);
    }
  };

  const scrollToExtendedSearch = (selectedTabIndex) => {
    altSearchPositionRef.current && customScrollToComponent(altSearchPositionRef.current);
    setExtendedSearchExpand(true);
    setExtendedSearchSelectedTab(selectedTabIndex);
  };

  const scrollingToFotoFlatInMediaMasonry = () => {
    if (getFotoFlat(event.products) && fotoFlatPosition && fotoFlatPosition.current) {
      customScrollToComponent(fotoFlatPosition.current);
    }
  };

  const scrollToForm = () => {
    customScrollToComponent(formPositionRef.current);
  };

  const scrollToMediaSection = () => {
    if (stillProcessingPosition && stillProcessingPosition.current) {
      customScrollToComponent(stillProcessingPosition.current);
    } else if (mediaSectionRef && mediaSectionRef.current) {
      customScrollToComponent(mediaSectionRef.current);
    }
  };

  const liftingUpFotoFlatPositionData = (fotoFlatPositionRef) => {
    setFotoFlatPosition(fotoFlatPositionRef);
  };

  const liftingUpFormPositionData = (formPositionRef) => {
    setFormPosition(formPositionRef);
  };

  const liftingUpStillProcessingPositionData = (stillProcessingnRef) => {
    setStillProcessingPosition(stillProcessingnRef);
  };

  const liftingUpAlternativeSearchPosition = (altSearchPositionRef) => {
    setAltSearchPositionRef(altSearchPositionRef);
  };

  // buying

  const handleBuyFotoFlat = (hlpDskDispatch) => {
    onRequestStart();
    const sn = viewer.event.participant.startnumber;

    const fotoFlatProduct = getProduct(viewer.event.products, FOTO_FLAT);

    hlpDskDispatch({
      type: 'addToHistory',
      payload: `buy FF: ${sn}`,
    });

    AddFotoFlatToCartMutation.commit(
      relay.environment,
      fotoFlatProduct.id,
      sn,
      (error, cart) => {
        if (error) {
          onRequestError(error);
        } else {
          setUpdatedCart(cart);
          setShowItemDialog(true);
        }
        onRequestStop();
      }
    );
  };

  // offer related

  function getVideoUrl(event) {
    const videoUrl = getVideo(event.products).configuration.previewVideoUrl;
    return videoUrl;
  }

  function getCertificatePreview(eventProducts) {
    return getCertificate(eventProducts).configuration.previewImageUrl;
  }

  // loading images

  const clearImagesLoadedFallback = () => {
    if (imagesLoadedFallback) {
      clearTimeout(imagesLoadedFallback);
      imagesLoadedFallback = null;
    }
  };

  const handleImagesLoaded = () => {
    if (!shouldLoadImpressions) {
      clearImagesLoadedFallback();
      setShouldLoadImpressions(true);
    }
  };

  const refetchMediaForNewStartnumber = (newStartnumber, customerKey) => {
    setIsLoading(true);
    relay.refetch(
      { startnumber: newStartnumber, secondFactor: customerKey },
      null,
      () => {
        setIsLoading(false);
        if (customerKey && viewer.event.participant && isBibSearchEvent) {
          setCustomerKey(viewer.event.sgId, snFromUrl, customerKey);
        }

        // track if no media were found
        if (!hasMedia(viewer.event.participant)) {
          trackNoMediaFound(snFromUrl);
        }
      }
    );
  };

  const trackNoMediaFound = (startNumber) => {
    trackEvent('Event', 'Search', 'No photos found for start number', {
      nonInteraction: true,
    });
  };

  // redirections

  if (event.legacy) {
    window.location.href = `https://archive.sportograf.com/shop/search/${event.sgId}/${event.participant.startnumber}`;
    return null;
  }

  if (!viewer.account && !event.participant && event.secondFactorType.type === 'Email') {
    return <Redirect to={`/event/${event.sgId}/secondFactorRedirection/${snFromUrl}`} />;
  }

  if (event.state === 'reminder') {
    return <Redirect to={`/event/${event.sgId}`} />;
  }

  return (
    <UserConsumer>
      {({ onLoginChange, isLoggedIn }) => (
        <HelpdeskWidgetContext.Consumer>
          {([state, hlpDskDispatch]) => (
            <div className="container-960 container-page" ref={eventCardRef}>
              <EventOnlineView
                ref={eventOnlineViewRef}
                onlineEvent={event}
                urlSn={snFromUrl}
                customerKey={customerKey}
                extendedSearchSelectedTab={extendedSearchSelectedTab}
                extendedSearchExpand={extendedSearchExpand}
                hasSmartSearchTab={event.smartSearch && participantMediaCount !== 0}
                hasGpsSearchTab={event.gps}
                hasExtendedSearch={event.participant !== null && hasExtendedOptions}
                hasDualSearchTab={event.searchDual}
                showSearchInstructionsDialog={showSearchInstructionsDialog}
                onExtendedSearchTabChange={handleExtendedSearchTabChanged}
                onExtendedSearchExpand={handleExtendedSearchExpand}
                onSearchTrigger={handleSearchTriggered}
                handleScrollToOffer={scrollingToFotoFlatInMediaMasonry}
                setFotoFlatRef={liftingUpFotoFlatPositionData}
                setFormRef={liftingUpFormPositionData}
                setAltSearchRef={liftingUpAlternativeSearchPosition}
                shouldShowAdditionalInstruction={
                  !event.participant && event.secondFactorType.type === FACTOR_EMAIL
                }
                userLoginEmail={userLoginEmail}
                isAccessGrantedByValidCodeFaceEvent={
                  Boolean(event.participant) &&
                  isFaceSearchEvent &&
                  event.secondFactorType?.type === FACTOR_CODE
                }
                isParticipant={event.participant && event.participant != null}
                codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
                shouldShowAdditionalOffer={
                  participantMediaCount !== 0 &&
                  getFotoFlat(event.products) &&
                  (includesVideo(event.products) ||
                    includesCertificate(event.products) ||
                    event.voucherCampaign != null)
                }
                showStillProcessingHint={
                  event.participant && event.pendingMediaProcessing
                }
                setStillProcessingPosition={liftingUpStillProcessingPositionData}
                onBuyFotoFlat={() => handleBuyFotoFlat(hlpDskDispatch)}
              />
              <div ref={mediaSectionRef}>
                {isLoading && <LoadingView />}
                {!isLoading && (
                  <>
                    {!event.participant && (
                      <SecondFactorErrorMsg
                        event={event}
                        onScrollToForm={scrollToForm}
                        userLoginEmail={userLoginEmail}
                        isLoggedIn={isLoggedIn}
                        onLoginChange={onLoginChange}
                        userIsOnTheNumberSearch={Boolean(formPositionRef)}
                      />
                    )}

                    {event.participant && (participantMediaCount !== 0 || event.pendingMediaProcessing) && (
                      <>
                        <div className="row">
                          <div className="col-xs-16 col-sm-12 col-sm-offset-2 search-page-header-with-hint ">
                            <h1 className="separator">
                              {isFaceSearchEvent && (
                                <FormattedMessageWrappedInSpan
                                  id="eventSnSearchPage.searchByFace.participantSubtitle"
                                  defaultMessage="We found {count, plural, one {# photo} other {# photos}} related to your photo"
                                  values={{ count: participantMediaCount }}
                                />
                              )}
                              {isBibSearchEvent && (
                                <FormattedMessageWrappedInSpan
                                  id="eventSnSearchPage.participantSubtitle"
                                  defaultMessage="We found {count, plural, one {# photo} other {# photos}} related to your bib number"
                                  values={{ count: participantMediaCount }}
                                />
                              )}
                              {hasExtendedOptions && (
                                <div
                                  className="extended-search-expand"
                                  onClick={handleExtendedSearchExpand}
                                >
                                  {!extendedSearchExpand && (
                                    <>
                                      <div className="extended-search-expand-desktop">
                                        <FormattedMessageWrappedInSpan
                                          id="extendedSearch.morePhotosDesktop"
                                          defaultMessage="+ Are there more photos of me?"
                                        />
                                      </div>
                                      <div className="extended-search-expand-mobile">
                                        <FormattedMessageWrappedInSpan
                                          id="extendedSearch.morePhotosMobile"
                                          defaultMessage="More?"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {extendedSearchExpand && (
                                    <>
                                      <div className="extended-search-expand-desktop">
                                        <FormattedMessageWrappedInSpan
                                          id="extendedSearch.hideMorePhotosDesktop"
                                          defaultMessage="- Hide extended search options"
                                        />
                                      </div>
                                      <div className="extended-search-expand-mobile">
                                        <FormattedMessageWrappedInSpan
                                          id="extendedSearch.hideMorePhotosMobile"
                                          defaultMessage="Hide"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </h1>
                          </div>
                        </div>
                        <ParticipantMediaList
                          eventHasSearchByFace={isFaceSearchEvent}
                          eventHasSecondFactor={event.secondFactorType ? true : false}
                          eventId={event.sgId}
                          startnumber={event.participant.startnumber}
                          customerKey={customerKey}
                          hasMorePhotosButton={
                            participantMediaCount <= event.morePhotosButtonCount &&
                            hasExtendedOptions
                          }
                          participantMedia={event.participant.media}
                          offer={event}
                          showOfferHintForStillProcessingMedia={
                            event.pendingMediaProcessing
                          }
                          participantMediaCount={participantMediaCount}
                          impressionMediaCount={impressionMediaCount}
                          hasVideoUrl={Boolean(videoUrl)}
                          onScrollToImpressions={handleScrollToImpressions}
                          onScrollToVoucher={handleScrollToVoucher}
                          onScrollToVideo={handleScrollToVideo}
                          onScrollToCertificate={handleScrollToCertificate}
                          onScrollToExtendedSearch={() =>
                            scrollToExtendedSearch(extendedSearchSelectedTab)
                          }
                          onImagesLoaded={handleImagesLoaded}
                          setFotoFlatRef={liftingUpFotoFlatPositionData}
                          hasCertificates={includesCertificate(event.products)}
                          onBuyFotoFlat={() => handleBuyFotoFlat(hlpDskDispatch)}
                        />
                      </>
                    )}
                    {event.participant && participantMediaCount < 6 && snFromUrl && (
                      <InsufficientResultsInformation
                        count={participantMediaCount}
                        raceResultsUrl={event.hostResultUrl}
                        showExtendedSearchAsAvailable={hasExtendedOptions}
                        showGpsSearchLink={event.gps}
                        showCollectionsLink={event.searchCollections}
                        showSmartSearchLink={event.smartSearch}
                        showDualSearchLink={event.searchDual}
                        tagWithStartNumber={isBibSearchEvent}
                        onDeepSearchClick={handleDeepSearchBtnClicked}
                        onCategorySearchClick={handleCategorySearchBtnClicked}
                        onGpsSearchClick={handleGpsSearchBtnClicked}
                        onDualSearchClick={handleDualSearchBtnClicked}
                        isFaceSearchEvent={isFaceSearchEvent}
                        scrollToEvent={scrollToForm}
                        onScrollToExtendedSearch={() =>
                          scrollToExtendedSearch(extendedSearchSelectedTab)
                        }
                      />
                    )}
                    {snFromUrl &&
                      event.participant &&
                      event.voucherCampaigns.length > 0 && (
                        <div className="row">
                          <h1 className="separator col-xs-16 col-sm-12 col-sm-offset-2" />
                        </div>
                      )}
                    {snFromUrl &&
                      event.participant &&
                      event.voucherCampaigns.map((voucherCampaign) => {
                        return (
                          <div
                            className="row mb-30"
                            key={voucherCampaign.id}
                            ref={(div) => {
                              if (div) {
                                vouchersRef.current.set(voucherCampaign.id, div);
                              }
                            }}
                          >
                            <div className="voucher-container col-xs-16 col-sm-8 col-sm-offset-4">
                              <div
                                className="voucher-text"
                                dangerouslySetInnerHTML={{
                                  __html: voucherCampaign.longText,
                                }}
                              />
                              <div className="voucher-banner">
                                <a
                                  href={voucherCampaign.url}
                                  onClick={() =>
                                    trackEvent('Event', 'Voucher', 'Banner Foto-Flat')
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={voucherCampaign.banner} />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {event.participant &&
                      includesVideo(event.products) &&
                      videoUrl &&
                      snFromUrl && (
                        <div ref={videoRef} className="event-search-video">
                          <div className="row">
                            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
                              <h1 className="separator">
                                <FormattedMessageWrappedInSpan
                                  id="eventSnSearchPage.videoSubtitle"
                                  defaultMessage="This event has a personalized video"
                                />
                                <EmbeddedVideo videoUrl={videoUrl} />
                              </h1>
                            </div>
                          </div>
                        </div>
                      )}
                    {snFromUrl &&
                      event.participant &&
                      includesCertificate(event.products) &&
                      certificateImgPreview && (
                        <div ref={certificateRef} className="event-search-certificate">
                          <div className="row">
                            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
                              <h1 className="separator">
                                <FormattedMessageWrappedInSpan
                                  id="eventSnSearchPage.certificateSubtitle"
                                  defaultMessage="This event has a printable participant certificate"
                                />
                                <div className="certificate-dialog-image ">
                                  <img
                                    src={certificateImgPreview}
                                    alt="certificate preview"
                                  />
                                </div>
                              </h1>
                            </div>
                          </div>
                        </div>
                      )}
                    {snFromUrl &&
                      event.participant &&
                      impressionMediaCount > 0 &&
                      shouldLoadImpressions && (
                        <div ref={impressionRef}>
                          <div className="row">
                            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
                              <h1 className="separator">
                                <FormattedMessageWrappedInSpan
                                  id="eventSnSearchPage.impressionSubtitle"
                                  defaultMessage="This event has {count, plural, one {# impression} other {# impressions}}"
                                  values={{ count: impressionMediaCount }}
                                />
                              </h1>
                            </div>
                          </div>
                          <MediaList
                            eventHasSearchByFace={isFaceSearchEvent}
                            eventHasSecondFactor={event.secondFactorType ? true : false}
                            media={event.impressionMedia.mediaList}
                            offer={event}
                            impressionMediaCount={impressionMediaCount}
                            participantMediaCount={participantMediaCount}
                            listType="impressions"
                            startnumber={event.participant.startnumber}
                            onScrollToImpressions={handleScrollToImpressions}
                            onScrollToVoucher={handleScrollToVoucher}
                            onScrollToVideo={handleScrollToVideo}
                            onScrollToCertificate={handleScrollToCertificate}
                            hasVideoUrl={Boolean(videoUrl)}
                            onScrollToExtendedSearch={() =>
                              scrollToExtendedSearch(extendedSearchSelectedTab)
                            }
                            hasCertificates={includesCertificate(event.products)}
                            hasCertificateUrl={Boolean(certificateImgPreview)}
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
              {codeSecondFactorForFaceEvent && event.participant && !snFromUrl && (
                <>
                  <Pricelist offer={event} />
                  <PaymentMethodView />
                </>
              )}
              {snFromUrl && (
                <Faq
                  eventHasSearchByFace={isFaceSearchEvent}
                  hasExtendedOptions={hasExtendedOptions}
                  onFindMore={() => {scrollToExtendedSearch(extendedSearchSelectedTab)}}
                />
              )}
              {shouldShowdialogAfterSearchWarn && (
                <SearchInstructionsDialog
                  open={showSearchInstructionsDialog}
                  message={event.dialogAfterSearch}
                  onAccept={() => setShowSearchInstructionsDialog(false)}
                />
              )}
              {updatedCart && (
                <ItemAddedDialog
                  open={showItemDialog}
                  lineItems={updatedCart.lineItems}
                  totalPrice={updatedCart.totalPrice}
                  onRequestClose={() => setShowItemDialog(false)}
                />
              )}
              <CustomDialog
                open={showCertificateInformation}
                onRequestClose={() => setShowCertificateInformation(false)}
              >
                <div className="notification-container">
                  <DialogContent>
                    <div className="notification-body">
                      <h1>
                        <FormattedMessageWrappedInSpan
                          id="pricelist.dialog.certificate.title"
                          defaultMessage="What is certificate?"
                          className="mb-15"
                        />
                      </h1>
                      <FormattedMessageWrappedInSpan
                        id="pricelist.dialog.certificate"
                        defaultMessage="You can create a certificate with a photo of your choice from the pictures of your Foto-Flat and with your personal data. You can download and print it."
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <div className="notification-action">
                      <button
                        className="accept"
                        onClick={() => setShowCertificateInformation(false)}
                      >
                        <FormattedMessageWrappedInSpan
                          id="searchInstructionsDialog.acceptButton"
                          defaultMessage="OK"
                        />
                      </button>
                    </div>
                  </DialogActions>
                </div>
              </CustomDialog>
            </div>
          )}
        </HelpdeskWidgetContext.Consumer>
      )}
    </UserConsumer>
  );
};

EventSnSearchPage.propTypes = {
  snFromUrl: PropTypes.string,
  customerKey: PropTypes.string,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  codeSecondFactorForFaceEvent: PropTypes.string,
};

export {EventSnSearchPage}


export default createRefetchContainer(
  withRequestHandler(EventSnSearchPage),
  {
    viewer: graphql`
      fragment EventSnSearchPage_viewer on Viewer {
        account {
          profile {
            email
          }
        }
        event(id: $eventId, language: $language) {
          id
          sgId
          pendingMediaProcessing
          gps
          legacy
          hostResultUrl
          impressionMedia {
            mediaInfo {
              count
            }
            mediaList(first: 100) {
              ...MediaList_media
            }
          }
          morePhotosButtonCount
          participant(startnumber: $startnumber, secondFactor: $secondFactor) {
            media {
              mediaInfo {
                count
              }
              ...ParticipantMediaList_participantMedia
            }
            startnumber
          }
          products {
            id
            mediaCollection {
              name
            }
            price
            type
            configuration {
              ... on SingleVideoConfiguration {
                previewVideoUrl
              }
              ... on EventCertificateConfiguration {
                previewImageUrl
              }
            }
          }
          secondFactorType {
            type
          }
          mainSearch
          searchDual
          smartSearch
          searchCollections
          state
          marathonFoto
          voucherCampaigns {
            id
            banner
            longText
            url
          }
          warnText
          dialogAfterSearch
          ...EventOnlineView_onlineEvent
          ...ParticipantMediaList_offer
          ...MediaList_offer
          ...Pricelist_offer
        }
      }
    `,
  },
  graphql`
    query EventSnSearchPageRefetchQuery(
      $eventId: ID!
      $startnumber: ID!
      $count: Int!
      $cursor: String
      $language: String
      $secondFactor: String
      $individualPart: String
      $isRecheckPage: Boolean!
    ) {
      viewer {
        ...EventSnSearchPage_viewer
      }
    }
  `
);
