import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import CartLineItem from './CartLineItem';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartBillItem(props) {
  const { billItem, ...other } = props;

  return (
    <CartLineItem
      discount={billItem.discount}
      finalPrice={billItem.bruttoPrice}
      hasDeleteOption={false}
      hasHelpIcon={false}
      lineItem={billItem.lineItem}
      originalPrice={billItem.price}
      vat={billItem.vat}
      {...other}
    />
  );
}

CartBillItem.propTypes = {
  // Relay props
  billItem: PropTypes.object,
  type: PropTypes.string
};

export { CartBillItem };

export default createFragmentContainer(CartBillItem, {
  billItem: graphql`
    fragment CartBillItem_billItem on BillItem {
      bruttoPrice
      discount
      lineItem {
        ...CartLineItem_lineItem
      }
      price
      vat
    }
  `,
});
