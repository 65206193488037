/**
 * @flow
 * @relayHash 025b7a667af0f03a0fed5d9fd3c4bdbc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddSingleFotoToCartInput = {|
  fotoMediaId: string,
  productId: string,
|};
export type AddSingleFotoToCartMutationVariables = {|
  input: AddSingleFotoToCartInput
|};
export type AddSingleFotoToCartMutationResponse = {|
  +addSingleFotoToCart: ?{|
    +cart: ?{|
      +id: string,
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string,
        +product: ?{|
          +label: ?string,
          +price: ?any,
        |},
      |}>,
      +totalPrice: ?any,
    |}
  |}
|};
export type AddSingleFotoToCartMutation = {|
  variables: AddSingleFotoToCartMutationVariables,
  response: AddSingleFotoToCartMutationResponse,
|};
*/


/*
mutation AddSingleFotoToCartMutation(
  $input: AddSingleFotoToCartInput!
) {
  addSingleFotoToCart(input: $input) {
    cart {
      id
      lineItems {
        id
        product {
          label
          price
          id
        }
      }
      totalPrice
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddSingleFotoToCartInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPrice",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddSingleFotoToCartMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addSingleFotoToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSingleFotoToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddSingleFotoToCartMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addSingleFotoToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSingleFotoToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddSingleFotoToCartMutation",
    "id": null,
    "text": "mutation AddSingleFotoToCartMutation(\n  $input: AddSingleFotoToCartInput!\n) {\n  addSingleFotoToCart(input: $input) {\n    cart {\n      id\n      lineItems {\n        id\n        product {\n          label\n          price\n          id\n        }\n      }\n      totalPrice\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '713685e8eae417b23c8ab71e288e96c6';

module.exports = node;
