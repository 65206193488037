import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const StyledSlider = withStyles({
  root: {
    color: '$copy-color-dark',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '$copy-color-dark',
    marginTop: -8,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 8px)',
  },
  track: {
    color: '$copy-color-dark',
    height: 8,
    borderRadius: 4,
  },
  rail: {
    color: '$copy-color-dark',
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const TimeSlider = (props) => {
  const { value, minValue, maxValue, onChange, onChangeCommitted } = props;
  
  return (
    <div>
      <div className="time-slider-time-date-info row">
        <div className="col-xs-6 col-xs-offset-2 col-sm-3 col-sm-offset-5">
          <h1>
            <FormattedTime
              value={new Date(value.min)}
              timeZone="UTC"
              hour="2-digit"
              minute="2-digit"
            />
          </h1>
          <h3>
            <FormattedDate
              value={new Date(value.min)}
              day="numeric"
              timeZone="UTC"
              month="numeric"
            />
          </h3>
        </div>
        <div className="col-xs-6 col-sm-3 ">
          <h1>
            <FormattedTime
              value={new Date(value.max)}
              timeZone="UTC"
              hour="2-digit"
              minute="2-digit"
            />
          </h1>
          <h3>
            <FormattedDate
              value={new Date(value.max)}
              day="numeric"
              timeZone="UTC"
              month="numeric"
            />
          </h3>
        </div>
      </div>
      <div>
        <div className="row time-slider-container">
          <div className="col-xs-3 col-sm-2 start-xs time-slider-label">
            <p>
              <FormattedTime
                value={new Date(minValue)}
                timeZone="UTC"
                hour="2-digit"
                minute="2-digit"
              />
              <br />
              <FormattedDate
                value={new Date(minValue)}
                day="numeric"
                timeZone="UTC"
                month="numeric"
              />
            </p>
          </div>
          <div className="col-xs-10 col-sm-8 ">
            <StyledSlider
              value={value.min}
              min={minValue}
              max={maxValue}
              onChange={onChange}
              onMouseUp={onChangeCommitted}
              onTouchEnd={onChangeCommitted}
            />
          </div>
          <div className="col-xs-3 col-sm-2 end-xs time-slider-label">
            <p>
              <FormattedTime
                value={new Date(maxValue)}
                timeZone="UTC"
                hour="2-digit"
                minute="2-digit"
              />
              <br />
              <FormattedDate
                value={new Date(maxValue)}
                day="numeric"
                month="numeric"
                timeZone="UTC"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TimeSlider.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
  onChangeCommitted: PropTypes.func
};

export default TimeSlider;
