/**
 * @flow
 * @relayHash f32ab8bd9ba422273c3ab68be7e72345
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveOrderCouponInput = {|
  orderId: string
|};
export type RemoveOrderCouponMutationVariables = {|
  input: RemoveOrderCouponInput
|};
export type RemoveOrderCouponMutationResponse = {|
  +removeOrderCoupon: ?{|
    +cart: ?{|
      +paymentRequired: ?boolean,
      +order: ?{|
        +bill: ?{|
          +totalBruttoPriceUnpayed: ?any
        |}
      |},
    |}
  |}
|};
export type RemoveOrderCouponMutation = {|
  variables: RemoveOrderCouponMutationVariables,
  response: RemoveOrderCouponMutationResponse,
|};
*/


/*
mutation RemoveOrderCouponMutation(
  $input: RemoveOrderCouponInput!
) {
  removeOrderCoupon(input: $input) {
    cart {
      paymentRequired
      order {
        bill {
          totalBruttoPriceUnpayed
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveOrderCouponInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paymentRequired",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bill",
  "storageKey": null,
  "args": null,
  "concreteType": "Bill",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBruttoPriceUnpayed",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveOrderCouponMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeOrderCoupon",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrderCouponPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveOrderCouponMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeOrderCoupon",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveOrderCouponPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveOrderCouponMutation",
    "id": null,
    "text": "mutation RemoveOrderCouponMutation(\n  $input: RemoveOrderCouponInput!\n) {\n  removeOrderCoupon(input: $input) {\n    cart {\n      paymentRequired\n      order {\n        bill {\n          totalBruttoPriceUnpayed\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc5ad7d1cda27e13ad52f1db74ee5779';

module.exports = node;
