// The first components of react-intl that were used in our code
// did not return a mere string, but a <span /> element,
// which contained the translated text.
// This behavior is due in part to React itself, since React allowed until
// version 16.0 only valid elements (HTML tags or React components)
// to be returned.
// With version 16.0 (React) this restriction has been removed and it
// was possible for components to return strings.
// React-intl has taken advantage of this update, and updated its components.
// Anyway, some of the style classes used in the code assumes that certain
// texts are wrapped in a <span />.
// Therefore, all `FormattedMessage' components, that were used in the codebase
// before the release of react-intl v3.0, were replaced with 'FormattedMessageWrappedInSpan`
// This ensures that all styles are applied correctly.

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

FormattedMessageWrappedInSpan.propTypes = {
  className: PropTypes.string,
};

export default function FormattedMessageWrappedInSpan(props) {
  return (
    <span className={props.className}>
      <FormattedMessage {...props} />
    </span>
  );
}
