import React, { Fragment, forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { injectIntl, defineMessages } from 'react-intl';
import {useParams} from 'react-router-dom'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import VoucherWithActions from '../misc/VoucherWithActions';

import CustomDate from '../misc/CustomDate';
import ExpandButton from '../misc/ExpandButton';
import FormattedPrice from '../misc/FormattedPrice';
import { FormattedMessageWrappedInSpan } from '../misc';
import { customScrollToComponent } from '../../utils/common';

import {
  BANKTRANSFER,
  PAYMENT_COMPLETED,
  COMPLETED,
  PAYMENT_FAILED,
  PAYMENT_IN_PROGRESS,
  IN_PAYMENT,
} from '../../utils/variables';

/****************************************************************************************

                                  FormattedPaymentState

*****************************************************************************************/

const FormattedPaymentState = props => {
  // TODO: discuss with backend about a better system for passing this info
  // right now we just get the order state, as defined in the DB
  // below logic should basically move to the backend

  const { state, paymentReceivedAt } = props;

  switch (state) {
    case PAYMENT_COMPLETED:
    case COMPLETED:
      return (
        <Fragment>
          {paymentReceivedAt ? (
            <p>
              <FormattedMessageWrappedInSpan
                id="orderCard.paymentStatus.paymentComplete"
                defaultMessage="Receipt of payment has been registered on: "
              />
              <CustomDate value={paymentReceivedAt} />
            </p>
          ) : (
            null
          )}
        </Fragment>
      );
    case PAYMENT_FAILED:
      return (
        <p>
          <FormattedMessageWrappedInSpan
            id="orderCard.paymentStatus.paymentFailed"
            defaultMessage="Payment failed"
          />
        </p>
      );
    case PAYMENT_IN_PROGRESS:
    case IN_PAYMENT:
      return (
        <p>
          <FormattedMessageWrappedInSpan
            id="orderCard.paymentStatus.paymentInProgress"
            defaultMessage="Payment in progress"
          />
        </p>
      );
    default:
      return null;
  }
};

/****************************************************************************************

                                  FormattedPaymentMethod

*****************************************************************************************/

const FormattedPaymentMethod = (props) => {
  const { state, paymentMethod, totalBruttoPrice, orderid, address } = props;
  if (paymentMethod.name !== BANKTRANSFER) {
    return <p>{paymentMethod.name}</p>;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-16">
          <h2>
            <FormattedMessageWrappedInSpan
              id="orderCard.paymentMethod.title"
              defaultMessage="Payment method:"
            />{' '}
            <FormattedMessageWrappedInSpan
              id="orderCard.paymentMethod.bankTransfer.title"
              defaultMessage="Bank transfer"
            />
          </h2>
        </div>
      </div>
      {state !== PAYMENT_COMPLETED && state !== COMPLETED && (
        <Fragment>
          <div className="order-card-bank-details-section">
            <div className="row">
              <div className="col-sm-9 col-xs-16">
                <h3>
                  <FormattedMessageWrappedInSpan
                    id="orderCard.paymentMethod.bankTransfer.fromGermany"
                    defaultMessage="For payments originated outside of Germany please use our IBAN and SWIFT code:"
                  />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.cardHolder"
                  defaultMessage="Holder:"
                />
              </div>
              <div className="col-sm-12 col-xs-9">Sportograf Digital Solutions GmbH</div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.bank"
                  defaultMessage="Bank:"
                />
              </div>
              <div className="col-sm-12 col-xs-9">
                UniCredit Bank - HypoVereinsbank,
                <br />
                80311 Munich
                <br />
                Germany
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.iban"
                  defaultMessage="IBAN:"
                />
              </div>
              <div className="col-sm-12 col-xs-9">DE89700202700035665633</div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.bic"
                  defaultMessage="BIC (Swift-code):"
                />
              </div>
              <div className="col-sm-12 col-xs-9">HYVEDEMMXXX</div>
            </div>
          </div>
          <div className="order-card-bank-details-section">
            <div className="row">
              <div className="col-sm-9 col-xs-16">
                <h3>
                  <FormattedMessageWrappedInSpan
                    id="orderCard.paymentMethod.bankTransfer.generalInfo"
                    defaultMessage="General information for your bank transfer"
                  />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.amount"
                  defaultMessage="Amount:"
                />
              </div>
              <div className="col-sm-12 col-xs-9">
                <FormattedPrice price={totalBruttoPrice} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-7">
                <FormattedMessageWrappedInSpan
                  id="orderCard.paymentMethod.bankTransfer.message"
                  defaultMessage="Message:"
                />
              </div>
              <div className="col-sm-12 col-xs-9">
                {address.firstName + ' ' + address.lastName + ' ' + orderid}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

FormattedPaymentMethod.propTypes = {
  state: PropTypes.string,
  paymentMethod: PropTypes.object,
  totalBruttoPrice: PropTypes.object,
  orderid: PropTypes.string,
  address: PropTypes.object,
};

/****************************************************************************************

                                        OrderCard

*****************************************************************************************/

const OrderCard = forwardRef((props, ref) => {
  const { expanded, intl, order, onExpandClick, eventName } = props;
  const params = useParams();
  const vouchersDivRef = useRef(null);
  const hasVouchers = order.vouchers.length > 0;

  const openInvoice = () => {
    window.open(order.invoice);
  };

  useEffect(() => {
    if (params.id === order.orderid) {
      onExpandClick(order.orderid);
    }
  }, []);

  useEffect(() => {
    if (expanded && params.id === order.orderid && vouchersDivRef.current) {
      setTimeout(() => {
        customScrollToComponent(vouchersDivRef.current);
      }, 200);
    }
  }, [expanded]);

  const makeListOfEventNames = (eventData) => {
    const orderRemoveDuplicateNames = new Set();
    eventData.map((event) => orderRemoveDuplicateNames.add(event.product.event.name));
    const ordersNamesArray = Array.from(orderRemoveDuplicateNames);
    return String(ordersNamesArray.join(`, `));
  };

  const eventNamesAsString = makeListOfEventNames(eventName);

  return (
    <Card classes={{ root: 'order-card' }} ref={ref}>
      <CardHeader
        action={<ExpandButton expanded={expanded} />}
        classes={{ root: 'order-card-header' }}
        title={
          <>
            <b>{eventNamesAsString}</b>
            <CustomDate value={order.paymentReceivedAt}/>
          </>
        }
        onClick={() => onExpandClick(order.orderid)}
        subheader={`${intl.formatMessage(translations.orderNumber)} ${order.orderid}`}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent>
          {order.legacy ? (
            <Fragment>
              <h2>
                <FormattedMessageWrappedInSpan
                  id="orderCard.legacyOrderInformation.title"
                  defaultMessage="No information available"
                />
              </h2>
              <p>
                <FormattedMessageWrappedInSpan
                  id="orderCard.legacyOrderInformation.description"
                  defaultMessage="The information of your order is not available on our new shop system. Please contact our support to get further information."
                />
              </p>
            </Fragment>
          ) : (
            order.payment &&
            order.payment.paymentMethod && (
              <Fragment>
                <h2>
                  <FormattedMessageWrappedInSpan
                    id="orderCard.paymentStatus.title"
                    defaultMessage="Payment status:"
                  />
                </h2>

                <FormattedPaymentMethod
                  totalBruttoPrice={order.bill.totalBruttoPrice}
                  orderid={order.orderid}
                  paymentMethod={order.payment.paymentMethod}
                  state={order.payment.state}
                  address={order.address}
                />

                <FormattedPaymentState
                  state={order.state}
                  paymentReceivedAt={order.paymentReceivedAt}
                  intl={intl}
                />
              </Fragment>
            )
          )}
          {order.invoice && (
            <button className="primary order-card-invoice-btn" onClick={openInvoice}>
              <FormattedMessageWrappedInSpan
                id="orderCard.downloadInvoice"
                defaultMessage="Download pdf invoice"
              />
            </button>
          )}
          {hasVouchers && (
            <Fragment>
              <h2 ref={vouchersDivRef}>
                <FormattedMessageWrappedInSpan
                  id="orderCard.vouchers"
                  defaultMessage="Your vouchers: "
                />
              </h2>
              {order.vouchers.map((voucher, index) => (
               <VoucherWithActions voucher={voucher} key={voucher.code}/>
              ))}
            </Fragment>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
});


const translations = defineMessages({
  orderNumber: {
    id: 'orderCard.orderNumber',
    defaultMessage: 'Order number:',
  },
  copyVoucherCode: {
    id: 'orderCard.voucherCopyMessage',
    defaultMessage: 'Copy',
  },
  copiedVoucherCode: {
    id: 'orderCard.voucherCopiedMessage',
    defaultMessage: 'Copied',
  },
  copyAndGoVoucher: {
    id: 'orderCard.voucherCopyAndGoMessage',
    defaultMessage: 'Copy and open website',
  },
});

OrderCard.propTypes = {
  expanded: PropTypes.bool,
  intl: PropTypes.object,
  order: PropTypes.object,
  onExpandClick: PropTypes.func,
  eventName: PropTypes.array,
};

export { OrderCard, FormattedPaymentMethod };

export default createFragmentContainer(injectIntl(OrderCard, { withRef: true }), {
  order: graphql`
    fragment OrderCard_order on Order {
      address {
        firstName
        lastName
      }
      bill {
        totalBruttoPrice
      }
      invoice
      legacy
      orderid
      state
      paymentReceivedAt
      payment {
        paymentMethod {
          name
        }
      }
      vouchers {
        code
        campaign {
          banner
          name
          url
        }
      }
    }
  `,
});
