import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

export default function StickyContainer(props) {
  const containerRef = useRef(null);
  const [pageYScroll, setPageYscroll] = useState(window.pageYOffset);
  const [elementBottomEdgePosition, setElementBottomEdgePosition] = useState(null);
  const [cordShouldBeUpdated, setCordsShouldBeUpdated] = useState(false);

  const prevDataRef = useRef(props.changingDataShouldUpdateCoords);

  const updatingCoords = () => {
    setElementBottomEdgePosition(
      containerRef.current.clientHeight +
        (props.marginTop ? props.marginTop : 0) +
        containerRef.current.previousElementSibling.offsetHeight +
        containerRef.current.previousElementSibling.offsetTop
    );
  };

  useEffect(() => {
    updatingCoords();
  }, []);



  useEffect(() => {
    // Check if the forwarded prop (step) is different from the previous one (prevStepRef.current)
    if (props.changingDataShouldUpdateCoords !== prevDataRef.current) {
      // Perform actions or updates when the prop changes
      // Update the prevStepRef to store the current prop for the next comparison
      //updatingCoords();
      prevDataRef.current = props.changingDataShouldUpdateCoords;
    }
  }, [props.changingDataShouldUpdateCoords]);


  useEffect(() => {
    updatingCoords();
  }, [props.changingDataShouldUpdateCoords])

  useEffect(() => {
    const handleScroll = () => setPageYscroll(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const isSticked =
    pageYScroll !== undefined
      ? pageYScroll + window.innerHeight <= elementBottomEdgePosition
      : false;

  return (
    <div
      className={classNames(`sticky-container-bottom ${!isSticked ? 'static' : ''}`, {
        sticked: isSticked,
      })}
      ref={containerRef}
    >
      {props.children}
    </div>
  );
}

StickyContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  marginTop: PropTypes.number,
  changingDataShouldUpdateCoords: PropTypes.any
};
