/**
 * @flow
 * @relayHash 20f19ecebb157c9e720ee85206f9fe23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadFacePhotoInput = {|
  eventId: string,
  photo: any,
  startnumber?: ?string,
|};
export type UploadFacePhotoMutationVariables = {|
  input: UploadFacePhotoInput
|};
export type UploadFacePhotoMutationResponse = {|
  +uploadFacePhoto: ?{|
    +startnumber: ?string
  |}
|};
export type UploadFacePhotoMutation = {|
  variables: UploadFacePhotoMutationVariables,
  response: UploadFacePhotoMutationResponse,
|};
*/


/*
mutation UploadFacePhotoMutation(
  $input: UploadFacePhotoInput!
) {
  uploadFacePhoto(input: $input) {
    startnumber
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UploadFacePhotoInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uploadFacePhoto",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UploadFacePhotoPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "startnumber",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UploadFacePhotoMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UploadFacePhotoMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UploadFacePhotoMutation",
    "id": null,
    "text": "mutation UploadFacePhotoMutation(\n  $input: UploadFacePhotoInput!\n) {\n  uploadFacePhoto(input: $input) {\n    startnumber\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac8d4814de091bc1ca3cafc81da03462';

module.exports = node;
