import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';

const ExtendedSearchInstructionsDialog = (props) => {
  const { open, showGps, onAccept, onCancel } = props;

  const linkToSupport = (
    <a href="mailto:support@sportograf.com" target="_blank" rel="noopener noreferrer">
      support@sportograf.com
    </a>
  );

  return (
    <CustomDialog open={open} onRequestClose={onCancel}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.message.title"
                defaultMessage="How to search?"
              />
            </h1>
            <p>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.message.pOne"
                defaultMessage="If you don't want to upload a photo to search for your photos, you can always use the alternative search options we offer:"
              />
            </p>
            <ol>
              <li>
                <FormattedMessageWrappedInSpan
                  id="extendedSearchInstructionsDialog.message.featureTwo"
                  defaultMessage="Search by time and place"
                />
              </li>
              {showGps && (
                <li>
                  <FormattedMessageWrappedInSpan
                    id="extendedSearchInstructionsDialog.message.featureThree"
                    defaultMessage="Search with your GPS/GPX data"
                  />
                </li>
              )}
            </ol>
            <p>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.message.pFour"
                defaultMessage="You can find the picture numbers in the bottom left corner, when you click the thumbnail of an image."
              />
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.message.pFive"
                defaultMessage="Send us a list of your picture numbers to {link}. If you are visible on all of them, we will create the FotoFlat for you."
                values={{ link: linkToSupport }}
              />
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button className="cancel" onClick={onCancel}>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.cancelButton"
                defaultMessage="Cancel"
              />
            </button>
            <button className="accept" onClick={onAccept}>
              <FormattedMessageWrappedInSpan
                id="extendedSearchInstructionsDialog.acceptButton"
                defaultMessage="Start your search"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

ExtendedSearchInstructionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  showGps: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
};

export default ExtendedSearchInstructionsDialog;
