/**
 * @flow
 * @relayHash 74b6165a105039073e45a2d94dd55af8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequestEventAccessInput = {|
  email: string,
  eventId: string,
  language?: ?string,
|};
export type RequestEventAccessMutationVariables = {|
  input: RequestEventAccessInput
|};
export type RequestEventAccessMutationResponse = {|
  +requestEventAccess: ?{|
    +email: ?string,
    +status: ?string,
  |}
|};
export type RequestEventAccessMutation = {|
  variables: RequestEventAccessMutationVariables,
  response: RequestEventAccessMutationResponse,
|};
*/


/*
mutation RequestEventAccessMutation(
  $input: RequestEventAccessInput!
) {
  requestEventAccess(input: $input) {
    email
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestEventAccessInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "requestEventAccess",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestEventAccessPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestEventAccessMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestEventAccessMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestEventAccessMutation",
    "id": null,
    "text": "mutation RequestEventAccessMutation(\n  $input: RequestEventAccessInput!\n) {\n  requestEventAccess(input: $input) {\n    email\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7568d5885d9da2746bd163203610931c';

module.exports = node;
