/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartLineItem_lineItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartBillItem_billItem$ref: FragmentReference;
declare export opaque type CartBillItem_billItem$fragmentType: CartBillItem_billItem$ref;
export type CartBillItem_billItem = {|
  +bruttoPrice: ?any,
  +discount: ?number,
  +lineItem: ?{|
    +$fragmentRefs: CartLineItem_lineItem$ref
  |},
  +price: ?any,
  +vat: ?any,
  +$refType: CartBillItem_billItem$ref,
|};
export type CartBillItem_billItem$data = CartBillItem_billItem;
export type CartBillItem_billItem$key = {
  +$data?: CartBillItem_billItem$data,
  +$fragmentRefs: CartBillItem_billItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartBillItem_billItem",
  "type": "BillItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bruttoPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "discount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lineItem",
      "storageKey": null,
      "args": null,
      "concreteType": "LineItem",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CartLineItem_lineItem",
          "args": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "vat",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e83114410b84a35e719a941410f69d74';

module.exports = node;
