import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan } from '../../misc';
import CustomDialog from './CustomDialog';

const DvdInforationDialog = (props) => {
  const { open, onRequestClose, onRequestRedirect } = props;

  return (
    <CustomDialog open={open} onRequestClose={onRequestClose}>
      <div className="notification-container">
        <div className="notification-body">
          <h1>
            <FormattedMessageWrappedInSpan
              id="cartPage.dvdInformationDialog.heading"
              defaultMessage="How to buy a DVD?"
              className="mb-15"
            />
          </h1>
          <FormattedMessageWrappedInSpan
            id="cartPage.dvdInformationDialog"
            defaultMessage='You can add a DVD that contains all of your photos in the "Cart" step.'
          />
        </div>
      </div>
      <div className="notification-action">
        <button
          className="accept"
          onClick={() => {
            onRequestClose();
            onRequestRedirect();
          }}
        >
          <FormattedMessageWrappedInSpan
            id="dvdInformationDialog.goBackButton"
            defaultMessage="Go to cart"
          />
        </button>
        <button className="accept" onClick={onRequestClose}>
          <FormattedMessageWrappedInSpan id="errorDialog.okButton" defaultMessage="OK" />
        </button>
      </div>
    </CustomDialog>
  );
};

DvdInforationDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onRequestRedirect: PropTypes.func,
};

export default DvdInforationDialog;
