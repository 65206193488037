/**
 * @flow
 * @relayHash 28a5e8d30470e115a8eeb01b831703fb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountPage_viewer$ref = any;
export type AccountPageQueryRendererQueryVariables = {|
  count: number
|};
export type AccountPageQueryRendererQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountPage_viewer$ref
  |}
|};
export type AccountPageQueryRendererQuery = {|
  variables: AccountPageQueryRendererQueryVariables,
  response: AccountPageQueryRendererQueryResponse,
|};
*/


/*
query AccountPageQueryRendererQuery(
  $count: Int!
) {
  viewer {
    ...AccountPage_viewer
    id
  }
}

fragment AccountPage_viewer on Viewer {
  account {
    profile {
      name
      email
      id
    }
    events(first: $count) {
      edges {
        node {
          id
          sgId
          ...EventListItem_event
        }
      }
    }
    rechecks {
      eventId
      event {
        sgId
        name
        banner
        id
      }
    }
    orders(first: $count) {
      edges {
        node {
          id
          cart {
            lineItems {
              product {
                event {
                  name
                  id
                  sgId
                  ...EventListItem_event
                }
                type
                id
              }
              id
            }
            id
          }
          orderid
          state
          ...OrderCard_order
        }
      }
    }
    id
  }
}

fragment EventListItem_event on Event {
  id
  sgId
  banner
  beginsAt
  name
  mainSearch
}

fragment OrderCard_order on Order {
  address {
    firstName
    lastName
    id
  }
  bill {
    totalBruttoPrice
  }
  invoice
  legacy
  orderid
  state
  paymentReceivedAt
  payment {
    paymentMethod {
      name
      id
    }
    id
  }
  vouchers {
    code
    campaign {
      banner
      name
      url
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "banner",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "beginsAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mainSearch",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountPageQueryRendererQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountPage_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountPageQueryRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "Profile",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "events",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "EventConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v1/*: any*/),
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rechecks",
                "storageKey": null,
                "args": null,
                "concreteType": "Recheck",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v1/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "orders",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "OrderConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Order",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "cart",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Cart",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "lineItems",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "LineItem",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "product",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "event",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "Event",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          (v2/*: any*/),
                                          (v4/*: any*/),
                                          (v5/*: any*/),
                                          (v6/*: any*/),
                                          (v7/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "type",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ]
                                  },
                                  (v2/*: any*/)
                                ]
                              },
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "orderid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "state",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "address",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Address",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "firstName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lastName",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bill",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Bill",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "totalBruttoPrice",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "invoice",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "legacy",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "paymentReceivedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "payment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Payment",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "paymentMethod",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PaymentMethod",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/)
                                ]
                              },
                              (v2/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "vouchers",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Voucher",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "code",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "campaign",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VoucherCampaign",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "url",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ]
                              },
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountPageQueryRendererQuery",
    "id": null,
    "text": "query AccountPageQueryRendererQuery(\n  $count: Int!\n) {\n  viewer {\n    ...AccountPage_viewer\n    id\n  }\n}\n\nfragment AccountPage_viewer on Viewer {\n  account {\n    profile {\n      name\n      email\n      id\n    }\n    events(first: $count) {\n      edges {\n        node {\n          id\n          sgId\n          ...EventListItem_event\n        }\n      }\n    }\n    rechecks {\n      eventId\n      event {\n        sgId\n        name\n        banner\n        id\n      }\n    }\n    orders(first: $count) {\n      edges {\n        node {\n          id\n          cart {\n            lineItems {\n              product {\n                event {\n                  name\n                  id\n                  sgId\n                  ...EventListItem_event\n                }\n                type\n                id\n              }\n              id\n            }\n            id\n          }\n          orderid\n          state\n          ...OrderCard_order\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EventListItem_event on Event {\n  id\n  sgId\n  banner\n  beginsAt\n  name\n  mainSearch\n}\n\nfragment OrderCard_order on Order {\n  address {\n    firstName\n    lastName\n    id\n  }\n  bill {\n    totalBruttoPrice\n  }\n  invoice\n  legacy\n  orderid\n  state\n  paymentReceivedAt\n  payment {\n    paymentMethod {\n      name\n      id\n    }\n    id\n  }\n  vouchers {\n    code\n    campaign {\n      banner\n      name\n      url\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f65da280c2a959c4c3d97e9005dc37e';

module.exports = node;
