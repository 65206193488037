import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const PastEventInformation = props => {
  const { eventname, currentEventDate, successorEvent, onRequestClose } = props;

  return (
    <div className="row">
      <div className="past-event-notification-container col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="past-event-notification">
          <div className="past-event-notification-close">
            <IconButton onClick={onRequestClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="past-event-notification-text">
            <FormattedMessageWrappedInSpan
              id="pastEventNotification.message"
              defaultMessage="You are currently visiting the edition of {eventname} ({year})"
              values={{
                eventname: eventname,
                year: new Date(currentEventDate).getFullYear(),
              }}
            />
          </div>
          <Link to={`/event/${successorEvent.sgId}`}>
            <button className="secondary fullwidth">
              <FormattedMessageWrappedInSpan
                id="pastEventNotification.link"
                defaultMessage="Go to the current ({year}) edition"
                values={{
                  year: new Date(successorEvent.beginsAt).getFullYear(),
                }}
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

PastEventInformation.propTypes = {
  eventname: PropTypes.string,
  currentEventDate: PropTypes.string,
  successorEvent: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export { PastEventInformation };

export default createFragmentContainer(PastEventInformation, {
  successorEvent: graphql`
    fragment PastEventInformation_successorEvent on Event {
      sgId
      beginsAt
    }
  `,
});
