import React from 'react';

import { UserConsumer } from '../context/UserContext';

import SportografNavigationBar from './SportografNavigationBar';
import MFNavigationBar from '../../components_marathonFoto/MFNavigationBar'

const Header = props => {
  return (
    <UserConsumer>
      {({ isMarathonFoto }) =>
        isMarathonFoto ? <MFNavigationBar {...props} /> : <SportografNavigationBar {...props} />
      }
    </UserConsumer>
  );
};

export default Header;
