import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';

import { trackEvent } from '../../../utils/ga-tracking';

const SearchInstructionsDialog = props => {
  const { open, onAccept, message } = props;

  let startTime;
  // We want to know how long the dialog is open;
  if (open) {
    startTime = new Date();
  }

  function handleCloseDialog() {
    const endTime = new Date();
    let timeDiff = endTime - startTime; // in ms
    // strip the ms
    timeDiff /= 1000;

    // get seconds
    const seconds = Math.round(timeDiff);

    // track the time the dialog was open
    trackEvent('Event', 'Warntext', 'Time dialog was open (in s)', {
      value: seconds,
    });
    onAccept();
  }

  return (
    <CustomDialog open={open} onRequestClose={handleCloseDialog}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button className="accept" onClick={handleCloseDialog}>
              <FormattedMessageWrappedInSpan
                id="searchInstructionsDialog.acceptButton"
                defaultMessage="OK"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

SearchInstructionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onAccept: PropTypes.func,
};

export default SearchInstructionsDialog;
