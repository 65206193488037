/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MediaListItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaList_media$ref: FragmentReference;
declare export opaque type MediaList_media$fragmentType: MediaList_media$ref;
export type MediaList_media = {|
  +edges: ?$ReadOnlyArray<?{|
    +node: ?{|
      +id: string,
      +sgId: ?number,
      +shotDate: ?any,
      +variants: ?$ReadOnlyArray<?{|
        +name: ?string,
        +url: ?string,
      |}>,
      +recheckStatus: ?string,
      +recheckRequestedAt?: ?any,
      +$fragmentRefs: MediaListItem_item$ref,
    |}
  |}>,
  +$refType: MediaList_media$ref,
|};
export type MediaList_media$data = MediaList_media;
export type MediaList_media$key = {
  +$data?: MediaList_media$data,
  +$fragmentRefs: MediaList_media$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "MediaList_media",
  "type": "MediaConnection",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isRecheckPage",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Media",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "sgId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "shotDate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "variants",
              "storageKey": null,
              "args": null,
              "concreteType": "MediaFile",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "url",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "recheckStatus",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "isRecheckPage",
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "recheckRequestedAt",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "MediaListItem_item",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c2fa49b42b0724594d9edda519c44d07';

module.exports = node;
