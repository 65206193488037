import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { FormattedMessageWrappedInSpan } from '../../misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const InsufficientResultsInformation = (props) => {
  const {
    count,
    raceResultsUrl,
    tagWithStartNumber,
    showGpsSearchLink,
    showSmartSearchLink,
    showDualSearchLink,
    onDeepSearchClick,
    onCategorySearchClick,
    onGpsSearchClick,
    onDualSearchClick,
    showExtendedSearchAsAvailable,
    showCollectionsLink,
    isFaceSearchEvent,
    scrollToEvent,
    onScrollToExtendedSearch,
    isInSmartSearch,
  } = props;

  const link = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={raceResultsUrl}
      className="link-blue-underline"
    >
      <FormattedMessageWrappedInSpan
        id="insufficientResultsView.checkStartnumber.link"
        defaultMessage="race-results"
      />
    </a>
  );

  return (
    <div className="insufficient-results-view-container">
      <div className="row">
        <div className="col-xs-16 col-sm-12 col-sm-offset-2">
          <h2 className="center-xs middle-xs">
            {count === 0 && (
              <FormattedMessageWrappedInSpan
                id="insufficientResultsView.title.noPhotos"
                defaultMessage="No photos found?"
              />
            )}
            {count !== 0 && (
              <FormattedMessageWrappedInSpan
                id="insufficientResultsView.title.notEnoughPhotos"
                defaultMessage="Not enough photos?"
              />
            )}
          </h2>
        </div>
      </div>
      <div className="row">
        {tagWithStartNumber && (
          <div className="insufficient-results-view-body col-xs-16 col-sm-12 col-sm-offset-2">
            <div className="insufficient-results-view-item">
              <h3>
                <FormattedMessageWrappedInSpan
                  id="insufficientResultsView.checkStartnumber.title"
                  defaultMessage="Check your bib number"
                />
              </h3>
              <p>
                <FormattedMessageWrappedInSpan
                  id="insufficientResultsView.checkStartnumber.description"
                  defaultMessage="Did you use the correct bib number? Please check your bib number again!"
                />
                {raceResultsUrl && (
                  <FormattedMessageWrappedInSpan
                    id="insufficientResultsView.checkStartnumber.raceResults"
                    defaultMessage="You can double-check it in the {raceResults}"
                    values={{ raceResults: link }}
                  />
                )}
              </p>
            </div>
            <div className="insufficient-results-view-item">
              <h3>
                <FormattedMessageWrappedInSpan
                  id="insufficientResultsView.addPhotos.title"
                  defaultMessage="Find & mark your photos"
                />
              </h3>
              <p>
                <FormattedMessageWrappedInSpan
                  id="insufficientResultsView.addPhotos.description"
                  defaultMessage="If no pictures are shown to you, we could not assign any of the pictures to your bib number. This can happen if we could not read the bib number. Maybe it was hidden, or just hard to read. But don't worry, there will still be pictures of you! We need your help to find them. Please use one of the options below to find pictures of you. You can simply mark the pictures with your bib number."
                />
              </p>
            </div>
            <div className="insufficient-results-view-item">
              {showExtendedSearchAsAvailable ? (
                <>
                  <h3>
                    <FormattedMessageWrappedInSpan
                      id="insufficientResultsView.options.title"
                      defaultMessage="Try our search system"
                    />
                  </h3>
                  {showSmartSearchLink && count > 0 && (
                    <div>
                      <div className="extended-search-title">
                        <a onClick={onDeepSearchClick} className="link-blue-underline">
                          <FormattedMessageWrappedInSpan
                            id="insufficientResultsView.smartSearchLink"
                            defaultMessage="Smart Search"
                          />
                        </a>
                      </div>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="insufficientResultsView.options.smartSearch.description"
                          defaultMessage="Maybe you did the race together with a friend? Enter her/his bib number and find photos around hers/his which may show you."
                        />
                      </p>
                    </div>
                  )}
                  {showCollectionsLink && (
                    <div>
                      <div className="extended-search-title">
                        <a
                          onClick={onCategorySearchClick}
                          className="link-blue-underline"
                        >
                          <FormattedMessageWrappedInSpan
                            id="insufficientResultsView.categorySearchLink"
                            defaultMessage="Categories"
                          />
                        </a>
                      </div>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="insufficientResultsView.options.categories.description"
                          defaultMessage="All photos spots are listed in categories. Please choose a category and a time range of your expected passing time."
                        />
                      </p>
                    </div>
                  )}
                  {showGpsSearchLink && (
                    <div>
                      <div className="extended-search-title">
                        <a onClick={onGpsSearchClick} className="link-blue-underline">
                          <FormattedMessageWrappedInSpan
                            id="insufficientResultsView.gpsSearchLink"
                            defaultMessage="GPS Search"
                          />
                        </a>
                      </div>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="insufficientResultsView.options.gpsSearch.description"
                          defaultMessage="Did you use a GPS tracker? Download the GPS data from your tracker as a GPX file and upload it on our website. It will show you photos that match the recorded location and time."
                        />
                      </p>
                    </div>
                  )}
                  {showDualSearchLink && (
                    <div>
                      <div className="extended-search-title">
                        <a onClick={onDualSearchClick} className="link-blue-underline">
                          <FormattedMessageWrappedInSpan
                            id="insufficientResultsView.options.faceDualSearch.title"
                            defaultMessage="Search with a selfie"
                          />
                        </a>
                      </div>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="insufficientResultsView.options.faceDualSearch.description"
                          defaultMessage="You can upload a selfie to find matching photos and request to add them to your package."
                        />
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h3>
                    <FormattedMessageWrappedInSpan
                      id="insufficientResultsView.optionsUnavailableNow.title"
                      defaultMessage="Additional search options will be available soon"
                    />
                  </h3>
                  <div>
                    <p>
                      <FormattedMessageWrappedInSpan
                        id="insufficientResultsView.optionsUnavailableNow.description"
                        defaultMessage="Additional search options (eg. by category or GPS data) will be available soon. Come back later to try them out."
                      />
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!tagWithStartNumber && (
          <div className="insufficient-results-view-body col-xs-16 col-sm-12 col-sm-offset-2">
            <div className="no-face-match-results-view-item">
              <h3>
                <FormattedMessageWrappedInSpan
                  id="noFaceMatchResultsView.tryAnotherPhoto.title"
                  defaultMessage="Try with another photo"
                />
              </h3>
              <p>
                <FormattedMessageWrappedInSpan
                  id="noFaceMatchResultsView.tryAnotherPhoto.description"
                  defaultMessage="Please check that your face is not blurry and the only one on the photo!"
                />
              </p>
              <button
                className={classNames('tertiary fullwidth', {
                  'bottom-btn ': isFaceSearchEvent && count === 0 && !isInSmartSearch,
                })}
                onClick={scrollToEvent}
              >
                <FormattedMessageWrappedInSpan
                  id="noFaceMatchResultsView.anotherPhoto"
                  defaultMessage="Try another photo"
                />
              </button>
            </div>
            <div className="no-face-match-results-view-item">
              {showExtendedSearchAsAvailable ? (
                <>
                  <h3>
                    <FormattedMessageWrappedInSpan
                      id="insufficientResultsView.options.title"
                      defaultMessage="Try our search system"
                    />
                  </h3>

                  {isFaceSearchEvent && count === 0 && !isInSmartSearch ? (
                    <>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="noFaceMatchResultsView.description"
                          defaultMessage="You can try alternative search options. It may be helpful especially if you were wearing things that cover your face (such as sunglasses or a helmet) during the competition."
                        />
                      </p>
                      <button
                        className="tertiary fullwidth bottom-btn"
                        onClick={onScrollToExtendedSearch}
                      >
                        <FormattedMessageWrappedInSpan
                          id="noFaceMatchResultsView.alternativeSearch"
                          defaultMessage="Alternative options"
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      {showSmartSearchLink && count > 0 && (
                        <div>
                          <div className="extended-search-title">
                            <a
                              onClick={onDeepSearchClick}
                              className="link-blue-underline"
                            >
                              <FormattedMessageWrappedInSpan
                                id="insufficientResultsView.smartSearchLink"
                                defaultMessage="Smart Search"
                              />
                            </a>
                          </div>
                          <p>
                            <FormattedMessageWrappedInSpan
                              id="noFaceMatchResultsView.options.smartSearch.description"
                              defaultMessage="You can use this feature to find your photos even if we couldn't mark them as yours."
                            />
                          </p>
                        </div>
                      )}
                      {showCollectionsLink && (
                        <div>
                          <div className="extended-search-title">
                            <a
                              onClick={onCategorySearchClick}
                              className="link-blue-underline"
                            >
                              <FormattedMessageWrappedInSpan
                                id="insufficientResultsView.categorySearchLink"
                                defaultMessage="Categories"
                              />
                            </a>
                          </div>
                          <p>
                            <FormattedMessageWrappedInSpan
                              id="insufficientResultsView.options.categories.description"
                              defaultMessage="All photos spots are listed in categories. Please choose a category and a time range of your expected passing time."
                            />
                          </p>
                        </div>
                      )}
                      {showGpsSearchLink && (
                        <div>
                          <div className="extended-search-title">
                            <a onClick={onGpsSearchClick} className="link-blue-underline">
                              <FormattedMessageWrappedInSpan
                                id="insufficientResultsView.gpsSearchLink"
                                defaultMessage="GPS Search"
                              />
                            </a>
                          </div>
                          <p>
                            <FormattedMessageWrappedInSpan
                              id="insufficientResultsView.options.gpsSearch.description"
                              defaultMessage="Did you use a GPS tracker? Download the GPS data from your tracker as a GPX file and upload it on our website. It will show you photos that match the recorded location and time."
                            />
                          </p>
                        </div>
                      )}
                      {showDualSearchLink && (
                        <div>
                          <div className="extended-search-title">
                            <a
                              onClick={onDualSearchClick}
                              className="link-blue-underline"
                            >
                              <FormattedMessageWrappedInSpan
                                id="insufficientResultsView.options.bibSearchDual.title"
                                defaultMessage="Bib number"
                              />
                            </a>
                          </div>
                          <p>
                            <FormattedMessageWrappedInSpan
                              id="insufficientResultsView.options.bibDualSearch.description"
                              defaultMessage="You can enter your bib number to find more photos and request to add them to your package."
                            />
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <h3>
                    <FormattedMessageWrappedInSpan
                      id="insufficientResultsView.optionsUnavailableNow.title"
                      defaultMessage="Additional search options will be available soon"
                    />
                  </h3>
                  <div>
                    <p>
                      <FormattedMessageWrappedInSpan
                        id="insufficientResultsView.optionsUnavailableNow.description"
                        defaultMessage="Additional search options (eg. by category or GPS data) will be available soon. Come back later to try them out."
                      />
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

InsufficientResultsInformation.propTypes = {
  count: PropTypes.number,
  raceResultsUrl: PropTypes.string,
  tagWithStartNumber: PropTypes.bool,
  showGpsSearchLink: PropTypes.bool,
  showSmartSearchLink: PropTypes.bool,
  showDualSearchLink: PropTypes.bool,
  showCollectionsLink: PropTypes.bool,
  showExtendedSearchAsAvailable: PropTypes.bool,
  onDeepSearchClick: PropTypes.func,
  onCategorySearchClick: PropTypes.func,
  onGpsSearchClick: PropTypes.func,
  onDualSearchClick: PropTypes.func,
  isFaceSearchEvent: PropTypes.bool,
  scrollToEvent: PropTypes.func,
  onScrollToExtendedSearch: PropTypes.func,
  isInSmartSearch: PropTypes.bool,
};

export { InsufficientResultsInformation };

export default injectIntl(InsufficientResultsInformation);
