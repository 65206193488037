import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import CartLineItemDvd from './CartLineItemDvd';
import CartLineItemFotoFlat from './CartLineItemFotoFlat';
import CartLineItemPhotoBook from './CartLineItemPhotoBook';
import CartLineItemSinglePhoto from './CartLineItemSinglePhoto';

import { FOTO_FLAT, SINGLE_PHOTO, PHOTO_DVD, PHOTO_BOOK } from '../../../utils/variables';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartLineItem(props) {
  const { lineItem, eventNames, ...other } = props;

  switch (lineItem.product.type) {
    case FOTO_FLAT:
      return <CartLineItemFotoFlat lineItem={lineItem} {...other} />;
    case SINGLE_PHOTO:
      return <CartLineItemSinglePhoto lineItem={lineItem} {...other} />;
    case PHOTO_DVD:
      return <CartLineItemDvd lineItem={lineItem} eventNames={eventNames} {...other} />;
    case PHOTO_BOOK:
      return <CartLineItemPhotoBook lineItem={lineItem} {...other} />;
    default:
      return null;
  }
}

CartLineItem.propTypes = {
  lineItem: PropTypes.object,
  eventNames: PropTypes.object
};

export { CartLineItem };

export default createFragmentContainer(CartLineItem, {
  lineItem: graphql`
    fragment CartLineItem_lineItem on LineItem {
      product {
        type
      }
      ...CartLineItemFotoFlat_lineItem
      ...CartLineItemSinglePhoto_lineItem
    }
  `,
});
