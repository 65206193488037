/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutOrderingAddressStep_viewer$ref: FragmentReference;
declare export opaque type CheckoutOrderingAddressStep_viewer$fragmentType: CheckoutOrderingAddressStep_viewer$ref;
export type CheckoutOrderingAddressStep_viewer = {|
  +countries: ?$ReadOnlyArray<?{|
    +name: ?string,
    +alpha2: ?string,
  |}>,
  +account?: ?{|
    +profile: ?{|
      +addresses: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +firstName: ?string,
            +lastName: ?string,
            +phone: ?string,
            +city: ?string,
            +postalCode: ?string,
            +state: ?string,
            +line2: ?string,
            +line1: ?string,
            +country: ?{|
              +alpha2: ?string,
              +name: ?string,
            |},
          |}
        |}>
      |},
      +email: ?string,
    |}
  |},
  +$refType: CheckoutOrderingAddressStep_viewer$ref,
|};
export type CheckoutOrderingAddressStep_viewer$data = CheckoutOrderingAddressStep_viewer;
export type CheckoutOrderingAddressStep_viewer$key = {
  +$data?: CheckoutOrderingAddressStep_viewer$data,
  +$fragmentRefs: CheckoutOrderingAddressStep_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "alpha2",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CheckoutOrderingAddressStep_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isLoggedIn",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "countries",
      "storageKey": null,
      "args": null,
      "concreteType": "Country",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isLoggedIn",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "account",
          "storageKey": null,
          "args": null,
          "concreteType": "Account",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "profile",
              "storageKey": null,
              "args": null,
              "concreteType": "Profile",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "addresses",
                  "storageKey": "addresses(first:10)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 10
                    }
                  ],
                  "concreteType": "AddressConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AddressEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Address",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "firstName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "lastName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "phone",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "city",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "postalCode",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "state",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "line2",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "line1",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "country",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "Country",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v0/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '700a7e99e1aa9d7eab58af58ac4071e6';

module.exports = node;
