/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import EventGpsSearchPage from './EventGpsSearchPage';
import QueryHandler from '../components/misc/QueryHandler';

import { getCustomerKey } from '../utils/browser-storage';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class EventGpsSearchPageQueryRenderer extends Component {
  constructor(props) {
    super(props);

    this.snFromUrl = props.match.params.startnumber;
    this.eventIdFromUrl = props.match.params.id;
    this.gpxFileIdFromUrl = props.match.params.fileId;
    this.customerKey = getCustomerKey(this.eventIdFromUrl, this.snFromUrl);
    this.queryParams = new URLSearchParams(this.props.location.search);
  }

  render() {
    const { match, history } = this.props;
    const code2FA = this.queryParams.get('code');

    return (
      <QueryHandler
        query={graphql`
          query EventGpsSearchPageQueryRendererQuery(
            $eventId: ID!
            $language: String
            $startnumber: ID!
            $gpxFileId: ID!
            $count: Int!
            $cursor: String
            $secondFactor: String
            $individualPart: String
            $noStartnumber: Boolean
            $isRecheckPage: Boolean!
          ) {
            viewer {
              ...EventGpsSearchPage_viewer
            }
          }
        `}
        variables={{
          eventId: this.eventIdFromUrl,
          language: match.params.language,
          startnumber: this.snFromUrl !== undefined ? this.snFromUrl : '',
          gpxFileId: this.gpxFileIdFromUrl,
          count: MEDIA_FETCH_COUNT,
          secondFactor: code2FA || this.customerKey,
          individualPart: '',
          noStartnumber: this.snFromUrl === undefined,
          isRecheckPage: false,
        }}
        redirectTo={`/event/${this.eventIdFromUrl}`}
        render={props => {
          return (
            <EventGpsSearchPage
              viewer={props.viewer}
              snFromUrl={match.params.startnumber}
              gpxFileIdFromUrl={match.params.fileId}
              customerKey={this.customerKey}
              history={history}
            />
          );
        }}
      />
    );
  }
}

EventGpsSearchPageQueryRenderer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default EventGpsSearchPageQueryRenderer;
