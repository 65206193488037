import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';
import { useParams } from 'react-router-dom';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { PrimaryButton } from '../../misc/Buttons';
import GDPRHint from '../GDPRHint';
import SecondFactorGuardInfoCarousel from './SecondFactorGuardInfoCarousel';
import { Tooltip, Collapse } from '@material-ui/core';
import isEmailProper from '../../../utils/isEmailProper';

import { VERIFY_EMAIL_NEXT_STEPS_MSG, MAIN_SEARCH_FACE, MAIN_SEARCH_BIB } from '../../../utils/variables';
import { NotificationDialogContext } from '../../context/NotificationDialogContext';
import withRequestHandler from '../../hoc/withRequestHandler';
import useEmailSpellChecking from '../../../hooks/useEmailSpellChecking';
import EmailSuggestionTooltip from '../../misc/EmailSuggestionTooltip';

import environment from '../../../environment';
import RequestEventAccessMutation from '../../../mutations/RequestEventAccessMutation';
import RequestAccessToEventMutation from '../../../mutations/RequestAccessToEventMutation';

const ENTER_KEY = 'Enter';

const SecondFactorGuard = (props) => {
  const {
    intl,
    eventSgId,
    setFormRef,
    shouldShowAdditionalInstruction,
    userLoginEmail,
    mainSearch,
    showHowToBuyHint,
    onShowHowToBuyHint,
  } = props;
  const notificationDialog = useContext(NotificationDialogContext);

  const params = useParams();

  const [email, setEmail] = useState('');
  const [startnumber, setStartnumber] = useState(
    params.startnumber ? params.startnumber : ''
  );
  const [showEmailError, setShowEmailError] = useState(false);
  const [showStartnumberError, setShowStartnumberError] = useState(false);
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedStartnumber, setFocusedStartnumber] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDetailedProcedure, setShowDetailedProcedure] = useState(false);
  const [linkSent, setLinkIsSent] = useState(false);

  let suggestedEmail = useEmailSpellChecking(email);

  const handleInputChanged = (event, inputFieldType) => {
    onShowHowToBuyHint && onShowHowToBuyHint(false);
    if (inputFieldType === 'email') {
      setShowEmailError(false);
      setEmail(event.target.value.toLowerCase().replace(/\s+/g, ''));
    }
    if (inputFieldType === 'startnumber') {
      setShowStartnumberError(false);
      setStartnumber(event.target.value.replace(/\s+/g, ''));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY) {
      verifyFields();
    }
  };

  const verifyFields = () => {
    if (!isEmailProper(email)) {
      setShowEmailError(true);
      moveUserToEmailForm();
      return;
    }
    if (mainSearch === MAIN_SEARCH_BIB && !startnumber) {
      setShowStartnumberError(true);
      moveUserToStartnumberForm();
      return;
    }
    requestAccess();
  };

  const requestAccess = () => {
    const { onRequestStart, onRequestError, onRequestStop } = props;
    onRequestStart();
    setShowSpinner(true);
    if (mainSearch === MAIN_SEARCH_FACE) {
      RequestEventAccessMutation.commit(
        environment,
        email,
        intl.locale,
        eventSgId,
        (error, resp_email) => {
          if (!error) {
            notificationDialog.onOpenNotificationDialog(VERIFY_EMAIL_NEXT_STEPS_MSG);
            setLinkIsSent(true);
          } else {
            onRequestError(error);
          }
          setShowSpinner(false);
          onRequestStop();
        }
      );
    } else {
      RequestAccessToEventMutation.commit(
        environment,
        email,
        intl.locale,
        eventSgId,
        startnumber,
        (error, resp_email) => {
          if (!error) {
            notificationDialog.onOpenNotificationDialog(VERIFY_EMAIL_NEXT_STEPS_MSG);
            setLinkIsSent(true);
          } else {
            onRequestError(error);
          }
          setShowSpinner(false);
          onRequestStop();
        }
      );
    }
  };

  const handleFocus = (event, inputFieldType) => {
    if (inputFieldType === 'email') {
      setFocusedEmail(true);
    }
    if (inputFieldType === 'startnumber') {
      setFocusedStartnumber(true);
    }
  };

  const handleBlur = (event, inputFieldType) => {
    if (inputFieldType === 'email') {
      setFocusedEmail(false);
    }
    if (inputFieldType === 'startnumber') {
      setFocusedStartnumber(false);
    }
  };

  const moveUserToEmailForm = (event) => {
    emailInputRef?.current?.focus();
    setFocusedEmail(true);
  };

  const moveUserToStartnumberForm = (event) => {
    startnumberInputRef?.current?.focus();
    setFocusedStartnumber(true);
  };

  const formRef = useRef(null);
  const emailInputRef = useRef(null);
  const startnumberInputRef = useRef(null);

  useEffect(() => {
    if (setFormRef) {
      setFormRef(formRef);
    }
  }, [formRef]);

  return (
    <div className="col-xs-16 col-sm-6">
      <div className="startnumber-search-container second-factor-container">
        <div className={classNames('container-label ml-15 mr-15')}>
          {shouldShowAdditionalInstruction ? (
            <FormattedMessageWrappedInSpan
              id="secondFactorGuard.floatingLabelText.additionalInstruction"
              defaultMessage="Email does not match"
            />
          ) : (
            <FormattedMessageWrappedInSpan
              id="secondFactorGuard.floatingLabelText"
              defaultMessage="This is a secured event"
            />
          )}
        </div>
        <div className="second-factor-guard-label-container ">
          <div className={classNames('second-factor-guard-label ml-15 mr-15')}>
            {shouldShowAdditionalInstruction ? (
              <FormattedMessageWrappedInSpan
                id="secondFactorGuard.additionalInstruction"
                defaultMessage="Email <span>{userEmail}</span> <b>does not match with any bib number</b>. Send a new validation link to the correct email address. Please make sure you use <b>the same email as the one you provided to event's organizer</b> while registering."
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                  span: (chunks) => (
                    <span className="second-factor-guard-text-highlight">{chunks}</span>
                  ),
                  userEmail: userLoginEmail ? userLoginEmail : '',
                }}
              />
            ) : (
              <>
                <FormattedMessageWrappedInSpan
                  className="second-factor-guard-details-subtitle"
                  id="secondFactorGuard.subtitle"
                  defaultMessage="<b>Your privacy matters</b>. To protect it, we have a special, <b>encrypted authentication procedure</b>, similar to storing passwords."
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
                <div
                  className={`second-factor-carousel carousel-invisible-${showDetailedProcedure}`}
                >
                  <Collapse in={showDetailedProcedure} timeout="auto">
                    <SecondFactorGuardInfoCarousel
                      shouldRunAutoplay={showDetailedProcedure}
                    />
                  </Collapse>
                </div>
                <button
                  className="secondary btn"
                  onClick={() => setShowDetailedProcedure((prevState) => !prevState)}
                >
                  {showDetailedProcedure ? (
                    <FormattedMessageWrappedInSpan
                      id="secondFactorGuard.hideDetails"
                      defaultMessage="Close details"
                    />
                  ) : (
                    <FormattedMessageWrappedInSpan
                      id="secondFactorGuard.expandDetails"
                      defaultMessage="See how it works"
                    />
                  )}
                </button>
                <div
                  className={classNames('second-factor-guard-details-instruction', {
                    error:
                      mainSearch === MAIN_SEARCH_FACE
                        ? showEmailError
                        : showEmailError || showStartnumberError,
                  })}
                >
                  <FormattedMessageWrappedInSpan
                    className="second-factor-guard-details-instruction"
                    id="secondFactorGuard.instruction"
                    defaultMessage="That's why we are asking you to enter <b>the details that you provided to the organiser while registering</b>."
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div
          ref={formRef}
          className={classNames('container-label ml-15 mr-15', {
            error: showEmailError,
          })}
          onClick={moveUserToEmailForm}
        >
          <FormattedMessageWrappedInSpan
            id="secondFactorGuard.emailInput.floatingLabelText"
            defaultMessage="Enter your email address"
          />
        </div>
        <div
          className={classNames('startnumber-search-textfield', 'second-factor', {
            focused: focusedEmail,
            'input-edit-icon': email === '',
          })}
          onClick={moveUserToEmailForm}
        >
          <div>
            <EmailSuggestionTooltip
              emailSuggestion={suggestedEmail}
              onAcceptSuggestion={() => {
                setEmail(suggestedEmail);
              }}
            >
              <input
                ref={emailInputRef}
                autoComplete="off"
                name="email"
                onChange={(e) => handleInputChanged(e, 'email')}
                onBlur={(e) => handleBlur(e, 'email')}
                onKeyPress={(e) => handleKeyPress(e)}
                onFocus={(e) => handleFocus(e, 'email')}
                placeholder={intl.formatMessage(translations.emailHintText)}
                type="text"
                value={email}
              />
            </EmailSuggestionTooltip>
          </div>
        </div>
        {mainSearch === MAIN_SEARCH_BIB && (
          <>
            <div
              className={classNames('container-label ml-15 mr-15', {
                error: showStartnumberError,
              })}
              onClick={moveUserToStartnumberForm}
            >
              <FormattedMessageWrappedInSpan
                id="secondFactorGuard.startnumberInput.floatingLabelText"
                defaultMessage="Enter your bib number"
              />
            </div>
            <div
              className={classNames('startnumber-search-textfield', 'second-factor', {
                focused: focusedStartnumber,
                'input-edit-icon': startnumber === '',
              })}
              onClick={moveUserToStartnumberForm}
            >
              <div>
                <input
                  ref={startnumberInputRef}
                  autoComplete="off"
                  name="startnumber"
                  onChange={(e) => handleInputChanged(e, 'startnumber')}
                  onBlur={(e) => handleBlur(e, 'startnumber')}
                  onKeyPress={(e) => handleKeyPress(e)}
                  onFocus={(e) => handleFocus(e, 'startnumber')}
                  placeholder="0000"
                  type="text"
                  value={startnumber}
                />
              </div>
            </div>
          </>
        )}
        <GDPRHint secondFactorNote={true} className="mb-15 ml-15 mr-15" />
        <Tooltip
          title={
            <FormattedMessageWrappedInSpan
              id="eventPage.howToBuyHint.secondFactor"
              defaultMessage="Please enter your details in the form above and then click this button to access and buy your photos."
            />
          }
          open={showHowToBuyHint}
          arrow
        >
          <div>
            <PrimaryButton
              leftLabel={
                linkSent ? (
                  <FormattedMessageWrappedInSpan
                    id="notificationDialogBody.verifyEmailNextSteps.title"
                    defaultMessage="Link sent"
                  />
                ) : shouldShowAdditionalInstruction ? (
                  <FormattedMessageWrappedInSpan
                    id="secondFactorGuard.verifyButton.additionalInstructioon"
                    defaultMessage="Retry to send link"
                  />
                ) : (
                  <FormattedMessageWrappedInSpan
                    id="secondFactorGuard.verifyButton"
                    defaultMessage="Send validation link"
                  />
                )
              }
              onClick={(e) => verifyFields(e)}
              hasFullWidth={true}
              hasSpinner={showSpinner}
              disabled={linkSent}
            />
          </div>
        </Tooltip>
      </div>
      <GDPRHint className="mt-15 mb-15 ml-15 mr-15" />
    </div>
  );
};

const translations = defineMessages({
  emailHintText: {
    id: 'secondFactorGuard.emailInput.hintText',
    defaultMessage: 'example@domain.com',
  },
});

SecondFactorGuard.propTypes = {
  intl: PropTypes.object,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  eventSgId: PropTypes.number,
  setFormRef: PropTypes.func,
  shouldShowAdditionalInstruction: PropTypes.bool,
  userLoginEmail: PropTypes.string,
  mainSearch: PropTypes.string,
  showHowToBuyHint: PropTypes.bool,
  onShowHowToBuyHint: PropTypes.func,
};

SecondFactorGuard.defaultProps = {
  mainSearch: 'startnumber',
};


export { SecondFactorGuard };

const wrappedComponent = withRequestHandler(SecondFactorGuard);

export default injectIntl(wrappedComponent);
