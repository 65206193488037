/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaList_offer$ref: FragmentReference;
declare export opaque type MediaList_offer$fragmentType: MediaList_offer$ref;
export type MediaList_offer = {|
  +products: ?$ReadOnlyArray<?{|
    +id: string,
    +mediaCollection: ?{|
      +name: ?string
    |},
    +price: ?any,
    +type: ?string,
  |}>,
  +voucherCampaigns: ?$ReadOnlyArray<?{|
    +id: string,
    +banner: ?string,
    +name: ?string,
    +shortText: ?string,
  |}>,
  +$refType: MediaList_offer$ref,
|};
export type MediaList_offer$data = MediaList_offer;
export type MediaList_offer$key = {
  +$data?: MediaList_offer$data,
  +$fragmentRefs: MediaList_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaList_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "products",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "mediaCollection",
          "storageKey": null,
          "args": null,
          "concreteType": "MediaCollection",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voucherCampaigns",
      "storageKey": null,
      "args": null,
      "concreteType": "VoucherCampaign",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "banner",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shortText",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '89da3f73f6f4db49e3d5fcd34ab185cd';

module.exports = node;
