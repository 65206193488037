import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';

const FaceSearchIDCookieDialog = (props) => {
  const { open, onClose, onCookieUse, onCookieDisableForEvent } = props;

  return (
    <CustomDialog open={open} onRequestClose={onClose}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="faceSearchIDCookieDialog.title"
                defaultMessage="Show previous search result"
              />
            </h1>
            <FormattedMessageWrappedInSpan
              id="faceSearchIDCookieDialog.body"
              defaultMessage="We stored your search result from a previous session in this browser. You can load it to see your past search result."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button
              onClick={() => {
                onCookieDisableForEvent();
                onClose();
              }}
            >
              <FormattedMessageWrappedInSpan
                id="faceSearchIDCookieDialog.closeButton"
                defaultMessage="Do not store for this event"
              />
            </button>
            <button className="accept" onClick={() => onCookieUse()}>
              <FormattedMessageWrappedInSpan
                id="faceSearchIDCookieDialog.loadPreviousButton"
                defaultMessage="Load previous result"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

FaceSearchIDCookieDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCookieUse: PropTypes.func,
  onCookieDisableForEvent: PropTypes.func,
};

export { FaceSearchIDCookieDialog };

export default FaceSearchIDCookieDialog;
