import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../components/misc';

const CookiesInfoPage = () => {
  return (
    <div className="container-960 container-page">
      <h2>
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.title" />
      </h2>
      <p>
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineOne" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineTwo" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineThree" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineFour" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineFive" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineSix" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineSeven" />
        <br />
        <FormattedMessageWrappedInSpan id="cookiesInfoPage.lineEight" />
      </p>
    </div>
  );
};

export default CookiesInfoPage;
