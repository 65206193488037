/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MediaList_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SuggestionsMediaList_offer$ref: FragmentReference;
declare export opaque type SuggestionsMediaList_offer$fragmentType: SuggestionsMediaList_offer$ref;
export type SuggestionsMediaList_offer = {|
  +$fragmentRefs: MediaList_offer$ref,
  +$refType: SuggestionsMediaList_offer$ref,
|};
export type SuggestionsMediaList_offer$data = SuggestionsMediaList_offer;
export type SuggestionsMediaList_offer$key = {
  +$data?: SuggestionsMediaList_offer$data,
  +$fragmentRefs: SuggestionsMediaList_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SuggestionsMediaList_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MediaList_offer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f7ac8e3c548e06da5eaf71f582ee6134';

module.exports = node;
