/**
 * @flow
 * @relayHash 9918d4596caea4d4cacbd0eb1784a3ec
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BookPreviewPageQueryVariables = {|
  eventId: string,
  language?: ?string,
|};
export type BookPreviewPageQueryResponse = {|
  +viewer: ?{|
    +event: ?{|
      +fotoBookTemplate: ?string
    |}
  |}
|};
export type BookPreviewPageQuery = {|
  variables: BookPreviewPageQueryVariables,
  response: BookPreviewPageQueryResponse,
|};
*/


/*
query BookPreviewPageQuery(
  $eventId: ID!
  $language: String
) {
  viewer {
    event(id: $eventId) {
      fotoBookTemplate(language: $language)
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fotoBookTemplate",
  "args": [
    {
      "kind": "Variable",
      "name": "language",
      "variableName": "language"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BookPreviewPageQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BookPreviewPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BookPreviewPageQuery",
    "id": null,
    "text": "query BookPreviewPageQuery(\n  $eventId: ID!\n  $language: String\n) {\n  viewer {\n    event(id: $eventId) {\n      fotoBookTemplate(language: $language)\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8c162d09c7f24c561b86e01dc1d649a';

module.exports = node;
