/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MediaList_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaCollectionList_offer$ref: FragmentReference;
declare export opaque type MediaCollectionList_offer$fragmentType: MediaCollectionList_offer$ref;
export type MediaCollectionList_offer = {|
  +$fragmentRefs: MediaList_offer$ref,
  +$refType: MediaCollectionList_offer$ref,
|};
export type MediaCollectionList_offer$data = MediaCollectionList_offer;
export type MediaCollectionList_offer$key = {
  +$data?: MediaCollectionList_offer$data,
  +$fragmentRefs: MediaCollectionList_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "MediaCollectionList_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MediaList_offer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6ce03c772dcb4203c3953d83568af30d';

module.exports = node;
