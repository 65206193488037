import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation UploadGpxFileMutation($input: UploadGpxFileInput!) {
    uploadGpxFile(input: $input) {
      gpxFile {
        id
      }
    }
  }
`;

function commit(environment, file, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        file: 'file',
      },
    },
    uploadables: {
      file: file,
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.uploadGpxFile.gpxFile.id);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
