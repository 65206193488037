/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventTwoStepsListItem_viewer$ref: FragmentReference;
declare export opaque type EventTwoStepsListItem_viewer$fragmentType: EventTwoStepsListItem_viewer$ref;
export type EventTwoStepsListItem_viewer = {|
  +account: ?{|
    +eventPurchases: ?{|
      +singleMedia: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |},
        +mediaList: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +variants: ?$ReadOnlyArray<?{|
                +name: ?string,
                +url: ?string,
              |}>,
            |}
          |}>
        |},
      |},
      +participants: ?$ReadOnlyArray<?{|
        +startnumber: ?string,
        +media: ?{|
          +mediaList: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +variants: ?$ReadOnlyArray<?{|
                  +name: ?string,
                  +url: ?string,
                |}>,
              |}
            |}>
          |}
        |},
      |}>,
    |}
  |},
  +$refType: EventTwoStepsListItem_viewer$ref,
|};
export type EventTwoStepsListItem_viewer$data = EventTwoStepsListItem_viewer;
export type EventTwoStepsListItem_viewer$key = {
  +$data?: EventTwoStepsListItem_viewer$data,
  +$fragmentRefs: EventTwoStepsListItem_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "MediaEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Media",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "variants",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaFile",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "EventTwoStepsListItem_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "eventPurchases",
          "storageKey": null,
          "args": [
            {
              "kind": "Literal",
              "name": "byStartnumber",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "eventId",
              "variableName": "eventId"
            }
          ],
          "concreteType": "EventPurchases",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "singleMedia",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "mediaInfo",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MediaInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "count",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "mediaList",
                  "storageKey": "mediaList(first:1)",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "MediaConnection",
                  "plural": false,
                  "selections": (v0/*: any*/)
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "participants",
              "storageKey": null,
              "args": null,
              "concreteType": "BoughtParticipant",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startnumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "media",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExtendedMediaConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "mediaList",
                      "storageKey": "mediaList(first:50)",
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 50
                        }
                      ],
                      "concreteType": "MediaConnection",
                      "plural": false,
                      "selections": (v0/*: any*/)
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9958d086bbaf50bcb0d4d19317e05369';

module.exports = node;
