export default function flattenMessages(nestedMessages, marathonFotoVersion, isMarathonFotoPage, prefix = '') {
  const selectedTranslations = isMarathonFotoPage ? {...nestedMessages, ...marathonFotoVersion} : nestedMessages;
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = selectedTranslations[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {

      const childMessages = isMarathonFotoPage ? marathonFotoVersion[key] || {} : nestedMessages[key] || {};
      Object.assign(messages, flattenMessages(childMessages, marathonFotoVersion, isMarathonFotoPage, prefixedKey));

      // Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}