import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTime, FormattedDate } from 'react-intl';

import FormattedPrice from '../../misc/FormattedPrice';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { PrimaryButton, SecondaryButton } from '../../misc/Buttons';
import { trackEvent } from '../../../utils/ga-tracking';
import {
  RECHECK_APPROVED,
  RECHECK_REJECTED,
  RECHECK_REQUESTED,
} from '../../../utils/variables';
import RecheckRequestSuccessfulInfo from '../informations/RecheckRequestSuccessfulInfo';

import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';

const ThumbnailPreviewEventDetails = (props) => {
  const {
    photoSgId,
    shotDate,
    showTagButton,
    fotoFlatProduct,
    singlePhotoProduct,
    onBuySinglePhoto,
    onBuyFotoFlat,
    onShowRecheckView,
    eventHasSearchByFace,
    showRecheckNotification,
    recheckRequestedAt,
    recheckStatus,
    isRecheckEventPage,
    goToSearchResults,
  } = props;

  const orderIsPossible =
    !isRecheckEventPage || (isRecheckEventPage && recheckStatus === RECHECK_APPROVED);

  if (showRecheckNotification) {
    return (
      <div className="thumbnail-preview-recheck-notification">
        <RecheckRequestSuccessfulInfo eventHasSearchByFace={eventHasSearchByFace} isDialogVersion={false}/>
      </div>
    );
  }

  return (
    <div className="thumbnail-preview-details">
      <div className="row">
        <div className="col-xs-16 col-sm-6  ">
          <div className="thumbnail-preview-shortInfo ">
            <CropOriginalRoundedIcon />
            <span>{photoSgId}</span>
          </div>
          {shotDate && (
            <div className="thumbnail-preview-shortInfo">
              <TimerRoundedIcon />
              <FormattedTime
                value={new Date(shotDate)}
                timeZone="UTC"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
              />
            </div>
          )}
          {recheckRequestedAt && (
            <div className="thumbnail-preview-shortInfo">
              <EventAvailableRoundedIcon />
              <span>
                <FormattedMessageWrappedInSpan
                  id="thumbnailPreviewEventDetails.recheckRequestTime"
                  defaultMessage="Recheck requested"
                />{' '}
                <FormattedDate
                  value={new Date(recheckRequestedAt)}
                  day="numeric"
                  month="numeric"
                  year="numeric"
                  timeZone="UTC"
                />
                {', '}
                <FormattedTime
                  value={new Date(recheckRequestedAt)}
                  timeZone="UTC"
                  hour="2-digit"
                  minute="2-digit"
                />
              </span>
            </div>
          )}
        </div>
        <div className="col-xs-16 col-sm-7 col-sm-offset-3">
          <div className="thumbnail-preview-details-actions">
            {fotoFlatProduct && orderIsPossible && (
              <PrimaryButton
                hasFullWidth={true}
                leftLabel={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewEventDetails.actions.buyFotoFlatButton"
                    defaultMessage="Buy Foto-Flat"
                  />
                }
                rightLabel={<FormattedPrice price={fotoFlatProduct.price} />}
                onClick={() => {
                  trackEvent('Event', 'Buy', 'Foto-Flat button');
                  onBuyFotoFlat();
                }}
              />
            )}
            {!fotoFlatProduct && showTagButton && !isRecheckEventPage && !recheckStatus && (
              <PrimaryButton
                hasFullWidth={true}
                leftLabel={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewEventDetails.actions.addToFotoFlat"
                    defaultMessage="That's me"
                  />
                }
                onClick={onShowRecheckView}
              />
            )}
            {Boolean(singlePhotoProduct) && orderIsPossible && (
              <SecondaryButton
                hasFullWidth={true}
                leftLabel={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewEventDetails.actions.buySinglePhoto"
                    defaultMessage="Buy single photo"
                  />
                }
                rightLabel={<FormattedPrice price={singlePhotoProduct.price} />}
                onClick={() => {
                  trackEvent('Event', 'Buy', 'Single photo button');
                  onBuySinglePhoto();
                }}
              />
            )}
            {Boolean(fotoFlatProduct) && showTagButton && !isRecheckEventPage && !recheckStatus && (
              <SecondaryButton
                hasFullWidth={true}
                leftLabel={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewEventDetails.actions.addToFotoFlat"
                    defaultMessage="That's me"
                  />
                }
                onClick={onShowRecheckView}
              />
            )}
            {!isRecheckEventPage && showTagButton && recheckStatus && (
              <SecondaryButton
                hasFullWidth={true}
                disabled={true}
                leftLabel={
                  <FormattedMessageWrappedInSpan
                    id="thumbnailPreviewEventDetails.actions.alreadyRequestedRecheck"
                    defaultMessage="Review already requested"
                  />
                }
                onClick={onShowRecheckView}
              />
            )} 
            {isRecheckEventPage && (
              <>
                {recheckStatus === RECHECK_REQUESTED && (
                  <button className="btn-default primary-new full-width" disabled={true}>
                    <FormattedMessageWrappedInSpan
                      id="thumbnailPreviewEventDetails.actions.recheckInProgress"
                      defaultMessage="In progress"
                    />
                  </button>
                )}
                {recheckStatus === RECHECK_REJECTED && (
                  <button
                    className="btn-default secondary-new full-width"
                    disabled={true}
                  >
                    <FormattedMessageWrappedInSpan
                      id="thumbnailPreviewEventDetails.actions.recheckRejected"
                      defaultMessage="Rejected"
                    />
                  </button>
                )}
                {recheckStatus === RECHECK_APPROVED && (
                  <button
                    className="btn-default secondary-new full-width"
                    onClick={() => goToSearchResults()}
                  >
                    <FormattedMessageWrappedInSpan
                      id="thumbnailPreviewEventDetails.actions.goToEvent"
                      defaultMessage="Go to event"
                    />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ThumbnailPreviewEventDetails.propTypes = {
  showTagButton: PropTypes.bool,
  photoSgId: PropTypes.number,
  shotDate: PropTypes.string,
  eventHasSearchByFace: PropTypes.bool,
  showRecheckNotification: PropTypes.bool,
  fotoFlatProduct: PropTypes.object,
  singlePhotoProduct: PropTypes.object,
  onBuyFotoFlat: PropTypes.func,
  onBuySinglePhoto: PropTypes.func,
  onShowRecheckView: PropTypes.func,
  recheckRequestedAt: PropTypes.string,
  recheckStatus: PropTypes.string,
  isRecheckEventPage: PropTypes.bool,
  goToSearchResults: PropTypes.func,
};

export default ThumbnailPreviewEventDetails;
