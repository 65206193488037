import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation CreateOrUpdateOrderWithPaypalExpressMutation(
    $input: CreateOrUpdateOrderWithPaypalExpressInput!
  ) {
    createOrUpdateOrderWithPaypalExpress(input: $input) {
      order {
        id
        address {
          firstName
          lastName
          phone
          title
          city
          postalCode
          line1
          line2
          country {
            alpha2
          }
          state
        }
        bill {
          ...CheckoutBillTable_bill
        }
        cart {
          availablePaymentMethods {
            id
            name
          }
          customer {
            email
          }
          paymentRequired
        }
        orderid
        payment {
          paymentMethod {
            id
            name
          }
          state
        }
        state
      }
    }
  }
`;

function commit(environment, nonce, language, cartId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        nonce,
        language,
      },
    },
    updater: store => {
      const payload = store.getRootField('createOrUpdateOrderWithPaypalExpress');
      const order = payload.getLinkedRecord('order');
      const cart = store.get(cartId);
      cart.setLinkedRecord(order, 'order');
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.createOrUpdateOrderWithPaypalExpress.order);
      }
    },
    onError: err => {
      callback && callback(err.message);
    },
  });
}

export default { commit };
