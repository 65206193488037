/**
 * @flow
 * @relayHash 75b10edcc5f507457acc7c66cba42876
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFotoBookToCartInput = {|
  configuration?: ?$ReadOnlyArray<?FotoBookItemInputObject>,
  count?: ?number,
  language?: ?string,
  productId: string,
|};
export type FotoBookItemInputObject = {|
  cropHeight: number,
  cropWidth: number,
  cropX: number,
  cropY: number,
  mediaId: string,
  position: string,
|};
export type AddFotoBookToCartMutationVariables = {|
  input: AddFotoBookToCartInput
|};
export type AddFotoBookToCartMutationResponse = {|
  +addFotoBookToCart: ?{|
    +cart: ?{|
      +id: string,
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string,
        +product: ?{|
          +id: string,
          +label: ?string,
          +price: ?any,
          +type: ?string,
        |},
      |}>,
      +totalPrice: ?any,
    |}
  |}
|};
export type AddFotoBookToCartMutation = {|
  variables: AddFotoBookToCartMutationVariables,
  response: AddFotoBookToCartMutationResponse,
|};
*/


/*
mutation AddFotoBookToCartMutation(
  $input: AddFotoBookToCartInput!
) {
  addFotoBookToCart(input: $input) {
    cart {
      id
      lineItems {
        id
        product {
          id
          label
          price
          type
        }
      }
      totalPrice
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddFotoBookToCartInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addFotoBookToCart",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFotoBookToCartPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cart",
        "storageKey": null,
        "args": null,
        "concreteType": "Cart",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "lineItems",
            "storageKey": null,
            "args": null,
            "concreteType": "LineItem",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "product",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "price",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalPrice",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddFotoBookToCartMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddFotoBookToCartMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddFotoBookToCartMutation",
    "id": null,
    "text": "mutation AddFotoBookToCartMutation(\n  $input: AddFotoBookToCartInput!\n) {\n  addFotoBookToCart(input: $input) {\n    cart {\n      id\n      lineItems {\n        id\n        product {\n          id\n          label\n          price\n          type\n        }\n      }\n      totalPrice\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5b82677adc60a1c13cde27e61b21ee7';

module.exports = node;
