/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartAdditionalProduct_additionalProduct$ref: FragmentReference;
declare export opaque type CartAdditionalProduct_additionalProduct$fragmentType: CartAdditionalProduct_additionalProduct$ref;
export type CartAdditionalProduct_additionalProduct = {|
  +price: ?any,
  +$refType: CartAdditionalProduct_additionalProduct$ref,
|};
export type CartAdditionalProduct_additionalProduct$data = CartAdditionalProduct_additionalProduct;
export type CartAdditionalProduct_additionalProduct$key = {
  +$data?: CartAdditionalProduct_additionalProduct$data,
  +$fragmentRefs: CartAdditionalProduct_additionalProduct$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartAdditionalProduct_additionalProduct",
  "type": "Product",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f89978e11f755a4bbdb9eb168854915c';

module.exports = node;
