/**
 * @flow
 * @relayHash 8b23f018cbdf668f814452858981e84b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RestartProductionInput = {|
  eventId: string
|};
export type RestartProductionMutationVariables = {|
  input: RestartProductionInput
|};
export type RestartProductionMutationResponse = {|
  +restartProduction: ?{|
    +status: ?string
  |}
|};
export type RestartProductionMutation = {|
  variables: RestartProductionMutationVariables,
  response: RestartProductionMutationResponse,
|};
*/


/*
mutation RestartProductionMutation(
  $input: RestartProductionInput!
) {
  restartProduction(input: $input) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RestartProductionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "restartProduction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RestartProductionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RestartProductionMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RestartProductionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RestartProductionMutation",
    "id": null,
    "text": "mutation RestartProductionMutation(\n  $input: RestartProductionInput!\n) {\n  restartProduction(input: $input) {\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eba4f73110c97804fe9aaf74d2493d51';

module.exports = node;
