import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NotificationDialog from '../content/dialogs/NotificationDialog';
import HtmlDialog from '../content/dialogs/HtmlDialog';

const NotificationDialogContext = React.createContext();

const NotificationDialogConsumer = NotificationDialogContext.Consumer;

class NotificationDialogProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      htmlDialogIsOpen: false,
      msg: '',
      msgType: '',
      html: '',
      onCloseCallback: null,
    };

    this.openNotificationDialog = this.openNotificationDialog.bind(this);
    this.openNotificationDialogWithMessage = this.openNotificationDialogWithMessage.bind(this);
    this.closeNotificationDialog = this.closeNotificationDialog.bind(this);
    this.openHtmlDialog = this.openHtmlDialog.bind(this);
    this.closeHtmlDialog = this.closeHtmlDialog.bind(this);
  }

  openNotificationDialog(msgType, onCloseCallback) {
    this.setState({ isOpen: true, msg: '', msgType, onCloseCallback });
  }

  openNotificationDialogWithMessage(msg, onCloseCallback) {
    this.setState({ isOpen: true, msg, msgType: '', onCloseCallback });
  }

  closeNotificationDialog() {
    this.setState({ isOpen: false, msg: '', msgType: '' }, () => {
      this.state.onCloseCallback && this.state.onCloseCallback();
    });
  }

  openHtmlDialog(html) {
    this.setState({ htmlDialogIsOpen: true, html });
  }

  closeHtmlDialog() {
    this.setState({ htmlDialogIsOpen: false, html: '' });
  }

  render() {
    const { isOpen, htmlDialogIsOpen, msg, msgType, html } = this.state;
    return (
      <NotificationDialogContext.Provider
        value={{
          isOpen: isOpen,
          htmlDialogIsOpen: htmlDialogIsOpen,
          msg: msg,
          msgType: msgType,
          html: html,
          onOpenNotificationDialog: this.openNotificationDialog,
          onOpenNotificationDialogWithMessage: this.openNotificationDialogWithMessage,
          onOpenHtmlDialog: this.openHtmlDialog,
          onCloseNotificationDialog: this.closeNotificationDialog,
          onCloseHtmlDialog: this.closeHtmlDialog,
        }}
      >
        {this.props.children}
        <NotificationDialog />
        <HtmlDialog />
      </NotificationDialogContext.Provider>
    );
  }
}

NotificationDialogProvider.propTypes = {
  children: PropTypes.object,
};

export { NotificationDialogContext };
export { NotificationDialogProvider };
export { NotificationDialogConsumer };
