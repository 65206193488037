/**
 * @flow
 * @relayHash 3a178c91c13d8b86ae069c64a747f463
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MediaCollectionList_mediaCollectionMedia$ref = any;
export type MediaCollectionListQueryVariables = {|
  eventId: string,
  categoryId: string,
  count: number,
  cursor?: ?string,
  fetchCategory?: ?boolean,
  fetchAllPhotos?: ?boolean,
  minTime?: ?any,
  isRecheckPage: boolean,
|};
export type MediaCollectionListQueryResponse = {|
  +viewer: ?{|
    +event: ?{|
      +media?: ?{|
        +$fragmentRefs: MediaCollectionList_mediaCollectionMedia$ref
      |},
      +mediaCollection?: ?{|
        +media: ?{|
          +$fragmentRefs: MediaCollectionList_mediaCollectionMedia$ref
        |}
      |},
    |}
  |}
|};
export type MediaCollectionListQuery = {|
  variables: MediaCollectionListQueryVariables,
  response: MediaCollectionListQueryResponse,
|};
*/


/*
query MediaCollectionListQuery(
  $eventId: ID!
  $categoryId: ID!
  $count: Int!
  $cursor: String
  $fetchCategory: Boolean
  $fetchAllPhotos: Boolean
  $minTime: NaiveDatetime
  $isRecheckPage: Boolean!
) {
  viewer {
    event(id: $eventId) {
      media @include(if: $fetchAllPhotos) {
        ...MediaCollectionList_mediaCollectionMedia
      }
      mediaCollection(id: $categoryId) @include(if: $fetchCategory) {
        media {
          ...MediaCollectionList_mediaCollectionMedia
        }
        id
      }
      id
    }
    id
  }
}

fragment MediaCollectionList_mediaCollectionMedia on ExtendedMediaConnection {
  mediaList(first: $count, after: $cursor, afterShotDate: $minTime) {
    edges {
      node {
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    ...MediaList_media
  }
}

fragment MediaListItem_item on Media {
  id
  sgId
  name
  shotDate
  recheckStatus
  variants {
    name
    url
    id
  }
}

fragment MediaList_media on MediaConnection {
  edges {
    node {
      id
      sgId
      shotDate
      variants {
        name
        url
        id
      }
      recheckStatus
      recheckRequestedAt @include(if: $isRecheckPage)
      ...MediaListItem_item
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fetchCategory",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fetchAllPhotos",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "minTime",
    "type": "NaiveDatetime",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isRecheckPage",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "media",
    "storageKey": null,
    "args": null,
    "concreteType": "ExtendedMediaConnection",
    "plural": false,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "MediaCollectionList_mediaCollectionMedia",
        "args": null
      }
    ]
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "categoryId"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "afterShotDate",
    "variableName": "minTime"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "media",
  "storageKey": null,
  "args": null,
  "concreteType": "ExtendedMediaConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mediaList",
      "storageKey": null,
      "args": (v5/*: any*/),
      "concreteType": "MediaConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MediaEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Media",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sgId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "shotDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "variants",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MediaFile",
                  "plural": true,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url",
                      "args": null,
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "recheckStatus",
                  "args": null,
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "isRecheckPage",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "recheckRequestedAt",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedHandle",
      "alias": null,
      "name": "mediaList",
      "args": (v5/*: any*/),
      "handle": "connection",
      "key": "MediaCollectionList_mediaList",
      "filters": [
        "afterShotDate"
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MediaCollectionListQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "fetchAllPhotos",
                "selections": (v2/*: any*/)
              },
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "fetchCategory",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "mediaCollection",
                    "storageKey": null,
                    "args": (v3/*: any*/),
                    "concreteType": "MediaCollection",
                    "plural": false,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MediaCollectionListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "fetchAllPhotos",
                "selections": [
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "fetchCategory",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "mediaCollection",
                    "storageKey": null,
                    "args": (v3/*: any*/),
                    "concreteType": "MediaCollection",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MediaCollectionListQuery",
    "id": null,
    "text": "query MediaCollectionListQuery(\n  $eventId: ID!\n  $categoryId: ID!\n  $count: Int!\n  $cursor: String\n  $fetchCategory: Boolean\n  $fetchAllPhotos: Boolean\n  $minTime: NaiveDatetime\n  $isRecheckPage: Boolean!\n) {\n  viewer {\n    event(id: $eventId) {\n      media @include(if: $fetchAllPhotos) {\n        ...MediaCollectionList_mediaCollectionMedia\n      }\n      mediaCollection(id: $categoryId) @include(if: $fetchCategory) {\n        media {\n          ...MediaCollectionList_mediaCollectionMedia\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment MediaCollectionList_mediaCollectionMedia on ExtendedMediaConnection {\n  mediaList(first: $count, after: $cursor, afterShotDate: $minTime) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    ...MediaList_media\n  }\n}\n\nfragment MediaListItem_item on Media {\n  id\n  sgId\n  name\n  shotDate\n  recheckStatus\n  variants {\n    name\n    url\n    id\n  }\n}\n\nfragment MediaList_media on MediaConnection {\n  edges {\n    node {\n      id\n      sgId\n      shotDate\n      variants {\n        name\n        url\n        id\n      }\n      recheckStatus\n      recheckRequestedAt @include(if: $isRecheckPage)\n      ...MediaListItem_item\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03d9790b38b5688a07b4a4d58f8846c7';

module.exports = node;
