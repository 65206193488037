/**
 * @flow
 * @relayHash 44cdea62ad1409e0d24efef2211213d4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HomePage_viewer$ref = any;
export type HomePageQueryVariables = {|
  count: number,
  cursor?: ?string,
  query?: ?string,
  landingpage?: ?string,
  noLandingpage?: ?boolean,
|};
export type HomePageQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: HomePage_viewer$ref
  |}
|};
export type HomePageQuery = {|
  variables: HomePageQueryVariables,
  response: HomePageQueryResponse,
|};
*/


/*
query HomePageQuery(
  $count: Int!
  $cursor: String
  $query: String
  $landingpage: String
  $noLandingpage: Boolean
) {
  viewer(landingpage: $landingpage) {
    ...HomePage_viewer
    id
  }
}

fragment EventListItem_event on Event {
  id
  sgId
  banner
  beginsAt
  name
  mainSearch
}

fragment EventList_viewer on Viewer {
  events(first: $count, after: $cursor, query: $query, frontpage: true) {
    edges {
      node {
        id
        beginsAt
        sgId
        state
        subEvents {
          id
          sgId
        }
        ...EventListItem_event
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment HomePage_viewer on Viewer {
  config {
    frontendCarousel
  }
  landingpage @skip(if: $noLandingpage) {
    css
  }
  ...EventList_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "landingpage",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "noLandingpage",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "landingpage",
    "variableName": "landingpage"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "frontpage",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HomePageQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "HomePage_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomePageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "config",
            "storageKey": null,
            "args": null,
            "concreteType": "Config",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "frontendCarousel",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "events",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "beginsAt",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subEvents",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "banner",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "mainSearch",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "events",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "EventList_events",
            "filters": [
              "query",
              "frontpage"
            ]
          },
          (v3/*: any*/),
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "noLandingpage",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "landingpage",
                "storageKey": null,
                "args": null,
                "concreteType": "Landingpage",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "css",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "HomePageQuery",
    "id": null,
    "text": "query HomePageQuery(\n  $count: Int!\n  $cursor: String\n  $query: String\n  $landingpage: String\n  $noLandingpage: Boolean\n) {\n  viewer(landingpage: $landingpage) {\n    ...HomePage_viewer\n    id\n  }\n}\n\nfragment EventListItem_event on Event {\n  id\n  sgId\n  banner\n  beginsAt\n  name\n  mainSearch\n}\n\nfragment EventList_viewer on Viewer {\n  events(first: $count, after: $cursor, query: $query, frontpage: true) {\n    edges {\n      node {\n        id\n        beginsAt\n        sgId\n        state\n        subEvents {\n          id\n          sgId\n        }\n        ...EventListItem_event\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment HomePage_viewer on Viewer {\n  config {\n    frontendCarousel\n  }\n  landingpage @skip(if: $noLandingpage) {\n    css\n  }\n  ...EventList_viewer\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ccdb024bae6aefc87a8aa08c2351b0d';

module.exports = node;
