/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import EventCategorySearchPage from './EventCategorySearchPage';
import QueryHandler from '../components/misc/QueryHandler';

import { getCustomerKey } from '../utils/browser-storage';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const ALL_PHOTOS_ID = 'allphotos';

class EventCategorySearchPageQueryRenderer extends Component {
  constructor(props) {
    super(props);

    this.categoryIdFromUrl = props.match.params.categoryId;
    this.snFromUrl = props.match.params.startnumber;
    this.eventIdFromUrl = props.match.params.id;
    this.customerKey = getCustomerKey(this.eventIdFromUrl, this.snFromUrl);
  }

  render() {
    const { match, location, history } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const code2FA = queryParams.get('code');

    return (
      <QueryHandler
        query={graphql`
          query EventCategorySearchPageQueryRendererQuery(
            $eventId: ID!
            $language: String
            $startnumber: ID!
            $isRecheckPage: Boolean!
            $categoryId: ID!
            $count: Int!
            $cursor: String
            $secondFactor: String
            $noStartnumber: Boolean
            $fetchCategory: Boolean
            $fetchAllPhotos: Boolean
            $minTime: NaiveDatetime
            $individualPart: String
          ) {
            viewer {
              ...EventCategorySearchPage_viewer
            }
          }
        `}
        variables={{
          eventId: this.eventIdFromUrl,
          language: match.params.language,
          startnumber: this.snFromUrl !== undefined ? this.snFromUrl : '',
          categoryId: this.categoryIdFromUrl,
          count: MEDIA_FETCH_COUNT,
          secondFactor: code2FA || this.customerKey,
          noStartnumber: this.snFromUrl === undefined,
          fetchCategory: this.categoryIdFromUrl !== ALL_PHOTOS_ID,
          fetchAllPhotos: this.categoryIdFromUrl === ALL_PHOTOS_ID,
          minTime: null,
          individualPart: '',
          isRecheckPage: false,
        }}
        redirectTo={`/event/${this.eventIdFromUrl}`}
        render={(props) => {
          return (
            <EventCategorySearchPage
              viewer={props.viewer}
              eventIdFromUrl={match.params.id}
              snFromUrl={
                match.params.startnumber !== undefined ? match.params.startnumber : ''
              }
              categoryIdFromUrl={match.params.categoryId}
              customerKey={this.customerKey}
              codeSecondFactorForFaceEvent={code2FA}
              history={history}
            />
          );
        }}
      />
    );
  }
}

EventCategorySearchPageQueryRenderer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default EventCategorySearchPageQueryRenderer;
