/**
 * @flow
 * @relayHash 8970ec3105f4d9455dd66240445b42c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequestRechecksInput = {|
  email: string,
  locale?: ?string,
  mediaIds?: ?$ReadOnlyArray<string>,
  startnumber: string,
|};
export type RequestRechecksMutationVariables = {|
  input: RequestRechecksInput
|};
export type RequestRechecksMutationResponse = {|
  +requestRechecks: ?{|
    +status: ?string
  |}
|};
export type RequestRechecksMutation = {|
  variables: RequestRechecksMutationVariables,
  response: RequestRechecksMutationResponse,
|};
*/


/*
mutation RequestRechecksMutation(
  $input: RequestRechecksInput!
) {
  requestRechecks(input: $input) {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestRechecksInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "requestRechecks",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestRechecksPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestRechecksMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestRechecksMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestRechecksMutation",
    "id": null,
    "text": "mutation RequestRechecksMutation(\n  $input: RequestRechecksInput!\n) {\n  requestRechecks(input: $input) {\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf19818661fa9e0f2da88173109112df';

module.exports = node;
