import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import CartItem from './CartItem';
import { SinglePhotoMsg } from '../../messages';

import { THUMBNAIL } from '../../../utils/variables';
import { getPhotoVariant } from '../../../utils/common';

import { FormattedMessageWrappedInSpan } from '../../misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartLineItemSinglePhoto(props) {
  const { lineItem, ...other } = props;

  const thumbnailUrl = useMemo(() => {
    const variant = getPhotoVariant(lineItem.productParams.foto.variants, THUMBNAIL);
    return variant.url;
  }, [lineItem.productParams.foto.variants]);

  return (
    <CartItem
      eventNames={[lineItem.product.event.name]}
      icon={
        <div className="cart-item-icon-container cart-item-icon-container_flex col-xs-5 col-sm-6">
          <img src={thumbnailUrl} className="single-img" />
        </div>
      }
      lineItem={lineItem}
      subtitle={
        <FormattedMessageWrappedInSpan
          id="cartProductSingleFoto.subtitle"
          defaultMessage="Photo ID {photoID}"
          values={{ photoID: lineItem.productParams.foto.sgId }}
        />
      }
      title={<SinglePhotoMsg />}
      {...other}
    />
  );
}

CartLineItemSinglePhoto.propTypes = {
  lineItem: PropTypes.object,
};

export { CartLineItemSinglePhoto };

export default createFragmentContainer(CartLineItemSinglePhoto, {
  lineItem: graphql`
    fragment CartLineItemSinglePhoto_lineItem on LineItem {
      product {
        event {
          name
        }
      }
      productParams {
        ... on SingleFotoParams {
          foto {
            sgId
            variants {
              name
              url
            }
          }
        }
      }
    }
  `,
});
