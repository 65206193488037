/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StartNumberSearch_info$ref: FragmentReference;
declare export opaque type StartNumberSearch_info$fragmentType: StartNumberSearch_info$ref;
export type StartNumberSearch_info = {|
  +id: string,
  +gps: ?boolean,
  +mainSearch: ?string,
  +searchDual: ?boolean,
  +secondFactorEventSalt: ?string,
  +secondFactorType: ?{|
    +type: ?string
  |},
  +sgId: ?number,
  +hostResultUrl: ?string,
  +faceWebcamOnly: ?boolean,
  +$refType: StartNumberSearch_info$ref,
|};
export type StartNumberSearch_info$data = StartNumberSearch_info;
export type StartNumberSearch_info$key = {
  +$data?: StartNumberSearch_info$data,
  +$fragmentRefs: StartNumberSearch_info$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "StartNumberSearch_info",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "individualPart",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainSearch",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "searchDual",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "secondFactorEventSalt",
      "args": [
        {
          "kind": "Variable",
          "name": "individualPart",
          "variableName": "individualPart"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "secondFactorType",
      "storageKey": null,
      "args": null,
      "concreteType": "SecondFactorType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hostResultUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "faceWebcamOnly",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '87009d0110ee17e66f964436f35ae7ed';

module.exports = node;
