import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { Link } from 'react-router-dom';

import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

import CustomDate from '../../misc/CustomDate';
import { SINGLE_MEDIA_ACCOUNT_PAGE } from '../../../utils/variables';

const EventInfoCard = (props) => {
  const {
    eventInfo,
    bestOfAvailable,
    onExpandBestof,
    eventBannerActAsLink,
    handleScrollToOffer,
    shouldShowAdditionalOffer,
    startnumber,
    displayBestof,
    onUseNavigation,
  } = props;

  const startnumberCouldRedirerectToResults = Boolean(startnumber) && startnumber != SINGLE_MEDIA_ACCOUNT_PAGE;

  return (
    <div className="col-xs-16 col-sm-6 col-sm-offset-2">
      {onUseNavigation && (
        <div className="navigation-back event-info-card-back mb-15" onClick={onUseNavigation}>
          <KeyboardBackspaceRoundedIcon />
          <FormattedMessageWrappedInSpan
            id="eventInfoCard.backButton"
            defaultMessage="Back to events"
          />
        </div>
      )}
      <div className="event-info-card-container">
        <div className="event-info-card-banner">
          {eventBannerActAsLink && startnumberCouldRedirerectToResults && (
            <Link to={`/event/${eventInfo.sgId}/smartsearch/${startnumber}`}>
              <div
                className="absolute-cover"
                style={{ backgroundImage: `url(${eventInfo.banner})` }}
              />
            </Link>
          )}
          {eventBannerActAsLink && !startnumberCouldRedirerectToResults && (
            <Link to={`/event/${eventInfo.sgId}`}>
              <div
                className="absolute-cover"
                style={{ backgroundImage: `url(${eventInfo.banner})` }}
              />
            </Link>
          )}
          {!eventBannerActAsLink && (
            <div
              className="absolute-cover"
              style={{ backgroundImage: `url(${eventInfo.banner})` }}
            />
          )}
        </div>
        <div className="event-info-card-info">
          {eventBannerActAsLink && startnumberCouldRedirerectToResults && (
            <Link to={`/event/${eventInfo.sgId}/smartsearch/${startnumber}`}>
              <div className="event-info-card-name">{eventInfo.name}</div>
            </Link>
          )}
          {eventBannerActAsLink && !startnumberCouldRedirerectToResults && (
            <Link to={`/event/${eventInfo.sgId}`}>
              <div className="event-info-card-name">{eventInfo.name}</div>
            </Link>
          )}
          {!eventBannerActAsLink && (
            <div className="event-info-card-name">{eventInfo.name}</div>
          )}
          <div className="event-info-card-details">
            <p>
              <span>{eventInfo.location}</span>
              <CustomDate value={eventInfo.beginsAt} />
            </p>
            <a target="_blank" href={eventInfo.hostHomepageUrl}>
              {eventInfo.hostHomepageName}
            </a>
          </div>
          {bestOfAvailable && (
            <div
              className={`event-info-card-expand-bestof ${
                displayBestof && 'distinctive'
              }`}
              onClick={onExpandBestof}
            >
              <FormattedMessageWrappedInSpan
                id="eventInfoCard.bestOfLink"
                defaultMessage="Bestof-Gallery"
              />
              <span className="icon eye"></span>
            </div>
          )}
          {shouldShowAdditionalOffer && (
            <div className="event-info-card-extras-info" onClick={handleScrollToOffer}>
              <FormattedMessageWrappedInSpan
                id="eventInfoCard.availableExtras"
                defaultMessage="This event's <b>Foto-Flat</b> contains <span>cool extras</span> "
                values={{
                  span: (chunks) => (
                    <span className="extras-info-highlight">{chunks}</span>
                  ),
                  b: (chunks) => <b className="extras-info-fotoflat">{chunks}</b>,
                }}
              />
              <span className="icon eye" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EventInfoCard.propTypes = {
  eventInfo: PropTypes.object,
  bestOfAvailable: PropTypes.bool,
  eventBannerActAsLink: PropTypes.bool,
  onExpandBestof: PropTypes.func,
  handleScrollToOffer: PropTypes.func,
  shouldShowAdditionalOffer: PropTypes.bool,
  startnumber: PropTypes.string,
  displayBestof: PropTypes.bool,
  onUseNavigation: PropTypes.func,
};

export { EventInfoCard };

export default createFragmentContainer(EventInfoCard, {
  eventInfo: graphql`
    fragment EventInfoCard_eventInfo on Event {
      id
      sgId
      beginsAt
      banner
      hostHomepageName
      hostHomepageUrl
      location
      name
      predecessorEvent {
        name
      }
    }
  `,
});
