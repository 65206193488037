import React, { Component } from 'react';

import { NotificationDialogConsumer } from '../context/NotificationDialogContext';

import { getDisplayName } from './common';

function withNotificationDialogConsumer(WrappedComponent) {
  class WithNotificationDialogConsumer extends Component {
    render() {
      return (
        <NotificationDialogConsumer>
          {consumerValues => <WrappedComponent {...consumerValues} {...this.props} />}
        </NotificationDialogConsumer>
      );
    }
  }

  WithNotificationDialogConsumer.displayName = `WithNotificationDialogConsumer(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithNotificationDialogConsumer;
}

export default withNotificationDialogConsumer;
