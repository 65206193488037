/**
 * @flow
 * @relayHash 7cab2dea2b4c74e6209625cc673bbecd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetCookieSettingsInput = {|
  optIn: boolean,
  userId: string,
|};
export type SetCookieSettingsMutationVariables = {|
  input: SetCookieSettingsInput
|};
export type SetCookieSettingsMutationResponse = {|
  +setCookieSettings: ?{|
    +cookieSettings: ?{|
      +optIn: ?boolean,
      +userId: ?string,
    |}
  |}
|};
export type SetCookieSettingsMutation = {|
  variables: SetCookieSettingsMutationVariables,
  response: SetCookieSettingsMutationResponse,
|};
*/


/*
mutation SetCookieSettingsMutation(
  $input: SetCookieSettingsInput!
) {
  setCookieSettings(input: $input) {
    cookieSettings {
      optIn
      userId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetCookieSettingsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setCookieSettings",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetCookieSettingsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cookieSettings",
        "storageKey": null,
        "args": null,
        "concreteType": "CookieSettings",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "optIn",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SetCookieSettingsMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SetCookieSettingsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SetCookieSettingsMutation",
    "id": null,
    "text": "mutation SetCookieSettingsMutation(\n  $input: SetCookieSettingsInput!\n) {\n  setCookieSettings(input: $input) {\n    cookieSettings {\n      optIn\n      userId\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfa21bb3ec3d96cf0e116459a8188f82';

module.exports = node;
