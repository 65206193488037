import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { useWindowSize } from '../../../hooks';
import { customScrollToComponent } from '../../../utils/common';

import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

////////////////////////////////////////////
// TODO missing test cases: useRef and useEffect (1. maxHeight is not set if ref is empty case, 2. click outside when options are opened closes them, 3. opening options on small screen scrolls view up to top border of the tile)
////////////////////////////////////////////

const AccountDownloadTileButton = ({
  children,
  icon,
  textInSpan,
  onClick,
  notActive,
  hasOpenedOptions,
  hasOptions,
  onCloseOptions,
  isPending,
  error,
  hideQuestionMarkIcon,
  disabled,
}) => {
  const tileRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState();
  const { windowWidth } = useWindowSize();

  const handleClickOutside = (event) => {
    if (hasOptions && tileRef && !tileRef.current.contains(event.target)) {
      if (hasOpenedOptions) {
        onCloseOptions();
      }
    }
  };

  useEffect(() => {
    if (hasOptions) {
      if (hasOpenedOptions) {
        if (window.innerWidth < 768) {
          customScrollToComponent(tileRef.current, 'middle');
        }
      }

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [hasOpenedOptions]);

  useEffect(() => {
    if (tileRef.current) {
      setMaxHeight(tileRef.current.offsetWidth);
    }
  }, [tileRef, windowWidth]);

  return (
    <div
      className='account-download-item-container'
      ref={tileRef}
      style={{ maxHeight: maxHeight, zIndex: hasOpenedOptions && 10 }}
    >
      <button
        disabled={isPending || disabled}
        className={classNames('accont-download-btn', {
          'btn-no-shadow': hasOpenedOptions,
          'btn-error': error,
          'btn-loading': isPending,
          'btn-no-action': notActive,
        })}
        onClick={onClick}
      >
        {error && <ErrorRoundedIcon className="account-download-action-icon" />}
        {notActive && !hideQuestionMarkIcon && <HelpRoundedIcon className="account-download-action-icon" />}
        {icon}
        {isPending && (
          <span className="account-download-progress">
            <CircularProgress size={40} thickness={3} />
          </span>
        )}

        {error && (
          <FormattedMessageWrappedInSpan
            id="accountEventPage.products.downloadError"
            defaultMessage="Error. Try again"
          />
        )}
        {!error && !isPending && textInSpan}
      </button>
      {children}
    </div>
  );
};

AccountDownloadTileButton.defaultProps = {
  hideQuestionMarkIcon: false,
  disabled: false,
};


AccountDownloadTileButton.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  onClick: PropTypes.func,
  notActive: PropTypes.bool,
  children: PropTypes.array,
  hasOpenedOptions: PropTypes.bool,
  hasOptions: PropTypes.bool,
  onCloseOptions: PropTypes.func,
  isPending: PropTypes.bool,
  error: PropTypes.bool,
  hideQuestionMarkIcon: PropTypes.bool,
  disabled:PropTypes.bool
};

export default AccountDownloadTileButton;
