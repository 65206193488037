import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

//material ui
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import GDPRHint from '../GDPRHint';

import UploadGpxFileMutation from '../../../mutations/UploadGpxFileMutation';

import environment from '../../../environment';

const ExtendedSearchGpsSearchTab = (props) => {
  const { eventId, startnumber, onNavigateTo } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileExtensionError, setShowFileExtensionError] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [showSearchBtn, setShowSearchBtn] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChanged = (event) => {
    const selectedFile = event.target.files[0];

    // 'selectedFile' can be undefined if no file has been selected in the file manager
    if (selectedFile) {
      // Check if the file selected by the user is a "gpx" file)
      if (checkFileExtension(selectedFile.name, 'gpx')) {
        // The backend currently supports file uploads up to 20.000.000 bytes.
        if (selectedFile.size > 20000000) {
          setSelectedFile(null);
          setShowFileExtensionError(false);
          setShowFileSizeError(true);
          setShowSearchBtn(false);
          return;
        }
        setSelectedFile(selectedFile);
        setShowFileExtensionError(false);
        setShowFileSizeError(false);
        setShowSearchBtn(true);
      } else {
        setSelectedFile(null);
        setShowFileExtensionError(true);
        setShowFileSizeError(false);
        setShowSearchBtn(false);
      }
    }
  };

  const handleUploadGpxFile = () => {
    setIsUploading(true);
    UploadGpxFileMutation.commit(environment, selectedFile, (error, fileId) => {
      if (error) {
        setIsUploading(false);
      } else {
        setIsUploading(false);
        onNavigateTo(`/event/${eventId}/gps/${fileId}/${startnumber}`);
      }
    });
  };

  return (
    <div className="extended-search-gpssearch-container">
      <div>
        <h2>
          <FormattedMessageWrappedInSpan
            id="extendedSearchGpsSearchTab.ownSearch.title"
            defaultMessage="Search with your GPS data"
          />
        </h2>
        <p>
          <FormattedMessageWrappedInSpan
            id="extendedSearchGpsSearchTab.ownSearch.description"
            defaultMessage="Upload your GPX file and we will show your photos"
          />
        </p>
        <div>
          <Button classes={{ root: 'gps-search-upload-btn' }}>
            <AttachFileIcon />
            {selectedFile ? (
              <span>{selectedFile.name}</span>
            ) : (
              <FormattedMessageWrappedInSpan
                id="extendedSearchGpsSearchTab.ownSearch.uploadButton"
                defaultMessage="Choose a file"
              />
            )}
            <input type="file" onChange={handleFileChanged} />
          </Button>
        </div>
        {showFileExtensionError && (
          <p className="file-extension-error">
            <FormattedMessageWrappedInSpan
              id="extendedSearchGpsSearchTab.ownSearch.fileExtensionError"
              defaultMessage="The file should have the following extension: .gpx"
            />
          </p>
        )}
        {showFileSizeError && (
          <p className="file-extension-error">
            <FormattedMessageWrappedInSpan
              id="extendedSearchGpsSearchTab.ownSearch.fileSizeError"
              defaultMessage="The file is too big. We currently support file uploads up to 20 MB."
            />
          </p>
        )}
        {isUploading && <CircularProgress />}
        {!isUploading && showSearchBtn && (
          <button className="primary" onClick={handleUploadGpxFile}>
            <FormattedMessageWrappedInSpan
              id="extendedSearchGpsSearchTab.ownSearch.searchButton"
              defaultMessage="Search"
            />
          </button>
        )}
        <GDPRHint className="mt-30" />
      </div>
    </div>
  );
};

const checkFileExtension = (filename, extension) => {
  var regex = new RegExp('.' + extension + '$', 'ig');
  return regex.test(filename);
};

ExtendedSearchGpsSearchTab.propTypes = {
  eventId: PropTypes.number,
  startnumber: PropTypes.string,
  onNavigateTo: PropTypes.func,
};

export { ExtendedSearchGpsSearchTab };

export default ExtendedSearchGpsSearchTab;
