import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { Redirect, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

import EventOnlineView from '../components/content/event/EventOnlineView';
import Faq from '../components/content/Faq';
import LoadingView from '../components/content/LoadingView';
import MediaCollectionList from '../components/content/lists/MediaCollectionList';
import TimeSlider from '../components/content/extended-search/TimeSlider';
import withRequestHandler from '../components/hoc/withRequestHandler';
import { UserContext } from '../components/context/UserContext'

import { ALL_PHOTOS_CATEGORY, MAIN_SEARCH_FACE } from '../utils/variables';
import { getMainSearchResultUrl } from '../utils/common';
import { includesFotoFlat } from '../utils/common';
import { customScrollToComponent } from '../utils/common';

const EventCategorySearchPage = (props) => {
  const {
    isLoading,
    snFromUrl,
    categoryIdFromUrl,
    eventIdFromUrl,
    viewer,
    customerKey,
    history,
    codeSecondFactorForFaceEvent,
  } = props;
  const event = viewer.event;
  const { categoryId } = useParams();
  const [currentCategory, setCurrentCategory] = useState();
  const [extendedSearchSelectedTab, setExtendedSearchSelectedTab] = useState(1);
  const [extendedSearchExpand, setExtendedSearchExpand] = useState(true);
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [inputRangeValue, setInputRangeValue] = useState();
  const [datesAreSet, setDatesAreSet] = useState(false);
  const [isInputSet, setIsInputSet] = useState(false);
  const [lastValueForFetchedImages, setLastValueForFetchedImages] = useState();
  const [searchFormPosition, setSearchFormPosition] = useState(null);
  const { onMarathonFotoChange } = useContext(UserContext);
  const timeSliderRef = useRef();
  const mediaSectionRef = useRef();
  const eventOnlineViewRef = useRef();

  const participantMediaCount = event.participant
    ? event.participant.media.mediaInfo.count
    : 0;
  const eventHasSecondFactor = Boolean(event.secondFactorType?.type);

  useEffect(() => {
    onMarathonFotoChange(event.marathonFoto);
  }, []);

  useEffect(() => {
    setCurrentCategory(categoryIdFromUrl);
  }, [categoryId]);

  const setUpDates = () => {
    setDatesAreSet(false);
    let mediaInfo = null;

    if (event.media) {
      if (categoryIdFromUrl === ALL_PHOTOS_CATEGORY) {
        mediaInfo = event.media.mediaInfo;
      }
    } else if (viewer.event.mediaCollection !== null) {
      mediaInfo = viewer.event.mediaCollection.media.mediaInfo;
    } else {
      return <Redirect to={`/event/${event.sgId}`} />;
    }

    if (!isInputSet) {
      setMinValue(new Date(mediaInfo.shotDateMin).getTime());
      setMaxValue(new Date(mediaInfo.shotDateMax).getTime());
      setInputRangeValue({
        min: new Date(mediaInfo.shotDateMin).getTime(),
        max: new Date(mediaInfo.shotDateMax).getTime(),
      });
    }
    setDatesAreSet(true);
  };

  useEffect(() => {
    setUpDates();
  }, [event]);

  useEffect(() => {
    setIsInputSet(false);
    refetchMediaForNewCategory(categoryIdFromUrl);
    customScrollToComponent(timeSliderRef.current);
  }, [categoryIdFromUrl]);

  //componentDidMount in this case
  useEffect(() => {
    customScrollToComponent(timeSliderRef.current);
  }, [datesAreSet]);

  const handleExtendedSearchTabChanged = (event, selectedTab) => {
    setExtendedSearchExpand(true);
    setExtendedSearchSelectedTab(selectedTab);
  };

  const handleExtendedSearchExpand = () => {
    setExtendedSearchSelectedTab((prevSelectedTab) =>
      extendedSearchExpand ? 1 : prevSelectedTab
    );
    setExtendedSearchExpand((prevExpand) => !prevExpand);
  };

  const scrollToEventOnlineView = () => {
    customScrollToComponent(eventOnlineViewRef.current);
  };

  const refetchMediaForNewCategory = (newCategoryId) => {
    props.onRequestStart();
    props.relay.refetch(
      {
        categoryId: newCategoryId,
        fetchCategory: newCategoryId !== ALL_PHOTOS_CATEGORY,
        fetchAllPhotos: newCategoryId === ALL_PHOTOS_CATEGORY,
      },
      null,
      () => {
        props.onRequestStop();
      }
    );
  };

  const refetchMediaForNewTimeRange = (newMinValue, categoryToFetch) => {
    setLastValueForFetchedImages(newMinValue);
    props.onRequestStart();
    props.relay.refetch(
      {
        categoryId: categoryToFetch,
        fetchCategory: categoryToFetch !== ALL_PHOTOS_CATEGORY,
        fetchAllPhotos: categoryToFetch === ALL_PHOTOS_CATEGORY,
        minTime: new Date(new Date(newMinValue).setSeconds(0)).toISOString(),
      },
      null,
      () => {
        props.onRequestStop();
      }
    );
  };

  const debouncedRefetching = useCallback(
    debounce((value, categoryToFetch) => {
      refetchMediaForNewTimeRange(value, categoryToFetch);
    }, 1000),
    []
  );

  const handleInputRangeValueChanged = (event, value) => {
    setIsInputSet(true);
    setInputRangeValue({ min: value, max: maxValue });
  };

  const handleInputFetchForNewValue = () => {
    if (inputRangeValue.min != lastValueForFetchedImages) {
      debouncedRefetching(inputRangeValue.min, currentCategory);
      customScrollToComponent(mediaSectionRef.current);
    }
  };

  if (event.state === 'reminder' || !event.searchCollections) {
    return <Redirect to={`/event/${event.sgId}`} />;
  }

  if (categoryIdFromUrl === ALL_PHOTOS_CATEGORY && !event.searchAllPhotos) {
    // If the search for all photos of the event is disabled by the backend but the
    // user tries to navigate to this category via the URL, the user should be redirected
    // to event page
    return <Redirect to={`/event/${event.sgId}`} />;
  }

  const goBackToMainSearchResults = () => {
    const destinationUrl = getMainSearchResultUrl(
      event.sgId,
      snFromUrl,
      eventHasSecondFactor,
      customerKey
    );
    history.push(destinationUrl);
  };

  const liftingUpFormPositionData = (formPositionRef) => {
    setSearchFormPosition(formPositionRef);
  };

  const scrollToForm = () => {
    customScrollToComponent(searchFormPosition);
  };

  return (
    <div className="container-960 container-page" ref={eventOnlineViewRef}>
      <EventOnlineView
        onlineEvent={event}
        urlSn={snFromUrl}
        customerKey={customerKey}
        extendedSearchSelectedTab={extendedSearchSelectedTab}
        extendedSearchExpand={extendedSearchExpand}
        extendedSearchSelectedCategoryId={categoryIdFromUrl}
        hasSmartSearchTab={event.smartSearch && participantMediaCount !== 0}
        hasGpsSearchTab={event.gps}
        hasDualSearchTab={event.searchDual}
        onExtendedSearchTabChange={handleExtendedSearchTabChanged}
        onExtendedSearchExpand={handleExtendedSearchExpand}
        setFormRef={liftingUpFormPositionData}
        codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
        showStillProcessingHint={event.pendingMediaProcessing}
        goBackToMainSearchResults={goBackToMainSearchResults}
      />
      {extendedSearchSelectedTab === 1 && extendedSearchExpand && datesAreSet && (
        <div ref={timeSliderRef}>
          <TimeSlider
            minValue={minValue}
            maxValue={maxValue}
            value={inputRangeValue}
            onChange={handleInputRangeValueChanged}
            onChangeCommitted={handleInputFetchForNewValue}
          />
        </div>
      )}
      <div ref={mediaSectionRef}>
        {isLoading && <LoadingView />}
        {!isLoading && extendedSearchSelectedTab === 1 && (
          <MediaCollectionList
            eventHasSearchByFace={event.mainSEarch === MAIN_SEARCH_FACE}
            eventHasSecondFactor={eventHasSecondFactor}
            eventId={eventIdFromUrl}
            categoryId={categoryIdFromUrl}
            startnumber={snFromUrl}
            mediaCollectionMedia={event.media || event.mediaCollection.media}
            offer={event}
            onScrollToMainSearch={scrollToForm}
            goBackToMainSearchResults={goBackToMainSearchResults}
            eventHasFotoFlat={includesFotoFlat(event.products)}
          />
        )}
      </div>
      <Faq onFindMore={scrollToEventOnlineView} />
    </div>
  );
};

EventCategorySearchPage.propTypes = {
  customerKey: PropTypes.string,
  isLoading: PropTypes.bool,
  snFromUrl: PropTypes.string,
  categoryIdFromUrl: PropTypes.string,
  eventIdFromUrl: PropTypes.string,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  codeSecondFactorForFaceEvent: PropTypes.string,
  history: PropTypes.object,
};

export { EventCategorySearchPage };

export default createRefetchContainer(
  withRequestHandler(EventCategorySearchPage),
  {
    viewer: graphql`
      fragment EventCategorySearchPage_viewer on Viewer {
        event(id: $eventId, language: $language) {
          id
          sgId
          gps
          hostResultUrl
          products {
            type
          }
          pendingMediaProcessing

          media @include(if: $fetchAllPhotos) {
            mediaInfo {
              shotDateMax
              shotDateMin
            }
            ...MediaCollectionList_mediaCollectionMedia
              @arguments(fetchAllPhotos: $fetchAllPhotos, fetchCategory: $fetchCategory)
          }

          mediaCollection(id: $categoryId) @include(if: $fetchCategory) {
            id
            media {
              mediaInfo {
                shotDateMax
                shotDateMin
              }
              ...MediaCollectionList_mediaCollectionMedia
                @arguments(fetchAllPhotos: $fetchAllPhotos, fetchCategory: $fetchCategory)
            }
          }

          participant(startnumber: $startnumber, secondFactor: $secondFactor)
            @skip(if: $noStartnumber) {
            media {
              mediaInfo {
                count
              }
            }
            startnumber
          }
          searchAllPhotos
          secondFactorType {
            type
          }
          mainSearch
          searchDual
          smartSearch
          searchCollections
          state
          marathonFoto
          ...EventOnlineView_onlineEvent
          ...MediaCollectionList_offer
        }
      }
    `,
  },
  graphql`
    query EventCategorySearchPageRefetchQuery(
      $eventId: ID!
      $language: String
      $startnumber: ID!
      $categoryId: ID!
      $count: Int!
      $cursor: String
      $secondFactor: String
      $individualPart: String
      $noStartnumber: Boolean
      $fetchCategory: Boolean
      $fetchAllPhotos: Boolean
      $minTime: NaiveDatetime
      $isRecheckPage: Boolean!
    ) {
      viewer {
        ...EventCategorySearchPage_viewer
      }
    }
  `
);
