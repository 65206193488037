import React, { useState } from 'react';
import PropTypes from 'prop-types';

import environment from '../../../environment';
import GenerateVideoMutation from '../../../mutations/GenerateVideoMutation';
import { AccountVideoDownloadForm } from './AccountDownloadVideoForm';

import VideoNotReadyDialog from '../dialogs/VideoNotReadyDialog';
import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountDownloadTileVideo = ({
  icon,
  textInSpan,
  eventSgId,
  values,
  isFaceSearchEvent,
  notActive,
  intl,
}) => {
  const [error, setError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isVideoNotReadyDialogOpen, setIsVideoNotReadyDialogOpen] = useState(false);
  const [isVideoForFaceEventDialogOpen, setIsVideoForFaceEventDialogOpen] =
    useState(false);
  const [valueForAvailableZip, setValueForAvailableZip] = useState({
    id: values,
    url: null,
  });

  const download = (url) => {
    window.location.href = url;
  };

  const generateVideoDownloadLink = (startNumber) => {
    setIsPending(true);
    GenerateVideoMutation.commit(environment, eventSgId, startNumber, (error, url) => {
      if (!error) {
        setValueForAvailableZip((prevState) => ({
          ...prevState,
          url: url,
        }));
        download(url);
      } else {
        setError(true);
      }
      setIsPending(false);
    });
  };

  const defineMainAction = () => {
    if (notActive) {
      setIsVideoNotReadyDialogOpen(true);
      return;
    }
    if (isFaceSearchEvent) {
      setIsVideoForFaceEventDialogOpen(true);
      return;
    } else {
      handleButtonClicked();
      return;
    }
  };

  const handleButtonClicked = () => {
    if (valueForAvailableZip.url) {
      download(valueForAvailableZip.url);
    } else generateVideoDownloadLink(valueForAvailableZip.id);
  };

  return (
    <>
      <AccountDownloadTileButton
        icon={icon}
        textInSpan={textInSpan}
        onClick={() => {
          defineMainAction();
        }}
        isPending={isPending}
        error={error}
        notActive={notActive}
      />
      <VideoNotReadyDialog
        open={isVideoNotReadyDialogOpen}
        onDialogClose={() => setIsVideoNotReadyDialogOpen(false)}
      />
      <AccountVideoDownloadForm
        eventSgId={eventSgId}
        open={isVideoForFaceEventDialogOpen}
        onDialogClose={() => setIsVideoForFaceEventDialogOpen(false)}
        intl={intl}
      />
    </>
  );
};

AccountDownloadTileVideo.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  notActive: PropTypes.bool,
  eventSgId: PropTypes.number,
  values: PropTypes.string,
  isFaceSearchEvent: PropTypes.bool,
  intl: PropTypes.object,
};

export default AccountDownloadTileVideo;
