import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation RequestLoginMutation($input: RequestLoginInput!) {
    requestLogin(input: $input) {
      email
      status
    }
  }
`;

function commit(environment, email, inCheckout, language, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        inCheckout,
        language,
        provider: 'email',
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.requestLogin);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
