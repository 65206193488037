/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecheckMediaList_account$ref: FragmentReference;
declare export opaque type RecheckMediaList_account$fragmentType: RecheckMediaList_account$ref;
export type RecheckMediaList_account = {|
  +eventPurchases: ?{|
    +availableZips: ?$ReadOnlyArray<?string>
  |},
  +$refType: RecheckMediaList_account$ref,
|};
export type RecheckMediaList_account$data = RecheckMediaList_account;
export type RecheckMediaList_account$key = {
  +$data?: RecheckMediaList_account$data,
  +$fragmentRefs: RecheckMediaList_account$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RecheckMediaList_account",
  "type": "Account",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventPurchases",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "eventId",
          "variableName": "eventId"
        }
      ],
      "concreteType": "EventPurchases",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "availableZips",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0f37df95da06278f79637ccf880314f3';

module.exports = node;
