import React, { useState } from 'react';
import PropTypes from 'prop-types';

import environment from '../../../environment';
import RestartProductionMutation from '../../../mutations/RestartProductionMutation';
import { FormattedMessageWrappedInSpan } from '../../misc';
import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountRestartTile = ({ icon, textInSpan, eventSgId }) => {
  const [error, setError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isRestartRequestSent, setIsRestartRequestSent] = useState(false);

  const handleRestartUpload = () => {
    setIsPending(true);
    RestartProductionMutation.commit(environment, eventSgId, (error, status) => {
      if (!error) {
        setIsRestartRequestSent(true);
      } else {
        setError(true);
      }
      setIsPending(false);
    });
  };

  return (
    <AccountDownloadTileButton
      icon={icon}
      textInSpan={
        isRestartRequestSent ? (
          <FormattedMessageWrappedInSpan
            id="accountEventPage.accountRestartTile.requestSent"
            defaultMessage="Request sent"
          />
        ) : (
          textInSpan
        )
      }
      onClick={() => {
        handleRestartUpload();
      }}
      isPending={isPending}
      error={error}
      notActive={isRestartRequestSent || isPending}
    />
  );
};

AccountRestartTile.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  eventSgId: PropTypes.number,
};

export default AccountRestartTile;
