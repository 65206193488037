/**
 * @flow
 * @relayHash 438ec9d34d78cd3e53bf2e349e27df8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RainbowFlagQueryVariables = {|
  eventId: string
|};
export type RainbowFlagQueryResponse = {|
  +viewer: ?{|
    +event: ?{|
      +countryCode: ?string
    |}
  |}
|};
export type RainbowFlagQuery = {|
  variables: RainbowFlagQueryVariables,
  response: RainbowFlagQueryResponse,
|};
*/


/*
query RainbowFlagQuery(
  $eventId: ID!
) {
  viewer {
    event(id: $eventId) {
      countryCode
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "countryCode",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RainbowFlagQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RainbowFlagQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RainbowFlagQuery",
    "id": null,
    "text": "query RainbowFlagQuery(\n  $eventId: ID!\n) {\n  viewer {\n    event(id: $eventId) {\n      countryCode\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a3dd52afe26fe2294c76dfeb8221ed3';

module.exports = node;
