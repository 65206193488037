/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartAdditionalProductDvd_additionalProduct$ref = any;
type CartOverviewItem_lineItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartPage_viewer$ref: FragmentReference;
declare export opaque type CartPage_viewer$fragmentType: CartPage_viewer$ref;
export type CartPage_viewer = {|
  +cart: ?{|
    +order: ?{|
      +orderid: ?string
    |},
    +additionalProducts: ?$ReadOnlyArray<?{|
      +id: string,
      +type: ?string,
      +$fragmentRefs: CartAdditionalProductDvd_additionalProduct$ref,
    |}>,
    +id: string,
    +lineItems: ?$ReadOnlyArray<?{|
      +id: string,
      +product: ?{|
        +event: ?{|
          +name: ?string
        |},
        +type: ?string,
      |},
      +price: ?any,
      +productParams: ?{|
        +foto?: ?{|
          +sgId: ?number
        |},
        +startnumber?: ?string,
      |},
      +$fragmentRefs: CartOverviewItem_lineItem$ref,
    |}>,
    +totalPrice: ?any,
  |},
  +$refType: CartPage_viewer$ref,
|};
export type CartPage_viewer$data = CartPage_viewer;
export type CartPage_viewer$key = {
  +$data?: CartPage_viewer$data,
  +$fragmentRefs: CartPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CartPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cart",
      "storageKey": null,
      "args": null,
      "concreteType": "Cart",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "order",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "orderid",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "additionalProducts",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "CartAdditionalProductDvd_additionalProduct",
              "args": null
            }
          ]
        },
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lineItems",
          "storageKey": null,
          "args": null,
          "concreteType": "LineItem",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "product",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "event",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Event",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                (v1/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "productParams",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "type": "SingleFotoParams",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "foto",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Media",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "sgId",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "InlineFragment",
                  "type": "FotoFlatParams",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "startnumber",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "CartOverviewItem_lineItem",
              "args": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalPrice",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '92c6fb5ee080c685212f348566bed460';

module.exports = node;
