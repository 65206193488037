import { useState, useEffect } from 'react';
import emailSpellChecker from '@zootools/email-spell-checker';

const useEmailSpellChecking = (userEmailInput) => {
  const [suggestedEmail, setSuggestedEmail] = useState(null);

  useEffect(() => {
    const checkEmail = async () => {
      const suggestion = emailSpellChecker.run({
        email: userEmailInput,
      });

      if (suggestion) {
        setSuggestedEmail(suggestion.full);
      } else {
        setSuggestedEmail(null);
      }
    };

    if (userEmailInput && userEmailInput.includes('@') && userEmailInput.includes('.')) {
      checkEmail();
    }
  }, [userEmailInput]);

  return suggestedEmail;
};

export default useEmailSpellChecking;
