import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import FormattedMessageWrappedInSpan from './FormattedMessageWrappedInSpan';

const EmailSuggestionTooltip = ({ emailSuggestion, onAcceptSuggestion, children }) => {
  const [lastValidSuggestion, setLastValidSuggestion] = useState(null);

  useEffect(() => {
    if (emailSuggestion) {
      setLastValidSuggestion(emailSuggestion);
    }
  }, [emailSuggestion]);

  return (
    <Tooltip
      enterDelay={1000}
      interactive
      classes={{ tooltip: 'hoverable-tooltip' }}
      title={
        <div onClick={onAcceptSuggestion}>
          <FormattedMessageWrappedInSpan
            id="emailSuggestionTooltip.hint"
            defaultMessage="Did you mean: <b>{emailSuggestion}</b>?"
            values={{
              emailSuggestion: emailSuggestion || lastValidSuggestion,
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      }
      open={Boolean(emailSuggestion)}
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
};

EmailSuggestionTooltip.propTypes = {
  emailSuggestion: PropTypes.string,
  onAcceptSuggestion: PropTypes.func,
  children: PropTypes.node,
};

export default EmailSuggestionTooltip;
