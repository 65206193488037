import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessageWrappedInSpan } from '../../misc';
import classNames from 'classnames';

import CartItem from './CartItem';
import { FotoFlatMsg } from '../../messages';
import { getPhotoVariant } from '../../../utils/common';

import { MAIN_SEARCH_FACE, THUMBNAIL } from '../../../utils/variables';

function CartLineItemFotoFlat(props) {
  const { lineItem, ...other } = props;

  const imgUrls = useMemo(() => {
    const { edges } = lineItem.media.mediaList;
    const _imgUrls = [];
    let i = 0;
    while (i < 3) {
      const randomIndex = Math.floor(Math.random() * edges.length);
      const thumbnail = getPhotoVariant(edges[randomIndex].node.variants, THUMBNAIL);
      _imgUrls.push(thumbnail.url);
      i++;
    }
    return _imgUrls;
  }, [lineItem]);

  return (
    <CartItem
      eventNames={[lineItem.product.event.name]}
      icon={
        <div className="cart-item-icon-container foto-flat-icon col-sm-6 col-xs-5">
          {imgUrls.map((url, i) => (
            <div
              className={classNames('photo-container', {
                'photo-deck-middle-photo': i === 1,
                'photo-deck-top-photo': i === 2,
              })}
              key={i}
            >
              <img src={url} />
            </div>
          ))}
        </div>
      }
      lineItem={lineItem}
      subtitle={
        lineItem.product.event.mainSearch === MAIN_SEARCH_FACE ? (
          <FormattedMessageWrappedInSpan
            id="cartProductFotoFlat.searchByFace.subtitle"
            defaultMessage="All of your photos"
          />
        ) : (
          <FormattedMessageWrappedInSpan
            id="cartProductFotoFlat.subtitle"
            defaultMessage="All photos for bib number {startNumber}"
            values={{ startNumber: lineItem.productParams.startnumber }}
          />
        )
      }
      title={<FotoFlatMsg />}
      {...other}
    />
  );
}

CartLineItemFotoFlat.propTypes = {
  lineItem: PropTypes.object,
};

export { CartLineItemFotoFlat };

export default createFragmentContainer(CartLineItemFotoFlat, {
  lineItem: graphql`
    fragment CartLineItemFotoFlat_lineItem on LineItem {
      media {
        mediaList(first: 50) {
          edges {
            node {
              variants {
                name
                url
              }
            }
          }
        }
      }
      product {
        event {
          name
          mainSearch
        }
      }
      productParams {
        ... on FotoFlatParams {
          startnumber
        }
      }
    }
  `,
});
