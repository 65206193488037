import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LoadingView from '../content/LoadingView';

const LanguageContext = React.createContext();
const LanguageConsumer = LanguageContext.Consumer;

import { isLanguageSupported, getAppLanguage, getLocaleWithCountryCode } from '../../intl/languages';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/browser-storage';
import { getLanguageFromPath } from '../../utils/common';

class LanguageProvider extends Component {
  constructor(props) {
    super(props);

    this._configurePath(props.location.pathname, props.location.search);

    props.history.listen(location => {
      this._configurePath(location.pathname, location.search);
    });

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    // Prevent the component to update if the `pathLanguage` is not a supported
    // langugae.
    const nextPathLanguage = getLanguageFromPath(nextProps.location.pathname);
    if (!isLanguageSupported(nextPathLanguage)) {
      return false;
    }
    return true;
  }

  handleLanguageChange(newLanguage) {
    setToLocalStorage('language', newLanguage);
    const pathLanguage = getLanguageFromPath(location.pathname);
    const regEx = new RegExp(`${pathLanguage}\\b`, 'gi');
    // \b allows us to perform a "whole word only" search

    const newPath = window.location.href.replace(regEx, newLanguage);
    window.location.replace(newPath);
  }

  _configurePath(path, search) {
    const { history } = this.props;
    const prefUserLanguage = getAppLanguage();
    if (path === '/') {
      setToLocalStorage('language', prefUserLanguage);
      history.replace(`/${prefUserLanguage}${search}`);
    } else {
      // make sure that the path does not end on "/"
      const sanitizedPath = path.replace(/\/*$/gi, '');

      const languageFromPath = getLanguageFromPath(sanitizedPath);
      if (!isLanguageSupported(languageFromPath)) {
        setToLocalStorage('language', prefUserLanguage);
        // pathname is /:something and :something is neither a supported language
        // nor a valid route
        history.replace(`/${prefUserLanguage}${sanitizedPath}${search}`);
      } else {
        // only replace history if necessary
        if (path.charAt(path.length - 1) === '/') {
          history.replace(`${sanitizedPath}${search}`);
        }
        // Only update the local storage if the language in the URL is different from the
        // current one
        if (getFromLocalStorage('language') !== languageFromPath) {
          setToLocalStorage('language', languageFromPath);
        }
      }
    }
  }

  render() {
    const pathLanguage = getLanguageFromPath(this.props.location.pathname);
    const locale = getLocaleWithCountryCode(pathLanguage);
    // It is necessary to make sure that `pathLanguage` is a string
    // which represents a supported language. If it is an unsupported string (e.g. event,
    // anotherString) the child components will fail to render. The following statement
    // is only necessary for the first render. afterwards the shouldComponentUpdate
    // method decides if the component should be rendered or not.
    if (isLanguageSupported(pathLanguage)) {
      return (
        <LanguageContext.Provider
          value={{ language: pathLanguage, onLanguageChange: this.handleLanguageChange, locale: locale }}
        >
          {this.props.children}
        </LanguageContext.Provider>
      );
    }
    return <LoadingView />;
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

const WrappedComponent = withRouter(LanguageProvider);

export { WrappedComponent as LanguageProvider };
export { LanguageConsumer };
