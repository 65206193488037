import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../../misc';

import FormattedPrice from '../../../misc/FormattedPrice';

import {
  MAGIC_LINK_SENT_MSG,
  MAGIC_LINK_EXPLANATION_MSG,
  PHOTO_DVD_EXPLANATION_MSG,
  PHOTO_BOOK_MOBILE_MSG,
  PHOTO_BOOK_SHIPPING_CHARGES_MSG,
  FRESHDESK_PAYPAL_CANCELLED_MSG,
  VERIFY_EMAIL_NEXT_STEPS_MSG,
} from '../../../../utils/variables';

const NotificationDialogBody = ({ msg, msgType }) => {
  if (msg) {
    return <span>{msg}</span>;
  }
  switch (msgType) {
    case MAGIC_LINK_SENT_MSG:
      return _renderMagicLinkSentBody();
    case MAGIC_LINK_EXPLANATION_MSG:
      return _renderMagicLinkExplanationBody();
    case PHOTO_DVD_EXPLANATION_MSG:
      return _renderPhotoDvdExplanationBody();
    case PHOTO_BOOK_MOBILE_MSG:
      return _renderPhotoBookMobileBody();
    case PHOTO_BOOK_SHIPPING_CHARGES_MSG:
      return _renderPhotoBookShippingChargesBody();
    case FRESHDESK_PAYPAL_CANCELLED_MSG:
      return _renderFreshdeskPaypalCancelledBody();
    case VERIFY_EMAIL_NEXT_STEPS_MSG:
      return _renderVerifyEmailNextStepsBody();
  }
  return null;
};

function _renderMagicLinkSentBody() {
  return (
    <Fragment>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.magicLinkSent.title"
          defaultMessage="Magic Link Sent!"
        />
      </h1>
      <FormattedMessageWrappedInSpan
        id="notificationDialogBody.magicLinkSent.message"
        defaultMessage="We have sent you an email with the link."
      />
    </Fragment>
  );
}

function _renderMagicLinkExplanationBody() {
  return (
    <Fragment>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.magicLinkExplanation.title"
          defaultMessage="What is a magic link?"
        />
      </h1>
      <h2>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.magicLinkExplanation.subtitle"
          defaultMessage="Your email address is your identity!"
        />
      </h2>
      <FormattedMessageWrappedInSpan
        id="notificationDialogBody.magicLinkExplanation.description"
        defaultMessage="A magic link helps us to protect your account! Instead of a user name, password or an order number we use your email address to verify your identity. So it is easy and save. Just enter your email address and we will send you a link. By clicking the magic link you will be automatically signed-in to your account.{br}The link is only valid for a limited time."
        values={{
          br: <br />,
        }}
      />
    </Fragment>
  );
}

function _renderPhotoDvdExplanationBody() {
  return (
    <>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.photoDvdExplanation.title"
          defaultMessage="What is Photo-DVD?"
          className="mb-15"
        />
      </h1>
      <FormattedMessageWrappedInSpan
        id="notificationDialogBody.photoDvdExplanation.message"
        defaultMessage="The Photo-DVD contains all articles from your shopping cart. You have 3 days after ordering to change them (e.g. you can add more photos). The DVD is then produced and sent. We ship as a consignment of goods, this can sometimes take a while. Thank you for your patience."
      />
    </>
  );
}

function _renderPhotoBookMobileBody() {
  return (
    <>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.photoDvdExplanation.title"
          defaultMessage="What is Photo-DVD?"
          className="mb-15"
        />
      </h1>
      <FormattedMessageWrappedInSpan
        id="notificationDialogBody.photoBookMobile.message"
        defaultMessage="Please use a device with a larger screen (minimum width 960px) to design your personal myRacebook."
      />
    </>
  );
}

function _renderPhotoBookShippingChargesBody() {
  return (
    <>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.photoBookShippingCharges.title"
          defaultMessage="Shipping charges"
          className="mb-15"
        />
      </h1>
      <div className="ndb-photo-book-shipping-charges">
        <table>
          <tbody>
            <tr>
              <td>
                <FormattedMessageWrappedInSpan
                  id="notificationDialogBody.photoBookShippingCharges.message.germany"
                  defaultMessage="Germany"
                />
              </td>
              <td>
                <FormattedPrice price={{ amount: 470, currency: 'EUR' }} />
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessageWrappedInSpan
                  id="notificationDialogBody.photoBookShippingCharges.message.international"
                  defaultMessage="International"
                />
              </td>
              <td>
                <FormattedPrice price={{ amount: 950, currency: 'EUR' }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function _renderFreshdeskPaypalCancelledBody() {
  const acceptsCookies = localStorage.getItem('userAcceptsStatsCookies') == 'true';
  return (
    <>
      <h1>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.freshdeskPaypalCancelled.title"
          defaultMessage="Trouble with payment? Contact us"
          className="mb-15"
        />
      </h1>
      {acceptsCookies && (
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.freshdeskPaypalCancelled.message"
          defaultMessage="Problems with your payment? Please contact our support, if you need help."
        />
      )}
      {!acceptsCookies && (
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.freshdeskPaypalCancelled.messageNoCookie"
          defaultMessage="Problems with your payment? Please contact support@sportograf.com, if you need help."
        />
      )}
    </>
  );
}

function _renderVerifyEmailNextStepsBody() {
  return (
    <Fragment>
      <h2>
        <FormattedMessageWrappedInSpan
          id="notificationDialogBody.verifyEmailNextSteps.title"
          defaultMessage="Link sent"
        />
      </h2>
      <FormattedMessageWrappedInSpan
        id="notificationDialogBody.verifyEmailNextSteps.description"
        defaultMessage="Verification email has been sent. Please click the link in the email to verify and continue. If you don't get an email within 5 minutes, please retry.{br}{br}<b>The email address must be the same as the one you have registered with to the Event.</b>"
        values={{
          br: <br />,
          b: (chunks) => <b>{chunks}</b>,
        }}
      />
    </Fragment>
  );
}

NotificationDialogBody.propTypes = {
  msg: PropTypes.string.isRequired,
  msgType: PropTypes.string.isRequired,
};

export default NotificationDialogBody;
