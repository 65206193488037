/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MediaList_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecheckMediaList_offer$ref: FragmentReference;
declare export opaque type RecheckMediaList_offer$fragmentType: RecheckMediaList_offer$ref;
export type RecheckMediaList_offer = {|
  +$fragmentRefs: MediaList_offer$ref,
  +$refType: RecheckMediaList_offer$ref,
|};
export type RecheckMediaList_offer$data = RecheckMediaList_offer;
export type RecheckMediaList_offer$key = {
  +$data?: RecheckMediaList_offer$data,
  +$fragmentRefs: RecheckMediaList_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RecheckMediaList_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MediaList_offer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '726c28db135345faa4b47aa9b73173ab';

module.exports = node;
