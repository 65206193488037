import React, { useState, useEffect } from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import LanguageDropdown from './LanguageDropdown';
import RainbowFlag from './RainbowFlag';
import { getAppLanguage } from '../../intl/languages';
import { LanguageConsumer } from '../context/LanguageContext';
import NavigationMenuMobile from './NavigationMenuMobile';
import { UserConsumer } from '../context/UserContext';
import QueryHandler from '../misc/QueryHandler';
import FormattedMessageWrappedInSpan from '../misc/FormattedMessageWrappedInSpan';
import { trackEvent } from '../../utils/ga-tracking';
import isMobile from '../../utils/isMobile';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

////////////////////////////////////////////
// TODO MISSING TESTS
// TODO change Twitter (X) icon to regular MUI icon after React version update that enables MUI update
////////////////////////////////////////////

const SportografNavigationBar = (props) => {
  const { location, match } = props;
  const transparentNavigation =
    location.pathname === `/${match.params.language}` ||
    location.pathname === `/${match.params.language}/about`;

  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [mobileAccountExpanded, setMobileAccountExpanded] = useState(false);
  const [mobileLanguageExpanded, setMobileLanguageExpanded] = useState(false);
  const [shouldOrderBeCleared, setShouldOrderBeCleared] = useState(false);
  const [cartId, setCartId] = useState();

  const [showSubbar, setShowSubbar] = useState(true);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setShowSubbar(false);
      } else {
        setShowSubbar(true);
      }
      if (window.scrollY > 40) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMobileExpand = () => {
    if (mobileExpanded) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setMobileExpanded((prevState) => !prevState);
    setMobileAccountExpanded(false);
    setMobileLanguageExpanded(false);
  };

  const hideMobileMenu = () => {
    setMobileExpanded(false);
    setMobileAccountExpanded(false);
    setMobileLanguageExpanded(false);

    document.body.style.overflow = 'initial';
  };

  const handleMobileAccountDropdown = () => {
    if (mobileExpanded) {
      setMobileAccountExpanded((prevState) => !prevState);
    }
  };

  const handleMobileLanguageDropdown = () => {
    if (mobileExpanded) {
      setMobileLanguageExpanded((prevState) => !prevState);
    }
  };

  return (
    <LanguageConsumer>
      {({ language, onLanguageChange }) => (
        <UserConsumer>
          {({ isLoggedIn, onLoginChange }) => (
            <div
              className={classNames('navigation-bar-container-outer ', {
                'navigation-bar-transparent': transparentNavigation,
                'show-subbar': showSubbar,
                'sticky-nav': isSticky && !isMobile() && !transparentNavigation,
              })}
            >
              <RainbowFlag hideGradients={transparentNavigation} />
              <section
                className={classNames(
                  'navigation-bar-container',
                  'container-full-width',
                  'container-960',
                  {
                    'mobile-expanded': mobileExpanded,
                  }
                )}
              >
                <Link className="navigation-bar-logo" to="/">
                  <img src="/images/sg-logo-new-no-text.png" />
                  <span>SPORTOGRAF</span>
                </Link>
                <div className="navigation-bar-items-container">
                  <div
                    onClick={hideMobileMenu}
                    className="navigation-bar-item navigation-bar-cart-item"
                  >
                    <QueryHandler
                      hasLoadingIndicator={false}
                      query={graphql`
                        query SportografNavigationBarQuery {
                          viewer {
                            account {
                              profile {
                                email
                              }
                              rechecks {
                                eventId
                              }
                            }
                            cart {
                              customer {
                                email
                              }
                              lineItems {
                                id
                              }
                              order {
                                orderid
                              }
                              id
                            }
                          }
                        }
                      `}
                      render={(props) => {
                        const { cart, account } = props.viewer;
                        if (
                          cart?.order?.orderid &&
                          cart?.customer.email === account?.profile.email
                        ) {
                          setShouldOrderBeCleared(true);
                          setCartId(cart.id);
                        } else {
                          setShouldOrderBeCleared(false);
                        }
                        if (cart) {
                          return (
                            <Link
                              className="navigation-bar-checkout"
                              to="/cart"
                              onClick={() =>
                                trackEvent('Navigation', 'Checkout', 'Cart icon')
                              }
                            >
                              <span data-count={cart.lineItems.length} />
                            </Link>
                          );
                        }
                        return null;
                      }}
                      shouldHandleError={false}
                    />
                  </div>
                  <div className="navigation-bar-items">
                    <div className="navigation-bar-links">
                      <div
                        className="navigation-bar-item navigation-bar-option-item"
                        onClick={hideMobileMenu}
                      >
                        <Link className="navigation-bar-home" to="/:language">
                          <FormattedMessageWrappedInSpan
                            id="navigationBar.homeItem"
                            defaultMessage="Home"
                          />
                        </Link>
                      </div>
                      {!isLoggedIn && (
                        <div
                          className="navigation-bar-item navigation-bar-option-item"
                          onClick={hideMobileMenu}
                        >
                          <Link className="navigation-bar-login" to="/login">
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.account.loginItem"
                              defaultMessage="Login"
                            />
                          </Link>
                        </div>
                      )}
                      {isLoggedIn && (
                        <div
                          className={classNames(
                            'navigation-bar-item',
                            'navigation-bar-account',
                            'navigation-bar-option-item',
                            {
                              'dropdown-expanded': mobileAccountExpanded,
                            }
                          )}
                        >
                          <div
                            className="navigation-bar-dropdown-main"
                            onClick={handleMobileAccountDropdown}
                          >
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.accountItem"
                              defaultMessage="My Account"
                            />
                          </div>
                          <div className="navigation-bar-dropdown">
                            <div
                              className="navigation-bar-dropdown-item"
                              onClick={hideMobileMenu}
                            >
                              <Link to="/account/events">
                                <FormattedMessageWrappedInSpan
                                  id="navigationBar.account.myEventsItem"
                                  defaultMessage="My photos"
                                />
                              </Link>
                            </div>
                            <div
                              className="navigation-bar-dropdown-item"
                              onClick={hideMobileMenu}
                            >
                              <Link to="/account/orders">
                                <FormattedMessageWrappedInSpan
                                  id="navigationBar.account.myOrdersItem"
                                  defaultMessage="My invoices"
                                />
                              </Link>
                            </div>
                            <div
                              className="navigation-bar-dropdown-item"
                              onClick={hideMobileMenu}
                            >
                              <Link to="/account/rechecks">
                                <FormattedMessageWrappedInSpan
                                  id="navigationBar.account.myRechecksItem"
                                  defaultMessage="My rechecks"
                                />
                              </Link>
                            </div>
                            <div
                              className="navigation-bar-dropdown-item"
                              onClick={() => {
                                onLoginChange(false, null, shouldOrderBeCleared, cartId);
                                hideMobileMenu();
                              }}
                            >
                              <Link to="/">
                                <FormattedMessageWrappedInSpan
                                  id="navigationBar.account.logoutItem"
                                  defaultMessage="Logout"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="navigation-bar-item navigation-bar-option-item"
                        onClick={hideMobileMenu}
                      >
                        <Link className="navigation-bar-about" to="/about">
                          <FormattedMessageWrappedInSpan
                            id="navigationBar.aboutItem"
                            defaultMessage="About Us"
                          />
                        </Link>
                      </div>
                    </div>
                    <LanguageDropdown
                      language={language}
                      onLanguageChange={onLanguageChange}
                      handleMobileLanguageDropdown={handleMobileLanguageDropdown}
                      mobileLanguageExpanded={mobileLanguageExpanded}
                      hideMobileMenu={hideMobileMenu}
                    />
                    <div
                      className={classNames('navigation-subbar   container-full-width ', {
                        'navigation-subbar-transparent': transparentNavigation,
                        'show-subbar': showSubbar,
                      })}
                    >
                      <div className=" container-960">
                        <div className="navigation-subbar-social-icons">
                          <a
                            href="https://www.facebook.com/sportograf/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <FacebookIcon />
                          </a>
                          <a
                            href="https://www.instagram.com/sportograf/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <InstagramIcon />
                          </a>
                          <a
                            href="https://twitter.com/sportograf"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {/* X (Twitter) icon */}
                            <svg
                              class="MuiSvgIcon-root x-icon"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                            >
                              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                            </svg>
                          </a>
                        </div>
                        <div className="navigation-subbar-links">
                          <Link className="navigation-bar-subbar-link" to="/contact">
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.contactItem"
                              defaultMessage="Contact"
                            />
                          </Link>
                          <Link className="navigation-bar-subbar-link" to="/legal">
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.legalItem"
                              defaultMessage="Legal"
                            />
                          </Link>
                          <Link className="navigation-bar-subbar-link" to="/privacy">
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.privacyItem"
                              defaultMessage="Privacy"
                            />
                          </Link>
                          <Link className="navigation-bar-subbar-link" to="/agb">
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.termsItem"
                              defaultMessage="Terms and conditions"
                            />
                          </Link>
                          <a
                            href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/home`}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="navigation-bar-subbar-link"
                          >
                            <FormattedMessageWrappedInSpan
                              id="navigationBar.faq"
                              defaultMessage="FAQ"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <NavigationMenuMobile handleMobileExpand={handleMobileExpand} />
              </section>
            </div>
          )}
        </UserConsumer>
      )}
    </LanguageConsumer>
  );
};

SportografNavigationBar.propTypes = {
  viewer: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export { SportografNavigationBar };
export default withRouter(SportografNavigationBar);
