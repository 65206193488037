import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { trackEvent } from '../../../utils/ga-tracking';
import { getAppLanguage } from '../../../intl/languages';

////////////////////////////////////////////
// TODO missing tests - rendering problem
//Invariant Violation: `Relay(injectIntl(MediaListItem))` tried to render a context that was not valid this means that `Relay(injectIntl(MediaListItem))` was rendered outside of a query renderer.
////////////////////////////////////////////

const MediaListInfoTile = (props) => {
  const { participantMediaCount, onClick, type } = props;
  const [startAnimate, setStartAnimate] = useState(false);
  const animatedElementRef = useRef();

  const linkToFAQForAlternativeSearchHint = (
    <a
      href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/solutions/articles/77000265399`}
      target="_blank"
      rel="noopener noreferrer"
      className="link-blue-underline"
    >
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.alternativeSearchHint.link"
        defaultMessage="Learn more"
      />
    </a>
  );

  const handleScroll = useCallback(() => {
    if (
      animatedElementRef.current?.getBoundingClientRect().top +
        animatedElementRef.current?.parentNode?.offsetTop <=
      window.innerHeight + window.scrollY
    ) {
      setStartAnimate(true);
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (
      animatedElementRef.current?.getBoundingClientRect().top +
        animatedElementRef.current?.parentNode?.offsetTop <=
      window.innerHeight - 100
    ) {
      setStartAnimate(true);
    } else {
      window.addEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const morePhotosTexts = {
    header: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.morePhotos.heading"
        defaultMessage="More Photos"
      />
    ),
    description: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.morePhotos.description"
        defaultMessage="We found {count} personal photos, but you can search for more."
        values={{ count: participantMediaCount }}
      />
    ),
    buttonText: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.extendedSearchHintButton"
        defaultMessage="+ Are there more photos of me?"
      />
    ),
  };

  const recheckShowOrderTexts = {
    header: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOrder.heading"
        defaultMessage="What now?"
      />
    ),
    description: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOrder.description"
        defaultMessage="Approved images have been added to your Foto-Flat. Go to your order and download them all or click on the photo here, to download a single photo directly."
      />
    ),
    buttonText: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOrder.button"
        defaultMessage="Go to order"
      />
    ),
  };

  const recheckPendingTexts = {
    header: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckPending.heading"
        defaultMessage="We are on it"
      />
    ),
    description: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckPending.description"
        defaultMessage="After we reviewed your request, we will notify you by email. Please be patient."
      />
    ),
    buttonText: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckPending.button"
        defaultMessage="Learn more"
      />
    ),
  };

  const recheckShowOfferTexts = {
    header: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOffer.heading"
        defaultMessage="Foto-Flat"
      />
    ),
    description: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOffer.description"
        defaultMessage="offer"
      />
    ),
    buttonText: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.recheckOffer.button"
        defaultMessage="Show offer"
      />
    ),
  };

  const alternativeSearchHintTexts = {
    header: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.alternativeSearchHint.heading"
        defaultMessage="How to buy?"
      />
    ),
    description: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.alternativeSearchHint.description"
        defaultMessage="You are in an alternative search. {link}."
        values={{
          link: linkToFAQForAlternativeSearchHint,
        }}
      />
    ),
    additionalInstruction: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.alternativeSearchHint.descriptionMainSearchWasUsed"
        defaultMessage="Click below to go back to your personal Foto-Flat offer."
      />
    ),
    buttonText: (
      <FormattedMessageWrappedInSpan
        id="mediaListInfoTile.alternativeSearchHint.buttonToGoBackToPhotos"
        defaultMessage="Go to offer"
      />
    ),
  };

  const defineTileTexts = () => {
    switch (type) {
      case 'morePhotos':
        return morePhotosTexts;
      case 'alternativeSearchHint':
        return alternativeSearchHintTexts;
      case 'recheckShowOrder':
        return recheckShowOrderTexts;
      case 'recheckShowOffer':
        return recheckShowOfferTexts;
      case 'recheckPending':
        return recheckPendingTexts;
    }
  };

  return (
    <div
      ref={animatedElementRef}
      className={classNames('more-info-tile more-info-tile-animated', {
        'more-info-tile-animated_start': startAnimate,
      })}
    >
      <div className="more-info-tile-header">{defineTileTexts().header}</div>
      <div className="more-info-tile-body">
        <div className="more-info-tile-item">{defineTileTexts().description}</div>
        {defineTileTexts().additionalInstruction && (
          <div className="more-info-tile-item">
            {defineTileTexts().additionalInstruction}
          </div>
        )}
      </div>
      <div
        className={classNames('more-info-tile-button', {
          'more-info-tile-button_small': type === 'morePhotos',
        })}
        onClick={() => {
          if (type === 'morePhotos') {
            trackEvent('Event', 'Search', 'Are there more photos of me box');
          }
          onClick();
        }}
      >
        <div>{defineTileTexts().buttonText}</div>
      </div>
    </div>
  );
};

MediaListInfoTile.propTypes = {
  participantMediaCount: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    'morePhotos',
    'alternativeSearchHint',
    'recheckShowOrder',
    'recheckShowOffer',
    'recheckPending',
  ]),
};

export default MediaListInfoTile;
