import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation GenerateEventCertificateMutation($input: GenerateEventCertificateInput!) {
    generateEventCertificate(input: $input) {
      url
    }
  }
`;

function commit(environment, eventId, mediaNodeId, startnumber, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        eventId,
        media: mediaNodeId,
        startnumber,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.generateEventCertificate.url);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
