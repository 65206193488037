/* global MEDIA_FETCH_COUNT: true */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';

import MediaList from './MediaList';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const DualSearchMediaList = ({
  eventHasSearchByFace,
  eventHasSecondFactor,
  media,
  relay,
  offer,
  participantMediaCount,
  impressionMediaCount,
  startnumber,
  goBackToMainSearchResults,
  eventHasFotoFlat,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleLoadMore = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    relay.loadMore(MEDIA_FETCH_COUNT, () => setIsFetching(false));
  };

  return (
    <MediaList
      eventHasSearchByFace={eventHasSearchByFace}
      eventHasSecondFactor={eventHasSecondFactor}
      media={media.mediaList}
      hasMore={relay.hasMore()}
      isFetching={isFetching}
      onLoadMore={handleLoadMore}
      offer={offer}
      participantMediaCount={participantMediaCount}
      impressionMediaCount={impressionMediaCount}
      listType="dual"
      //to pass search startnumber for ff in the dual search view: startnumber={searchStartnumber && startnumber === undefined ? searchStartnumber : startnumber}
      startnumber={startnumber}
      goBackToMainSearchResults={goBackToMainSearchResults}
      eventHasFotoFlat={eventHasFotoFlat}
      //isDualMainSearchType={searchStartnumber && startnumber === undefined}
    />
  );
};

DualSearchMediaList.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  startnumber: PropTypes.string,
  searchStartnumber: PropTypes.string,
  media: PropTypes.object,
  offer: PropTypes.object,
  relay: PropTypes.object,
  goBackToMainSearchResults: PropTypes.func,
  eventHasFotoFlat: PropTypes.bool,
};

export { DualSearchMediaList };

export default createPaginationContainer(
  DualSearchMediaList,
  {
    media: graphql`
      fragment DualSearchMediaList_media on ExtendedMediaConnection {
        mediaList(first: $count, after: $cursor)
          @connection(key: "DualSearchMediaList_mediaList") {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          ...MediaList_media
        }
      }
    `,
    offer: graphql`
      fragment DualSearchMediaList_offer on Event {
        ...MediaList_offer
      }
    `,
  },
  {
    direction: 'forward',
    // Function that should indicate which connection to paginate over, given the fragment
    // props.
    getConnectionFromProps(props) {
      return props.media && props.media.mediaList;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        eventId: props.eventId,
        startnumber: props.startnumber !== undefined ? props.startnumber : '',
        searchStartnumber: props.searchStartnumber,
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        isRecheckPage: false,
      };
    },
    query: graphql`
      query DualSearchMediaListPaginationQuery(
        $eventId: ID!
        $startnumber: ID!
        $searchStartnumber: ID!
        $count: Int!
        $cursor: String
        $secondFactor: String
        $isRecheckPage: Boolean!
      ) {
        viewer {
          event(id: $eventId) {
            participant(startnumber: $startnumber, secondFactor: $secondFactor) {
              suggestionsByStartnumber(startnumber: $searchStartnumber) {
                mediaInfo {
                  count
                }
                ...DualSearchMediaList_media
              }
            }
          }
        }
      }
    `,
  }
);

