/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MediaList_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ParticipantMediaList_offer$ref: FragmentReference;
declare export opaque type ParticipantMediaList_offer$fragmentType: ParticipantMediaList_offer$ref;
export type ParticipantMediaList_offer = {|
  +$fragmentRefs: MediaList_offer$ref,
  +$refType: ParticipantMediaList_offer$ref,
|};
export type ParticipantMediaList_offer$data = ParticipantMediaList_offer;
export type ParticipantMediaList_offer$key = {
  +$data?: ParticipantMediaList_offer$data,
  +$fragmentRefs: ParticipantMediaList_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ParticipantMediaList_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MediaList_offer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '22ee11439ed316455cd9324f15200438';

module.exports = node;
