/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NoPhotosForOrderActions_eventInfo$ref: FragmentReference;
declare export opaque type NoPhotosForOrderActions_eventInfo$fragmentType: NoPhotosForOrderActions_eventInfo$ref;
export type NoPhotosForOrderActions_eventInfo = {|
  +name: ?string,
  +state: ?string,
  +sgId: ?number,
  +searchCollections: ?boolean,
  +gps: ?boolean,
  +pendingMediaProcessing: ?boolean,
  +secondFactorType: ?{|
    +type: ?string
  |},
  +$refType: NoPhotosForOrderActions_eventInfo$ref,
|};
export type NoPhotosForOrderActions_eventInfo$data = NoPhotosForOrderActions_eventInfo;
export type NoPhotosForOrderActions_eventInfo$key = {
  +$data?: NoPhotosForOrderActions_eventInfo$data,
  +$fragmentRefs: NoPhotosForOrderActions_eventInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NoPhotosForOrderActions_eventInfo",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "searchCollections",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "gps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingMediaProcessing",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "secondFactorType",
      "storageKey": null,
      "args": null,
      "concreteType": "SecondFactorType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1d94e26ca7b6230d264601ec2459a8c3';

module.exports = node;
