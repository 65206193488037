import React, {useEffect, useRef, createContext, useReducer} from 'react';
import {getAppLanguage} from '../../intl/languages';
import helpdesk_widget_intl from './helpdesk-widget-intl.json';
import isDev from "../../utils/DevDetect";

export const HelpdeskWidgetContext = createContext([[], () => {}]);

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

/* USAGE of the Helpdesk Widget Context
To set props for the widget, just use Context
<code>
  import { HelpdeskWidgetContext } from './HelpdeskWidget'

  export default MyComponent = () => {

    const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);

    useEffect(() => {
      hlpDskDispatch({type: 'eventName', payload: "MyEventName"})
    },[])

    return(
    ...
    );
  }
</code>
*/

const HelpdeskWidget = ({children}) => {

  const inputEl = useRef(null);

  const initialState = {
    eventName: "",
    eventId: "",
    url: "",
    history: "",
    status: "",
    name: "",
    email: ""
  }

  const reducer = (state, action) => {
    const _state = {...state}//, url: location.href}
    switch (action.type) {
      case 'eventName':
        return {..._state, eventName: action.payload};
      case 'eventId':
        return {..._state, eventId: action.payload};
      case 'url':
        return {..._state, url: action.payload, history: state.history + action.payload + "\n"};
      case 'addToHistory':
        return {..._state, history: state.history + action.payload + "\n"};
      case 'history':
        return {..._state, history: action.payload};
      case 'status':
        return {..._state, status: action.payload};
      case 'name':
        return {..._state, name: action.payload};
      case 'email':
        return {..._state, email: action.payload};
      case 'open':
        action.payload == true &&
          window.FreshworksWidget &&
          window.FreshworksWidget('open', 'ticketForm');
        return {..._state};
      case 'show':
        action.payload == true &&
          window.FreshworksWidget &&
          window.FreshworksWidget('show');
        action.payload == false &&
          window.FreshworksWidget &&
          window.FreshworksWidget('hide');

        return {..._state};
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (localStorage.getItem('userAcceptsStatsCookies') !== 'true') {
      return;
    }

    if (window.FreshworksWidget === undefined) {
      window.fwSettings = {
        'widget_id': 77000002600,
        'locale': helpdesk_widget_intl[getAppLanguage()] ? getAppLanguage() : 'en'
      };
      !function () {if ("function" != typeof window.FreshworksWidget) {var n = function () {n.q.push(arguments)}; n.q = [], window.FreshworksWidget = n} }()

      const script = document.createElement("script");
      script.src = "https://euc-widget.freshworks.com/widgets/77000002600.js";
      script.async = true;
      script.defer = true;
      inputEl.current.appendChild(script);
    }
  }, [])

  useEffect(() => {
    if (window.FreshworksWidget === undefined) {
      return;
    }

    window.FreshworksWidget('identify', 'ticketForm', {
      name: state.name,
      email: state.email,
    });

    window.FreshworksWidget('prefill', 'ticketForm', {
      custom_fields: {
        cf_event: state.eventName,
        cf_event_id: state.eventId,
        cf_history: state.history,
        cf_eventstatus: state.status,
      }
    });
    location.href !== state.url && dispatch({type: 'url', payload: location.href});
    window.FreshworksWidget("setLabels", helpdesk_widget_intl);
    !isDev() && window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_event', 'custom_fields.cf_event_id', 'custom_fields.cf_url', 'custom_fields.cf_eventstatus', 'custom_fields.cf_history']);
  })

  return (
    <>
      <div ref={inputEl}>
        {/* Script is inserted here */}
      </div>
      <HelpdeskWidgetContext.Provider value={[state, dispatch]}>
        {children}
      </HelpdeskWidgetContext.Provider>
    </>
  );
}

export default HelpdeskWidget;
