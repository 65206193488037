import React from 'react';
import PropTypes from 'prop-types';

import FormattedMessageWrappedInSpan from './FormattedMessageWrappedInSpan'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

// so far it works only for YOUTUBE and VIMEO, otherwise it returns DIV with an information about an error
const EmbeddedVideo = (props) => {
  const { videoUrl } = props;
  const isYouTubeLink = /youtube|youtu\.be/.test(videoUrl);
  const isVimeoLink = /vimeo/.test(videoUrl);

  if (isVimeoLink) {
    const videoId = /([^\/]+$)/.exec(videoUrl);
    return (
      <>
        <div className="embedded-video">
          <iframe
            src={`https://player.vimeo.com/video/${videoId[0]}?h=bca8b328e2&title=0&byline=0&portrait=0`}
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </>
    );
  } else if (isYouTubeLink) {
    let videoId = '';
    if (/youtube/.test(videoUrl)) {
    const videoIdFromLong = /(\/watch\?v=)([^\n\r]*)/.exec(videoUrl);
    videoId = videoIdFromLong[2];
    } else if (/youtu\.be/.test(videoUrl)) {
      const videoIdFromShort = /([^\/]+$)/.exec(videoUrl);
      videoId = videoIdFromShort[1];
        }
    return (
      <div className="embedded-video">
        <iframe src={`https://www.youtube.com/embed/${videoId}`}></iframe>
      </div>
    );
  } else {
    return (
      <div className="embedded-video error">
        <FormattedMessageWrappedInSpan
          id="eventSnSearchPage.video.error"
          defaultMessage="{error} We're sorry. The video could not be loaded"
          values={{ error: <ErrorRoundedIcon /> }}
        />
      </div>
    );
  }
};

EmbeddedVideo.propTypes = {
  videoUrl: PropTypes.string,
};

export default EmbeddedVideo;
