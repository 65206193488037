/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartLineItemSinglePhoto_lineItem$ref: FragmentReference;
declare export opaque type CartLineItemSinglePhoto_lineItem$fragmentType: CartLineItemSinglePhoto_lineItem$ref;
export type CartLineItemSinglePhoto_lineItem = {|
  +product: ?{|
    +event: ?{|
      +name: ?string
    |}
  |},
  +productParams: ?{|
    +foto?: ?{|
      +sgId: ?number,
      +variants: ?$ReadOnlyArray<?{|
        +name: ?string,
        +url: ?string,
      |}>,
    |}
  |},
  +$refType: CartLineItemSinglePhoto_lineItem$ref,
|};
export type CartLineItemSinglePhoto_lineItem$data = CartLineItemSinglePhoto_lineItem;
export type CartLineItemSinglePhoto_lineItem$key = {
  +$data?: CartLineItemSinglePhoto_lineItem$data,
  +$fragmentRefs: CartLineItemSinglePhoto_lineItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CartLineItemSinglePhoto_lineItem",
  "type": "LineItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productParams",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "SingleFotoParams",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "foto",
              "storageKey": null,
              "args": null,
              "concreteType": "Media",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sgId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "variants",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MediaFile",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "url",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '75d0de122720010d414cffcf81e350cd';

module.exports = node;
