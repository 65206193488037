/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BookPreviewPage_viewer$ref: FragmentReference;
declare export opaque type BookPreviewPage_viewer$fragmentType: BookPreviewPage_viewer$ref;
export type BookPreviewPage_viewer = {|
  +account: ?{|
    +boughtEvent: ?{|
      +fotoBookTemplate: ?string,
      +name: ?string,
      +products: ?$ReadOnlyArray<?{|
        +configuration: ?{|
          +variants?: ?{|
            +languages: ?$ReadOnlyArray<?string>
          |}
        |},
        +id: string,
        +price: ?any,
        +type: ?string,
      |}>,
      +sgId: ?number,
    |},
    +eventPurchases: ?{|
      +bonusMedia: ?{|
        +mediaList: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +sgId: ?number,
              +variants: ?$ReadOnlyArray<?{|
                +format: ?string,
                +name: ?string,
                +url: ?string,
              |}>,
            |}
          |}>
        |}
      |},
      +participants: ?$ReadOnlyArray<?{|
        +media: ?{|
          +mediaList: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +sgId: ?number,
                +variants: ?$ReadOnlyArray<?{|
                  +format: ?string,
                  +name: ?string,
                  +url: ?string,
                |}>,
              |}
            |}>
          |}
        |}
      |}>,
      +singleMedia: ?{|
        +mediaList: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +sgId: ?number,
              +variants: ?$ReadOnlyArray<?{|
                +format: ?string,
                +name: ?string,
                +url: ?string,
              |}>,
            |}
          |}>
        |}
      |},
    |},
  |},
  +$refType: BookPreviewPage_viewer$ref,
|};
export type BookPreviewPage_viewer$data = BookPreviewPage_viewer;
export type BookPreviewPage_viewer$key = {
  +$data?: BookPreviewPage_viewer$data,
  +$fragmentRefs: BookPreviewPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaList",
    "storageKey": "mediaList(first:1000)",
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1000
      }
    ],
    "concreteType": "MediaConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Media",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "variants",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaFile",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "format",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "BookPreviewPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "boughtEvent",
          "storageKey": null,
          "args": (v0/*: any*/),
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fotoBookTemplate",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "products",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "configuration",
                  "storageKey": null,
                  "args": null,
                  "concreteType": null,
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "type": "FotoBookConfiguration",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "variants",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "FotoBookVariants",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "languages",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "price",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            (v3/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "eventPurchases",
          "storageKey": null,
          "args": (v0/*: any*/),
          "concreteType": "EventPurchases",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "bonusMedia",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": (v4/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "participants",
              "storageKey": null,
              "args": null,
              "concreteType": "BoughtParticipant",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "media",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExtendedMediaConnection",
                  "plural": false,
                  "selections": (v4/*: any*/)
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "singleMedia",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": (v4/*: any*/)
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9616473c27a08828b1c43ed2c58e1dd7';

module.exports = node;
