import { useState } from 'react';

// ['lineOne', 'lineTwo', 'lineThree', 'lineFour']
//

// Simple Form Hook used for creating Sportograf DVD labels
function useSimpleForm(formFields) {
  const initalState = {};

  formFields.forEach(f => {
    initalState[f.name] = f.initialValue || '';
  });

  const [fields, setFields] = useState(initalState);

  function handleFieldValueChange(event) {
    event.persist();
    setFields(fields => ({
      ...fields,
      [event.target.name]: event.target.value,
    }));
  }

  return [fields, handleFieldValueChange];
}

export default useSimpleForm;
