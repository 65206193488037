import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../misc';

const InvalidFaceFileInformation = () => {
  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="notification-info">
          <h2>
            <FormattedMessageWrappedInSpan
              id="invalidFaceFileInformation.message"
              defaultMessage="We could not find any photos with the photo you provided. Please note that you will only see pictures that are not already tagged with your bib number. Also make sure a single face is clearly visible in the photo and you're searching in the correct EVENT."
            />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default InvalidFaceFileInformation;
