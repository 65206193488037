import React, { Component, Fragment } from 'react';

import ErrorDialog from '../content/dialogs/ErrorDialog';

import { getDisplayName } from './common';

import environment from '../../environment';
import RequestLoginMutation from '../../mutations/RequestLoginMutation';
import RequestLoginWhenExpiredMutation from '../../mutations/RequestLoginWhenExpiredMutation';

import { setToLocalStorage } from '../../utils/browser-storage';

function withRequestHandler(WrappedComponent) {
  class WithRequestHandler extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        error: null,
        showErrorDialog: false,
        showLoadingDialog: false,
        newLinkSent: false,
        isSendingNewLink: false,
      };

      this.handleRequestStart = this.handleRequestStart.bind(this);
      this.handleRequestStop = this.handleRequestStop.bind(this);
      this.handleRequestError = this.handleRequestError.bind(this);
      this.handleRequestLogin = this.handleRequestLogin.bind(this);
      this.handleRequestMLResend = this.handleRequestMLResend.bind(this);
    }

    handleRequestStart() {
      // Only update state if necessary
      if (!this.state.isLoading) {
        this.setState({ isLoading: true });
      }
    }

    handleRequestStop() {
      // Only update state if necessary
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
    }

    handleRequestError(error) {
      this.setState({ isLoading: false, showErrorDialog: true, error });
    }

    handleRequestLogin(email) {
      this.setState({ isSendingNewLink: true });
      setToLocalStorage('email', email);
      this.handleRequestStart();
      RequestLoginMutation.commit(
        environment,
        email,
        false,
        this.props.match.params.language,
        (error, res) => {
          this.setState({ isSendingNewLink: false });
          if (error) {
            this.handleRequestError(error);
          } else {
            this.setState({ newLinkSent: true });
            this.handleRequestStop();
          }
        }
      );
    }

    handleRequestMLResend(token) {
      this.setState({ isSendingNewLink: true });
      this.handleRequestStart();
      RequestLoginWhenExpiredMutation.commit(
        environment,
        token,
        this.props.match.params.language,
        (error, res) => {
          this.setState({ isSendingNewLink: false });
          if (error) {
            this.handleRequestError(error);
          } else {
            this.setState({ newLinkSent: true });
            this.handleRequestStop();
          }
        }
      );
    }

    render() {
      const { isLoading, error, showErrorDialog } = this.state;
      return (
        <Fragment>
          <WrappedComponent
            isLoading={isLoading}
            onRequestStart={this.handleRequestStart}
            onRequestStop={this.handleRequestStop}
            onRequestError={this.handleRequestError}
            {...this.props}
          />
          <ErrorDialog
            open={showErrorDialog}
            error={error}
            onRequestClose={() => this.setState({ showErrorDialog: false, error: null })}
            onResendLink={this.handleRequestMLResend}
            newLinkSent={this.state.newLinkSent}
            isSendingNewLink={this.state.isSendingNewLink}
          />
        </Fragment>
      );
    }
  }

  WithRequestHandler.displayName = `WithRequestHandler(${getDisplayName(
    WrappedComponent
  )})`;

  return WithRequestHandler;
}

export default withRequestHandler;
