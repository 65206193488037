import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import { FormattedMessageWrappedInSpan } from '../../misc';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import FindReplaceRoundedIcon from '@material-ui/icons/FindReplaceRounded';
import WrapTextRoundedIcon from '@material-ui/icons/WrapTextRounded';

////////////////////////////////////////////
// TODO MISSING TEST CASE: Swiper: interactive behaviour with useEffect+useRef
////////////////////////////////////////////

export default function SecondFactorGuardInfoCarousel(props) {
  const swiperRef = useRef();
  let settings = {
    spaceBetween: 20,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    modules: [Autoplay, Pagination],
    pagination: {
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
  };

  useEffect(() => {
    swiperRef?.current?.swiper?.autoplay.stop();
  }, []);

  useEffect(() => {
    if (props.shouldRunAutoplay) {
      swiperRef?.current?.swiper?.autoplay.start();
    } else {
      swiperRef?.current?.swiper?.autoplay.stop();
    }
  }, [props.shouldRunAutoplay]);

  return (
    <Swiper {...settings} ref={swiperRef}>
      <SwiperSlide>
        <div className="second-factor-guard-details-cell">
          <WrapTextRoundedIcon />
          <FormattedMessageWrappedInSpan
            className="second-factor-guard-details-subtitle"
            id="secondFactorGuard.details.firstStep"
            defaultMessage="The organizer has provided us with an anonymized list of email addresses."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="second-factor-guard-details-cell">
          <VpnKeyRoundedIcon />
          <FormattedMessageWrappedInSpan
            className="second-factor-guard-details-subtitle"
            id="secondFactorGuard.details.secondStep"
            defaultMessage="The email you input below will be locally encrypted in your browser in the same manner."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="second-factor-guard-details-cell">
          <FindReplaceRoundedIcon />
          <FormattedMessageWrappedInSpan
            className="second-factor-guard-details-subtitle"
            id="secondFactorGuard.details.thirdStep"
            defaultMessage="Only when the data matches, we will send you an email with a link to access the photos."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

SecondFactorGuardInfoCarousel.propTypes = {
  shouldRunAutoplay: PropTypes.bool,
};
