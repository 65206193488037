import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessageWrappedInSpan } from '../components/misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartEmptyPage(props) {

  let initCount = 5;
  const [countDown, setCountDown] = useState(initCount);

  useEffect(() => {
    let interval = setInterval(() => {
      if(initCount >= 0)
        setCountDown(initCount--);
    }, 1000);
    const timer = setTimeout(() => {
      clearInterval(interval);
      props.history.push('/');
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-960">
      <div className="cart-page-content">
        <div className="row">
          <div className="col-xs-16 col-sm-14 col-sm-offset-1">
            <h1 className="cart-page-header">
              <FormattedMessageWrappedInSpan
                id="cartEmptyPage.title"
                defaultMessage="Your cart is empty."
              />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-6 col-sm-offset-1">
            <div className="cart-empty-page-link-container">
              <Link to="/">
                <FormattedMessageWrappedInSpan
                  id="cartEmptyPage.continueLinkCountDown"
                  defaultMessage="Continue Shopping. Redirecting in {count}..."
                  values={{ count: countDown }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartEmptyPage;
