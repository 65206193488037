import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';

const VideoNotReadyDialog = ({ open, onDialogClose }) => {
  return (
    <CustomDialog open={open} onRequestClose={onDialogClose}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="accountEventPage.videoNotReadyDialog.title"
                defaultMessage="We're on it"
              />
            </h1>
            <FormattedMessageWrappedInSpan
              id="accountEventPage.videoNotReadyDialog.instructionText"
              defaultMessage="We're still processing your video. Please try again later."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button className="accept" onClick={onDialogClose}>
              <FormattedMessageWrappedInSpan
                id="searchInstructionsDialog.acceptButton"
                defaultMessage="OK"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

VideoNotReadyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func,
};

export default VideoNotReadyDialog;
