import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import EventTwoStepsListItem from './EventTwoStepsListItem';
import QueryHandler from '../../misc/QueryHandler';

const EventTwoStepsListItemQueryRenderer = (props) => {
  const { eventId, event, itemHeight, setIsLoading } = props;

  return (
    <QueryHandler
      query={graphql`
        query EventTwoStepsListItemQueryRendererQuery($eventId: ID!) {
          viewer {
            ...EventTwoStepsListItem_viewer
          }
        }
      `}
      variables={{
        eventId: eventId,
      }}
      render={(props) => {
        return (
          <EventTwoStepsListItem viewer={props.viewer} event={event} eventId={eventId} itemHeight={itemHeight} setIsLoading={setIsLoading}/>
        );
      }}
    />
  );
};

EventTwoStepsListItemQueryRenderer.propTypes = {
  eventId: PropTypes.string,
  viewer: PropTypes.object,
  event: PropTypes.object,
  itemHeight: PropTypes.number,
  setIsLoading: PropTypes.func,
};

export default EventTwoStepsListItemQueryRenderer;
