import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import CartItem from './CartItem';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartAdditionalPorduct(props) {
  const { additionalProduct, eventNames, ...other } = props;
  return (
    <CartItem
      finalPrice={additionalProduct.price}
      hasAddToCartOption={true}
      hasDeleteOption={false}
      eventNames={eventNames}
      {...other}
    />
  );
}

CartAdditionalPorduct.propTypes = {
  additionalProduct: PropTypes.object,
  eventNames: PropTypes.object,
};

export { CartAdditionalPorduct };

export default createFragmentContainer(CartAdditionalPorduct, {
  additionalProduct: graphql`
    fragment CartAdditionalProduct_additionalProduct on Product {
      price
    }
  `,
});
