import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import EventOnlineView from '../components/content/event/EventOnlineView';
import Faq from '../components/content/Faq';
import InsufficientResultsInformation from '../components/content/informations/InsufficientResultsInformation';
import SuggestionsMediaList from '../components/content/lists/SuggestionsMediaList';

import { getMainSearchResultUrl } from '../utils/common';
import { includesFotoFlat } from '../utils/common';
import { UserContext } from '../components/context/UserContext';
import { MAIN_SEARCH_BIB, MAIN_SEARCH_FACE } from '../utils/variables';
import { customScrollToComponent } from '../utils/common';

////////////////////////////////////////////
// TODO MISSING CASES:
// setting up refs
////////////////////////////////////////////

class EventSmartSearchPage extends Component {
  constructor(props) {
    super(props);

    const { participant } = props.viewer.event;
    const count = participant ? participant.suggestions.mediaInfo.count : 0;

    this.state = {
      extendedSearchSelectedTab: count === 0 ? 1 : 0,
      extendedSearchExpand: count !== 0,
    };
  }

  static contextType = UserContext;

  componentDidMount() {
    const { onMarathonFotoChange } = this.context;
    if (onMarathonFotoChange) {
      onMarathonFotoChange(this.props.viewer.event.marathonFoto);
    }
    this._scrollToMediaSection();
  }

  handleExtendedSearchTabChanged = (event, value) => {
    this.setState({
      extendedSearchSelectedTab: value,
      extendedSearchExpand: true,
    });
  };

  handleExtendedSearchExpand = () => {
    this.setState((prevState) => {
      const {
        extendedSearchExpand: prevExpand,
        extendedSearchSelectedTab: prevSelectedTab,
      } = prevState;

      return {
        extendedSearchExpand: !prevExpand,
        // if the extended search changes from open to close it is important to reset
        // the selected tab to its default. Otherwise the media list will not be rendered.
        extendedSearchSelectedTab: prevExpand ? 0 : prevSelectedTab,
      };
    });
  };

  scrollToEventOnlineView(tabIndex) {
    customScrollToComponent(this.eventOnlineView);
    this.setState({
      extendedSearchExpand: true,
      extendedSearchSelectedTab: tabIndex,
    });
  }

  _scrollToMediaSection() {
    if (this.mediaSection) {
      customScrollToComponent(this.mediaSection);
    }
  }

  render() {
    const { customerKey, viewer, snFromUrl, history, codeSecondFactorForFaceEvent } =
      this.props;
    const { event } = viewer;
    const eventHasSecondFactor = Boolean(event.secondFactorType?.type);

    if (event.state === 'reminder') {
      return <Redirect to={`/event/${event.sgId}`} />;
    }

    const { extendedSearchSelectedTab, extendedSearchExpand } = this.state;

    const participantMediaCount = event.participant
      ? event.participant.media.mediaInfo.count
      : 0;

    const goBackToMainSearchResults = () => {
      const destinationUrl = getMainSearchResultUrl(
        event.sgId,
        snFromUrl,
        eventHasSecondFactor,
        customerKey
      );
      history.push(destinationUrl);
    };

    if (!event.smartSearch) {
      return <Redirect to={`/event/${event.sgId}`} />;
    }

    return (
      <div className="container-960 container-page">
        <EventOnlineView
          customerKey={customerKey}
          ref={(view) => (this.eventOnlineView = view)}
          onlineEvent={event}
          urlSn={snFromUrl}
          extendedSearchSelectedTab={extendedSearchSelectedTab}
          extendedSearchExpand={extendedSearchExpand}
          hasSmartSearchTab={event.smartSearch && participantMediaCount !== 0}
          hasGpsSearchTab={event.gps}
          hasDualSearchTab={event.searchDual}
          onExtendedSearchTabChange={this.handleExtendedSearchTabChanged}
          onExtendedSearchExpand={this.handleExtendedSearchExpand}
          codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
          showStillProcessingHint={event.pendingMediaProcessing}
          goBackToMainSearchResults={goBackToMainSearchResults}
        />
        <div
          ref={(div) => {
            this.mediaSection = div;
          }}
        >
          {extendedSearchSelectedTab === 0 && participantMediaCount !== 0 && (
            <SuggestionsMediaList
              customerKey={customerKey}
              eventHasSearchByFace={event.mainSearch === MAIN_SEARCH_FACE}
              eventHasSecondFactor={event.secondFactorType ? true : false}
              eventId={event.sgId}
              startnumber={event.participant.startnumber}
              suggestionsMedia={event.participant.suggestions}
              offer={event}
              participantMediaCount={participantMediaCount}
              goBackToMainSearchResults={goBackToMainSearchResults}
              eventHasFotoFlat={includesFotoFlat(event.products)}
            />
          )}
          {!extendedSearchExpand && participantMediaCount === 0 && (
            <InsufficientResultsInformation
              count={participantMediaCount}
              raceResultsUrl={event.hostResultUrl}
              showGpsSearchLink={event.gps}
              showDualSearchLink={event.searchDual}
              showSmartSearchLink={event.smartSearch}
              tagWithStartNumber={event.mainSearch === MAIN_SEARCH_BIB}
              onDeepSearchClick={() => this.scrollToEventOnlineView(0)}
              onCategorySearchClick={() => this.scrollToEventOnlineView(1)}
              onGpsSearchClick={() => this.scrollToEventOnlineView(2)}
              onDualSearchClick={() => this.scrollToEventOnlineView(3)}
              isInSmartSearch={true}
            />
          )}
        </div>
        <Faq onFindMore={() => this.scrollToEventOnlineView(0)} />
      </div>
    );
  }
}

EventSmartSearchPage.propTypes = {
  customerKey: PropTypes.string,
  snFromUrl: PropTypes.string,
  viewer: PropTypes.object,
  codeSecondFactorForFaceEvent: PropTypes.string,
  history: PropTypes.object,
};

export { EventSmartSearchPage };

export default createFragmentContainer(EventSmartSearchPage, {
  viewer: graphql`
    fragment EventSmartSearchPage_viewer on Viewer {
      event(id: $eventId) {
        id
        sgId
        gps
        hostResultUrl
        products {
          type
        }
        pendingMediaProcessing

        participant(startnumber: $startnumber, secondFactor: $secondFactor) {
          media {
            mediaInfo {
              count
            }
          }
          suggestions {
            mediaInfo {
              count
            }
            ...SuggestionsMediaList_suggestionsMedia
          }
          startnumber
        }
        secondFactorType {
          type
        }
        mainSearch
        searchDual
        smartSearch
        state
        marathonFoto
        ...EventOnlineView_onlineEvent
        ...SuggestionsMediaList_offer
      }
    }
  `,
});
