import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RECHECK } from '../../../utils/variables';
import { FormattedMessageWrappedInSpan, FormattedPrice } from '../../misc';
import { trackEvent } from '../../../utils/ga-tracking';

const FotoFlatOffer = (props) => {
  const {
    onScrollToImpressions,
    onScrollToVoucher,
    onScrollToVideo,
    onScrollToCertificate,
    offer,
    participantMediaCount,
    impressionMediaCount,
    onBuyFotoFlatClick,
    setFotoFlatRef,
    hasVideo,
    hasVideoUrl,
    hasCertificates,
    type,
    goToSearchResults,
    showOfferHintForStillProcessingMedia,
  } = props;
  // all 'icon eyes' and scrollToImpression won't work in dual search page if we want to show an offer in this view
  //const isDualSearchPage = useLocation().pathname.includes('dual');

  const scrollToImpressions = () => {
    onScrollToImpressions();
  };

  const scrollToVoucher = (voucherCampaignId) => {
    onScrollToVoucher(voucherCampaignId);
  };

  const scrollToVideo = () => {
    onScrollToVideo();
  };

  const scrollToCertificate = () => {
    onScrollToCertificate();
  };

  const fotoFlatRef = useRef(null);

  useEffect(() => {
    if (setFotoFlatRef) {
      setFotoFlatRef(fotoFlatRef);
    }
  }, [setFotoFlatRef]);

  return (
    <div
      className="fotoflat-offer-container pricelist-container medialist-item-container"
      ref={fotoFlatRef}
    >
      <div className="pricelist">
        <div className="pricelist-header">
          <FormattedMessageWrappedInSpan
            id="global.fotoFlat"
            defaultMessage="Foto-Flat"
          />
        </div>
        <div className="pricelist-body">
          <div className="pricelist-item pricelist-item-fotoflat">
            {showOfferHintForStillProcessingMedia ? (
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.personalPhotos.processingEventMedia"
                defaultMessage="All {count} personal digital photos (JPG) and <b>all coming personal digital photos (with no extra cost)</b>"
                values={{
                  count: participantMediaCount > 0 ? participantMediaCount : '',
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            ) : (
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.personalPhotos.default"
                defaultMessage="All {count} personal digital fotos (JPG)"
                values={{ count: participantMediaCount > 0 ? participantMediaCount : '' }}
              />
            )}
          </div>
          {impressionMediaCount > 0 && (
            <div
              className="pricelist-item pricelist-item-impressions pricelist-item_actionable"
              onClick={() => {
                if (onScrollToImpressions) {
                  scrollToImpressions();
                }
              }}
            >
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.impressionPhotos"
                defaultMessage="All {count} digital impressions (JPG)"
                values={{ count: impressionMediaCount }}
              />
              {onScrollToImpressions && <span className="icon eye" />}
            </div>
          )}
          {hasVideo && (
            <div
              className={classNames('pricelist-item pricelist-item-video ', {
                'pricelist-item_actionable': hasVideoUrl && Boolean(onScrollToVideo),
              })}
              onClick={(event) => {
                if (hasVideoUrl && onScrollToVideo) {
                  scrollToVideo(event);
                }
              }}
            >
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.videoOffert"
                defaultMessage="Impression video with <b>your personal finish scene</b> (MP4)"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
              {hasVideoUrl && onScrollToVideo && <span className="icon eye" />}
            </div>
          )}
          {hasCertificates && (
            <div
              className={classNames('pricelist-item ', {
                'pricelist-item_actionable': Boolean(onScrollToCertificate),
              })}
              onClick={() => {
                if (onScrollToCertificate) {
                  scrollToCertificate();
                }
              }}
            >
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.certificatesOffert"
                defaultMessage="Printable certificate (JPG)"
              />
              {onScrollToCertificate && <span className="icon eye" />}
            </div>
          )}
          {offer.voucherCampaigns &&
            offer.voucherCampaigns.map((voucherCampaign) => {
              return (
                <div
                  key={voucherCampaign.id}
                  className={classNames('pricelist-item pricelist-item-voucher', {
                    'pricelist-item_actionable': Boolean(onScrollToVoucher),
                  })}
                  onClick={() => {
                    if (onScrollToVoucher) {
                      scrollToVoucher(voucherCampaign.id);
                    }
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: voucherCampaign.shortText,
                    }}
                  />
                  {onScrollToVoucher && <span className="icon eye" />}
                </div>
              );
            })}
          <div className="main-price">
            <FormattedPrice price={offer.price} />
          </div>
        </div>
        <div
          className={classNames({
            'pricelist-main-price': type != RECHECK,
            'more-info-tile-button': type === RECHECK,
          })}
          onClick={() => {
            if (type === RECHECK) {
              goToSearchResults();
            } else {
              trackEvent('Event', 'Buy', 'Foto-Flat banner');
              onBuyFotoFlatClick();
            }
          }}
        >
          {type === RECHECK ? (
            <div>
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.showButton"
                defaultMessage="Show offer"
              />
            </div>
          ) : (
            <div className="pricelist-call-to-action">
              <FormattedMessageWrappedInSpan
                id="fotoFlatOffer.buyButton"
                defaultMessage="Buy now"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FotoFlatOffer.defaultProps = {
  showOfferHintForStillProcessingMedia: false,
};

FotoFlatOffer.propTypes = {
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  offer: PropTypes.shape({
    id: PropTypes.string,
    price: PropTypes.object,
    voucherCampaigns: PropTypes.array,
  }),
  onBuyFotoFlatClick: PropTypes.func,
  onScrollToImpressions: PropTypes.func,
  onScrollToVoucher: PropTypes.func,
  onScrollToVideo: PropTypes.func,
  onScrollToCertificate: PropTypes.func,
  setFotoFlatRef: PropTypes.func,
  hasVideo: PropTypes.bool,
  hasVideoUrl: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  type: PropTypes.oneOf(['main', 'recheck']),
  goToSearchResults: PropTypes.func,
  showOfferHintForStillProcessingMedia: PropTypes.bool,
};

export default FotoFlatOffer;
