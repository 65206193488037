import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan, FormattedPrice } from '../../../components/misc';
import { TextButton } from '../../../components/misc/Buttons';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import environment from '../../../environment';
import ApplyOrderCouponMutation from '../../../mutations/ApplyOrderCouponMutation';
import RemoveOrderCouponMutation from '../../../mutations/RemoveOrderCouponMutation';

import withRequestHandler from '../../hoc/withRequestHandler';

////////////////////////////////////////////
// TODO MISSING TEST CASES:
// functions in lines: remove coupon 42-43, handle remove 83-85 and mutation in 89-102
// related to problem with testing nested MUI components
////////////////////////////////////////////

const CartCouponItem = (props) => {

  let initResult = props.bill.coupon ? true : false;
  let initCouponVat = props.bill.coupon ? props.bill.coupon.vat : null;
  let initCoupon = props.bill.coupon ? props.bill.coupon.code : '';
  let billAmount = props.bill.coupon ? -Math.abs(props.bill.coupon.brutto.amount) : 0;
  let billCurrency = props.bill.coupon ? props.bill.coupon.brutto.currency : "EUR";
  const couponShouldBeRemoved = props.couponShouldBeRemoved;

  const ENTER_KEY = 'Enter';

  const [couponVat, setCouponVat] = useState(initCouponVat);
  const [couponValue, setCouponValue] = useState(initCoupon);
  const [showResult, setShowResult] = useState(initResult);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showCouponError, setShowCouponError] = useState(false);
  const [couponErrorType, setCouponErrorType] = useState();
  const [demoPrice, setDemoPrice] = useState({ amount: billAmount, currency: billCurrency });

  useEffect(() => {
    if (couponShouldBeRemoved) {
      handleCouponRemove();
    }
  }, [couponShouldBeRemoved])

  const handleCouponChange = event => {
    setCouponValue(event.target.value.trim());
  };

  const handleClickApplyCoupon = () => {
    if (couponValue === '') {
      setShowCouponError(true);
      setCouponErrorType('no_input');
    } else {
      setShowSpinner(true);

      handleCouponApply();
    }
  };

  const handleCouponApply = () => {
    ApplyOrderCouponMutation.commit(
      environment,
      couponValue,
      props.orderId,
      (errorMsg, cart) => {
        if (!errorMsg && cart.order.bill.coupon) {
          setDemoPrice({ amount: -Math.abs(cart.order.bill.coupon.brutto.amount), currency: cart.order.bill.coupon.brutto.currency });
          setCouponVat(cart.order.bill.coupon.vat);
          setShowSpinner(false);
          setShowResult(true);
          setShowCouponError(false);
          setCouponErrorType();
          props.onCouponChanged(cart);
        } else {
          setShowResult(true);
          setShowSpinner(false);
          setShowCouponError(true);
          if (errorMsg.message.toLowerCase().includes('not_found')) {
            setCouponErrorType('not_found');
          }
          if (errorMsg.message.toLowerCase().includes('rule_missmatch')) {
            setCouponErrorType('rule_missmatch');
          }
          if (errorMsg.message.toLowerCase().includes('total_lt_value')) {
            setCouponErrorType('total_lt_value');
          }
        }
      }
    );
  };

  const handleClickRemoveCoupon = () => {
    setShowSpinner(true);
    handleCouponRemove();
  };

  const handleCouponRemove = () => {
    RemoveOrderCouponMutation.commit(
      environment,
      props.orderId,
      (errorMsg, cart) => {
        if (!errorMsg) {
          setCouponValue('');
          setShowResult(false);
          setShowSpinner(false);
          setShowCouponError(false);
          setCouponErrorType();
          props.onCouponChanged(cart);
        } else {
          setShowSpinner(false);
          setShowResult(true);
          setShowCouponError(true);
          setCouponErrorType('not_removed');
        }
      }
    );
  }

  const handleKeypress = (event) => {
    if (event.key === ENTER_KEY) {
      handleClickApplyCoupon();
    }
  };

  const defineCouponErrorMessage = () => {
    switch (couponErrorType) {
      case 'not_removed':
        return <FormattedMessageWrappedInSpan id="cartCoupon.errorNotRemoved" defaultMessage="Coupon could not be removed. Please try again." />;
      case 'not_found':
        return <FormattedMessageWrappedInSpan id="cartCoupon.errorNotFound" defaultMessage="We couldn't find this coupon code. Please verify if it is correct." />;
      case 'rule_missmatch':
        return <FormattedMessageWrappedInSpan id="cartCoupon.errorNotApplicable" defaultMessage="The coupon is valid, but it cannot be applied to your order. Please review the coupon rules." />;
      case 'no_input':
        return <FormattedMessageWrappedInSpan id="cartCoupon.errorNoInput" defaultMessage="Please enter the coupon code." />;
      case 'total_lt_value':
        return <FormattedMessageWrappedInSpan id="cartCoupon.errorNotEnoughtValue" defaultMessage="Coupon couldn't be applied. Total order value must value must be equal to or exceed the coupon value." />;
      default:
        return <FormattedMessageWrappedInSpan id="cartCoupon.error" defaultMessage="No valid coupon found." />;
    }
  }

  return (
    <div className="cart-coupon-outer-container mt-40">
      {showSpinner && (
        <div className="cart-coupon-inner-container">
          <div className="cart-coupon-spinner">
            <CircularProgress />
          </div>
        </div>
      )}
      {!showSpinner && (
        <div className={classNames({ 'cart-coupon-inner-container': !showResult }, { 'cart-coupon-result-container': showResult })}>

          <div className="cart-coupon-code-field">
            <TextField
              id="coupon-input"
              label="Coupon Code"
              margin="normal"
              disabled={showResult && !showCouponError}
              error={showCouponError}
              value={couponValue}
              onChange={handleCouponChange}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment className="cart-coupon-apply-adornment" position="start">
                    {(showResult && !showCouponError) ? (
                      <TextButton
                        hasFullWidth={false}
                        onClick={handleClickRemoveCoupon}
                        leftLabel={
                          <FormattedMessageWrappedInSpan
                            id="cartCouponItem.remove"
                            defaultMessage="Remove"
                          />
                        }
                      />
                    ) : (
                      <TextButton
                        hasFullWidth={false}
                        onClick={handleClickApplyCoupon}
                        leftLabel={
                          <FormattedMessageWrappedInSpan
                            id="cartCouponItem.apply"
                            defaultMessage="Apply"
                          />
                        }
                      />
                    )}

                  </InputAdornment>
                ),
              }}
            />
          </div>

          {showResult && !showCouponError && (
            <div className="cart-coupon-code-value-container cart-coupon-code-value">
              <FormattedMessageWrappedInSpan id="cartCoupon.title" defaultMessage="Value" />
              <FormattedPrice price={demoPrice} />
            </div>
          )}
          {showResult && showCouponError && (
            <div className="cart-coupon-code-error-container cart-coupon-code-error">
              {defineCouponErrorMessage()}
            </div>
          )}
        </div>
      )}
      {!showSpinner && showResult && !showCouponError && (couponVat !== null) && (
        <div className="cart-coupon-footer">
          <div className="cart-coupon-footer-action-container">
            <p className="cart-coupon-vat">
              <FormattedMessageWrappedInSpan
                id="cartItem.vat"
                defaultMessage="VAT: {vat}%"
                values={{ vat: couponVat }}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

CartCouponItem.propTypes = {
  orderId: PropTypes.string,
  bill: PropTypes.object,
  couponShouldBeRemoved: PropTypes.bool,
  onCouponChanged: PropTypes.func,
};

export { CartCouponItem };

export default withRequestHandler(CartCouponItem);
