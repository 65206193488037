/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderCard_order$ref: FragmentReference;
declare export opaque type OrderCard_order$fragmentType: OrderCard_order$ref;
export type OrderCard_order = {|
  +address: ?{|
    +firstName: ?string,
    +lastName: ?string,
  |},
  +bill: ?{|
    +totalBruttoPrice: ?any
  |},
  +invoice: ?string,
  +legacy: ?boolean,
  +orderid: ?string,
  +state: ?string,
  +paymentReceivedAt: ?any,
  +payment: ?{|
    +paymentMethod: ?{|
      +name: ?string
    |}
  |},
  +vouchers: ?$ReadOnlyArray<?{|
    +code: ?string,
    +campaign: ?{|
      +banner: ?string,
      +name: ?string,
      +url: ?string,
    |},
  |}>,
  +$refType: OrderCard_order$ref,
|};
export type OrderCard_order$data = OrderCard_order;
export type OrderCard_order$key = {
  +$data?: OrderCard_order$data,
  +$fragmentRefs: OrderCard_order$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderCard_order",
  "type": "Order",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "address",
      "storageKey": null,
      "args": null,
      "concreteType": "Address",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bill",
      "storageKey": null,
      "args": null,
      "concreteType": "Bill",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalBruttoPrice",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "invoice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacy",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentReceivedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "payment",
      "storageKey": null,
      "args": null,
      "concreteType": "Payment",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "paymentMethod",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentMethod",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vouchers",
      "storageKey": null,
      "args": null,
      "concreteType": "Voucher",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "campaign",
          "storageKey": null,
          "args": null,
          "concreteType": "VoucherCampaign",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "banner",
              "args": null,
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b58d2b344127b6bfd65d0388ca21302d';

module.exports = node;
