import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../misc';

const InvalidStartnumberInformation = () => {
  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="notification-info">
          <h2>
            <FormattedMessageWrappedInSpan
              id="invalidStartnumberInformation.message"
              defaultMessage="We could not find any photos with the bib number you provided. Please note that you will only see pictures that have not been found with your selfie. Also make sure you're searching in the correct EVENT."
            />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default InvalidStartnumberInformation;
