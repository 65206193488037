import React, { useLayoutEffect, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import QueryHandler from '../misc/QueryHandler';

const RainbowFlag = ({ hideGradients }) => {
  const [eventCountryCode, setEventCountryCode] = useState('');
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState(false);
  const { id: eventId } = useParams();

  const countriesToHideFlag = [
    'AF', // Afghanistan
    'BH', // Bahrain
    'BN', // Brunei
    'EG', // Egypt
    'IR', // Iran
    'IQ', // Iraq
    'IL', // Israel
    'JM', // Jamaica
    'JO', // Jordan
    'KW', // Kuwait
    'LB', // Lebanon
    'MY', // Malaysia
    'MV', // Maldives
    'MR', // Mauritania
    'NG', // Nigeria
    'OM', // Oman
    'PK', // Pakistan
    'PS', // Palestine
    'QA', // Qatar
    'SA', // Saudi Arabia
    'SO', // Somalia
    'SD', // Sudan
    'SY', // Syria
    'TR', // Turkey
    'AE', // United Arab Emirates
    'US', // United States
    'YE', // Yemen
  ];

  const isTimeToDisplay = useMemo(() => {
    const date = new Date();
    return date.getMonth() === 5;
  }, []);

  useLayoutEffect(() => {
    if (isTimeToDisplay && !countriesToHideFlag.includes(eventCountryCode)) {
      setShouldBeDisplayed(true);
    } else {
      setShouldBeDisplayed(false);
    }
  }, [eventCountryCode, eventId]);

  useLayoutEffect(() => {
    if (!eventId) {
      setEventCountryCode('');
    }
    if (eventId) {
      setShouldBeDisplayed(false)
    }
  }, [eventId]);

  return (
    <>
      {eventId && (
        <QueryHandler
          query={graphql`
            query RainbowFlagQuery($eventId: ID!) {
              viewer {
                event(id: $eventId) {
                  countryCode
                }
              }
            }
          `}
          variables={{
            eventId: eventId,
          }}
          render={(props) => {
            setEventCountryCode(props.viewer.event.countryCode);
            return null;
          }}
        />
      )}
      {shouldBeDisplayed && (
        <>
          <div
            className={classNames('rainbow-flag', 'rainbow-flag_left', {
              'rainbow-flag_top-corner': hideGradients,
              'rainbow-flag_gradient': !hideGradients,
            })}
          ></div>
          <div
            className={classNames('rainbow-flag', 'rainbow-flag_right', {
              'rainbow-flag_top-corner': hideGradients,
              'rainbow-flag_gradient': !hideGradients,
            })}
          ></div>
        </>
      )}
    </>
  );
};

RainbowFlag.propTypes = {
  hideGradients: PropTypes.bool,
};

export default RainbowFlag;
