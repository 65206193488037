import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingView = () => {
  return (
    <div className="loading-view-container">
      <CircularProgress size={40} thickness={3} />
    </div>
  );
};

export default LoadingView;
