/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';

import MediaList from './MediaList';

class ParticipantMediaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
    };
  }

  handleLoadMore = () => {
    if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ isFetching: true });
    this.props.relay.loadMore(MEDIA_FETCH_COUNT, () =>
      this.setState({ isFetching: false })
    );
  };

  render() {
    const { isFetching } = this.state;
    const {
      eventHasSearchByFace,
      eventHasSecondFactor,
      participantMedia,
      hasMorePhotosButton,
      relay,
      offer,
      participantMediaCount,
      impressionMediaCount,
      startnumber,
      onScrollToImpressions,
      onScrollToVoucher,
      onScrollToVideo,
      onScrollToCertificate,
      onScrollToExtendedSearch,
      onImagesLoaded,
      setFotoFlatRef,
      hasVideoUrl,
      hasCertificates,
      hasCertificateUrl,
      showOfferHintForStillProcessingMedia,
      onBuyFotoFlat,
    } = this.props;

    return (
      <MediaList
        eventHasSearchByFace={eventHasSearchByFace}
        eventHasSecondFactor={eventHasSecondFactor}
        hasMore={relay.hasMore()}
        isFetching={isFetching}
        media={participantMedia.mediaList}
        offer={offer}
        hasMorePhotosButton={hasMorePhotosButton}
        listType="participant"
        participantMediaCount={participantMediaCount}
        impressionMediaCount={impressionMediaCount}
        startnumber={startnumber}
        onLoadMore={this.handleLoadMore}
        onScrollToImpressions={onScrollToImpressions}
        onScrollToVoucher={onScrollToVoucher}
        onScrollToVideo={onScrollToVideo}
        onScrollToCertificate={onScrollToCertificate}
        onScrollToExtendedSearch={onScrollToExtendedSearch}
        onImagesLoaded={onImagesLoaded}
        setFotoFlatRef={setFotoFlatRef}
        hasVideoUrl={hasVideoUrl}
        hasCertificates={hasCertificates}
        hasCertificateUrl={hasCertificateUrl}
        showOfferHintForStillProcessingMedia={showOfferHintForStillProcessingMedia}
        onBuyFotoFlat={onBuyFotoFlat}
      />
    );
  }
}

ParticipantMediaList.defaultProps = {
  eventHasSearchByFace: false,
  eventHasSecondFactor: false,
  showOfferHintForStillProcessingMedia: false,
};

ParticipantMediaList.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  participantMediaCount: PropTypes.number,
  hasMorePhotosButton: PropTypes.bool,
  impressionMediaCount: PropTypes.number,
  startnumber: PropTypes.string,
  participantMedia: PropTypes.object,
  offer: PropTypes.object,
  relay: PropTypes.object,
  onScrollToImpressions: PropTypes.func,
  onScrollToVoucher: PropTypes.func,
  onScrollToVideo: PropTypes.func,
  onScrollToCertificate: PropTypes.func,
  onScrollToExtendedSearch: PropTypes.func,
  onImagesLoaded: PropTypes.func,
  setFotoFlatRef: PropTypes.func,
  hasVideoUrl: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  hasCertificateUrl: PropTypes.bool,
  showOfferHintForStillProcessingMedia: PropTypes.bool,
  onBuyFotoFlat: PropTypes.func,
};

export { ParticipantMediaList };

export default createPaginationContainer(
  ParticipantMediaList,
  {
    participantMedia: graphql`
      fragment ParticipantMediaList_participantMedia on ExtendedMediaConnection {
        mediaList(first: $count, after: $cursor)
          @connection(key: "ParticipantMediaList_mediaList") {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          ...MediaList_media
        }
      }
    `,
    offer: graphql`
      fragment ParticipantMediaList_offer on Event {
        ...MediaList_offer
      }
    `,
  },
  {
    direction: 'forward',
    // Function that should indicate which connection to paginate over, given the fragment
    // props.
    getConnectionFromProps(props) {
      return props.participantMedia && props.participantMedia.mediaList;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        eventId: props.eventId,
        startnumber: props.startnumber,
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        secondFactor: props.customerKey,
        isRecheckPage: false,
      };
    },
    query: graphql`
      query ParticipantMediaListQuery(
        $eventId: ID!
        $startnumber: ID!
        $count: Int!
        $cursor: String
        $secondFactor: String,
        $isRecheckPage: Boolean!,
      ) {
        viewer {
          event(id: $eventId) {
            participant(startnumber: $startnumber, secondFactor: $secondFactor) {
              media {
                ...ParticipantMediaList_participantMedia
              }
            }
          }
        }
      }
    `,
  }
);
