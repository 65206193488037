/**
 * @flow
 * @relayHash 4e5098f5ed4a8683bd0638c388420e01
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOrderInput = {|
  email: string,
  language?: ?string,
|};
export type CreateOrderMutationVariables = {|
  input: CreateOrderInput
|};
export type CreateOrderMutationResponse = {|
  +createOrder: ?{|
    +order: ?{|
      +id: string,
      +cart: ?{|
        +availablePaymentMethods: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +customer: ?{|
          +email: ?string
        |},
        +paymentRequired: ?boolean,
      |},
      +orderid: ?string,
      +state: ?string,
    |}
  |}
|};
export type CreateOrderMutation = {|
  variables: CreateOrderMutationVariables,
  response: CreateOrderMutationResponse,
|};
*/


/*
mutation CreateOrderMutation(
  $input: CreateOrderInput!
) {
  createOrder(input: $input) {
    order {
      id
      cart {
        availablePaymentMethods {
          id
          name
        }
        customer {
          email
          id
        }
        paymentRequired
        id
      }
      orderid
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateOrderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availablePaymentMethods",
  "storageKey": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paymentRequired",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderid",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateOrderMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrderPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cart",
                "storageKey": null,
                "args": null,
                "concreteType": "Cart",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  },
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateOrderMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrderPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cart",
                "storageKey": null,
                "args": null,
                "concreteType": "Cart",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateOrderMutation",
    "id": null,
    "text": "mutation CreateOrderMutation(\n  $input: CreateOrderInput!\n) {\n  createOrder(input: $input) {\n    order {\n      id\n      cart {\n        availablePaymentMethods {\n          id\n          name\n        }\n        customer {\n          email\n          id\n        }\n        paymentRequired\n        id\n      }\n      orderid\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '062a9223ffed859b506f4151d72eeb71';

module.exports = node;
