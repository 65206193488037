export default function fetchBestofConfig(eventId, httpMethod) {
  return new Promise(function(resolve, reject) {
    const url = '/bestof/' + eventId + '/config.xml';

    const client = new XMLHttpRequest();
    client.onload = function() {
      const config = {
        isAvailable: false,
        responseXML: undefined,
      };
      // Cloudfront returns the frontpage (index.html) with HTTP 200 for non-existing
      // config.xml files. Therefore it is necessary to additionally check the
      // content-type.
      // The content-type can either be application/xml or text/xml (depends on the
      // HTTP method HEAD or GET).
      const contentType = client.getResponseHeader('Content-Type');
      if (this.status === 200 && contentType.includes('xml')) {
        config.isAvailable = true;
        config.responseXML = this.responseXML;
      }
      resolve(config);
    };

    client.open(httpMethod, url);
    client.send();
  });
}
