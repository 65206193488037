import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmailProper from '../../../utils/isEmailProper';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import classNames from 'classnames';

import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import GDPRHint from '../GDPRHint';
import { FormattedMessageWrappedInSpan } from '../../misc';
import EmailSuggestionTooltip from '../../misc/EmailSuggestionTooltip';
import useEmailSpellChecking from '../../../hooks/useEmailSpellChecking';

const ENTER_KEY = 'Enter';

const RequestRecheckView = ({
  isStartNumberInputDisabled,
  onCancel,
  lastUserInputStartNumber,
  imagesToCheckCount,
  startnumber: startnumberFromProps,
  email: emailFromProps,
  onSubmit,
  setLastUserInputStartNumber,
}) => {
  const intl = useIntl();
  const isNotOnMainSearch = () => {
    let location = useLocation();
    return /dual|category|gps|smartsearch/.test(location.pathname);
  };
  const notMainSearch = isNotOnMainSearch();
  const searchNumber = useParams().startnumber;

  const [emailInputValue, setEmailInputValue] = useState(emailFromProps.trim() || '');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [startNumberInputValue, setStartNumberInputValue] = useState(
    notMainSearch && lastUserInputStartNumber
      ? lastUserInputStartNumber
      : startnumberFromProps
      ? startnumberFromProps
      : searchNumber
  );
  const [startNumberIsInvalid, setStartNumberIsInvalid] = useState(false);

  const suggestedEmail = useEmailSpellChecking(emailInputValue);

  const handleStartnumberChanged = (event) => {
    setStartNumberInputValue(event.target.value.replace(/ /gi, ''));
    if (!notMainSearch && event.target.value == startnumberFromProps) {
      setStartNumberIsInvalid(true);
    } else if (!isEmpty(event.target.value)) {
      setStartNumberIsInvalid(false);
    } else {
      setStartNumberIsInvalid(true);
    }
  };

  const handleEmailChanged = (event) => {
    setEmailInputValue(event.target.value.trim());
    if (isEmailProper(event.target.value)) {
      setEmailIsInvalid(false);
    } else {
      setEmailIsInvalid(true);
    }
  };

  const handleKeyPressed = (event, value) => {
    if (event.key === ENTER_KEY) {
      submitForm();
    }
  };

  const validateInput = () => {
    // For a few weeks now (as of september 4th 2019), users have been increasingly
    // entering their e-mail address instead of their starting number. Since the causes
    // for the problem are not fully understandable, I check if there is an e-mail address
    // entered in the start number field.

    if (
      startNumberInputValue === '' ||
      (startNumberInputValue &&
        isEmail(startNumberInputValue.trim(), {
          allow_utf8_local_part: false,
        }))
    ) {
      setStartNumberIsInvalid(true);
      return false;
    }

    if (!isEmailProper(emailInputValue)) {
      setEmailIsInvalid(true);
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (validateInput()) {
      onSubmit(startNumberInputValue, emailInputValue.trim());
      if (notMainSearch) {
        setLastUserInputStartNumber(startNumberInputValue);
      }
    }
  };

  return (
    <div className="notification-container">
      <div className="notification-body">
        {!isStartNumberInputDisabled && (
          <Tooltip
            title={
              notMainSearch ? (
                <FormattedMessageWrappedInSpan
                  id="requestRecheckView.informationAboutUsage.tooltipNotMainSearch"
                  defaultMessage="We will review the photo. You will be notified by email. If we approved your request, the photo will be added to your package for free. Even if you already placed your order."
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="requestRecheckView.informationAboutUsage.tooltipMainSearch"
                  defaultMessage="This function is intended for customers who, for example, search for more photos of themselves using a friend's bib number."
                />
              )
            }
          >
            <h1 className="notification-title-big-margin-bottom ">
              <FormattedMessageWrappedInSpan
                id="requestRecheckView.dialogTitleWithPluralization"
                defaultMessage="{count, plural, one {Tag this photo} other {Tag {count} photos}} with your bib number {icon}"
                values={{
                  icon: <HelpRoundedIcon className="display-sm-none" />,
                  count: imagesToCheckCount,
                }}
              />
            </h1>
          </Tooltip>
        )}
        <div className="startnumber-search-container startnumber-search-container-darker ">
          {!isStartNumberInputDisabled && (
            <div
              className={classNames(
                'notification-start-number-label container-label ml-15 mr-15',
                {
                  error: startNumberIsInvalid,
                }
              )}
            >
              {startNumberIsInvalid ? (
                !notMainSearch && startNumberInputValue === startnumberFromProps ? (
                  <FormattedMessageWrappedInSpan
                    id="requestRecheckView.startNumberLabelRecheckErrorSameNumber"
                    defaultMessage="This photo is already tagged with this bib number"
                  />
                ) : (
                  <FormattedMessageWrappedInSpan
                    id="requestRecheckView.startNumberLabelRecheckError"
                    defaultMessage="Enter a valid bib number"
                  />
                )
              ) : (
                <FormattedMessageWrappedInSpan
                  id="requestRecheckView.startNumberLabel"
                  defaultMessage="Enter your bib number (alphanumeric)"
                />
              )}
            </div>
          )}
          {!isStartNumberInputDisabled && (
            <div className="startnumber-search-textfield input-edit-icon">
              <div>
                <input
                  autoComplete="off"
                  autoFocus={startNumberInputValue === ''}
                  disabled={isStartNumberInputDisabled}
                  onInput={handleStartnumberChanged}
                  onKeyPress={handleKeyPressed}
                  placeholder="0000"
                  value={startNumberInputValue || ''}
                />
              </div>
            </div>
          )}
        </div>
        {isStartNumberInputDisabled && (
          <h1>
            <FormattedMessageWrappedInSpan
              id="requestRecheckView.searchByFace.dialogTitleWithPluralization"
              defaultMessage="{count, plural, one {Tag this photo} other {Tag {count} photos}}"
              values={{
                count: imagesToCheckCount,
              }}
            />
          </h1>
        )}
        {isStartNumberInputDisabled && (
          <input
            autoComplete="off"
            autoFocus={startNumberInputValue === ''}
            disabled={isStartNumberInputDisabled}
            hidden={isStartNumberInputDisabled}
            onInput={handleStartnumberChanged}
            onKeyPress={handleKeyPressed}
            placeholder="0000"
            value={startNumberInputValue || ''}
          />
        )}
        <EmailSuggestionTooltip
          emailSuggestion={suggestedEmail}
          onAcceptSuggestion={() => {
            setEmailInputValue(suggestedEmail);
          }}
        >
          <TextField
            autoFocus={startNumberInputValue !== ''}
            className={classNames('notification-input', 'email-search-textfield')}
            error={emailIsInvalid}
            label={
              <FormattedMessageWrappedInSpan
                id="requestRecheckView.emailTextField.label"
                defaultMessage="Email"
              />
            }
            onInput={handleEmailChanged}
            onKeyPress={handleKeyPressed}
            placeholder={intl.formatMessage({
              id: 'requestRecheckView.emailTextField.placeholder',
              defaultMessage: 'Enter your email address',
            })}
            value={emailInputValue || ''}
          />
        </EmailSuggestionTooltip>
        <div className="mt-20">
          <GDPRHint />
        </div>
      </div>

      <div className="notification-action">
        <button onClick={onCancel}>
          <FormattedMessageWrappedInSpan
            id="requestRecheckView.cancelButton"
            defaultMessage="Cancel"
          />
        </button>

        <button
          className={`thats-me-submit ${
            emailIsInvalid || startNumberIsInvalid ? 'disabled' : 'accept'
          }`}
          onClick={submitForm}
        >
          <FormattedMessageWrappedInSpan
            id="requestRecheckView.acceptButton"
            defaultMessage="Submit"
          />
        </button>
      </div>
    </div>
  );
};

RequestRecheckView.defaultProps = {
  isStartNumberInputDisabled: false,
  imagesToCheckCount: 1,
  email: '',
  startnumber: '',
};

RequestRecheckView.propTypes = {
  isStartNumberInputDisabled: PropTypes.bool,
  startnumber: PropTypes.string,
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  intl: PropTypes.object,
  lastUserInputStartNumber: PropTypes.string,
  setLastUserInputStartNumber: PropTypes.func,
  imagesToCheckCount: PropTypes.number,
};

export default RequestRecheckView;
