/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Pricelist_offer$ref: FragmentReference;
declare export opaque type Pricelist_offer$fragmentType: Pricelist_offer$ref;
export type Pricelist_offer = {|
  +products: ?$ReadOnlyArray<?{|
    +id: string,
    +mediaCollection: ?{|
      +name: ?string
    |},
    +price: ?any,
    +type: ?string,
    +configuration: ?{|
      +previewVideoUrl?: ?string,
      +previewImageUrl?: ?string,
    |},
  |}>,
  +voucherCampaigns: ?$ReadOnlyArray<?{|
    +id: string,
    +banner: ?string,
    +longText: ?string,
    +name: ?string,
    +shortText: ?string,
    +url: ?string,
  |}>,
  +$refType: Pricelist_offer$ref,
|};
export type Pricelist_offer$data = Pricelist_offer;
export type Pricelist_offer$key = {
  +$data?: Pricelist_offer$data,
  +$fragmentRefs: Pricelist_offer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Pricelist_offer",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "products",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "mediaCollection",
          "storageKey": null,
          "args": null,
          "concreteType": "MediaCollection",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "configuration",
          "storageKey": null,
          "args": null,
          "concreteType": null,
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "type": "SingleVideoConfiguration",
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "previewVideoUrl",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "InlineFragment",
              "type": "EventCertificateConfiguration",
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "previewImageUrl",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "voucherCampaigns",
      "storageKey": null,
      "args": null,
      "concreteType": "VoucherCampaign",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "banner",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "longText",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shortText",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '906f3bfa38287224697d4dfcb46a0b5c';

module.exports = node;
