import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { languages } from '../../intl/languages';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    const language = languages.find(l => l.iso === props.language);

    this.state = {
      languageIso: language.iso,
    };
  }

  handleLanguageChange = iso => {
    const language = languages.find(l => l.iso === iso);
    this.setState({
      languageIso: language.iso,
    });
    if (language.iso !== this.state.languageIso) {
      this.props.onLanguageChange(iso);
    }

    this.props.hideMobileMenu();
  };

  render() {
    const { languageIso } = this.state;
    const { handleMobileLanguageDropdown, mobileLanguageExpanded } = this.props;
    return (
      <div
        className={classNames('navigation-bar-item', 'navigation-bar-languages', {
          'dropdown-expanded': mobileLanguageExpanded,
        })}
      >
        <div
          className={`navigation-bar-dropdown-main icon-${languageIso}`}
          onClick={handleMobileLanguageDropdown}
        >
          <span /> {languageIso}
        </div>
        <div className="navigation-bar-dropdown">
          {languages.map(language => (
            <div
              className={`navigation-bar-dropdown-item icon-${language.iso}`}
              key={language.index}
              onClick={() => this.handleLanguageChange(language.iso)}
            >
              <span /> {language.title}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

LanguageDropdown.propTypes = {
  onLanguageChange: PropTypes.func,
  language: PropTypes.string,
  hideMobileMenu: PropTypes.func,
  handleMobileLanguageDropdown: PropTypes.func,
  mobileLanguageExpanded: PropTypes.bool,
};

export default LanguageDropdown;
