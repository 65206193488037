import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';

const LoadingDialog = ({ open, onRequestClose }) => {
  return (
    <CustomDialog open={open} onRequestClose={onRequestClose} hideCloseIcon={true}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <CircularProgress />
          </div>
        </DialogContent>
      </div>
    </CustomDialog>
  );
};

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default LoadingDialog;
