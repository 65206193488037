/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CheckoutOrderingAddressStep_cart$ref = any;
type CheckoutOrderingPaymentStep_cart$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutOrderingSteps_cart$ref: FragmentReference;
declare export opaque type CheckoutOrderingSteps_cart$fragmentType: CheckoutOrderingSteps_cart$ref;
export type CheckoutOrderingSteps_cart = {|
  +id: string,
  +customer: ?{|
    +email: ?string
  |},
  +lineItems: ?$ReadOnlyArray<?{|
    +id: string,
    +product: ?{|
      +eventId: ?number
    |},
  |}>,
  +order: ?{|
    +id: string,
    +state: ?string,
    +orderid: ?string,
  |},
  +shipmentRequired: ?boolean,
  +paymentRequired: ?boolean,
  +totalPrice: ?any,
  +availablePaymentMethods: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +defaultPaymentType: ?string,
  |}>,
  +$fragmentRefs: CheckoutOrderingAddressStep_cart$ref & CheckoutOrderingPaymentStep_cart$ref,
  +$refType: CheckoutOrderingSteps_cart$ref,
|};
export type CheckoutOrderingSteps_cart$data = CheckoutOrderingSteps_cart;
export type CheckoutOrderingSteps_cart$key = {
  +$data?: CheckoutOrderingSteps_cart$data,
  +$fragmentRefs: CheckoutOrderingSteps_cart$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CheckoutOrderingSteps_cart",
  "type": "Cart",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customer",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lineItems",
      "storageKey": null,
      "args": null,
      "concreteType": "LineItem",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "product",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "eventId",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderid",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shipmentRequired",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentRequired",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "availablePaymentMethods",
      "storageKey": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "defaultPaymentType",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CheckoutOrderingAddressStep_cart",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CheckoutOrderingPaymentStep_cart",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '82be888b1113f4a74ad2c05fe218700c';

module.exports = node;
