import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { UserConsumer } from '../../context/UserContext';

import { trackEvent } from '../../../utils/ga-tracking';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

const cartNavigationStepsSteps = (props) => {
  const { currentStep, history, goToAddressStep, goToPaymentStep, goToRegisterStep, isFormError, isOrder, isLoggedInNow } =
    props;

  const goToCheckout = () => {
    trackEvent('Checkout', 'Start checkout', 'Proceed to checkout from cart overview');
    history.push('/checkout');
  };

  const goToCart = () => {
    history.push('/cart');
  };

  const isButtonInactive = (stepToCheck) => {
    switch (stepToCheck) {
      case 2:
        return isLoggedInNow;
      case 3:
        return currentStep < 3 && (!isLoggedInNow && !isOrder);
      case 4:
        return currentStep != 3 || isFormError;
      default:
        return false;
    }
  };

  const isButtonDone = (stepToCheck) => {
    switch (stepToCheck) {
      case 2:
        return currentStep > stepToCheck || isLoggedInNow || isOrder;
      case 1:
      case 3:
        return stepToCheck < currentStep;
      default:
        return false;
    }
  };

  return (
    <UserConsumer>
      {({ isLoggedIn }) => (
        <div className="cart-navigation">
          <div className="cart-navigation_step ">
            <button
              className={`rounded-icon-button medium 
              ${currentStep === 1 ? 'blue' : 'black'} `}
              onClick={goToCart}
            >
              {isButtonDone(1) ? <DoneRoundedIcon /> : 1}
            </button>
            <FormattedMessageWrappedInSpan
              id="cartNavigationSteps.step1"
              defaultMessage="Cart"
            />
          </div>
          <span className="cart-navigation_ruler" />
          <div className="cart-navigation_step">
            <button
              onClick={() => {
                if (goToRegisterStep && !isLoggedIn) {
                  goToRegisterStep()
                }
                else {
                  goToCheckout()
                }

              }}
              className={`rounded-icon-button medium ${isButtonDone(2) ? 'black' : currentStep === 2 ? 'blue' : 'grey'
                } ${isButtonInactive(2) ? 'blocked' : ''}`}
            >
              {isButtonDone(2) ? <DoneRoundedIcon /> : 2}
            </button>
            {isLoggedIn && <FormattedMessageWrappedInSpan
              id="cartNavigationSteps.step2.isLogged"
              defaultMessage="Logged in"
            />}
            {!isLoggedIn && currentStep < 3 && !isOrder &&
              <FormattedMessageWrappedInSpan
                id="cartNavigationSteps.step2.checkout"
                defaultMessage="Login (optional)"
              />
            }
            {!isLoggedIn && (currentStep > 2 || isOrder) &&
              <FormattedMessageWrappedInSpan
                id="cartNavigationSteps.step2.guest"
                defaultMessage="Guest"
              />}
          </div>
          <span className="cart-navigation_ruler" />
          <div className="cart-navigation_step">
            <button
              onClick={() => {
                if (currentStep === 4) {
                  goToAddressStep();
                }
                if (!isLoggedIn && !isOrder) {
                  return;
                }
                goToCheckout();
              }}
              className={`rounded-icon-button medium ${isButtonDone(3) ? 'black' : currentStep === 3 ? 'blue' : 'grey'
                } ${isButtonInactive(3) ? 'blocked' : ''}`}
            >
              {isButtonDone(3) ? <DoneRoundedIcon /> : 3}
            </button>
            <FormattedMessageWrappedInSpan
              id="cartNavigationSteps.step3"
              defaultMessage="Address"
            />
          </div>
          <span className="cart-navigation_ruler" />
          <div className="cart-navigation_step">
            <button
              onClick={goToPaymentStep}
              className={`rounded-icon-button medium ${currentStep === 4 ? 'blue' : 'grey'
                } ${isButtonInactive(4) ? 'blocked' : ''}`}
            >
              4
            </button>
            <FormattedMessageWrappedInSpan
              id="cartNavigationSteps.step4"
              defaultMessage="Payment"
            />
          </div>
        </div>
      )}
    </UserConsumer>
  );
};

cartNavigationStepsSteps.propTypes = {
  currentStep: PropTypes.number,
  isLoggedInNow: PropTypes.bool,
  history: PropTypes.object,
  goToPaymentStep: PropTypes.func,
  goToAddressStep: PropTypes.func,
  isOrder: PropTypes.bool,
  goToRegisterStep: PropTypes.func
};

export default cartNavigationStepsSteps;
