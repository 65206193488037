import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Spinner = props => {
  return (
    <div
      className={classNames('spinner', props.className)}
      style={{
        borderTopColor: props.color,
        height: props.size,
        width: props.size,
      }}
    />
  );
};

Spinner.defaultProps = {
  color: '#fff',
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Spinner;
