import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import CustomDialog from './CustomDialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

const MultiselectModeOptionDialog = (props) => {
  const { open, onAccept, onRefuse, onDialogClose } = props;

  return (
    <CustomDialog open={open} onRequestClose={onDialogClose}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="multiselectModeOptionDialog.title"
                defaultMessage="Select images for a review"
              />
            </h1>
            <div className="multiselect-what-is-recheck mb-20 mt-10">
              <HelpRoundedIcon />
              <FormattedMessageWrappedInSpan
                id="multiselectModeOptionDialog.message.whatIsRecheckProcedure"
                defaultMessage="If our system overlooks a photo of yours, you can send us a request to add the image to your photos for free."
              />
            </div>
            <FormattedMessageWrappedInSpan
              id="multiselectModeOptionDialog.message.selectMode"
              defaultMessage="You can select images for a review one by one or switch to multiselect mode to pick multiple images at once."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button
              onClick={() => {
                onRefuse();
                onDialogClose();
              }}
            >
              <FormattedMessageWrappedInSpan
                id="multiselectModeOptionDialog.refuseButton"
                defaultMessage="Continue one by one"
              />
            </button>
            <button
              className="accept"
              onClick={() => {
                onDialogClose();
                onAccept();
              }}
            >
              <FormattedMessageWrappedInSpan
                id="multiselectModeOptionDialog.acceptButton"
                defaultMessage="Go multiselect"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

MultiselectModeOptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func,
  onRefuse: PropTypes.func,
  onDialogClose: PropTypes.func,
};

export default MultiselectModeOptionDialog;
