import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const FormattedPrice = props => {
  const { price } = props;

  return (
    <FormattedNumber
      value={(price.amount / 100).toFixed(2)}
      style="currency"
      currency={price.currency}
    />
  );
};

FormattedPrice.propTypes = {
  price: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
};

export default FormattedPrice;
