import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from '../CustomDialog';
import NotificationDialogBody from './NotificationDialogBody';
import NotificationDialogAction from './NotificationDialogAction';
import { NotificationDialogConsumer } from '../../../context/NotificationDialogContext';

const NotificationDialog = () => (
  <NotificationDialogConsumer>
    {({ isOpen, msg, msgType, onCloseNotificationDialog }) => (
      <CustomDialog open={isOpen} onRequesClose={onCloseNotificationDialog}>
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              <NotificationDialogBody msg={msg} msgType={msgType} />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="notification-action">
              <NotificationDialogAction
                msgType={msgType}
                onClick={onCloseNotificationDialog}
              />
            </div>
          </DialogActions>
        </div>
      </CustomDialog>
    )}
  </NotificationDialogConsumer>
);

export default NotificationDialog;
