/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventOnlineView_onlineEvent$ref = any;
type SuggestionsMediaList_offer$ref = any;
type SuggestionsMediaList_suggestionsMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventSmartSearchPage_viewer$ref: FragmentReference;
declare export opaque type EventSmartSearchPage_viewer$fragmentType: EventSmartSearchPage_viewer$ref;
export type EventSmartSearchPage_viewer = {|
  +event: ?{|
    +id: string,
    +sgId: ?number,
    +gps: ?boolean,
    +hostResultUrl: ?string,
    +products: ?$ReadOnlyArray<?{|
      +type: ?string
    |}>,
    +pendingMediaProcessing: ?boolean,
    +participant: ?{|
      +media: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |}
      |},
      +suggestions: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |},
        +$fragmentRefs: SuggestionsMediaList_suggestionsMedia$ref,
      |},
      +startnumber: ?string,
    |},
    +secondFactorType: ?{|
      +type: ?string
    |},
    +mainSearch: ?string,
    +searchDual: ?boolean,
    +smartSearch: ?boolean,
    +state: ?string,
    +marathonFoto: ?boolean,
    +$fragmentRefs: EventOnlineView_onlineEvent$ref & SuggestionsMediaList_offer$ref,
  |},
  +$refType: EventSmartSearchPage_viewer$ref,
|};
export type EventSmartSearchPage_viewer$data = EventSmartSearchPage_viewer;
export type EventSmartSearchPage_viewer$key = {
  +$data?: EventSmartSearchPage_viewer$data,
  +$fragmentRefs: EventSmartSearchPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "mediaInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "EventSmartSearchPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "startnumber",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "secondFactor",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "eventId"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sgId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "gps",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hostResultUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "products",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": true,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pendingMediaProcessing",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "participant",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "secondFactor",
              "variableName": "secondFactor"
            },
            {
              "kind": "Variable",
              "name": "startnumber",
              "variableName": "startnumber"
            }
          ],
          "concreteType": "Participant",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "media",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "suggestions",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "SuggestionsMediaList_suggestionsMedia",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startnumber",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "secondFactorType",
          "storageKey": null,
          "args": null,
          "concreteType": "SecondFactorType",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mainSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchDual",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "smartSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "marathonFoto",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventOnlineView_onlineEvent",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "SuggestionsMediaList_offer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e93665782fec8822b09ff97032b6947';

module.exports = node;
