import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import EventInfoCard from './EventInfoCard';
import StartNumberSearch from './StartNumberSearch';
import SecondFactorGuard from './SecondFactorGuard';
import ExtendedSearch from '../extended-search/ExtendedSearch';
import StillProcessingImagesInfo from '../informations/StillProcessingImagesInfo';
import SearchInstructionsDialog from '../dialogs/SearchInstructionsDialog';
import GDPRHint from '../GDPRHint';
import { FormattedMessageWrappedInSpan } from '../../misc';
import {
  ALTERNATIVE,
  FACTOR_CODE,
  MAIN_SEARCH_INACTIVE,
  PARTICIPANT,
} from '../../../utils/variables';

const EventOnlineView = forwardRef((props, ref) => {
  const {
    customerKey,
    onlineEvent,
    onExpandBestof,
    bestOfAvailable,
    extendedSearchSelectedCategoryId,
    showSearchInstructionsDialog,
    extendedSearchSelectedTab,
    extendedSearchExpand,
    onExtendedSearchTabChange,
    onExtendedSearchExpand,
    hasSmartSearchTab,
    hasCategorySearchTab,
    hasGpsSearchTab,
    hasDualSearchTab,
    hasExtendedSearch,
    onSearchTrigger,
    handleScrollToOffer,
    shouldShowAdditionalOffer,
    notOnMainSearchPage,
    setFormRef,
    setAltSearchRef,
    shouldShowAdditionalInstruction,
    userLoginEmail,
    showHowToBuyHint,
    onShowHowToBuyHint,
    cookies,
    isAccessGrantedByValidCodeFaceEvent,
    codeSecondFactorForFaceEvent,
    showStillProcessingHint,
    onBuyFotoFlat,
    setStillProcessingPosition,
    goBackToMainSearchResults,
    eventSecondFactorType,
  } = props;

  const isMainEventPage = props.match.path === '/:language/event/:id';

  const [startNumber, setStartNumber] = useState(props.urlSn || '');
  const [secondFactor, setSecondFactor] = useState(
    customerKey
      ? window.atob(customerKey)
      : codeSecondFactorForFaceEvent
      ? window.atob(codeSecondFactorForFaceEvent)
      : ''
  );
  const [showWarnTextDialog, setShownWarnTextDialog] = useState(false);
  const [warnTextMessage, setWarnTextMessage] = useState();
  const searchStartNumber = props.urlSearchSn;
  const isEventPage = props.match.path === '/:language/event/:id';
  const isMainSearchPage = props.match.path === '/:language/event/:id/search/:startnumber';

  const stillProcessigRef = useRef();

  const handleInputChange = (name, value) => {
    switch (name) {
      case 'startNumber':
        setStartNumber(value);
        break;
      case 'secondFactor':
        setSecondFactor(value);
        break;
    }
  };

  const handleExtendedSearchHintClosed = () => {
    if (!extendedSearchExpand) {
      onExtendedSearchExpand();
    }
  };

  const extendedOptionsAvailableAfterMainSearch =
    searchStartNumber && (hasSmartSearchTab || hasDualSearchTab);
  const extendedOptionsAvailableBeforeMainSearch =
    hasGpsSearchTab || hasCategorySearchTab;
  const hasExtendedOptionsVisible =
    extendedOptionsAvailableAfterMainSearch || extendedOptionsAvailableBeforeMainSearch;

  useEffect(() => {
    if (stillProcessigRef && setStillProcessingPosition) {
      setStillProcessingPosition(stillProcessigRef);
    }
  }, [stillProcessigRef]);

  useEffect(() => {
    if (isMainEventPage) {
      if (eventSecondFactorType != FACTOR_CODE) {
        if (!onlineEvent.participantUser && onlineEvent.dialogBeforeSecondFactor) {
          setShownWarnTextDialog(true);
          setWarnTextMessage(onlineEvent.dialogBeforeSecondFactor);
        }
        if (onlineEvent.participantUser && onlineEvent.dialogBeforeSearch) {
          setShownWarnTextDialog(true);
          setWarnTextMessage(onlineEvent.dialogBeforeSearch);
        }
      } else {
        if (onlineEvent.dialogBeforeSecondFactor && !codeSecondFactorForFaceEvent) {
          setShownWarnTextDialog(true);
          setWarnTextMessage(onlineEvent.dialogBeforeSecondFactor);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (onlineEvent.dialogBeforeSearch && codeSecondFactorForFaceEvent && isMainEventPage) {
      setShownWarnTextDialog(true);
      setWarnTextMessage(onlineEvent.dialogBeforeSearch);
    }
  }, [codeSecondFactorForFaceEvent]);

  return (
    <div ref={ref}>
      <div className="row">
        <EventInfoCard
          eventInfo={onlineEvent}
          onExpandBestof={onExpandBestof}
          bestOfAvailable={bestOfAvailable}
          eventBannerActAsLink={isEventPage ? false : true}
          handleScrollToOffer={handleScrollToOffer}
          shouldShowAdditionalOffer={shouldShowAdditionalOffer}
        />
        {onlineEvent.mainSearch != MAIN_SEARCH_INACTIVE && (
          <>
            {onlineEvent.participantUser && (
              <StartNumberSearch
                setFormRef={setFormRef}
                info={onlineEvent}
                onInputChange={handleInputChange}
                onSearchTrigger={onSearchTrigger}
                onExtendedSearchHintClosed={handleExtendedSearchHintClosed}
                startNumber={startNumber}
                secondFactor={secondFactor}
                showSearchInstructionsDialog={showSearchInstructionsDialog}
                hasExtendedSearch={hasExtendedSearch}
                showHowToBuyHint={showHowToBuyHint}
                onShowHowToBuyHint={onShowHowToBuyHint}
                cookies={cookies}
                isAccessGrantedByValidCodeFaceEvent={isAccessGrantedByValidCodeFaceEvent}
                codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
                hasExtendedOptionsVisible={hasExtendedOptionsVisible}
              />
            )}
            {!onlineEvent.participantUser && (
              <SecondFactorGuard
                setFormRef={setFormRef}
                eventSgId={onlineEvent.sgId}
                shouldShowAdditionalInstruction={shouldShowAdditionalInstruction}
                userLoginEmail={userLoginEmail}
                startNumber={startNumber}
                mainSearch={onlineEvent.mainSearch}
                showHowToBuyHint={showHowToBuyHint}
                onShowHowToBuyHint={onShowHowToBuyHint}
              />
            )}
          </>
        )}
        {onlineEvent.mainSearch === MAIN_SEARCH_INACTIVE && (
          <>
            <div className="col-xs-16 col-sm-6 ">
              <div
                className="info-box-container"
                dangerouslySetInnerHTML={{ __html: onlineEvent.noMainSearchText }}
              />
              <GDPRHint className="mt-15 mb-15 ml-15 mr-15" />
              {hasExtendedSearch && (
                <div className="startnumber-search-hint">
                  <a href="#" onClick={() => onExtendedSearchExpand()}>
                    <FormattedMessageWrappedInSpan
                      id="FaceRecognitionUpload.alternateSearchHint"
                      defaultMessage="Alternative search options"
                    />
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showStillProcessingHint && (
        <div className="row" ref={stillProcessigRef}>
          <div className="col-xs-16 col-sm-12 col-sm-offset-2">
            <StillProcessingImagesInfo
              handleScrollToOffer={handleScrollToOffer}
              onGoBackToOffer={goBackToMainSearchResults}
              version={isMainSearchPage ? PARTICIPANT : ALTERNATIVE}
            />
          </div>
        </div>
      )}
      {hasExtendedSearch && (
        <>
          <ExtendedSearch
            startnumber={startNumber}
            searchStartnumber={searchStartNumber}
            event={onlineEvent}
            selectedCategoryId={extendedSearchSelectedCategoryId}
            selectedTab={extendedSearchSelectedTab}
            expand={extendedSearchExpand}
            onTabChange={onExtendedSearchTabChange}
            hasSmartSearchTab={hasSmartSearchTab}
            hasCategorySearchTab={hasCategorySearchTab}
            hasGpsSearchTab={hasGpsSearchTab}
            hasDualSearchTab={hasDualSearchTab}
            notOnMainSearchPage={notOnMainSearchPage}
            codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
            setAltSearchRef={setAltSearchRef}
          />
        </>
      )}
      {showWarnTextDialog && (
        <SearchInstructionsDialog
          message={warnTextMessage}
          open={showWarnTextDialog}
          onAccept={() => setShownWarnTextDialog(false)}
        />
      )}
    </div>
  );
});

EventOnlineView.displayName = 'EventOnlineView';

EventOnlineView.defaultProps = {
  hasExtendedSearch: true,
  urlSearchSn: '',
};

EventOnlineView.propTypes = {
  match: PropTypes.object,
  extendedSearchSelectedCategoryId: PropTypes.string,
  urlSn: PropTypes.string,
  urlSearchSn: PropTypes.string,
  customerKey: PropTypes.string,
  bestOfAvailable: PropTypes.bool,
  extendedSearchExpand: PropTypes.bool,
  hasSmartSearchTab: PropTypes.bool,
  hasCategorySearchTab: PropTypes.bool,
  hasGpsSearchTab: PropTypes.bool,
  hasDualSearchTab: PropTypes.bool,
  hasExtendedSearch: PropTypes.bool,
  showSearchInstructionsDialog: PropTypes.bool,
  extendedSearchSelectedTab: PropTypes.number,
  onlineEvent: PropTypes.object,
  onExpandBestof: PropTypes.func,
  onExtendedSearchTabChange: PropTypes.func,
  onExtendedSearchExpand: PropTypes.func,
  onSearchTrigger: PropTypes.func,
  handleScrollToOffer: PropTypes.func,
  shouldShowAdditionalOffer: PropTypes.bool,
  notOnMainSearchPage: PropTypes.bool,
  setFormRef: PropTypes.func,
  setAltSearchRef: PropTypes.func,
  shouldShowAdditionalInstruction: PropTypes.bool,
  userLoginEmail: PropTypes.string,
  showHowToBuyHint: PropTypes.bool,
  onShowHowToBuyHint: PropTypes.func,
  isAccessGrantedByValidCodeFaceEvent: PropTypes.bool,
  codeSecondFactorForFaceEvent: PropTypes.string,
  cookies: PropTypes.object,
  showStillProcessingHint: PropTypes.bool,
  onBuyFotoFlat: PropTypes.func,
  setStillProcessingPosition: PropTypes.func,
  goBackToMainSearchResults: PropTypes.func,
  eventSecondFactorType: PropTypes.string,
};

export { EventOnlineView };

export default createFragmentContainer(withRouter(EventOnlineView), {
  onlineEvent: graphql`
    fragment EventOnlineView_onlineEvent on Event {
      id
      sgId
      hostResultUrl
      name
      participantUser
      mainSearch
      noMainSearchText
      dialogBeforeSearch
      dialogBeforeSecondFactor
      ...EventInfoCard_eventInfo
      ...StartNumberSearch_info
      ...ExtendedSearch_event
    }
  `,
});
