/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaListItem_item$ref: FragmentReference;
declare export opaque type MediaListItem_item$fragmentType: MediaListItem_item$ref;
export type MediaListItem_item = {|
  +id: string,
  +sgId: ?number,
  +name: ?string,
  +shotDate: ?any,
  +recheckStatus: ?string,
  +variants: ?$ReadOnlyArray<?{|
    +name: ?string,
    +url: ?string,
  |}>,
  +$refType: MediaListItem_item$ref,
|};
export type MediaListItem_item$data = MediaListItem_item;
export type MediaListItem_item$key = {
  +$data?: MediaListItem_item$data,
  +$fragmentRefs: MediaListItem_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaListItem_item",
  "type": "Media",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shotDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recheckStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "variants",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaFile",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4df16a2ee629c1693812d6357074638a';

module.exports = node;
