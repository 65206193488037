import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import CartItem from './CartItem';
import { PhotoBookMsg } from '../../messages';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartLineItemPhotoBook(props) {
  const { lineItem, ...other } = props;

  return (
    <CartItem
      icon={
        <div className="cart-item-icon-container cart-item-icon-container_flex col-xs-6 col-xs-5">
          <img src="/images/packshot-7992.png" />
        </div>
      }
      lineItem={lineItem}
      subtitle={
        <FormattedMessageWrappedInSpan
          id="cartLineItemPhotoBook.additionalShipping"
          defaultMessage="Shipping costs not included"
        />
      }
      subtitleHasHelpIcon={true}
      title={<PhotoBookMsg />}
      {...other}
    />
  );
}

CartLineItemPhotoBook.propTypes = {
  lineItem: PropTypes.object,
};

export { CartLineItemPhotoBook };

export default CartLineItemPhotoBook;
