/**
 * @flow
 * @relayHash dd2ce3c2324e9a15e0d61cd0bdbfaba3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PayWithAdyenInput = {|
  data: string,
  orderId: string,
|};
export type PayWithAdyenMutationVariables = {|
  input: PayWithAdyenInput
|};
export type PayWithAdyenMutationResponse = {|
  +payWithAdyen: ?{|
    +merchantReference: ?string,
    +resultCode: ?string,
    +action: ?string,
  |}
|};
export type PayWithAdyenMutation = {|
  variables: PayWithAdyenMutationVariables,
  response: PayWithAdyenMutationResponse,
|};
*/


/*
mutation PayWithAdyenMutation(
  $input: PayWithAdyenInput!
) {
  payWithAdyen(input: $input) {
    merchantReference
    resultCode
    action
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PayWithAdyenInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "payWithAdyen",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PayWithAdyenPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "merchantReference",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "resultCode",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "action",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PayWithAdyenMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PayWithAdyenMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PayWithAdyenMutation",
    "id": null,
    "text": "mutation PayWithAdyenMutation(\n  $input: PayWithAdyenInput!\n) {\n  payWithAdyen(input: $input) {\n    merchantReference\n    resultCode\n    action\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0188ea923a05f49fff915c25c50a03a';

module.exports = node;
