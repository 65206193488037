import React, { useEffect } from 'react';
import ScrollOut from 'scroll-out';
import { CountUp } from 'countup.js';

import { FormattedMessageWrappedInSpan } from '../../../components/misc';

import AboutPreparationSvg from './AboutPreparationSvg';
import AboutPersonaldataSvg from './AboutPersonaldataSvg';
import AboutStartnumberSvg from './AboutStartnumberSvg';
import AboutWebshopSvg from './AboutWebshopSvg';
import AboutTestimonials from './AboutTestimonials';
import AboutEditing from './AboutEditing';
import { useWindowSize } from '../../../hooks';
import isMobile from '../../../utils/isMobile';

import aboutHistoryImage from '../../../static/images/aboutPage/about-history.jpg';
import aboutBarbaraImage from '../../../static/images/aboutPage/about-barbara.jpg';
import aboutBenediktImage from '../../../static/images/aboutPage/about-benedikt.jpg';
import aboutChristianImage from '../../../static/images/aboutPage/about-christian.jpg';
// import aboutDeniseImage from '../../../static/images/aboutPage/about-denise.jpg';
import aboutGoranImage from '../../../static/images/aboutPage/about-goran.jpg';
import aboutGregImage from '../../../static/images/aboutPage/about-greg.jpg';
import aboutGuidoImage from '../../../static/images/aboutPage/about-guido.jpg';
import aboutLaurentImage from '../../../static/images/aboutPage/about-laurent.jpg';
import aboutManuelImage from '../../../static/images/aboutPage/about-manuel.jpg';
import aboutMarcoImage from '../../../static/images/aboutPage/about-marco.jpg';
import aboutMatzeImage from '../../../static/images/aboutPage/about-matze.png';
import aboutNiklasImage from '../../../static/images/aboutPage/about-niklas.jpg';
import aboutSaskiaImage from '../../../static/images/aboutPage/about-saskia.jpg';
import aboutSelmaImage from '../../../static/images/aboutPage/about-selma.jpg';
import aboutTillImage from '../../../static/images/aboutPage/about-till.jpg';
import aboutTomImage from '../../../static/images/aboutPage/about-tom.jpg';
import aboutFotoflat1Image from '../../../static/images/aboutPage/about-fotoflat-1.jpg';
import aboutFotoflat2Image from '../../../static/images/aboutPage/about-fotoflat-2.jpg';
import aboutFotoflat3Image from '../../../static/images/aboutPage/about-fotoflat-3.jpg';
import aboutFotoflat4Image from '../../../static/images/aboutPage/about-fotoflat-4.jpg';
import aboutFotoflat5Image from '../../../static/images/aboutPage/about-fotoflat-5.jpg';
import aboutStartnumberImage from '../../../static/images/aboutPage/about-startnumber.jpg';

const AboutPage = () => {
  const { windowHeight } = useWindowSize();

  useEffect(() => {
    ScrollOut({
      once: true,
      threshold: isMobile() ? 0.3 : 0.7,
      onShown: element => {
        if (element.getAttribute('data-countup')) {
          const animation = new CountUp(
            element,
            parseInt(element.getAttribute('data-countup')),
            {
              startVal: parseInt(element.getAttribute('data-countup-start')),
              duration: 2,
            }
          );
          animation.pauseResume();
        }
      },
    });
  }, []);

  useEffect(() => {
    const fotoFlatImgs = Array.from(document.querySelectorAll('.about-fotoflat-images>*'));
    fotoFlatImgs.forEach((image) => {
      image.addEventListener('mouseover', () => {
        image.classList.add('about-fotoflat-image--hovered');
        const nothoveredImgs = fotoFlatImgs.filter((fotoflatImgOther => fotoflatImgOther != image));
        nothoveredImgs.forEach((notHoveredImg) => {
          notHoveredImg.classList.add('about-fotoflat-image--not-hovered');
        })
      });
      //for mobile
      image.addEventListener('touchstart', () => {
        image.classList.add('about-fotoflat-image--hovered');
        const nothoveredImgs = fotoFlatImgs.filter((fotoflatImgOther => fotoflatImgOther != image));
        nothoveredImgs.forEach((notHoveredImg) => {
          notHoveredImg.classList.add('about-fotoflat-image--not-hovered');
        })
      });
      image.addEventListener('mouseout', () => {
        image.classList.remove('about-fotoflat-image--hovered');
        const allNotHovered =  Array.from(document.getElementsByClassName('about-fotoflat-image--not-hovered'));
        allNotHovered.forEach(notHoveredImg => notHoveredImg.classList.remove('about-fotoflat-image--not-hovered'));
      })
      //for mobile
      image.addEventListener('touchend', () => {
        image.classList.remove('about-fotoflat-image--hovered');
        const allNotHovered =  Array.from(document.getElementsByClassName('about-fotoflat-image--not-hovered'));
        allNotHovered.forEach(notHoveredImg => notHoveredImg.classList.remove('about-fotoflat-image--not-hovered'));
      })
    });
  }, [])

  return (
    <div className="about-container">
      <section className="about-hero-container" style={{ minHeight: windowHeight }}>
        <FormattedMessageWrappedInSpan
          id="aboutPage.title"
          defaultMessage="Sportograf: Our name says everything about us. We are sports enthusiasts who love to take the best possible photos for each participant."
        />
      </section>
      <section
        className="about-history-container about-standard"
        style={{ minHeight: windowHeight }}
      >
        <div>
          <img data-scroll src={aboutHistoryImage} alt="" />
        </div>
        <div data-scroll>
          <FormattedMessageWrappedInSpan
            id="aboutPage.history"
            defaultMessage="Our story began when Tom Janas and Guido Holz - frustrated by photos they were supposed to order as paper prints at a high price during bike races - had the idea to create the world's first purely digital photo service. Said, done. In December 2005 they programmed the first version of the website during their exchange semester in Spain and Italy. The first photo sale was euphorically toasted with a beer and so things took their course."
          />
        </div>
      </section>
      <section className="about-numbers-container" style={{ minHeight: windowHeight }}>
        <div className="about-numbers-badges">
          <div className="about-numbers-badge">
            <div data-scroll data-countup="550" className="about-number">
              550
            </div>
            <div className="about-number-description">
              <FormattedMessageWrappedInSpan
                id="aboutPage.numbersBadge.events"
                defaultMessage="events worldwide"
              />
            </div>
          </div>
          <div className="about-numbers-badge">
            <div data-scroll data-countup="500" className="about-number">
              500
            </div>
            <div className="about-number-description">
              <FormattedMessageWrappedInSpan
                id="aboutPage.numbersBadge.photographers"
                defaultMessage="freelance photographers"
              />
            </div>
          </div>
          <div className="about-numbers-badge">
            <div className="about-number">
              <span data-scroll data-countup="10">
                10
              </span>{' '}
              <span>mio.</span>
            </div>
            <div className="about-number-description">
              <FormattedMessageWrappedInSpan
                id="aboutPage.numbersBadge.athletes"
                defaultMessage="photographed athletes"
              />
            </div>
          </div>
          <div className="about-numbers-badge">
            <div className="about-number">
              <span data-scroll data-countup="50">
                50
              </span>{' '}
              <span>mio.</span>
            </div>
            <div className="about-number-description">
              <FormattedMessageWrappedInSpan
                id="aboutPage.numbersBadge.photos"
                defaultMessage="images per year"
              />
            </div>
          </div>
        </div>
        <div data-scroll className="about-numbers-text">
          <FormattedMessageWrappedInSpan
            id="aboutPage.sacrificeToMakePhotos"
            defaultMessage="In order to take the best possible photos, we get up at 4 o'clock in the morning and arrive at the event site on time for the sunrise, we lie in the mud during an obstacle course race, or stand on a mountain pass road in the Alps in the pouring rain and freezing snow. We are sports(wo)men ourselves and know both perspectives. We know which photos an athlete desires!"
          />
        </div>
        <div className="about-image-grid about-image-grid-top">
          <div>
            <div>
              <img src={aboutBarbaraImage} alt="" />
              <img src={aboutBenediktImage} alt="" />
              <img src={aboutChristianImage} alt="" />
              <img src={aboutGoranImage} alt="" />
              <img src={aboutGregImage} alt="" />
              <img src={aboutGuidoImage} alt="" />
              <img src={aboutTillImage} alt="" />
              <img src={aboutTomImage} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-different-container" style={{ minHeight: windowHeight }}>
        <div className="about-image-grid about-image-grid-bottom">
          <div>
            <div>
              {/* <img className="about-image-grid-featured" src={aboutTeamImage} alt="" /> */}
              <img src={aboutMarcoImage} alt="" />
              <img src={aboutMatzeImage} alt="" />
              <img src={aboutNiklasImage} alt="" />
              <img src={aboutSelmaImage} alt="" />
            </div>
          </div>
        </div>
        <div data-scroll className="about-different-text">
          <FormattedMessageWrappedInSpan
            id="aboutPage.whatMakesUsSpecial"
            defaultMessage="<h1>What differentiates us from others</h1>Sportograf has always had its finger on the pulse of the time. We were the first supplier on the market to make our photos available exclusively for digital download and 15 years later we still have new ideas to make our products and services even better."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
      </section>
      <section
        className="about-standard about-standard-dark"
        style={{ minHeight: windowHeight }}
      >
        <div data-scroll className="about-preparation-animation">
          <AboutPreparationSvg />
        </div>
        <div data-scroll>
          <FormattedMessageWrappedInSpan
            id="aboutPage.preparation"
            defaultMessage="<h1>Careful preparation</h1>In the preparation lies the foundation stone for unique sports photos. Weeks before the event we look at the course of the event and think about where our photographers will take the most spectacular pictures. We are not satisfied with start and finish pictures. No, we want to show the characteristics of a race. On our photos it must be recognizable that the race did not take place in Zurich, but in Berlin, not in the Rocky Mountains, but in the Dolomites, not in Bali, but in the Dominican Republic."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
      </section>
      <section
        className="about-standard about-fotoflat-container"
        style={{ minHeight: windowHeight }}
      >
        <div data-scroll>
          <FormattedMessageWrappedInSpan
            id="aboutPage.fotoFlat"
            defaultMessage="<h1>Foto-Flat</h1>We do not offer overpriced single pictures but sell our pictures in the so-called Foto-Flat - a package, which contains all personal pictures and additionally, a selection of the best generic impressions of the event. We show the emotions of the athlete in close-ups and don't lose sight of the overall picture of the race. Portrait photos are supplemented by wide-angle shots of the athletes in the environment of the race. This model is so successful that it is now being copied by many other photo services - but mostly at higher prices than we are used to."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
        <div data-scroll className="about-fotoflat-animation">
          <div className="about-fotoflat-images" >
            <img src={aboutFotoflat5Image} alt="" />
            <img src={aboutFotoflat4Image} alt="" />
            <img src={aboutFotoflat3Image} alt="" />
            <img src={aboutFotoflat2Image} alt="" />
            <img src={aboutFotoflat1Image} alt="" />
          </div>
        </div>
      </section>
      <div className="about-editing-container" style={{ minHeight: windowHeight }}>
        <div data-scroll className="about-editing-text">
          <FormattedMessageWrappedInSpan
            id="aboutPage.photoProcessing"
            defaultMessage="<h1>Image processing</h1>At Sportograf, every single photo is processed and optimized with a specially developed software. This guarantees an incomparable look and customers can upload their photos directly to social media or share with friends - without applying further filters."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
        <AboutEditing />
      </div>
      <section
        className="about-standard about-standard-dark"
        style={{ minHeight: windowHeight }}
      >
        <div>
          <FormattedMessageWrappedInSpan
            id="aboutPage.numberRecognition"
            defaultMessage="<h1>Number recognition</h1>We developed our software for number recognition and assignment completely in our own IT department. More than 15 years of software development allows us to assign photos precisely to the individual athlete. With this technology we can sort more than one million pictures by bib numbers overnight. By entering the personal bib number, all associated photos are displayed. We are very proud of this software and it is probably Sportograf's best kept secret."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
        <div data-scroll className="about-startnumber-animation">
          <div>
            <img src={aboutStartnumberImage} alt="" />
            <AboutStartnumberSvg />
          </div>
        </div>
      </section>
      <section
        className="about-standard about-personal-data-container"
        style={{ minHeight: windowHeight }}
      >
        <div className="about-personal-data-animation">
          <div>
            <div data-scroll>
              <AboutPersonaldataSvg />
              <div
                data-scroll
                data-countup="912"
                data-countup-start="381"
                className="about-personal-data-animation-countup"
              >
                912
              </div>
            </div>
          </div>
        </div>
        <div data-scroll>
          <FormattedMessageWrappedInSpan
            id="aboutPage.useOfPersonalData"
            defaultMessage="<h1>Dealing with personal data</h1>Not only after the introduction of the new GDPR, customers find their photos in our webshop exclusively through their bib number, never through the name of a participant. Further authentication options: additional entry of date of birth or email address. This way we guarantee: only those who are pictured can see the photo."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
      </section>
      <section
        className="about-standard about-standard-dark"
        style={{ minHeight: windowHeight }}
      >
        <div data-scroll>
          <FormattedMessageWrappedInSpan
            id="aboutPage.webshop"
            defaultMessage="<h1>Webshop</h1>Our webshop is intuitive to use and makes it easy for athletes to find their personal photos. In addition to searching for bib numbers, you can also find your photos by uploading your personal track recording (using Garmin or Strava), or searching at specific locations and time intervals."
	    values={{
	      h1: chunks => <h1>{chunks}</h1>
	    }}
          />
        </div>
        <div data-scroll className="about-webshop-animation">
          <AboutWebshopSvg />
        </div>
      </section>
      <section
        className="about-testimonials-container"
        style={{ minHeight: windowHeight }}
      >
        <h1>
          <FormattedMessageWrappedInSpan
            id="aboutPage.customerTestimonials"
            defaultMessage="Customer Testimonials"
          />
        </h1>
        <div data-scroll className="about-testimonials-carousel">
          <AboutTestimonials />
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
