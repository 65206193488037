import { createTheme } from '@material-ui/core/styles';

// Customizing the theme
const theme = {
  palette: {
    primary: {
      main: '#253F50',
    },
    secondary: {
      main: '#253F50',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  overrides: {
    MuiCard: {
      root: {
        '&.faq-card': {
          paddingRight: '15px',
        },
      },
    },
    MuiCardContent: {
      root: {
        '&.faq-card-text': {
          marginRight: '40px',
        },
      },
    },
    MuiCardHeader: {
      action: {
        marginRight: 0,
      },
      title: {
        color: '#414F5F',
      },
    },
    MuiRadio: {
      root: {
        color: 'currentColor',
        padding: 3,
        marginRight: 5,
        '& .MuiSvgIcon-root': {
          height: 15,
          width: 15,
          color: 'currentColor',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: 'currentColor',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#0E1921',
        color: '#FAFAFB',
        fontSize: '12px',
        padding: '10px',
        '&.hoverable-tooltip': {
          '&:hover': {
            backgroundColor: '#1b2d3b',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#0A5BA0',
      },
    },
    MuiDialog: {
      root: {
        paddingRight: 0, // fixes bug, where in some browsers a right padding was visible
      },
      paper: {
        margin: 0,
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: 0,
      },
      spacing: {
        margin: 0,
      },
    },
    MuiDialogContent: {
      root: {
        borderRadius: '4px',
        minHeight: '200px',
        padding: 0,
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '12px',
        color: 'rgba(0,0,0,0.3)',
        textAlign: 'left',
        lineHeight: 1.5,
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#414F5F',
      },
    },
    MuiInput: {
      root: {
        background: 'transparent',
      },
      input: {
        '&::placeholder': {
          fontSize: '12px',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid rgb(224, 224, 224)',
        },
        '&:hover': {
          '&:before': {
            borderBottom: '1px solid rgb(224, 224, 224) !important;',
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '10px',
        textTransform: 'uppercase',
      },
    },
    MuiSelect: {
      root: {
        background: 'transparent',
        fontSize: '14px',
      },
      selectMenu: {
        background: 'transparent',
        '&:focus': {
          background: 'transparent',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        lineHeight: '14px',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0E1921',
      },
    },
    MuiStepper: {
      root: {
        background: 'transparent',
      },
    },
    MuiStepLabel: {
      alternativeLabel: {
        fontSize: '12px',
      },
    },
    MuiSwitch: {
      colorPrimary: {
        color: '#0A5BA0',
        '&$checked': {
          color: '#0A5BA0',
          '& + $track': {
            backgroundColor: '#0A5BA0',
          },
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 99999,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#A2B6BC',
      },
    },
    MuiTab: {
      root: {
        backgroundColor: '#f8fafb',
        color: '#5E6C7C',
        fontWeight: 'bold',
      },
      wrapper: {
        color: '#5E6C7C',
      },
    },
  },
};

export const customMuiTheme = createTheme(theme);
