import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation RestartProductionMutation($input: RestartProductionInput!) {
    restartProduction(input: $input) {
      status
    }
  }
`;

function commit(environment, eventId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        eventId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.restartProduction.status);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
