/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartAdditionalProduct_additionalProduct$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartAdditionalProductDvd_additionalProduct$ref: FragmentReference;
declare export opaque type CartAdditionalProductDvd_additionalProduct$fragmentType: CartAdditionalProductDvd_additionalProduct$ref;
export type CartAdditionalProductDvd_additionalProduct = {|
  +$fragmentRefs: CartAdditionalProduct_additionalProduct$ref,
  +$refType: CartAdditionalProductDvd_additionalProduct$ref,
|};
export type CartAdditionalProductDvd_additionalProduct$data = CartAdditionalProductDvd_additionalProduct;
export type CartAdditionalProductDvd_additionalProduct$key = {
  +$data?: CartAdditionalProductDvd_additionalProduct$data,
  +$fragmentRefs: CartAdditionalProductDvd_additionalProduct$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartAdditionalProductDvd_additionalProduct",
  "type": "Product",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "CartAdditionalProduct_additionalProduct",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f36f03764f3d6dd73ab191d3755de0c1';

module.exports = node;
