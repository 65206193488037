import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';

import CustomDialog from '../dialogs/CustomDialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormattedMessageWrappedInSpan } from '../../misc';

import GenerateVideoMutation from '../../../mutations/GenerateVideoMutation';
import environment from '../../../environment';

const ENTER_KEY = 'Enter';

const AccountVideoDownloadForm = (props) => {
  const { eventSgId, onDialogClose, open, intl } = props;

  const [failed, setFailed] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [showError, setShowError] = useState(false);
  const [startnumber, setStartnumber] = useState('');
  const [urls, setUrls] = useState({});

  const handleStartnumberInputChanged = (event) => {
    setShowError(false);
    setStartnumber(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY && !isPending) {
      submitForm();
    }
  };

  const submitForm = () => {
    if (!startnumber) {
      setShowError(true);
      return;
    }

    const sn = startnumber.trim();

    if (sn in urls) {
      download(urls[sn]);
    } else {
      generateVideoDownloadLink(sn);
    }
  };

  const getErrorMessage = () => {
    if (showError) {
      return (
        <FormattedMessageWrappedInSpan
          id="accountVideoDownloadForm.inputErrorText"
          defaultMessage="This field is required"
        />
      );
    }
    if (failed) {
      return (
        <FormattedMessageWrappedInSpan
          id="accountVideoDownloadForm.outcomeErrorText"
          defaultMessage="Could not download. Have you given a correct bib number?"
        />
      );
    }

    return false;
  };

  const not_ready = () => {
    return !startnumber || isPending;
  };

  const download = (url) => {
    window.location.href = url;
    onDialogClose();
  };

  const generateVideoDownloadLink = (sn) => {
    setIsPending(true);
    GenerateVideoMutation.commit(environment, eventSgId, sn, (error, url) => {
      if (!error) {
        setFailed(false);
        download(url);
        _setUrl(sn, url);
      } else {
        setFailed(true);
      }
      setStartnumber(sn);
      setIsPending(false);
    });
  };

  const _setUrl = (sn, url) => {
    let copy = urls;

    copy[sn] = url;

    setUrls(copy);
  };

  return (
    <CustomDialog open={open} onRequestClose={onDialogClose}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="accountVideoDownloadForm.heading"
                defaultMessage="Enter your bib number"
                className="mb-15"
              />
            </h1>
            <FormGroup>
              <TextField
                autoComplete="off"
                autoFocus={startnumber === ''}
                className="notification-input"
                error={getErrorMessage()}
                id="startnumber"
                helperText={getErrorMessage()}
                label={
                  <FormattedMessageWrappedInSpan
                    id="accountVideoDownloadForm.startnumberTextFieldLabel"
                    defaultMessage="Bib Number"
                  />
                }
                onChange={handleStartnumberInputChanged}
                onKeyPress={handleKeyPress}
                placeholder={intl.formatMessage(
                  translations.startnumberTextFieldPlaceholder
                )}
                value={startnumber}
              />
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action with-spinner">
            <button
              className={classNames('accept', { disabled: not_ready() })}
              disabled={not_ready()}
              onClick={submitForm}
            >
              <FormattedMessageWrappedInSpan
                id="accountVideoDownloadForm.acceptButton"
                defaultMessage="Download"
              />
              {isPending && (
                <div className="download-dropdown-expand-icon-container">
                  <CircularProgress
                    classes={{ circle: 'circular-progress-circle-copy-color-medium' }}
                    size={20}
                    thickness={3}
                  />
                </div>
              )}
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

const translations = defineMessages({
  startnumberTextFieldPlaceholder: {
    id: 'accountVideoDownloadForm.startnumberTextFieldPlaceholder',
    defaultMessage: 'Enter one bib number',
  },
});

AccountVideoDownloadForm.propTypes = {
  eventSgId: PropTypes.number,
  onDialogClose: PropTypes.func,
  open: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(AccountVideoDownloadForm);
export { AccountVideoDownloadForm };
