import { useEffect, useState } from 'react';
import isMobile from '../utils/isMobile'

// getting the Height / Width of the window on a specific device
function useWindowSize() {
  const [size, setSize] = useState(getSize);
  const event = isMobile() ? 'orientationchange' : 'resize'

  function handleResize() {
    setSize(getSize())
  }

  useEffect(() => {
    window.addEventListener(event, handleResize);

    return () => {
      window.removeEventListener(event, handleResize);
    };
  },[event]);

  return size;
}

function getSize() {
  return {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  }
}

export default useWindowSize;
