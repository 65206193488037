import React from 'react';
import { FormattedDate } from 'react-intl';

import { FormattedMessageWrappedInSpan } from '../../components/misc';

export default function TermsAndConditionPage(props) {
  return (
    <div className="container-960 container-page">
      <h2>
        <FormattedMessageWrappedInSpan id="termsAndConditionPage.title" />
      </h2>
      <br />
      <div>
        <b>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.subHeader" />
        </b>
        <p>
          <FormattedDate
            value={new Date(2016, 7, 18)}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;1&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.scope.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.scope.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.scope.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;2&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.offer.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.offer.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.offer.pTwo" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.offer.pThree" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.offer.pFour" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;3&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.prices.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.prices.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.prices.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;4&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.delivery.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.delivery.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.delivery.pTwo" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.delivery.pThree" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;5&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.reservation.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.reservation.pOne" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;6&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.shipping.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.shipping.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.shipping.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;7&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.customer.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.customer.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.customer.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;8&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.warranty.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.warranty.pOne" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;9&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.dataProtection.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.dataProtection.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.dataProtection.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;10&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pTwo" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pThree" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pFour" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pFive" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.imageRights.pSix" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;11&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.copyright.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.copyright.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.copyright.pTwo" />
        </p>
      </div>
      <div>
        <b>
          §&nbsp;12&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.title" />
        </b>
        <ul>
          <li>
            <b>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.right.title" />
            </b>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.right.pOne" />
            </p>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.right.pTwo" />
            </p>
          </li>
          <li>
            <b>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.consequences.title" />
            </b>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.consequences.pOne" />
            </p>
          </li>
          <li>
            <b>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.extinguishment.title" />
            </b>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.extinguishment.pOne" />
            </p>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.extinguishment.pTwo" />
            </p>
            <p>
              <FormattedMessageWrappedInSpan id="termsAndConditionPage.cancellation.extinguishment.pThree" />
            </p>
          </li>
        </ul>
      </div>
      <div>
        <b>
          §&nbsp;13&nbsp;
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.finalProvisions.title" />
        </b>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.finalProvisions.pOne" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.finalProvisions.pTwo" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.finalProvisions.pThree" />
        </p>
        <p>
          <FormattedMessageWrappedInSpan id="termsAndConditionPage.finalProvisions.pFour" />
        </p>
      </div>
    </div>
  );
}
