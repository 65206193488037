/**
 * @flow
 * @relayHash ac8ae662ed680c5a97ca78bca58d42d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventListItem_event$ref = any;
export type SubeventsPageQueryVariables = {|
  eventId: string
|};
export type SubeventsPageQueryResponse = {|
  +viewer: ?{|
    +event: ?{|
      +id: string,
      +subEvents: ?$ReadOnlyArray<?{|
        +id: string,
        +sgId: ?number,
        +state: ?string,
        +$fragmentRefs: EventListItem_event$ref,
      |}>,
    |}
  |}
|};
export type SubeventsPageQuery = {|
  variables: SubeventsPageQueryVariables,
  response: SubeventsPageQueryResponse,
|};
*/


/*
query SubeventsPageQuery(
  $eventId: ID!
) {
  viewer {
    event(id: $eventId) {
      id
      subEvents {
        id
        sgId
        state
        ...EventListItem_event
      }
    }
    id
  }
}

fragment EventListItem_event on Event {
  id
  sgId
  banner
  beginsAt
  name
  mainSearch
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SubeventsPageQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subEvents",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "EventListItem_event",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SubeventsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subEvents",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "banner",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "beginsAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mainSearch",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SubeventsPageQuery",
    "id": null,
    "text": "query SubeventsPageQuery(\n  $eventId: ID!\n) {\n  viewer {\n    event(id: $eventId) {\n      id\n      subEvents {\n        id\n        sgId\n        state\n        ...EventListItem_event\n      }\n    }\n    id\n  }\n}\n\nfragment EventListItem_event on Event {\n  id\n  sgId\n  banner\n  beginsAt\n  name\n  mainSearch\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1c13da5fa01e5b4753f4589e7a47f3e';

module.exports = node;
