import React, { Component } from 'react';
import { FormattedMessageWrappedInSpan } from '../../components/misc';
import isEmailProper from '../../utils/isEmailProper';

import { getFromLocalStorage } from '../../utils/browser-storage';

const ENTER_KEY = 'Enter';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: getFromLocalStorage('email') || '',
      name: '',
      message: '',
      showInputErrorMsg: false,
      emailIsValid: true,
    };
  }

  _handleInputChange = (event, newValue) => {
    this.setState({
      showInputErrorMsg: false,
      emailIsValid: true,
      [event.target.name]: newValue,
    });
  };

  _handleSubmitButtonClicked = () => {
    if (!this._checkInputFields()) {
      this.setState({ showInputErrorMsg: true });
      return;
    }

    if (!isEmailProper(this.state.email)) {
      this.setState({
        showInputErrorMsg: true,
        emailIsValid: false,
      });
      return;
    }

    console.log('TODO - Send email to support');
  };

  _handleKeyPressed = (event) => {
    if (event.key === ENTER_KEY) {
      this._handleSubmitButtonClicked();
    }
  };

  _checkInputFields() {
    for (let key in this.state) {
      if (this.state[key] === '') {
        return false;
      }
    }
    return true;
  }
  
  render() {
    return (
      <div className="container-960 container-page">
        <h2>
          <FormattedMessageWrappedInSpan
            id="contactPage.title"
            defaultMessage="Contact"
          />
        </h2>
        <br />
        <h1>
          <FormattedMessageWrappedInSpan
            id="contactPage.subtitle"
            defaultMessage="HOW TO CONTACT US"
          />
        </h1>
        <br />
        <h3>
          <span>Sportograf Digital Solutions GmbH</span>
          <br />
          <span>Süsterfeldstr 170</span>
          <br />
          <span>52072 Aachen</span>
          <br />
          <FormattedMessageWrappedInSpan
            id="contactPage.info.country"
            defaultMessage="Germany"
          />
          <br />
          <span>
            <FormattedMessageWrappedInSpan
              id="contactPage.info.email"
              defaultMessage="Email"
            />
            :&nbsp;
            <a href="mailto:support@sportograf.com" style={{ textDecoration: 'none' }}>
              support@sportograf.com
            </a>
          </span>
        </h3>
      </div>
    );
  }
}

export default ContactPage;
