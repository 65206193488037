/* global amazon: true */
/* global AMAZON_MERCHANT_ID: true */
/* global AMAZON_PUBLIC_KEY_ID: true */

const amazonLanguages = ["en_GB", "de_DE", "fr_FR", "it_IT", "es_ES"]

const renderAmazonComponent = (element, config) => {
  const {currency, locale} = config;

  config.onReady(
    amazon.Pay.renderButton(element, {
        "ledgerCurrency": currency,
        "placement": "Checkout",
        "merchantId": AMAZON_MERCHANT_ID,
        "productType": "PayOnly",
        "checkoutLanguage": getCheckoutLanguage(locale),
        "buttonColor": "Gold",
        "publicKeyId": AMAZON_PUBLIC_KEY_ID,
    })
  )
}

const getCheckoutLanguage = (locale) => {
  locale = locale.replace("-","_");
  if (amazonLanguages.includes(locale))
    return locale;
  else
    return "en_GB";
}

const initAmazonComponent = (element, config) => {
  renderAmazonComponent(element, config);
}

export default initAmazonComponent;
