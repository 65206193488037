import React from 'react'
import Slider from 'react-slick';

import gfnyTestimonialImage from '../../../static/images/aboutPage/about-testimonial-gfny.png';
import blackForestTestimonialImage from '../../../static/images/aboutPage/about-testimonial-black-forest.jpg';
import hsmTestimonialImage from '../../../static/images/aboutPage/about-testimonial-hsm.jpg';
import octagonTestimonialImage from '../../../static/images/aboutPage/about-testimonial-octagon.png';
import spartanTestimonialImage from '../../../static/images/aboutPage/about-testimonial-spartan.png';
import sccTestimonialImage from '../../../static/images/aboutPage/about-testimonial-scc.png';

const AboutTestimonials = () => {
  return (
    <div>
      <Slider
        // adaptiveHeight={true}
        // autoplay={true}
        // swipe={false}
        // dots={true}
        // draggable={false}
        // arrows={true}
        // dots={true}
        // autoplaySpeed={20000}
        // pauseOnHover={false}
        // touchMove={false}
        // lazyLoad={true}
      >
        <div className="about-testimonial">
          <div>
            <div>
              <img src={gfnyTestimonialImage} alt=""/>
            </div>
            <div>
              GFNY is a global road cycling series which has constantly grown over the past years and now offers 20 events for cycling enthusiasts worldwide. We have worked with Sportograf since our first race in NYC 2011 and love the high end quality of photos they provide to our participants. Sportograf photographers are extremely skilled and highly motivated to work long hours in order to capture the best possible images. At GFNY, our sole focus is on the rider. So being able to offer an affordable photo flat of a rider’s pictures instead of single images at ridiculous prices is a natural extension of that.
            </div>
          </div>
        </div>
        <div className="about-testimonial">
          <div>
            <div>
              <img src={spartanTestimonialImage} alt=""/>
            </div>
            <div>
              Spartan Europa arbeitet bereits seit 2015 mit Sportograf zusammen. Die Kooperation fand immer auf Augenhöhe statt und wir arbeiten gemeinsam an Ideen, wie wir den Service für die Athleten noch weiter verbessern können. Unsere Teilnehmer lieben die Fotos von Sportograf und warten oft ungeduldig, um sie möglichst schnell ihren Followern auf Instagram zeigen zu können. Die professionellen Fotos sind ein großes Plus für unsere Events und wir freuen uns, mit Sportograf den besten Fotodienst an Bord zu haben. Egal ob Actionbild oder Hero Tent - die Bilder sind immer super!
            </div>
          </div>
        </div>
        <div className="about-testimonial">
          <div>
            <div>
              <img src={hsmTestimonialImage} alt=""/>
            </div>
            <div>
              Wir arbeiten seit 2 Jahren mit Sportograf für zahlreiche unserer Events wie den Zürich Marathon, die Spartacus Runs oder die Swiss Trail Tour zusammen. Der Austausch ist sehr kooperativ, wir können gemeinsam spannende Geschichten schreiben und einen attraktiven Mehrwert für unsere Teilnehmer generieren. Sportograf liefert viele hervorragende Illustrationen aus dem Wettkampf und überliefert damit die mit einem Event verbundenen Emotionen.
            </div>
          </div>
        </div>
        <div className="about-testimonial">
          <div>
            <div>
              <img src={octagonTestimonialImage} alt=""/>
            </div>
            <div>
              Since 2012, Octagon Esedos and Sportograf have a cooperation for several of cycling events, such as the MTB stage races Andalucía, La Rioja, Asturias and Catalunya Bike Race, as well as two cyclosportives, Quebrantahuesos and Tres Nacions. We’re impressed with the high quality photos and the efficient handling of so many images of each event. As an organiser it’s great to partner with an international company that has worldwide exposure and relationships. The participants value Sportograf’s photos because they conserve their memories and emotions, and show the environment where it took place.
            </div>
          </div>
        </div>
        <div className="about-testimonial">
          <div>
            <div>
              <img src={blackForestTestimonialImage} alt=""/>
            </div>
            <div>
              Schon seit 2007 ist Sportograf jedes Jahr beim Black Forest ULTRA Bike Marathon mit dabei und macht Fotos von den Teilnehmern. Die Fotografen stehen früh morgens auf, um lange vor allen Fahrern am richtigen Ort zu stehen und dort spektakuläre Bilder für jeden zu machen. Wir schätzen das freundschaftliche Verhältnis zu Sportograf und fachsimpeln auch gerne mal über die neuesten Mountainbike-Trends. Sie an Bord zu haben ist auf jeden Fall eine Bereicherung für unseren Bike Marathon und alle Teilnehmer!
            </div>
          </div>
        </div>
        <div className="about-testimonial">
          <div>
            <div>
              <img src={sccTestimonialImage} alt=""/>
            </div>
            <div>
              Angefangen hat alles 2013 bei den Cross Days, unserem fototechnisch wohl anspruchsvollsten Event. 2018 haben wir gemeinsam einen gewaltigen Schritt nach vorne gemacht, als wir den Fotoservice unserer beiden größten Veranstaltungen, dem GENERALI BERLINER HALBMARATHON und dem BMW BERLIN-MARATHON, auch Sportograf anvertrauten. Wir wurden nicht enttäuscht. Im Gegenteil: die Sportografen sind kompetent und kreativ, sie denken mit und sind schnell. So ganz nebenbei macht es großen Spaß mit ihnen zusammenzuarbeiten, denn das Team besteht aus lauter sympathischen Menschen. Weitere Events wie z.B. der AVON Frauenlauf Berlin sind 2019 noch hinzugekommen. Wir freuen uns auf eine erfolgreiche Zusammenarbeit und viele spannende Ideen in den nächsten Jahren.
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default AboutTestimonials;
