/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartLineItemFotoFlat_lineItem$ref: FragmentReference;
declare export opaque type CartLineItemFotoFlat_lineItem$fragmentType: CartLineItemFotoFlat_lineItem$ref;
export type CartLineItemFotoFlat_lineItem = {|
  +media: ?{|
    +mediaList: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +variants: ?$ReadOnlyArray<?{|
            +name: ?string,
            +url: ?string,
          |}>
        |}
      |}>
    |}
  |},
  +product: ?{|
    +event: ?{|
      +name: ?string,
      +mainSearch: ?string,
    |}
  |},
  +productParams: ?{|
    +startnumber?: ?string
  |},
  +$refType: CartLineItemFotoFlat_lineItem$ref,
|};
export type CartLineItemFotoFlat_lineItem$data = CartLineItemFotoFlat_lineItem;
export type CartLineItemFotoFlat_lineItem$key = {
  +$data?: CartLineItemFotoFlat_lineItem$data,
  +$fragmentRefs: CartLineItemFotoFlat_lineItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CartLineItemFotoFlat_lineItem",
  "type": "LineItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "ExtendedMediaConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "mediaList",
          "storageKey": "mediaList(first:50)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            }
          ],
          "concreteType": "MediaConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "MediaEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Media",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "variants",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MediaFile",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "url",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "product",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "mainSearch",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productParams",
      "storageKey": null,
      "args": null,
      "concreteType": null,
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "FotoFlatParams",
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startnumber",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '33052fe5b199489c8e7eae6232ae17fb';

module.exports = node;
