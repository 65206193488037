/**
 * @flow
 * @relayHash b4b58e6338f6cde13d7690ff1be52068
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FinishPaypalExpressPaymentInput = {|
  orderId: string
|};
export type FinishPaypalExpressPaymentMutationVariables = {|
  input: FinishPaypalExpressPaymentInput
|};
export type FinishPaypalExpressPaymentMutationResponse = {|
  +finishPaypalExpressPayment: ?{|
    +order: ?{|
      +id: string,
      +orderid: ?string,
      +state: ?string,
    |}
  |}
|};
export type FinishPaypalExpressPaymentMutation = {|
  variables: FinishPaypalExpressPaymentMutationVariables,
  response: FinishPaypalExpressPaymentMutationResponse,
|};
*/


/*
mutation FinishPaypalExpressPaymentMutation(
  $input: FinishPaypalExpressPaymentInput!
) {
  finishPaypalExpressPayment(input: $input) {
    order {
      id
      orderid
      state
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FinishPaypalExpressPaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "finishPaypalExpressPayment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FinishPaypalExpressPaymentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "order",
        "storageKey": null,
        "args": null,
        "concreteType": "Order",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orderid",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FinishPaypalExpressPaymentMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FinishPaypalExpressPaymentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FinishPaypalExpressPaymentMutation",
    "id": null,
    "text": "mutation FinishPaypalExpressPaymentMutation(\n  $input: FinishPaypalExpressPaymentInput!\n) {\n  finishPaypalExpressPayment(input: $input) {\n    order {\n      id\n      orderid\n      state\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c24bca0be1cbe6998ce5f4af788564e8';

module.exports = node;
