import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GDPRHint from '../GDPRHint';

import { FormattedMessageWrappedInSpan } from '../../misc';
import Collapse from '@material-ui/core/Collapse';

import WebcamDialog from '../dialogs/WebcamDialog/WebcamDialog';

function FaceRecognitionUpload(props) {
  const { onChangeUpload, onChangeWebcam, onChangePrivacyPolicy, onChangeIdentityConfirmation, useWebcam, imgSrc } =
    props;

  const [showWebcam, setShowWebcam] = useState(false);

  return (
    <>
      <div className="face-recognition-upload-container">
        <div className="container-label">
          {(useWebcam && (
            <FormattedMessageWrappedInSpan
              id="FaceRecognitionUpload.searchLabelWebcamOnly"
              defaultMessage="Take a selfie with your webcam"
            />
          )) || (
            <FormattedMessageWrappedInSpan
              id="FaceRecognitionUpload.searchLabel"
              defaultMessage="Take a selfie or upload a photo of you"
            />
          )}
        </div>
        <div className="face-recognition-file-upload">
          <div className="face-recognition-img">
            {(props.imgSrc !== null && props.imgSrc !== undefined && (
              <img src={props.imgSrc} />
            )) || <span />}
          </div>
          {(useWebcam && (
            <input
              id={props.elementId}
              type="button"
              onClick={(e) => setShowWebcam(!showWebcam)}
            />
          )) || (
            <input
              id={props.elementId}
              type="file"
              accept="image/*"
              onChange={onChangeUpload}
            />
          )}
        </div>
        <div className="startnumber-search-sublabel">
          <FormattedMessageWrappedInSpan
            id="FaceRecognitionUpload.searchSubLabel"
            defaultMessage="The selfie will not be uploaded before you click 'Search with selfie' and will not be stored after your search request."
          />
        </div>
        <Collapse in={imgSrc !== null && imgSrc !== undefined} timeout="auto">
          <GDPRHint
            className="mb-15 ml-15 mr-15"
            faceNote={true}
            onChangePrivacyPolicy={onChangePrivacyPolicy}
            onChangeIdentityConfirmation={onChangeIdentityConfirmation}
          />
        </Collapse>
      </div>
      <WebcamDialog
        open={showWebcam}
        onRequestClose={setShowWebcam}
        onChange={onChangeWebcam}
      />
    </>
  );
}

FaceRecognitionUpload.defaultProps = {
  imgSrc: null,
  elementId: 'faceRecoInput',
};

FaceRecognitionUpload.propTypes = {
  imgSrc: PropTypes.string,
  elementId: PropTypes.string,
  useWebcam: PropTypes.bool,
  onChangeUpload: PropTypes.func,
  onChangeWebcam: PropTypes.func,
  onChangePrivacyPolicy: PropTypes.func,
  onChangeIdentityConfirmation: PropTypes.func,
};

export { FaceRecognitionUpload };

export default FaceRecognitionUpload;
