import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { getAppLanguage } from '../../intl/languages';

import CookieSettingsDialog from '../content/dialogs/CookieSettingsDialog';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const Footer = (props) => {
  const { statsCookies, socialCookies, intl, onCookiesChange } = props;

  const [cookieSettingsDialogOpen, setCookieSettingsDialogOpen] = useState(false);

  return (
    <Fragment>
      <div className="footer-container-outer">
        <div className="footer-container container-960 container-full-width">
          <div className="row">
            <div className="footer-item col-xs-16 col-sm-4 col-xs-offset-1 col-sm-offset-0 first-sm last-xs">
              <div className="footer-payment-methods">
                <div className="footer-payment-amazon-pay" />
                <div className="footer-payment-bank-transfer" />
                <div className="footer-payment-visa" />
                <div className="footer-payment-mastercard" />
                <div className="footer-payment-paypal" />
                <div className="footer-payment-sofort-uberweisung"></div>
                <div className="footer-payment-ideal"></div>
                <div className="footer-payment-bancontact"></div>
                <div className="footer-payment-giropay"></div>
                <div className="footer-payment-postepay"></div>
                <div className="footer-payment-alipay"></div>
              </div>
            </div>
            <div className="footer-item col-sm-offset-1 col-sm-3 col-xs-8 col-xs-offset-1">
              <Link to="/">{intl.formatMessage(translations.homeItem)}</Link>
              <a
                href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/home`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {intl.formatMessage(translations.faqItem)}
              </a>
              <a
                href="https://blog.sportograf.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                {intl.formatMessage(translations.blogItem)}
              </a>
              <Link to="/jobs">{intl.formatMessage(translations.jobsItem)}</Link>
              <Link to="/about">{intl.formatMessage(translations.aboutItem)}</Link>
            </div>
            <div className="footer-item col-sm-offset-1 col-sm-3 col-xs-7">
              <Link to="/contact">{intl.formatMessage(translations.contact)}</Link>
              <Link to="/legal">{intl.formatMessage(translations.legal)}</Link>
              <Link to="/agb">{intl.formatMessage(translations.condition)}</Link>
              <Link to="/privacy">{intl.formatMessage(translations.privacy)}</Link>
              <a onClick={() => setCookieSettingsDialogOpen(true)}>
                {intl.formatMessage(translations.cookies)}
              </a>
            </div>
            <div className="footer-item footer-social-icons col-sm-offset-1 col-sm-3 col-xs-16">
              <a
                href="https://www.instagram.com/sportograf/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/sportograf/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/sportograf"
                target="_blank"
                rel="noreferrer noopener"
              >
                {/* X (Twitter) icon */}
                <svg class="MuiSvgIcon-root x-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" ><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <CookieSettingsDialog
        statsCookies={statsCookies}
        socialCookies={socialCookies}
        onCookiesChange={onCookiesChange}
        onRequestClose={(needsRefresh) => {
          setCookieSettingsDialogOpen(false);
          if (needsRefresh) {
            refreshPage();
          }
        }}
        open={cookieSettingsDialogOpen}
      />
    </Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

const translations = defineMessages({
  homeItem: {
    id: 'footer.homeItem',
    defaultMessage: 'Home',
  },
  aboutItem: {
    id: 'footer.aboutItem',
    defaultMessage: 'About Us',
  },
  jobsItem: {
    id: 'footer.jobsItem',
    defaultMessage: 'Jobs',
  },
  faqItem: {
    id: 'footer.faqItem',
    defaultMessage: 'FAQ',
  },
  blogItem: {
    id: 'footer.blogItem',
    defaultMessage: 'Blog',
  },
  contact: {
    id: 'footer.navigationItem.contact',
    defaultMessage: 'Contact',
  },
  legal: {
    id: 'footer.navigationItem.legal',
    defaultMessage: 'Legal',
  },
  condition: {
    id: 'footer.navigationItem.condition',
    defaultMessage: 'Terms and Conditions',
  },
  privacy: {
    id: 'footer.navigationItem.privacy',
    defaultMessage: 'Privacy',
  },
  cookies: {
    id: 'footer.navigationItem.cookies',
    defaultMessage: 'Cookies',
  },
});

Footer.propTypes = {
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
  intl: PropTypes.object,
  onCookiesChange: PropTypes.func,
};

export { Footer }
export default injectIntl(Footer);
