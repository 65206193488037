import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import EventOnlineView from '../components/content/event/EventOnlineView';
import Faq from '../components/content/Faq';
import InvalidFaceFileInformation from '../components/content/informations/InvalidFaceFileInformation';
import InvalidStartnumberInformation from '../components/content/informations/InvalidStartnumberInformation';
import LoadingView from '../components/content/LoadingView';
import DualSearchMediaList from '../components/content/lists/DualSearchMediaList';
import withRequestHandler from '../components/hoc/withRequestHandler';
import SecondFactorErrorMsg from '../components/messages/SecondFactorErrorMsg';
import { UserContext } from '../components/context/UserContext'

import { FACTOR_EMAIL, MAIN_SEARCH_FACE } from '../utils/variables';
import { getCustomerKey } from '../utils/browser-storage';
import { includesFotoFlat } from '../utils/common';
import { getMainSearchResultUrl } from '../utils/common';
import { customScrollToComponent } from '../utils/common';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const EventDualSearchPage = ({
  searchDualStartnumberFromUrl,
  onRequestStart,
  onRequestStop,
  relay,
  isLoading,
  snFromUrl,
  viewer,
  history,
  codeSecondFactorForFaceEvent,
}) => {
  const { event } = viewer;
  const [extendedSearchSelectedTab, setExtendedSearchSelectedTab] = useState(3);
  const [extendedSearchExpand, setExtendedSearchExpand] = useState(true);
  const [showNoPhotosFoundInformation, setShowNoPhotosFoundInformation] = useState(false);
  const [searchFormPosition, setSearchFormPosition] = useState(null);
  const { onMarathonFotoChange } = useContext(UserContext);
  const eventCard = useRef();
  const mediaSection = useRef();
  const userLoginEmail = viewer.account?.profile.email;
  const participantMediaCount = event.participant
    ? event.participant.media.mediaInfo.count
    : 0;
  const suggestedtMediaCount = event.participant
    ? event.participant.suggestionsByStartnumber.mediaInfo.count
    : 0;
  const customerKey = getCustomerKey(event.sgId, snFromUrl);
  const eventHasSecondFactor = Boolean(event.secondFactorType?.type);

  useEffect(() => {
    onMarathonFotoChange(event.marathonFoto);
    scrollToMediaSection();
  }, []);

  useEffect(() => {
    refetchMediaForNewSearch(searchDualStartnumberFromUrl);
    scrollToMediaSection();
  }, [searchDualStartnumberFromUrl]);

  const handleExtendedSearchTabExpand = () => {
    setExtendedSearchExpand((prevExpand) => !prevExpand);
    setExtendedSearchSelectedTab((prevSelectedTab) =>
      extendedSearchExpand ? 3 : prevSelectedTab
    );
  };

  const handleExtendedSearchTabChanged = (event, value) => {
    setExtendedSearchSelectedTab(value);
    setExtendedSearchExpand(true);
  };

  const handleDeepSearchBtnClicked = () => {
    scrollToEventOnlineView(0);
  };

  const handleCategorySearchBtnClicked = () => {
    scrollToEventOnlineView(1);
  };

  const handleGpsSearchBtnClicked = () => {
    scrollToEventOnlineView(2);
  };

  const handleDualSearchBtnClicked = () => {
    scrollToEventOnlineView(3);
  };

  const scrollToEventOnlineView = (tabIndex) => {
    customScrollToComponent(eventCard.current);
    setExtendedSearchExpand(true);
    setExtendedSearchExpand(tabIndex);
  };

  const liftingUpFormPositionData = (formPositionRef) => {
    setSearchFormPosition(formPositionRef);
  };

  const scrollToForm = () => {
    customScrollToComponent(searchFormPosition);
  };

  const refetchMediaForNewSearch = (newSearchStartnumber) => {
    onRequestStart();
    setShowNoPhotosFoundInformation(false);
    relay.refetch({ searchStartnumber: newSearchStartnumber }, null, (err) => {
      if (err) {
        onRequestStop();
        setShowNoPhotosFoundInformation(true);
      } else {
        onRequestStop();
      }
    });
  };

  const scrollToMediaSection = () => {
    if (mediaSection.current) {
      customScrollToComponent(mediaSection.current);
    }
  };

  const goBackToMainSearchResults = () => {
    const destinationUrl = getMainSearchResultUrl(
      event.sgId,
      snFromUrl,
      eventHasSecondFactor,
      customerKey
    );
    history.push(destinationUrl);
  };

  if (event.state === 'reminder') {
    return <Redirect to={`/event/${event.sgId}`} />;
  }

  return (
    <div className="container-960 container-page" ref={eventCard}>
      <EventOnlineView
        onlineEvent={event}
        urlSn={snFromUrl}
        urlSearchSn={searchDualStartnumberFromUrl}
        customerKey={customerKey}
        extendedSearchSelectedTab={extendedSearchSelectedTab}
        extendedSearchExpand={extendedSearchExpand}
        hasSmartSearchTab={event.smartSearch && participantMediaCount !== 0}
        hasGpsSearchTab={event.gps}
        hasDualSearchTab={event.searchDual}
        onExtendedSearchTabChange={handleExtendedSearchTabChanged}
        onExtendedSearchExpand={handleExtendedSearchTabExpand}
        userLoginEmail={userLoginEmail}
        setFormRef={liftingUpFormPositionData}
        shouldShowAdditionalInstruction={
          !event.participant && event.secondFactorType.type === FACTOR_EMAIL
        }
        codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
        showStillProcessingHint={event.pendingMediaProcessing}
        goBackToMainSearchResults={goBackToMainSearchResults}
      />
      <div ref={mediaSection}>
        {isLoading && <LoadingView />}
        {!isLoading && event.participant === null && (
          <SecondFactorErrorMsg
            event={event}
            onScrollToForm={scrollToForm}
            userLoginEmail={userLoginEmail}
          />
        )}
        {!isLoading && event.participant !== null && extendedSearchSelectedTab === 3 && (
          <>
            {!showNoPhotosFoundInformation &&
              event.participant.suggestionsByStartnumber.mediaInfo.count !== 0 && (
                <DualSearchMediaList
                  eventHasSearchByFace={event.mainSearch === MAIN_SEARCH_FACE}
                  eventHasSecondFactor={eventHasSecondFactor}
                  eventId={event.sgId}
                  searchStartnumber={searchDualStartnumberFromUrl}
                  startnumber={snFromUrl}
                  media={event.participant.suggestionsByStartnumber}
                  offer={event}
                  participantMediaCount={suggestedtMediaCount}
                  onScrollToMainSearch={scrollToForm}
                  goBackToMainSearchResults={goBackToMainSearchResults}
                  eventHasFotoFlat={includesFotoFlat(event.products)}
                />
              )}
            {(showNoPhotosFoundInformation ||
              event.participant.suggestionsByStartnumber.mediaInfo.count === 0) &&
              ((event.mainSearch === MAIN_SEARCH_FACE && <InvalidStartnumberInformation />) || (
                <InvalidFaceFileInformation />
              ))}
          </>
        )}
      </div>
      <Faq onFindMore={() => scrollToEventOnlineView(0)} />
    </div>
  );
};

EventDualSearchPage.propTypes = {
  eventHasSecondFactor: PropTypes.bool,
  isLoading: PropTypes.bool,
  snFromUrl: PropTypes.string,
  searchDualStartnumberFromUrl: PropTypes.string,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  codeSecondFactorForFaceEvent: PropTypes.string,
  history: PropTypes.func,
};

export { EventDualSearchPage };

export default createRefetchContainer(
  withRequestHandler(EventDualSearchPage),
  {
    viewer: graphql`
      fragment EventDualSearchPage_viewer on Viewer {
        account {
          profile {
            email
          }
        }
        event(id: $eventId, language: $language) {
          id
          sgId
          gps
          hostResultUrl
          products {
            type
          }
          pendingMediaProcessing

          participant(startnumber: $startnumber, secondFactor: $secondFactor) {
            media {
              mediaInfo {
                count
              }
            }

            suggestionsByStartnumber(startnumber: $searchStartnumber) {
              mediaInfo {
                count
              }
              ...DualSearchMediaList_media
            }

            startnumber
          }
          secondFactorType {
            type
          }
          mainSearch
          searchDual
          smartSearch
          state
          marathonFoto
          ...EventOnlineView_onlineEvent
          ...DualSearchMediaList_offer
        }
      }
    `,
  },
  graphql`
    query EventDualSearchPageRefetchQuery(
      $eventId: ID!
      $language: String
      $startnumber: ID!
      $searchStartnumber: ID!
      $count: Int!
      $cursor: String
      $secondFactor: String
      $individualPart: String
      $isRecheckPage: Boolean!
    ) {
      viewer {
        ...EventDualSearchPage_viewer
      }
    }
  `
);
