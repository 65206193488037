import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation LoginMutation($input: LoginInput!) {
    login(input: $input) {
      auth {
        email
        status
        token {
          access
          refresh
        }
      }
    }
  }
`;

function commit(environment, provider, code, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        provider,
        code,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.login.auth);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
