import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CustomDialog from '../CustomDialog';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const AllowWebcamDialog = (props) => {
  const { open, onRequestClose, onRequestConfirm } = props;

  return (
    <>
      <CustomDialog
        modal={false}
        open={open}
        onRequestClose={onRequestClose}
        hasFixedWidth={true}
      >
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="webcamDialog.allowWebcamText.title"
                  defaultMessage="Webcam access required"
                />
              </h1>
              <FormattedMessageWrappedInSpan
                id="webcamDialog.allowWebcamText"
                defaultMessage="Please grant access to your webcam to take a selfie."
              />
            </div>
          </DialogContent>
          <DialogActions disableSpacing={true}>
            <div className="notification-action">
              <button className="accept" onClick={() => onRequestClose()}>
                <CloseIcon />
              </button>
            </div>
            <div className="notification-action">
              <button className="accept" onClick={() => onRequestConfirm()}>
                <CheckIcon />
              </button>
            </div>
          </DialogActions>
        </div>
      </CustomDialog>
    </>
  );
};

export default AllowWebcamDialog;

AllowWebcamDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onRequestConfirm: PropTypes.func,
};
