/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import EventSmartSearchPage from './EventSmartSearchPage';
import QueryHandler from '../components/misc/QueryHandler';

import { getCustomerKey } from '../utils/browser-storage';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class EventSmartSearchPageQueryRenderer extends Component {
  constructor(props) {
    super(props);

    this.snFromUrl = props.match.params.startnumber;
    this.eventIdFromUrl = props.match.params.id;
    this.customerKey = getCustomerKey(this.eventIdFromUrl, this.snFromUrl);
  }

  render() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const code2FA = queryParams.get('code');

    return (
      <QueryHandler
        query={graphql`
          query EventSmartSearchPageQueryRendererQuery(
            $eventId: ID!
            $startnumber: ID!
            $count: Int!
            $cursor: String
            $secondFactor: String
            $individualPart: String
            $isRecheckPage: Boolean!
          ) {
            viewer {
              ...EventSmartSearchPage_viewer
            }
          }
        `}
        variables={{
          eventId: this.eventIdFromUrl,
          startnumber: this.snFromUrl,
          count: MEDIA_FETCH_COUNT,
          secondFactor: code2FA || this.customerKey,
          individualPart: '',
          isRecheckPage: false,
        }}
        redirectTo={`/event/${this.eventIdFromUrl}`}
        render={props => {
          return (
            <EventSmartSearchPage
              customerKey={this.customerKey}
              snFromUrl={this.snFromUrl}
              eventIdFromUrl={this.eventIdFromUrl}
              viewer={props.viewer}
              codeSecondFactorForFaceEvent={code2FA}
              history={this.props.history}
            />
          );
        }}
      />
    );
  }
}

EventSmartSearchPageQueryRenderer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

export default EventSmartSearchPageQueryRenderer;
