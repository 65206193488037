import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AddFotoFlatToCartMutation($input: AddFotoFlatToCartInput!) {
    addFotoFlatToCart(input: $input) {
      cart {
        id
        lineItems {
          id
          product {
            label
            price
          }
        }
        totalPrice
      }
    }
  }
`;

function commit(environment, productId, startnumber, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        productId,
        startnumber,
      },
    },
    updater: store => {
      const payload = store.getRootField('addFotoFlatToCart');
      const cart = payload.getLinkedRecord('cart');
      const viewer = store.get('root');
      viewer.setLinkedRecord(cart, 'cart');
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.addFotoFlatToCart.cart);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
