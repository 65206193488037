import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation UploadFacePhotoMutation($input: UploadFacePhotoInput!) {
    uploadFacePhoto(input: $input) {
      startnumber
    }
  }
`;

function commit(environment, photo, eventId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        photo: 'photo',
        eventId,
        startnumber: null,
      },
    },
    uploadables: {
      photo: photo,
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.uploadFacePhoto.startnumber);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
