/**
 * @flow
 * @relayHash edc52f99c19fa1576eb4599096810eb7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFotoFlatToCartInput = {|
  productId: string,
  startnumber: string,
|};
export type AddFotoFlatToCartMutationVariables = {|
  input: AddFotoFlatToCartInput
|};
export type AddFotoFlatToCartMutationResponse = {|
  +addFotoFlatToCart: ?{|
    +cart: ?{|
      +id: string,
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string,
        +product: ?{|
          +label: ?string,
          +price: ?any,
        |},
      |}>,
      +totalPrice: ?any,
    |}
  |}
|};
export type AddFotoFlatToCartMutation = {|
  variables: AddFotoFlatToCartMutationVariables,
  response: AddFotoFlatToCartMutationResponse,
|};
*/


/*
mutation AddFotoFlatToCartMutation(
  $input: AddFotoFlatToCartInput!
) {
  addFotoFlatToCart(input: $input) {
    cart {
      id
      lineItems {
        id
        product {
          label
          price
          id
        }
      }
      totalPrice
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddFotoFlatToCartInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPrice",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddFotoFlatToCartMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addFotoFlatToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddFotoFlatToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddFotoFlatToCartMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addFotoFlatToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddFotoFlatToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddFotoFlatToCartMutation",
    "id": null,
    "text": "mutation AddFotoFlatToCartMutation(\n  $input: AddFotoFlatToCartInput!\n) {\n  addFotoFlatToCart(input: $input) {\n    cart {\n      id\n      lineItems {\n        id\n        product {\n          label\n          price\n          id\n        }\n      }\n      totalPrice\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54fee613705a5c87132c974dcf9ced0f';

module.exports = node;
