/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartBillItem_billItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutBillTable_bill$ref: FragmentReference;
declare export opaque type CheckoutBillTable_bill$fragmentType: CheckoutBillTable_bill$ref;
export type CheckoutBillTable_bill = {|
  +charges: ?any,
  +items: ?$ReadOnlyArray<?{|
    +id: string,
    +lineItem: ?{|
      +product: ?{|
        +type: ?string
      |}
    |},
    +$fragmentRefs: CartBillItem_billItem$ref,
  |}>,
  +coupon: ?{|
    +vat: ?any,
    +brutto: ?any,
    +code: ?string,
  |},
  +totalBruttoPrice: ?any,
  +totalBruttoPriceUnpayed: ?any,
  +totalNettoPrice: ?any,
  +$refType: CheckoutBillTable_bill$ref,
|};
export type CheckoutBillTable_bill$data = CheckoutBillTable_bill;
export type CheckoutBillTable_bill$key = {
  +$data?: CheckoutBillTable_bill$data,
  +$fragmentRefs: CheckoutBillTable_bill$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CheckoutBillTable_bill",
  "type": "Bill",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "charges",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "items",
      "storageKey": null,
      "args": null,
      "concreteType": "BillItem",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lineItem",
          "storageKey": null,
          "args": null,
          "concreteType": "LineItem",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "product",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "CartBillItem_billItem",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "coupon",
      "storageKey": null,
      "args": null,
      "concreteType": "BillCoupon",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vat",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "brutto",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBruttoPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBruttoPriceUnpayed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalNettoPrice",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '12e599dabad75b7163267004656265dd';

module.exports = node;
