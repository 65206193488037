import React, { useRef, useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import includes from 'lodash.includes';
import { RemoveScroll } from 'react-remove-scroll';
import { useLocation } from 'react-router-dom';

import { HelpdeskWidgetContext } from '../HelpdeskWidget';

import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

import Image from '../../misc/Image';
import RequestRecheckView from './RequestRecheckView';
import ThumbnailPreviewAccountDetails from './ThumbnailPreviewAccountDetails';
import ThumbnailPreviewEventDetails from './ThumbnailPreviewEventDetails';

import { getPhotoVariant } from '../../../utils/common';
import {
  THUMBNAIL,
  PREVIEW,
  SOCIAL_MEDIA,
  ORIGINAL,
  ACCOUNT,
  RECHECK,
} from '../../../utils/variables';

//////////////////////////////
// TODO missing test cases:
// MUI elements and key events with useRefs
//////////////////////////////

const ThumbnailPreview = (props) => {
  const {
    eventHasSearchByFace,
    eventHasSecondFactor,
    media,
    slideIndex,
    fotoFlatProduct,
    singlePhotoProduct,
    startnumber,
    email,
    rechecks,
    open,
    showRecheckView,
    onRequestClose,
    onSlide,
    onBuyFotoFlat,
    onBuySinglePhoto,
    onRequestRecheck,
    onShowRecheckView,
    onHideRecheckView,
    canRestartUpload,
    startNumbers,
    isFaceSearchEvent,
    hasCertificates,
    lastUserInputStartNumber,
    setLastUserInputStartNumber,
    onGoToSearchResults,
    onGoToAccountEventPage,
    recheckHasOrder,
    listType,
    restartState,
    hasBoughtFotoFlat
  } = props;

  let location = useLocation();

  const notOnMainSearchPage = () => {
    return /dual|category|gps|smartsearch/.test(location.pathname);
  };

  const hiddenLinkRef = useRef();
  const sliderRef = useRef();

  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const [showDetails, setShowDetails] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const newWidth = window.innerWidth;
      if (isSmallDevice && newWidth > 768) {
        setIsSmallDevice(false);
      } else if (!isSmallDevice && newWidth <= 768) {
        setIsSmallDevice(true);
      }
    };

    const handleKeyDown = (event) => {
      if (sliderRef.current) {
        if (event.keyCode === 37) {
          sliderRef.current.slickPrev();
        } else if (event.keyCode === 39) {
          sliderRef.current.slickNext();
        }
      }
    };

    // ComponentDidMount
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('keydown', handleKeyDown);

    // ComponentWillUnmount
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSmallDevice]);

  useEffect(() => {
    hlpDskDispatch({ type: 'show', payload: !(isSmallDevice && open && showDetails) });
  }, [showDetails, hlpDskDispatch, open, isSmallDevice]);

  const handleShowDetailsClick = () => {
    setShowDetails(!showDetails);
  };


  const getSocialMediaUrl = (edge) => {
    const socialMedia = getPhotoVariant(edge.node.variants, SOCIAL_MEDIA);
    return socialMedia ? socialMedia.url : null;
  };

  const getOriginalUrl = (edge) => {
    const original = getPhotoVariant(edge.node.variants, ORIGINAL);
    return original ? original.url : null;
  };

  const _getBestVariantAvailable = (variants) => {
    const isAccountEventPage = listType === ACCOUNT;
    let photoVariant = null;
    if (isAccountEventPage) {
      photoVariant = getPhotoVariant(variants, ORIGINAL);
      if (!photoVariant) {
        photoVariant = getPhotoVariant(variants, SOCIAL_MEDIA);
      }
    }
    // The preview resolution will only be displayed in the photo search
    if (!isAccountEventPage) {
      if (!photoVariant) {
        photoVariant = getPhotoVariant(variants, PREVIEW);
      }
    }
    if (!photoVariant) {
      photoVariant = getPhotoVariant(variants, THUMBNAIL);
    }
    return photoVariant;
  };

  if (!open) {
    return null;
  }

  const currentMedia = props.media.edges[slideIndex];

  const sliderSettings = {
    infinite: true,
    lazyLoad: true,
    initialSlide: slideIndex,
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
        },
      },
    ],
  };

  const thumbnailPreviewDetails = (
    <div className="thumbnail-preview-details-container">
      {listType === ACCOUNT ||
        ((listType != RECHECK || (listType === RECHECK && !recheckHasOrder)) && (
          <ThumbnailPreviewEventDetails
            photoSgId={currentMedia.node.sgId}
            shotDate={currentMedia.node.shotDate}
            fotoFlatProduct={fotoFlatProduct}
            singlePhotoProduct={singlePhotoProduct}
            showRecheckNotification={includes(rechecks, currentMedia.node.sgId)}
            onBuySinglePhoto={onBuySinglePhoto}
            onBuyFotoFlat={onBuyFotoFlat}
            onShowRecheckView={onShowRecheckView}
            eventHasSearchByFace={eventHasSearchByFace}
            showTagButton={!(eventHasSecondFactor && eventHasSearchByFace && !notOnMainSearchPage()) && Boolean(startnumber && startnumber !== 'no-photos-found')}
            goToSearchResults={onGoToSearchResults}
            isRecheckEventPage={listType === RECHECK}
            recheckRequestedAt={currentMedia.node.recheckRequestedAt}
            recheckStatus={
              currentMedia.node.recheckStatus && currentMedia.node.recheckStatus
            }
          />
        ))}
      {(listType === ACCOUNT || (listType === RECHECK && recheckHasOrder)) && (
        <Fragment>
          <ThumbnailPreviewAccountDetails
            photoNodeId={currentMedia.node.id}
            photoSgId={currentMedia.node.sgId}
            shotDate={currentMedia.node.shotDate}
            socialMediaUrl={getSocialMediaUrl(currentMedia)}
            originalUrl={getOriginalUrl(currentMedia)}
            startNumbers={startNumbers}
            canRestartUpload={canRestartUpload}
            isFaceSearchEvent={isFaceSearchEvent}
            hasCertificates={hasCertificates}
            eventSgId={props.eventSgId}
            onGoToSearchResults={onGoToSearchResults}
            onGoToAccountEventPage={onGoToAccountEventPage}
            listType={listType}
            recheckRequestedAt={currentMedia.node.recheckRequestedAt}
            recheckStatus={currentMedia.node.recheckStatus}
            recheckHasOrder={recheckHasOrder}
            restartState={restartState}
            hasBoughtFotoFlat={hasBoughtFotoFlat}
          />
          <a
            className="thumbnail-preview-hidden-link"
            download
            href=""
            ref={hiddenLinkRef}
          />
        </Fragment>
      )}
    </div>
  );

  return (
    <Dialog
      classes={{
        container: 'thumbnail-preview-dialog-container',
        paper: 'thumbnail-preview-dialog-paper',
      }}
      fullScreen={true}
      open={open}
      onClose={onRequestClose}
    >
      <RemoveScroll className="thumbnail-preview-remove-scroll">
        {!showRecheckView && (
          <IconButton
            classes={{ root: 'thumbnail-preview-dialog-close-icon-btn' }}
            onClick={onRequestClose}
          >
            <CloseIcon />
          </IconButton>
        )}

        {showRecheckView && (
          <div className="thumbnail-preview-recheck-view-container">
            <RequestRecheckView
              isStartNumberInputDisabled={eventHasSecondFactor || eventHasSearchByFace}
              startnumber={startnumber}
              email={email}
              onSubmit={(sn, email) =>
                onRequestRecheck([currentMedia.node.sgId], sn, email)
              }
              onCancel={onHideRecheckView}
              lastUserInputStartNumber={lastUserInputStartNumber}
              setLastUserInputStartNumber={setLastUserInputStartNumber}
            />
          </div>
        )}
        {!showRecheckView && (
          <Fragment>
            <div className="thumbnail-preview-slider-container">
              <Slider
                ref={sliderRef}
                className="react-slick-inner"
                afterChange={onSlide}
                {...sliderSettings}
              >
                {media.edges.map((edge) => {
                  const variant = _getBestVariantAvailable(edge.node.variants);
                  return (
                    <div
                      key={edge.node.id}
                      className="thumbnail-preview-slider-img-container"
                    >
                      <Image
                        src={variant.url}
                        hasBackground={false}
                        hasHoverEffect={false}
                        hasFixHeight={true}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div
              className="thumbnail-preview-details-controller"
              onClick={isSmallDevice ? handleShowDetailsClick : null}
            >
              {isSmallDevice && (
                <IconButton className="thumbnail-preview-expand-btn">
                  {showDetails ? (
                    <AddIcon className="animation-x-to-plus" />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              )}
              {isSmallDevice ? (
                <Collapse in={showDetails} timeout="auto">
                  {thumbnailPreviewDetails}
                </Collapse>
              ) : (
                thumbnailPreviewDetails
              )}
            </div>
          </Fragment>
        )}
      </RemoveScroll>
    </Dialog>
  );
};

ThumbnailPreview.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  open: PropTypes.bool,
  showRecheckView: PropTypes.bool,
  restartState: PropTypes.string,
  slideIndex: PropTypes.number,
  eventSgId: PropTypes.number,
  startnumber: PropTypes.string,
  email: PropTypes.string,
  rechecks: PropTypes.array,
  media: PropTypes.object,
  fotoFlatProduct: PropTypes.object,
  singlePhotoProduct: PropTypes.object,
  startNumbers: PropTypes.array,
  onSlide: PropTypes.func,
  onBuySinglePhoto: PropTypes.func,
  onBuyFotoFlat: PropTypes.func,
  onRequestRecheck: PropTypes.func,
  onRequestClose: PropTypes.func,
  onShowRecheckView: PropTypes.func,
  onHideRecheckView: PropTypes.func,
  isFaceSearchEvent: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  lastUserInputStartNumber: PropTypes.string,
  setLastUserInputStartNumber: PropTypes.func,
  canRestartUpload: PropTypes.bool,
  onGoToSearchResults: PropTypes.func,
  onGoToAccountEventPage: PropTypes.func,
  recheckHasOrder: PropTypes.bool,
  listType: PropTypes.string,
  hasBoughtFotoFlat: PropTypes.bool,
};

export default ThumbnailPreview;
