import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ACCOUNT,
  RECHECK,
  RECHECK_APPROVED,
  RECHECK_REJECTED,
  RECHECK_REQUESTED,
} from '../../utils/variables';

const Image = (props) => {
  return (
    <div
      className={classNames('img-container', props.className, {
        'fix-height': props.hasFixHeight,
        'full-width': props.imgHasFullWidth,
        'with-background': props.hasBackground,
      })}
      onClick={props.onClick}
    >
      <img
        className={classNames('image', {
          'img-hover': props.hasHoverEffect,
          'full-width': props.imgHasFullWidth,
        })}
        src={props.src}
      />
      {props.recheckStatus === RECHECK_APPROVED && (
        <div
          className={classNames(
            'protection-overlay protection-overlay_approved-recheck',
            {
              'protection-overlay-hover protection-overlay-hover_approved-recheck':
                props.hasHoverEffect,
            }
          )}
        />
      )}
      {props.recheckStatus === RECHECK_REJECTED && (
        <div
          className={classNames(
            'protection-overlay protection-overlay_rejected-recheck',
            {
              'protection-overlay-hover protection-overlay-hover_rejected-recheck':
                props.hasHoverEffect,
            }
          )}
        />
      )}
      {(props.listType != RECHECK || props.recheckStatus === RECHECK_REQUESTED) && (
        <div
          className={classNames('protection-overlay protection-overlay_transparent', {
            'protection-overlay-hover': props.hasHoverEffect,
          })}
        />
      )}
      {props.bottomLeftElement && props.listType != RECHECK && (
        <div className="img-element bottom-left">{props.bottomLeftElement}</div>
      )}
      {props.bottomLeftElement && props.listType === RECHECK && (
        <div className="img-info-tag img-recheck-info-element">{props.bottomLeftElement}</div>
      )}
      {props.topRightElement && (
        <div className="img-element top-right">{props.topRightElement}</div>
      )}
    </div>
  );
};

Image.defaultProps = {
  hasBackground: true,
  hasHoverEffect: true,
  hasFixHeight: false,
  imgHasFullWidth: false,
  recheckStatus: null,
};

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  hasBackground: PropTypes.bool,
  hasHoverEffect: PropTypes.bool,
  hasFixHeight: PropTypes.bool,
  imgHasFullWidth: PropTypes.bool,
  bottomLeftElement: PropTypes.node,
  topRightElement: PropTypes.node,
  onClick: PropTypes.func,
  recheckStatus: PropTypes.string,
  listType: PropTypes.string,
};

export default Image;
