import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';

import { RECHECK, RECHECK_APPROVED, RECHECK_REJECTED, RECHECK_REQUESTED } from '../../../utils/variables';
import MediaList from './MediaList';

const RecheckMediaList = (props) => {
  const {
    eventSgId,
    startnumber,
    recheckMedia,
    offer,
    account,
    impressionMediaData,
    setUpRecheckStillInProgress,
    onOpenRecheckProcedureDialog,
    eventHasSearchByFace,
  } = props;
  const { eventPurchases } = account;

  const hasApprovedRechecks = recheckMedia.mediaList.edges.some(
    (recheck) => recheck.node.recheckStatus === RECHECK_APPROVED
  );
  const hasOnlyRejectedRechecks = recheckMedia.mediaList.edges.every(
    (recheck) => recheck.node.recheckStatus === RECHECK_REJECTED 
  );
  const hasPendingRechecks = recheckMedia.mediaList.edges.some(
    (recheck) => recheck.node.recheckStatus === RECHECK_REQUESTED
  );
  const recheckHasOrder =
    eventPurchases && eventPurchases.availableZips.includes(startnumber);

  useEffect(() => {
    if (hasPendingRechecks) {
      setUpRecheckStillInProgress();
    }
  }, []);

  return (
    <MediaList
      offer={offer}
      media={recheckMedia.mediaList}
      listType={RECHECK}
      recheckHasOrder={recheckHasOrder}
      eventHasSearchByFace={eventHasSearchByFace}
      impressionMediaCount={impressionMediaData.impressionMedia.mediaInfo.count}
      hasApprovedRechecks={hasApprovedRechecks}
      hasOnlyRejectedRechecks={hasOnlyRejectedRechecks}
      onOpenRecheckProcedureDialog={onOpenRecheckProcedureDialog}
      eventSgId={eventSgId}
      startnumber={startnumber}
    />
  );
};

RecheckMediaList.propTypes = {
  eventSgId: PropTypes.string,
  startnumber: PropTypes.string,
  recheckMedia: PropTypes.object,
  offer: PropTypes.object,
  impressionMediaData: PropTypes.object,
  account: PropTypes.object,
  setUpRecheckStillInProgress: PropTypes.func,
  onOpenRecheckProcedureDialog: PropTypes.func,
  eventHasSearchByFace: PropTypes.bool,
};

export { RecheckMediaList };

export default createPaginationContainer(
  RecheckMediaList,
  {
    recheckMedia: graphql`
      fragment RecheckMediaList_recheckMedia on ExtendedMediaConnection {
        mediaList(first: $count, after: $cursor)
          @connection(key: "RecheckMediaList_mediaList") {
          edges {
            node {
              recheckStatus
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }

          ...MediaList_media
        }
      }
    `,
    offer: graphql`
      fragment RecheckMediaList_offer on Event {
        ...MediaList_offer
      }
    `,
    impressionMediaData: graphql`
      fragment RecheckMediaList_impressionMediaData on Event {
        impressionMedia {
          mediaInfo {
            count
          }
        }
      }
    `,
    account: graphql`
      fragment RecheckMediaList_account on Account {
        eventPurchases(eventId: $eventId) {
          availableZips
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.recheckMedia && props.recheckMedia.mediaList;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        eventId: props.eventId,
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        isRecheckPage: true,
      };
    },
    query: graphql`
      query RecheckMediaListQuery(
        $eventId: ID!
        $count: Int!
        $cursor: String
        $isRecheckPage: Boolean!
      ) {
        viewer {
          account {
            rechecks(eventId: $eventId) {
              media {
                ...RecheckMediaList_recheckMedia
              }
            }
          }
        }
      }
    `,
  }
);
