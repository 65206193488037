/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventListItem_event$ref: FragmentReference;
declare export opaque type EventListItem_event$fragmentType: EventListItem_event$ref;
export type EventListItem_event = {|
  +id: string,
  +sgId: ?number,
  +banner: ?string,
  +beginsAt: ?any,
  +name: ?string,
  +mainSearch: ?string,
  +$refType: EventListItem_event$ref,
|};
export type EventListItem_event$data = EventListItem_event;
export type EventListItem_event$key = {
  +$data?: EventListItem_event$data,
  +$fragmentRefs: EventListItem_event$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "EventListItem_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "banner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beginsAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainSearch",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6f0923f4c07ed120f1eb24640c32b9fb';

module.exports = node;
