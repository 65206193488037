import React from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';
import { FormattedMessageWrappedInSpan } from '../../misc';

import CustomDialog from './CustomDialog';
import RecheckRequestSuccessfulInfo from '../informations/RecheckRequestSuccessfulInfo';

const RecheckSuccessDialog = ({ open, onRequestClose, eventHasSearchByFace, numberOfRechecksSent, onContinueAddingMore }) => {
  return (
    <CustomDialog open={open} onRequestClose={onRequestClose}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <RecheckRequestSuccessfulInfo eventHasSearchByFace={eventHasSearchByFace} numberOfRechecksSent={numberOfRechecksSent} />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
          <button onClick={onContinueAddingMore}>
              <FormattedMessageWrappedInSpan
                id="recheckSuccessDialog.addMoreButton"
                defaultMessage="Add more"
              />
              </button>
            <button className="accept" onClick={onRequestClose}>
              <FormattedMessageWrappedInSpan
                id="recheckSuccessDialog.acceptButton"
                defaultMessage="OK"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

RecheckSuccessDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  eventHasSearchByFace: PropTypes.bool,
  numberOfRechecksSent: PropTypes.number,
  onContinueAddingMore: PropTypes.func,
};

export default RecheckSuccessDialog;
