/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventListItem_event$ref = any;
type OrderCard_order$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountPage_viewer$ref: FragmentReference;
declare export opaque type AccountPage_viewer$fragmentType: AccountPage_viewer$ref;
export type AccountPage_viewer = {|
  +account: ?{|
    +profile: ?{|
      +name: ?string,
      +email: ?string,
    |},
    +events: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +sgId: ?number,
          +$fragmentRefs: EventListItem_event$ref,
        |}
      |}>
    |},
    +rechecks: ?$ReadOnlyArray<?{|
      +eventId: ?string,
      +event: ?{|
        +sgId: ?number,
        +name: ?string,
        +banner: ?string,
      |},
    |}>,
    +orders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +cart: ?{|
            +lineItems: ?$ReadOnlyArray<?{|
              +product: ?{|
                +event: ?{|
                  +name: ?string,
                  +id: string,
                  +sgId: ?number,
                  +$fragmentRefs: EventListItem_event$ref,
                |},
                +type: ?string,
              |}
            |}>
          |},
          +orderid: ?string,
          +state: ?string,
          +$fragmentRefs: OrderCard_order$ref,
        |}
      |}>
    |},
  |},
  +$refType: AccountPage_viewer$ref,
|};
export type AccountPage_viewer$data = AccountPage_viewer;
export type AccountPage_viewer$key = {
  +$data?: AccountPage_viewer$data,
  +$fragmentRefs: AccountPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "FragmentSpread",
  "name": "EventListItem_event",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "AccountPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "profile",
          "storageKey": null,
          "args": null,
          "concreteType": "Profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "events",
          "storageKey": null,
          "args": (v1/*: any*/),
          "concreteType": "EventConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "EventEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Event",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "rechecks",
          "storageKey": null,
          "args": null,
          "concreteType": "Recheck",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "eventId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "event",
              "storageKey": null,
              "args": null,
              "concreteType": "Event",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "banner",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orders",
          "storageKey": null,
          "args": (v1/*: any*/),
          "concreteType": "OrderConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Order",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "cart",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Cart",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "lineItems",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "LineItem",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "product",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "Product",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "event",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "Event",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v2/*: any*/),
                                    (v3/*: any*/),
                                    (v4/*: any*/)
                                  ]
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "type",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "orderid",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "OrderCard_order",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bff82ceb352a2efd616f2a8bd21c91da';

module.exports = node;
