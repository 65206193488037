import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTopOnMount.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(ScrollToTopOnMount);
