import React, { useState } from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessageWrappedInSpan } from '../components/misc';

import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const MFNavigationBar = (props) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const cartWithItems = (
    <QueryHandler
      hasLoadingIndicator={false}
      query={graphql`
        query MFNavigationBarQuery {
          viewer {
            cart {
              lineItems {
                id
              }
            }
          }
        }
      `}
      render={(props) => {
        const { cart } = props.viewer;

        return (
          <div
            className={`header__bubble-container num-items-in-cart-${
              cart ? cart.lineItems.length : '0'
            }`}
          >
            <span className="header__navigation-bubble js-num-items-in-cart-bubble">
              {cart ? cart.lineItems.length : '0'}
            </span>
          </div>
        );
      }}
      shouldHandleError={false}
    />
  );

  return (
    <div className="mf-header marathon-foto bootstrap-styles ">
      <div className="MF-body">
        <header id="site-header">
          <div className="header">
            <div className="container">
              <nav className="mf-navbar">
                <div className="container-fluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      onClick={() => setOpenMobileMenu((current) => !current)}
                      className={classNames('navbar-toggle', {
                        collapsed: !openMobileMenu,
                      })}
                      data-toggle="collapse"
                      data-target="#navbar"
                      aria-expanded="false"
                      aria-controls="mf-navbar"
                      aria-label="Navbar Toggle"
                    >
                      <i className="fa fa-fw fa-bars opener" aria-hidden="true"></i>
                    </button>
                    <Link className="header__cart-nav-item visible-xs" to="/cart">
                      <img
                        style={{ zIndex: 99999 }}
                        src="https://marathonfoto.com/assets/responsive/third-party/nebo/images/icons/header/cart@2x.png?v=1.1.0.0"
                        alt="Cart"
                        width="25"
                      />

                      {cartWithItems}
                    </Link>
                    <a
                      className="header__logo-wrapper"
                      href="https://marathonfoto.com/"
                      aria-label="GradImages Logo"
                    >
                      <div className="header__logo"></div>
                      <div className="header__sportograf">
                        <FormattedMessageWrappedInSpan
                          id="MFNavigationBar.poweredBySportograf"
                          defaultMessage="Powered by Sportograf"
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    id="mf-navbar"
                    className={classNames('navbar-collapse collapse', {
                      in: openMobileMenu,
                    })}
                    aria-expanded={openMobileMenu}
                  >
                    <ul className="nav navbar-nav navbar-right header-navbar__nav">
                      <li className="header__navigation-item header__navigation-item--fmp">
                        <a href="https://marathonfoto.com/Home/SearchEmail">
                          <FormattedMessageWrappedInSpan
                            id="MFNavigationBar.archivalEvents"
                            defaultMessage="Search Archival Events"
                          />
                        </a>
                      </li>
                      <li className="header__navigation-item">
                        <a href="https://marathonfoto.com/Home/UpcomingEvents">
                          <FormattedMessageWrappedInSpan
                            id="MFNavigationBar.browseEvents"
                            defaultMessage="{browse} Events"
                            values={{
                              browse: (
                                <b>
                                  <FormattedMessageWrappedInSpan
                                    id="MFNavigationBar.browseHiddenInSmall"
                                    defaultMessage="Browse"
                                    className="hidden-sm"
                                  />
                                </b>
                              ),
                            }}
                          />
                        </a>
                      </li>
                      <li
                        onClick={() => setOpenDropdown((current) => !current)}
                        className={classNames(
                          'dropdown-mf header__navigation-item header__navigation-item--contact header__icon-container',
                          { open: openDropdown }
                        )}
                      >
                        <a
                          href="javascript:void(0);"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded={JSON.stringify(openDropdown)}
                        >
                          <img
                            src="https://marathonfoto.com/assets/responsive/third-party/nebo/images/icons/header/chat@2x.png?v=1.1.0.0"
                            alt="Chat"
                            width="25"
                          />
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="https://marathonfoto.com/Home/ContactUs">
                              <FormattedMessageWrappedInSpan
                                id="MFNavigationBar.dropdown.contact"
                                defaultMessage="Contact Us"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="https://marathonfoto.com/Home/EmailUs">
                              <FormattedMessageWrappedInSpan
                                id="MFNavigationBar.dropdown.questionsComments"
                                defaultMessage="Questions/Comments"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="https://marathonfoto.com/Home/FAQ">
                              <FormattedMessageWrappedInSpan
                                id="MFNavigationBar.dropdown.faq"
                                defaultMessage="FAQ"
                              />
                            </a>
                          </li>
                          <li>
                            <a href="https://marathonfoto.com/Track">
                              <FormattedMessageWrappedInSpan
                                id="MFNavigationBar.dropdown.trackOrders"
                                defaultMessage="Track Orders"
                              />
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="header__navigation-item header__icon-container hidden-xs">
                        <Link className="js-header-cart-link" to="/cart">
                          <img
                            style={{ zIndex: 99999 }}
                            src="https://marathonfoto.com/assets/responsive/third-party/nebo/images/icons/header/cart@2x.png?v=1.1.0.0"
                            alt="Cart"
                            width="25"
                          />
                          {cartWithItems}
                        </Link>
                        <div className="header__cart-badge hide js-cart-badge">
                          <div className="header__cart-badge-text">
                            <FormattedMessageWrappedInSpan
                              id="MFNavigationBar.cart.added"
                              defaultMessage="Added to Cart!"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

MFNavigationBar.propTypes = {
  viewer: PropTypes.object,
};

export default MFNavigationBar;
