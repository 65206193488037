import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import StartNumberSearch from '../event/StartNumberSearch';
import { MAIN_SEARCH_BIB, MAIN_SEARCH_FACE } from '../../../utils/variables';

const ExtendedSearchDualSearchTab = (props) => {
  const { event, startnumber } = props;

  const [searchStartNumber, setSearchStartNumber] = useState(props.searchStartnumber);

  const handleInputChange = (target, value) => {
    setSearchStartNumber(value)
  }

  return (
    <div className="extended-search-snsearch-container">
      <div className="row">
        <div className="col-xs-16">
          <h2>
            <FormattedMessageWrappedInSpan
              id="extendedSearchDualSearchTab.ownSearch.title"
              defaultMessage="Find more photos"
            />
          </h2>
          <p>
            <FormattedMessageWrappedInSpan
              id="extendedSearchDualSearchTab.ownSearch.description"
              defaultMessage="Use the input below to find additional images we might have of you and add them to your FotoFlat for free."
            />
          </p>
        </div>
      </div>
      <div className="row mt-30">
        <StartNumberSearch
          className="col-xs-16 col-sm-8"
          info={event}
          onInputChange={handleInputChange}
          startNumber={searchStartNumber}
          secondFactor={startnumber}
          searchType={event.mainSearch === MAIN_SEARCH_FACE ? MAIN_SEARCH_BIB : MAIN_SEARCH_FACE}
          dualSearch={true}
          showSearchHint={false}
        />
      </div>
    </div>
  );
}

ExtendedSearchDualSearchTab.propTypes = {
  event: PropTypes.object,
  startnumber: PropTypes.string,
  searchStartnumber: PropTypes.string,
};

export { ExtendedSearchDualSearchTab };

export default createFragmentContainer(ExtendedSearchDualSearchTab, {
  event: graphql`
    fragment ExtendedSearchDualSearchTab_event on Event {
      id
      sgId
      mainSearch
      ...StartNumberSearch_info
    }
  `,
});
