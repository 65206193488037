import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { ReactReader, ReactReaderStyle } from 'react-reader';
import find from 'lodash.find';
import remove from 'lodash.remove';
import cloneDeep from 'lodash.clonedeep';
import Cropper from 'cropperjs';
import classNames from 'classnames';
import { Redirect, withRouter } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CheckIcon from '@material-ui/icons/Check';
import Collapse from '@material-ui/core/Collapse';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import CustomDialog from '../components/content/dialogs/CustomDialog';
import ExpandButton from '../components/misc/ExpandButton';
import Image from '../components/misc/Image';
import ItemAddedDialog from '../components/content/dialogs/ItemAddedDialog';
import LoadingDialog from '../components/content/dialogs/LoadingDialog';
import MediaListMasonry from '../components/content/lists/MediaListMasonry';
import PhotoBookOffer from '../components/content/photo-book/PhotoBookOffer';
import withRequestHandler from '../components/hoc/withRequestHandler';
import QueryHandler from '../components/misc/QueryHandler';
import { PrimaryButton } from '../components/misc/Buttons';
import { FormattedMessageWrappedInSpan } from '../components/misc';
import { customScrollToComponent } from '../utils/common';

import AddFotoBookToCartMutation from '../mutations/AddFotoBookToCartMutation';

import { SOCIAL_MEDIA, THUMBNAIL } from '../utils/variables';
import { getPhotoBook, getPhotoVariant } from '../utils/common';
import { trackEvent } from '../utils/ga-tracking';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const reactReaderStyles = {
  ...ReactReaderStyle,
  arrow: {
    ...ReactReaderStyle.arrow,
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Open Sans',
  },
};
reactReaderStyles.reader.boxShadow = '0 0 4px #ccc';

const config = {
  4291: {
    placeholder: {
      count: 7,
      pages: '44-55',
    },
    book: {
      pagesCount: 104,
    },
  },
  3858: {
    placeholder: {
      count: 7,
      pages: '44-55',
    },
    book: {
      pagesCount: 104,
    },
  },
  3601: {
    placeholder: {
      count: 12,
      pages: 'cover, 14-29',
    },
    book: {
      pagesCount: 84,
    },
  },
  3848: {
    placeholder: {
      count: 9,
      pages: '24-37',
    },
    book: {
      pagesCount: 100,
    },
  },
  // events int prod
  4721: {
    placeholder: {
      count: 7,
      pages: '44-55',
    },
    book: {
      pagesCount: 104,
    },
  },
  5071: {
    placeholder: {
      count: 12,
      pages: 'cover, 14-29',
    },
    book: {
      pagesCount: 84,
    },
  },
  5074: {
    placeholder: {
      count: 12,
      pages: 'cover, 12-27',
    },
    book: {
      pagesCount: 60,
    },
  },
  5041: {
    placeholder: {
      count: 9,
      pages: '24-37',
    },
    book: {
      pagesCount: 100,
    },
  },
  5338: {
    placeholder: {
      count: 7,
      pages: '44-55',
    },
    book: {
      pagesCount: 98,
    },
  },
  5456: {
    placeholder: {
      count: 7,
      pages: '14-26',
    },
    book: {
      pagesCount: 74,
    },
  },
  5090: {
    placeholder: {
      count: 12,
      pages: 'cover, 10-35',
    },
    book: {
      pagesCount: 42,
    },
  },
  7992: {
    placeholder: {
      count: 7,
      pages: '14-26',
    },
    book: {
      pagesCount: 74,
    },
  },
  8479: {
    placeholder: {
      count: 7,
      pages: '12-26',
    },
    book: {
      pagesCount: 74,
    },
  },
};

const MOBILE_BREAKPOINT = 768;

class BookPreviewPage extends React.Component {
  constructor(props) {
    super(props);

    this.currentImgTag = {}; // represents the current selected img in the book
    this.placeholders = [];

    this.reactReaderRef = React.createRef();
    this.offerRef = React.createRef();
    this.resizeInfoRef = React.createRef();

    const photoBook = getPhotoBook(props.viewer.account.boughtEvent.products);

    const _isMobile = window.innerWidth <= MOBILE_BREAKPOINT;
    const _reactReaderHeight = this._calculateReactReaderHeight();

    this.state = {
      cartLineItems: [],
      cartTotalPrice: null,
      dialogHasBeenVisibleOnce: false,
      expandedCardId: 0,
      isFirstRendition: true,
      isGoThrough: true,
      isMobileDevice: _isMobile,
      isPortrait: window.orientation === 0,
      photoBookLanguage: photoBook.configuration.variants.languages[0],
      reactReaderHeight: _reactReaderHeight,
      selectedPhoto: null,
      selectedPhotos: [],
      showPhotoSelectionDialog: false,
      showProductVariantsDialog: false,
      showResizeView: false,
      showResizeInfo: false,
      showItemAddedDialog: false,
      showInfoDialog: false,
      showHintToInstructions: false,
    };

    this.handleCloseSelectionDialog = this.handleCloseSelectionDialog.bind(this);
    this.handleCloseInfoDialog = this.handleCloseInfoDialog.bind(this);
    this.handleGetRendition = this.handleGetRendition.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleSelectPhoto = this.handleSelectPhoto.bind(this);
    this.handleRefCallback = this.handleRefCallback.bind(this);
    this.handleCropPhoto = this.handleCropPhoto.bind(this);
    this.handleHideResizeView = this.handleHideResizeView.bind(this);
    this.handleBuyPhotoBook = this.handleBuyPhotoBook.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleMouseEvent = this.handleMouseEvent.bind(this);
    this.handleBookContainerClick = this.handleBookContainerClick.bind(this);
    this.handlePhotoBookLanguageChange = this.handlePhotoBookLanguageChange.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleDeviceOrientationChange = this.handleDeviceOrientationChange.bind(this);
    this._addPlaceholder = this._addPlaceholder.bind(this);
  }

  componentDidMount() {
    const photoBookProduct = getPhotoBook(this.props.viewer.account.boughtEvent.products);
    // Check if the photo book is available in different variants
    if (photoBookProduct.configuration.variants.languages.length > 1) {
      // Show a dialog where the user can decide which variant he wants
      this.setState({ showProductVariantsDialog: true });
    }

    // Now 'reactReaderRef' should be set. Therefore the 'reactReaderHeight' will be
    // calculated again.
    this.setState({ reactReaderHeight: this._calculateReactReaderHeight() });

    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleDeviceOrientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('orientationchange', this.handleDeviceOrientationChange);
  }

  handleCloseSelectionDialog() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.setState({
      showPhotoSelectionDialog: false,
      showResizeView: false,
      showResizeInfo: false,
    });
  }

  handleCloseInfoDialog(triggerAddPhotoBookToCart) {
    this.setState({ showInfoDialog: false });
    if (triggerAddPhotoBookToCart) {
      this.addPhotoBookToCart();
    }
  }

  handleHideResizeView() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.setState({ showResizeView: false, showResizeInfo: false });
  }

  // This methods gets called every time a new page of the epub is rendered on screen
  handleGetRendition(rendition) {
    this.rendition = rendition;
    const self = this;
    rendition.hooks.render.register(function (contents) {
      // 1. Find all img tags
      const imgTags = contents.document.querySelectorAll('img');
      // 2. In the list of img tags, search for a string (regex)
      imgTags.forEach((imgTag) => {
        if (imgTag.src.search(/SPORTOGRAF-[0-9]{1,2}\./) !== -1) {
          // Save position in book
          const posInBook = imgTag.src;

          imgTag.parentNode.classList.add('epub-img-container');
          imgTag.classList.add('epub-img');
          imgTag.id = `${imgTag.parentNode.id}-img`;

          // Save all found placeholders in a list
          self._addPlaceholder(posInBook, contents.id, imgTag.parentNode.id, imgTag.id);

          // Check if a photo has already been selected for this placeholder
          const photo = find(
            self.state.selectedPhotos,
            (sp) => sp.posInBook === posInBook
          );
          if (photo) {
            const { crop } = photo;
            const calcWidth = imgTag.parentNode.clientWidth / crop.width;
            const calcHeight = imgTag.parentNode.clientHeight / crop.height;
            const calcLeft = calcWidth * (crop.x - crop.initialOffsetX);
            const calcTop = calcHeight * (crop.y - crop.initialOffsetY);

            self._modifyImgDOMNode(
              imgTag,
              calcWidth,
              calcHeight,
              calcLeft,
              calcTop,
              photo.url
            );
          }

          // create div for overlay
          const hoverDiv = contents.document.createElement('div');
          hoverDiv.id = `${imgTag.parentNode.id}-placeholder-overlay`;
          imgTag.parentNode.appendChild(hoverDiv);

          // The following two listeners keeps track if the user "hovers" over a
          // photo/placeholder
          imgTag.parentNode.addEventListener('mouseenter', function () {
            self.handleMouseEvent('enter', posInBook);
          });
          imgTag.parentNode.addEventListener('mouseleave', function () {
            self.handleMouseEvent('leave', posInBook);
          });

          imgTag.parentNode.addEventListener('click', function () {
            // contents.id is the iFrame id
            self.handleOverlayClick(
              posInBook,
              contents.id,
              imgTag.parentNode.id,
              imgTag.id
            );
          });
        }
      });
    });

    rendition.hooks.content.register(function (contents) {
      const elements = contents.document.querySelectorAll('div');
      elements.forEach(function (el) {
        if (el.id.search('placeholder-overlay') !== -1) {
          const tmpId = el.id.split('-')[0];
          const element = (
            <div
              id={`${tmpId}-placeholder-overlay-content`}
              className="placeholder-overlay-content"
              onClick={() => {
                el.parentNode.click();
              }}
            >
              <div className="placeholder-overlay-btn">Select Photo</div>
            </div>
          );
          ReactDOM.render(element, el);
        }
      });
    });
  }

  handleOverlayClick(posInBook, iFrameId, imgContainerId, imgTagId) {
    this.currentImgTag = { id: imgTagId, iFrameId, imgContainerId, posInBook };

    // If the customer selects a placeholder in the book for which he has already selected
    // a photo, the Crop view should be displayed directly.
    const croppedPhoto = find(
      this.state.selectedPhotos,
      (sp) => sp.posInBook === this.currentImgTag.posInBook
    );

    // IMPORTANT: Only set state AFTER the variables above have been set
    if (croppedPhoto) {
      const mediaEdges = this._getAllMedia();
      const mediaEdge = find(mediaEdges, (edge) => edge.node.id === croppedPhoto.mediaId);
      this.setState({
        showPhotoSelectionDialog: true,
        showResizeView: true,
        showResizeInfo: true,
        selectedPhoto: mediaEdge.node,
      });
    } else {
      this.setState({ dialogHasBeenVisibleOnce: true, showPhotoSelectionDialog: true });
    }
  }

  handleMouseEvent(mouseState, posInBook) {
    // mouseState is either 'enter' or 'leave'
    // Since photos/placeholders can extend over two pages, it is necessary to trigger the
    // "hover" effect on both sides when the user moves the mouse cursor over the
    // photo/placeholder.
    const placeholder = find(this.placeholders, (p) => p.posInBook === posInBook);
    if (placeholder) {
      placeholder.elements.forEach((e) => {
        const iFrame = document.getElementById(e.iFrameId);
        const overlayContent = iFrame.contentWindow.document.getElementById(
          `${e.imgContainerId}-placeholder-overlay-content`
        );
        if (mouseState === 'enter') {
          overlayContent.style.opacity = 1;
        } else if (mouseState === 'leave') {
          overlayContent.style.opacity = 0;
        }
      });
    }
  }

  handleSelectPhoto(photo) {
    this.setState({ showResizeView: true, selectedPhoto: photo, showResizeInfo: true });
  }

  handleRefCallback(element) {
    if (!element) {
      // This method is also called when the element is removed from the DOM. However,
      // if the element no longer exists, the subsequent steps should not be executed at
      // all. Therefore, the method is terminated by the return statement.
      return;
    }

    const { iFrameId, imgContainerId } = this.currentImgTag;
    const imgContainer = this._getImgContainer(iFrameId, imgContainerId);

    const self = this;

    new Cropper(element, {
      background: false,
      checkCrossOrigin: false,
      zoomable: false,
      movable: false,
      rotatable: false,
      scalable: false,
      aspectRatio: imgContainer.clientWidth / imgContainer.clientHeight,
      viewMode: 1, // restrict the crop box to not exceed the size of the canvas
      ready: function () {
        const cropper = this.cropper;
        self.cropper = cropper;
        const imageData = cropper.getImageData();
        const { width, height } = imageData;

        cropper.setCropBoxData({
          left: 0,
          top: 0,
        });

        const photo = find(
          self.state.selectedPhotos,
          (sp) =>
            sp.posInBook === self.currentImgTag.posInBook &&
            sp.mediaId === self.state.selectedPhoto.id
        );

        if (photo) {
          cropper.setCropBoxData({
            left: imageData.width * (photo.crop.x + photo.crop.initialOffsetX),
            top: imageData.height * (photo.crop.y + photo.crop.initialOffsetY),
            width: photo.crop.width * imageData.width,
            height: photo.crop.height * imageData.height,
          });
        } else {
          cropper.setCropBoxData({
            width: width > height ? width / 3 : null,
            height: height > width ? height / 3 : null,
          });
          const cropBoxData = cropper.getCropBoxData();
          self.cropDetails = cropBoxData;
          self.initialCropOffsetX = cropBoxData.left;
          self.initialCropOffsetY = cropBoxData.top;
          self.editorImgWidth = imageData.width;
          self.editorImgHeight = imageData.height;
        }
      },
      crop: function (event) {
        const { width, height } = this.cropper.getImageData();
        const cropBoxData = this.cropper.getCropBoxData();
        if (cropBoxData.width < width / 3 && cropBoxData.height < height / 3) {
          this.cropper.setCropBoxData({
            width: width > height ? width / 3 : null,
            height: height > width ? height / 3 : null,
          });
        }
      },
      cropend: function (event) {
        self.cropDetails = this.cropper.getCropBoxData();
      },
    });
  }

  handleCropPhoto() {
    this.handleCloseSelectionDialog();
    const { top, left, width, height } = this.cropDetails;

    const { iFrameId, imgContainerId } = this.currentImgTag;
    const imgContainer = this._getImgContainer(iFrameId, imgContainerId);

    const calcWidth = this._calculateImgWidth(
      this.editorImgWidth,
      imgContainer.clientWidth,
      width,
      this.initialCropOffsetX
    );
    const calcHeight = this._calculateImgHeight(
      this.editorImgHeight,
      imgContainer.clientHeight,
      height,
      this.initialCropOffsetY
    );

    const calcLeft = this._calculateImgPositionX(
      this.editorImgWidth,
      calcWidth,
      left,
      this.initialCropOffsetX
    );
    const calcTop = this._calculateImgPositionY(
      this.editorImgHeight,
      calcHeight,
      top,
      this.initialCropOffsetY
    );

    const { posInBook } = this.currentImgTag;
    const placeholder = find(this.placeholders, (p) => p.posInBook === posInBook);
    if (placeholder) {
      placeholder.elements.forEach((e) => {
        const imgTag = this._getImgTag(e.iFrameId, e.imgTagId);

        const { selectedPhoto } = this.state;
        const variant = getPhotoVariant(this.state.selectedPhoto.variants, SOCIAL_MEDIA);

        this._modifyImgDOMNode(
          imgTag,
          calcWidth,
          calcHeight,
          calcLeft,
          calcTop,
          variant.url
        );

        const photo = {
          mediaId: selectedPhoto.id,
          name: selectedPhoto.name,
          imgTagId: e.imgTagId,
          posInBook: posInBook,
          url: variant.url,
          crop: {
            x: left / this.editorImgWidth,
            y: top / this.editorImgHeight,
            initialOffsetX: this.initialCropOffsetX / this.editorImgWidth,
            initialOffsetY: this.initialCropOffsetY / this.editorImgHeight,
            width: width / this.editorImgWidth,
            height: height / this.editorImgHeight,
          },
        };

        // Update state
        const selectedPhotosClone = cloneDeep(this.state.selectedPhotos);
        remove(selectedPhotosClone, (sp) => sp.imgTagId === e.imgTagId);
        selectedPhotosClone.push(photo);
        this.setState({ selectedPhotos: selectedPhotosClone });
      });
    }

    trackEvent('Book Preview', 'Configuration', 'Crop Photo');
  }

  handleBuyPhotoBook() {
    if (!this._isConfigurationCompleted()) {
      this.setState({ showInfoDialog: true });
    } else {
      this.addPhotoBookToCart();
    }
  }

  handleCardClick(id) {
    this.setState((currentState) => ({
      expandedCardId: currentState.expandedCardId === id ? -1 : id,
    }));

    if (id === 2) {
      this.setState({ isGoThrough: false, showHintToInstructions: false });
      customScrollToComponent(this.reactReaderRef.current);
    }
    trackEvent('Book Preview', 'Info', 'Info Card Click');
  }

  handleBookContainerClick(event) {
    this.setState({ showHintToInstructions: true });
  }

  handleDeviceOrientationChange() {
    this.setState({ isPortrait: window.orientation === 0 });
  }

  handlePhotoBookLanguageChange(language) {
    this.setState({ photoBookLanguage: language });
  }

  handleWindowResize() {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      this.setState({
        isMobileDevice: true,
      });
    } else if (this.state.isMobileDevice && window.innerWidth > MOBILE_BREAKPOINT) {
      this.setState({ isMobileDevice: false });
    }

    this.setState({
      reactReaderHeight: this._calculateReactReaderHeight(),
      // Every time the window size changes, the iFrames of the React Reader will get
      // a new id. If at this time the crop view is visible, an error will occur.
      showPhotoSelectionDialog: false,
      showResizeView: false,
      showResizeInfo: false,
    });
  }

  addPhotoBookToCart() {
    const { onRequestStart, onRequestStop, onRequestError, viewer, relay } = this.props;

    onRequestStart();

    const config = this.state.selectedPhotos.map((sp) => ({
      cropHeight: sp.crop.height,
      cropWidth: sp.crop.width,
      cropX: sp.crop.x - sp.crop.initialOffsetX,
      cropY: sp.crop.y - sp.crop.initialOffsetY,
      mediaId: sp.mediaId,
      position: sp.posInBook.split('/').reverse()[0],
    }));

    const photoBook = getPhotoBook(viewer.account.boughtEvent.products);
    AddFotoBookToCartMutation.commit(
      relay.environment,
      photoBook.id,
      config,
      this.state.photoBookLanguage,
      (error, cart) => {
        if (error) {
          onRequestError(error);
        } else {
          onRequestStop();
          this.setState({
            showItemAddedDialog: true,
            cartLineItems: cart.lineItems,
            cartTotalPrice: cart.totalPrice,
          });
        }
      }
    );

    trackEvent('Book Preview', 'Buy', 'Add Photo Book To Cart');
  }

  _addPlaceholder(posInBook, iFrameId, imgContainerId, imgTagId) {
    const placeholder = find(this.placeholders, (p) => p.posInBook === posInBook);
    const element = { iFrameId, imgContainerId, imgTagId };
    if (placeholder) {
      // I cannot use the iFrame id here because it does not remain constant.
      const existingElement = find(
        placeholder.elements,
        (e) => e.imgContainerId === imgContainerId
      );
      if (!existingElement) {
        placeholder.elements.push(element);
      } else {
        // Since the iFrame id does not remain constant, it is necessary to update it
        existingElement.iFrameId = iFrameId;
      }
    } else {
      this.placeholders.push({ posInBook, elements: [element] });
    }
  }

  _getImgTag(iFrameId, imgTagId) {
    const iFrame = document.getElementById(iFrameId);
    const imgTag = iFrame.contentWindow.document.getElementById(imgTagId);
    return imgTag;
  }

  _getImgContainer(iFrameId, imgContainerId) {
    const iFrame = document.getElementById(iFrameId);
    const imgContainer = iFrame.contentWindow.document.getElementById(imgContainerId);
    return imgContainer;
  }

  _calculateReactReaderHeight() {
    // 0.5238095238
    const ratio = window.innerWidth <= MOBILE_BREAKPOINT ? 0.55 : 0.5238095238;
    if (this.reactReaderRef.current) {
      return this.reactReaderRef.current.clientWidth * ratio;
    }
    return window.innerWidth * ratio;
  }

  _calculateImgWidth(editorImgWidth, bookContainerWidth, editorCropWidth) {
    return (editorImgWidth * bookContainerWidth) / editorCropWidth;
  }

  _calculateImgHeight(editorImgHeight, bookContainerHeight, editorCropHeight) {
    return (editorImgHeight * bookContainerHeight) / editorCropHeight;
  }

  _calculateImgPositionX(editorImgWidth, calculatedWidth, cropX, cropOffsetX) {
    return ((cropX - cropOffsetX) * calculatedWidth) / editorImgWidth;
  }

  _calculateImgPositionY(editorImgHeight, calculatedHeight, cropY, cropOffsetY) {
    return ((cropY - cropOffsetY) * calculatedHeight) / editorImgHeight;
  }

  _modifyImgDOMNode(img, width, height, left, top, src) {
    // Modify img container
    img.parentNode.style.overflow = 'hidden';

    // Modify img
    img.src = src;
    img.style.position = 'relative';
    img.style.width = `${Math.abs(width.toFixed(2))}px`;
    img.style.height = `${Math.abs(height.toFixed(2))}px`;
    img.style.top = `-${Math.abs(top.toFixed(2))}px`;
    img.style.left = `-${Math.abs(left.toFixed(2))}px`;
  }

  _getAllMedia() {
    const purchases = this.props.viewer.account.eventPurchases;

    const mediaEdges = [
      ...purchases.bonusMedia.mediaList.edges,
      ...purchases.singleMedia.mediaList.edges,
    ];
    purchases.participants.forEach((p) => {
      p.media.mediaList.edges.forEach((edge) => {
        mediaEdges.push(edge);
      });
    });

    return mediaEdges;
  }

  _isReadyToConfigurePhotoBook() {
    if (this.state.isGoThrough) {
      return false;
    }
    if (this.state.isMobileDevice && this.state.isPortrait) {
      return false;
    }

    return true;
  }

  _isConfigurationCompleted() {
    const photoBookConfig = config[this.props.viewer.account.boughtEvent.sgId];
    return this.state.selectedPhotos.length === photoBookConfig.placeholder.count;
  }

  _renderPhotoSelectionView() {
    const mediaEdges = this._getAllMedia();

    return (
      <Fragment>
        <h1 className="custom-dialog-h1">
          <FormattedMessageWrappedInSpan
            id="bookPreviewPage.photoSelection.title"
            defaultMessage="Please select your photo"
          />
        </h1>
        <div className="row center-xs book-preview-row">
          <div className="col-xs-16 col-sm-16">
            <MediaListMasonry>
              {mediaEdges.map((mediaEdge, index) => {
                // PROD:
                const mediaVariant = getPhotoVariant(mediaEdge.node.variants, THUMBNAIL);

                const chosenPhoto = find(
                  this.state.selectedPhotos,
                  (sp) =>
                    sp.posInBook === this.currentImgTag.posInBook &&
                    sp.mediaId === mediaEdge.node.id
                );

                return (
                  <div
                    key={index}
                    className="medialist-item-container"
                    onClick={() => this.handleSelectPhoto(mediaEdge.node)}
                  >
                    <Image
                      className={classNames({ 'img-selected': Boolean(chosenPhoto) })}
                      src={mediaVariant.url}
                    />
                  </div>
                );
              })}
            </MediaListMasonry>
          </div>
        </div>
      </Fragment>
    );
  }

  _renderResizeView() {
    const { isMobileDevice, selectedPhoto, showResizeInfo } = this.state;

    const variant = getPhotoVariant(selectedPhoto.variants, SOCIAL_MEDIA);

    return (
      <Fragment>
        <IconButton
          classes={{ root: 'book-resize-dialog-back-icon-btn' }}
          onClick={this.handleHideResizeView}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          classes={{ root: 'book-resize-dialog-close-icon-btn' }}
          onClick={this.handleCloseSelectionDialog}
        >
          <CloseIcon />
        </IconButton>
        {!showResizeInfo && (
          <IconButton
            classes={{ root: 'book-resize-dialog-info-icon-btn' }}
            onClick={() => {
              this.setState({ showResizeInfo: true });
            }}
          >
            <InfoIcon />
          </IconButton>
        )}
        {!showResizeInfo && (
          <IconButton
            classes={{ root: 'book-resize-dialog-check-icon-btn' }}
            onClick={this.handleCropPhoto}
          >
            <CheckIcon />
          </IconButton>
        )}

        <div
          className="book-preview-crop-view-body"
          onClick={() => {
            showResizeInfo && this.setState({ showResizeInfo: false });
          }}
          onTouchEnd={() => {
            showResizeInfo && this.setState({ showResizeInfo: false });
          }}
        >
          <img
            ref={(imgTag) => this.handleRefCallback(imgTag)}
            style={{
              maxHeight: isMobileDevice ? 'none' : `${window.innerHeight - 10}px`,
            }}
            src={variant.url}
          />
        </div>

        {showResizeInfo && (
          <div className="book-preview-crop-info center-text" ref={this.resizeInfoRef}>
            <FormattedMessageWrappedInSpan
              id="bookPreviewPage.resizeView.blendingHint"
              defaultMessage="Choose a slightly larger image section, as this can lead to blending during production.{br}Please consider the book fold when positioning.{br}Watermarks will not be printed."
              values={{ br: <br /> }}
            />
            <IconButton
              className="book-preview-crop-info-close-btn"
              onClick={() => {
                this.setState({ showResizeInfo: false });
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const event = this.props.viewer.account.boughtEvent;
    const photoBook = getPhotoBook(event.products);

    // If the user navigates via url to :eventId/book and no book is offered for
    // this event, redirect the user to the account page
    if (!photoBook) {
      return <Redirect to={`/account/event/${event.sgId}`} />;
    }

    const {
      cartLineItems,
      cartTotalPrice,
      dialogHasBeenVisibleOnce,
      expandedCardId,
      isGoThrough,
      isMobileDevice,
      isPortrait,
      photoBookLanguage,
      reactReaderHeight,
      selectedPhotos,
      showPhotoSelectionDialog,
      showProductVariantsDialog,
      showResizeView,
      showItemAddedDialog,
      showInfoDialog,
      showHintToInstructions,
    } = this.state;

    // Hard coded photo book configuration. Defined in this file below the "imports".
    const photoBookConfig = config[event.sgId];

    if (
      photoBook.configuration.variants.languages.length > 1 &&
      showProductVariantsDialog
    ) {
      return (
        <CustomDialog
          classes={{ paperScrollPaper: 'product-variant-language-dialog-scroll-paper' }}
          modal={true}
          open={showProductVariantsDialog}
          onRequestClose={() => this.setState({ showProductVariantsDialog: false })}
        >
          <div className="notification-container">
            <DialogContent>
              <div className="notification-body">
                <h1>
                  <FormattedMessageWrappedInSpan
                    id="bookPreviewPage.productVariantsDialog.title"
                    defaultMessage="This myRacebook is available in different languages. Choose your language!"
                  />
                </h1>
                <div className="product-variant-language-container">
                  {photoBook.configuration.variants.languages.map((language) => (
                    <div
                      className={classNames(`product-variant-language-${language}`, {
                        selected: photoBookLanguage === language,
                      })}
                      key={`product-variant-language-${language}`}
                      onClick={() => this.handlePhotoBookLanguageChange(language)}
                    />
                  ))}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="notification-action">
                <button
                  className={classNames({
                    accept: photoBookLanguage !== undefined,
                    disabled: photoBookLanguage === undefined,
                  })}
                  disabled={photoBookLanguage === undefined}
                  onClick={() => this.setState({ showProductVariantsDialog: false })}
                >
                  <FormattedMessageWrappedInSpan
                    id="bookPreviewPage.productVariantsDialog.acceptButton"
                    defaultMessage="Choose"
                  />
                </button>
              </div>
            </DialogActions>
          </div>
        </CustomDialog>
      );
    }

    return (
      <Fragment>
        <div className="container-960 container-page">
          <div className="row">
            <div className="col-xs-16 col-sm-8">
              <div className="book-preview-card-container">
                <Card classes={{ root: 'faq-card' }}>
                  <CardHeader
                    action={<ExpandButton expanded={expandedCardId === 0} />}
                    classes={{ root: 'book-preview-card-header' }}
                    onClick={() => {
                      isGoThrough || this.handleCardClick(0);
                    }}
                    title={
                      <FormattedMessageWrappedInSpan
                        id="bookPreviewPage.leftCard.title"
                        defaultMessage="What is myRacebook?"
                      />
                    }
                  />
                  <Collapse in={expandedCardId === 0} timeout="auto">
                    <CardContent classes={{ root: 'book-preview-card-text' }}>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="bookPreviewPage.leftCard.pOne"
                          defaultMessage="In 'myRacebook {eventName}' you will find the gripping report about the event with interesting stories about the event and epic impression photos as well as great facts - and yourself!"
                          values={{ eventName: event.name }}
                        />
                      </p>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="bookPreviewPage.leftCard.pTwo"
                          defaultMessage="<b>Now it's your turn</b> - select up to {placeholderCount} of your most impressive Sportograf photos and add them to the marked places in the myRacebook with one click. This way also tell your part of the {eventName}."
                          values={{
                            b: (chunks) => <b>{chunks}</b>,
                            placeholderCount: photoBookConfig.placeholder.count,
                            eventName: event.name,
                          }}
                        />
                      </p>
                      {isGoThrough && (
                        <div className="center-text">
                          <PrimaryButton
                            leftLabel={
                              <FormattedMessageWrappedInSpan
                                id="bookPreviewPage.cardButton"
                                defaultMessage="Ok"
                              />
                            }
                            onClick={() => this.handleCardClick(1, true)}
                          />
                        </div>
                      )}
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="bookPreviewPage.leftCard.pThree"
                          defaultMessage="More info:{br}Book: Paperback matt with {bookPagesCount} pages.{br}Shipping: The book will be sent directly to your home. The duration takes about 14 working days."
                          values={{
                            br: <br />,
                            bookPagesCount: photoBookConfig.book.pagesCount,
                          }}
                        />
                      </p>
                    </CardContent>
                  </Collapse>
                </Card>
              </div>
            </div>
            <div className="col-xs-16 col-sm-8">
              <div className="book-preview-card-container">
                <Card classes={{ root: 'faq-card' }}>
                  <CardHeader
                    action={<ExpandButton expanded={expandedCardId === 1} />}
                    classes={{ root: 'book-preview-card-header' }}
                    onClick={() => {
                      isGoThrough || this.handleCardClick(1);
                    }}
                    title={
                      <FormattedMessageWrappedInSpan
                        id="bookPreviewPage.rightCard.title"
                        defaultMessage="How do I edit myRacebook?"
                      />
                    }
                  />
                  <Collapse in={expandedCardId === 1} timeout="auto">
                    <CardContent classes={{ root: 'book-preview-card-text' }}>
                      <p>
                        <FormattedMessageWrappedInSpan
                          id="bookPreviewPage.rightCard.description"
                          defaultMessage="Flip through the book. On the pages {pages} you can replace the placeholders with your own Sportograf photos from your Foto-Flat and choose the most exciting part. If you don't like your selection, you can replace it by clicking on it again.{br}If you do not replace a placeholder, another impression photo of the race will automatically be added."
                          values={{
                            br: <br />,
                            pages: photoBookConfig.placeholder.pages,
                          }}
                        />
                      </p>
                      {isGoThrough && (
                        <div className="center-text">
                          <PrimaryButton
                            leftLabel={
                              <FormattedMessageWrappedInSpan
                                id="bookPreviewPage.cardButton"
                                defaultMessage="Ok"
                              />
                            }
                            onClick={() => this.handleCardClick(2, true)}
                          />
                        </div>
                      )}
                    </CardContent>
                  </Collapse>
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* Container overlaying the React Reader to show additional informations */}
        <div>
          {!this._isReadyToConfigurePhotoBook() && (
            <div className="book-preview-epub-info-container">
              <div
                onClick={isGoThrough ? this.handleBookContainerClick : null}
                style={{ height: `${reactReaderHeight}px` }}
              >
                {showHintToInstructions && (
                  <p>
                    <FormattedMessageWrappedInSpan
                      id="bookPreviewPage.hintToInstructions"
                      defaultMessage="Please confirm that you have read the instructions (see above)"
                    />
                  </p>
                )}
                {!showHintToInstructions && isMobileDevice && isPortrait && (
                  <p>
                    <FormattedMessageWrappedInSpan
                      id="bookPreviewPage.rotateDeviceInfo"
                      defaultMessage="Please rotate your device (make sure the rotation lock is off)"
                    />
                  </p>
                )}
              </div>
            </div>
          )}

          {/* React Reader */}
          <div
            className="book-container"
            style={{ height: `${reactReaderHeight}px` }}
            ref={this.reactReaderRef}
          >
            <div
              className={classNames({
                blur: isGoThrough || (isMobileDevice && isPortrait),
              })}
            >
              <QueryHandler
                hasLoadingIndicator={false}
                query={graphql`
                  query BookPreviewPageQuery($eventId: ID!, $language: String) {
                    viewer {
                      event(id: $eventId) {
                        fotoBookTemplate(language: $language)
                      }
                    }
                  }
                `}
                variables={{ eventId: event.sgId, language: photoBookLanguage }}
                render={(props) => (
                  <Fragment>
                    {!isMobileDevice && (
                      <div
                        className="book-divider"
                        style={{ height: `${reactReaderHeight - 110}px` }}
                      />
                    )}
                    <ReactReader
                      epubOptions={{ minSpreadWidth: 300 }}
                      getRendition={this.handleGetRendition}
                      location={0}
                      // location={43}
                      showToc={false}
                      styles={reactReaderStyles}
                      url={event.fotoBookTemplate}
                    />
                  </Fragment>
                )}
              />
            </div>
          </div>
          {selectedPhotos.length > 0 && (
            <div className="book-reader-cart-icon-btn-container">
              <IconButton
                classes={{ root: 'book-reader-cart-icon-btn' }}
                onClick={() => {
                  customScrollToComponent(this.offerRef.current, 'bottom');
                }}
              >
                <ShoppingCartIcon />
              </IconButton>
            </div>
          )}
          <div className="book-preview-placeholder-hint">
            <FormattedMessageWrappedInSpan
              id="bookPreviewPage.placeholderHint"
              defaultMessage="(You can insert your personal photos on the pages: {pages})"
              values={{ pages: photoBookConfig.placeholder.pages }}
            />
          </div>
        </div>

        <div className="container-960 container-page" ref={this.offerRef}>
          <PhotoBookOffer
            count={selectedPhotos.length}
            countMax={photoBookConfig.placeholder.count}
            isBuyButtonDisabled={!dialogHasBeenVisibleOnce}
            onBuy={this.handleBuyPhotoBook}
            price={photoBook.price}
          />
          <CustomDialog
            open={showPhotoSelectionDialog}
            fullWidth={true}
            onRequestClose={this.handleCloseSelectionDialog}
            modal={showResizeView}
            classes={{
              paperScrollPaper: classNames('book-preview-dialog-scroll-paper', {
                'resize-view': showResizeView,
              }),
            }}
          >
            {showPhotoSelectionDialog && !showResizeView && (
              <DialogTitle classes={{ root: 'book-select-dialog-title' }}>
                <IconButton onClick={this.handleCloseSelectionDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            )}
            <div
              className={classNames('book-preview-dialog-body', {
                'resize-view': showResizeView,
              })}
            >
              {showResizeView
                ? this._renderResizeView()
                : this._renderPhotoSelectionView()}
            </div>
          </CustomDialog>
          <CustomDialog
            open={showInfoDialog}
            onRequestClose={() => this.handleCloseInfoDialog(false)}
          >
            <div className="notification-container">
              <DialogContent>
                <div className="notification-body">
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="bookPreviewPage.infoDialog.title"
                      defaultMessage="Are you sure? Choose wisely :)"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="bookPreviewPage.infoDialog.message"
                    defaultMessage="From now on, no further changes to myRacebook are possible! Please only continue if you are absolutely sure!"
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <div className="notification-action">
                  <button onClick={() => this.handleCloseInfoDialog(false)}>
                    <FormattedMessageWrappedInSpan
                      id="bookPreviewPage.infoDialog.cancelButton"
                      defaultMessage="Cancel"
                    />
                  </button>
                  <button
                    className="accept"
                    onClick={() => this.handleCloseInfoDialog(true)}
                  >
                    <FormattedMessageWrappedInSpan
                      id="bookPreviewPage.infoDialog.buyButton"
                      defaultMessage="Buy anyway"
                    />
                  </button>
                </div>
              </DialogActions>
            </div>
          </CustomDialog>
          <ItemAddedDialog
            open={showItemAddedDialog}
            lineItems={cartLineItems}
            totalPrice={cartTotalPrice}
            onRequestClose={() => this.setState({ showItemAddedDialog: false })}
          />
          <LoadingDialog open={this.props.isLoading} />
        </div>
      </Fragment>
    );
  }
}

BookPreviewPage.propTypes = {
  isLoading: PropTypes.bool,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  relay: PropTypes.object,
  viewer: PropTypes.object,
};

export default createFragmentContainer(withRouter(withRequestHandler(BookPreviewPage)), {
  viewer: graphql`
    fragment BookPreviewPage_viewer on Viewer {
      account {
        boughtEvent(eventId: $eventId) {
          fotoBookTemplate
          name
          products {
            configuration {
              ... on FotoBookConfiguration {
                variants {
                  languages
                }
              }
            }
            id
            price
            type
          }
          sgId
        }
        eventPurchases(eventId: $eventId) {
          bonusMedia {
            mediaList(first: 1000) {
              edges {
                node {
                  id
                  name
                  sgId
                  variants {
                    format
                    name
                    url
                  }
                }
              }
            }
          }
          participants {
            media {
              mediaList(first: 1000) {
                edges {
                  node {
                    id
                    name
                    sgId
                    variants {
                      format
                      name
                      url
                    }
                  }
                }
              }
            }
          }
          singleMedia {
            mediaList(first: 1000) {
              edges {
                node {
                  id
                  name
                  sgId
                  variants {
                    format
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
