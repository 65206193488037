import React, { Component } from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../components/misc';

import EventListItem from '../components/content/lists/EventListItem';
import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class SubeventsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container-960 container-full-width container-page">
        <h2>
          <FormattedMessageWrappedInSpan id="subeventsPage.title" defaultMessage="Subevents" />
        </h2>
        <hr />
        <QueryHandler
          query={graphql`
            query SubeventsPageQuery($eventId: ID!) {
              viewer {
                event(id: $eventId) {
                  id
                  subEvents {
                    id
                    sgId
                    state
                    ...EventListItem_event
                  }
                }
              }
            }
          `}
          variables={{ eventId: this.props.match.params.id }}
          render={props => {
            return (
              <div className="row">
                {props.viewer.event.subEvents.map(subEvent => (
                  <EventListItem
                    event={subEvent}
                    hasPhotoCount={false}
                    key={subEvent.id}
                    linkTo={`/event/${subEvent.sgId}`}
                  />
                ))}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

SubeventsPage.propTypes = {
  match: PropTypes.object,
};

export default SubeventsPage;
