import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

function FixedElementsPortal({ children }) {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    const portalRoot = document.getElementById('portal-root-for-fixed-el');
    portalRoot.appendChild(container);
    container.classList.add('container-960');

    return () => {
      portalRoot.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
}

export default FixedElementsPortal;