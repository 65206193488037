/**
 * @flow
 * @relayHash 2787bb65ea65bc20b0b0c08cb9e9f710
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdyenPaymentMethodsInput = {|
  orderId: string
|};
export type AdyenPaymentMethodsMutationVariables = {|
  input: AdyenPaymentMethodsInput
|};
export type AdyenPaymentMethodsMutationResponse = {|
  +adyenPaymentMethods: ?{|
    +paymentMethods: ?string,
    +storedPaymentMethods: ?string,
  |}
|};
export type AdyenPaymentMethodsMutation = {|
  variables: AdyenPaymentMethodsMutationVariables,
  response: AdyenPaymentMethodsMutationResponse,
|};
*/


/*
mutation AdyenPaymentMethodsMutation(
  $input: AdyenPaymentMethodsInput!
) {
  adyenPaymentMethods(input: $input) {
    paymentMethods
    storedPaymentMethods
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AdyenPaymentMethodsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adyenPaymentMethods",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdyenPaymentMethodsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "paymentMethods",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "storedPaymentMethods",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdyenPaymentMethodsMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AdyenPaymentMethodsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AdyenPaymentMethodsMutation",
    "id": null,
    "text": "mutation AdyenPaymentMethodsMutation(\n  $input: AdyenPaymentMethodsInput!\n) {\n  adyenPaymentMethods(input: $input) {\n    paymentMethods\n    storedPaymentMethods\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd22c5e22d1fa192374dc70322adeff5';

module.exports = node;
