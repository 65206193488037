import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { useLocation } from 'react-router-dom';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomDialog from '../dialogs/CustomDialog';
import { getReminderTokenFromPath } from '../../../utils/common';

import environment from '../../../environment';
import AcceptReminderForEventMutation from '../../../mutations/AcceptReminderForEventMutation';

const EventReminderAccepted = (props) => {
  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);

  const { onTokenReceived, invalidTokenCallback, rerequestEmailCallback } = props;

  const closeDialog = () => {
    setDialogOpen(false);
    onTokenReceived();
  };

  useEffect(() => {
    const token = getReminderTokenFromPath(location.search);
    AcceptReminderForEventMutation.commit(environment, token, (err, status) => {
      if (status != 'ok') {
        if (err.message?.toLowerCase().includes('invalid')) {
          rerequestEmailCallback();
        }
        invalidTokenCallback();
        setDialogOpen(false);
      } else {
        setShowSpinner(false);
      }
      return;
    });
  }, []);

  return (
    <CustomDialog open={dialogOpen} onRequestClose={closeDialog}>
      <div className="notification-container">
        {showSpinner && (
          <DialogContent>
            <div className="notification-body">
              <CircularProgress />
            </div>
          </DialogContent>
        )}
        {!showSpinner && (
          <>
            <DialogContent>
              <div className="notification-body">
                <div className="event-reminder-icon" />
                <h1>
                  <FormattedMessageWrappedInSpan
                    id="eventReminderAccepted.title"
                    defaultMessage="Thank you!"
                  />
                </h1>
                <FormattedMessageWrappedInSpan
                  id="eventReminderAccepted.message"
                  defaultMessage="Thank you for subscribing to our Reminder. As soon as the event goes online we will notify you."
                />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="notification-action">
                <button onClick={closeDialog}>
                  <FormattedMessageWrappedInSpan
                    id="eventReminderAccepted.closeButton"
                    defaultMessage="Close"
                  />
                </button>
              </div>
            </DialogActions>
          </>
        )}
      </div>
    </CustomDialog>
  );
};

EventReminderAccepted.propTypes = {
  onTokenReceived: PropTypes.func,
  invalidTokenCallback: PropTypes.func,
  rerequestEmailCallback: PropTypes.func,
};

export default EventReminderAccepted;
