import ReactGA from 'react-ga4';
import uniqid from 'uniqid';

import {
  setToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from './browser-storage';

const GA_USER_ID = 'ga-user-id';

export function initGA(trackingId) {
  let userId = getFromLocalStorage(GA_USER_ID);

  if (!userId) {
    userId = uniqid();
    setToLocalStorage(GA_USER_ID, userId);
  }

  ReactGA.initialize(trackingId, {
    gaOptions: {
      userId: userId,
    },
  });

  ReactGA.set({ anonymizeIp: true });
}

export function disableGA(disableId) {
  let userId = getFromLocalStorage(GA_USER_ID);
  if (userId) {
    removeFromLocalStorage(GA_USER_ID);
    window[disableId] = true;
  }
}

export function setPage(path) {
  if (_permitToTrack()) {
    ReactGA.set({ page: path });
  }
}

export function trackPageview(path) {
  if (_permitToTrack()) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
}

export function trackEvent(category, action, label, args) {
  if (_permitToTrack()) {
    ReactGA.event({
      category,
      action,
      label,
      value: args ? args.value : 0,
      nonInteraction: args ? args.nonInteraction : false,
    });
  }
}

// Check if it's possible to use GA
// Only true of the user accepts Statistics cookies
function _permitToTrack() {
  return Boolean(getFromLocalStorage('userAcceptsStatsCookies'));
}
