import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
import { Redirect } from 'react-router-dom';

import FormattedMessageWrappedInSpan from './FormattedMessageWrappedInSpan';
import LoadingView from '../content/LoadingView';
import withUserConsumer from '../hoc/withUserConsumer';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

import environment from '../../environment';
////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const QueryHandler = (props) => {
  const {
    query,
    variables,
    hasLoadingBackground,
    hasLoadingIndicator,
    render,
    redirectTo,
    shouldHandleError,
  } = props;

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props }) => {
        if (error && shouldHandleError) {
          return <WrappedQueryErrorHandlder error={error} redirectTo={redirectTo} />;
        }
        if (props) {
          return render(props);
        }
        if (hasLoadingIndicator) {
          return (
            <Fragment>
              {hasLoadingBackground && (
                <div className="loading-view-navigation-background" />
              )}
              <LoadingView />
            </Fragment>
          );
        }
        return null;
      }}
    />
  );
};

QueryHandler.defaultProps = {
  hasLoadingBackground: false,
  hasLoadingIndicator: true,
  redirectTo: '/',
  shouldHandleError: true,
};

QueryHandler.propTypes = {
  hasLoadingBackground: PropTypes.bool,
  hasLoadingIndicator: PropTypes.bool,
  query: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  shouldHandleError: PropTypes.bool,
  variables: PropTypes.object,
};

export default QueryHandler;

/****************************************************************************************

                                      QueryErrorHandler

*****************************************************************************************/

class QueryErrorHandler extends Component {
  componentDidMount() {
    const { error, onLoginChange } = this.props;

    if (error.message.includes('unauthenticated')) {
      onLoginChange(false, () => {
        window.location.reload();
      });
    }
  }

  _renderFailedToFetchError() {
    return (
      <Fragment>
        <div className="error-view-navigation-background" />
        <div className="error-view-container container-960 container-page">
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1 center-xs">
              <h4>
                <FormattedMessageWrappedInSpan
                  id="queryErrorHandler.connectionFailed.msg"
                  defaultMessage="We couldn't connect you to the server."
                />
              </h4>
              <h4>
                <FormattedMessageWrappedInSpan
                  id="queryErrorHandler.connectionFailed.retry"
                  defaultMessage="Please try again later!"
                />
              </h4>
              <h4>
                <FormattedMessageWrappedInSpan
                  id="queryErrorHandler.connectionFailed.apology"
                  defaultMessage="We are very sorry!"
                />
              </h4>
            </div>
            <div className="col-xs-4 left-xs   ">
              <ErrorOutlineRoundedIcon />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { error, redirectTo } = this.props;
    const msg = error.message.toLowerCase();
    if (msg.includes('invalid_token')) {
      return null;
    }

    if (msg.includes('failed to fetch')) {
      return this._renderFailedToFetchError();
    }

    return <Redirect to={redirectTo} />;
  }
}

QueryErrorHandler.propTypes = {
  error: PropTypes.object,
  onLoginChange: PropTypes.func,
  redirectTo: PropTypes.string,
};

const WrappedQueryErrorHandlder = withUserConsumer(QueryErrorHandler);
