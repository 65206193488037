import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormattedMessageWrappedInSpan  from './FormattedMessageWrappedInSpan';

import { Tooltip } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const VoucherWithActions = ({ voucher }) => {
  const [voucherCodeCopied, setVoucherCodeCopied] = useState(false);

  const copyVoucherCodeToClipboard = (voucherCode) => {
    navigator.clipboard.writeText(voucherCode).then(
      () => {
        setVoucherCodeCopied(true);
        setTimeout(() => {
          setVoucherCodeCopied(false);
        }, '1000');
      },
      (error) => {
        sendError(error);
        callback && callback(error, null);
      }
    );
  };

  const copyVoucherCodeGoToWeb = (voucherCode, linkUrl) => {
    navigator.clipboard.writeText(voucherCode).then(
      () => {
        window.open(linkUrl, '_blank', 'noreferrer');
      },
      (error) => {
        sendError(error);
        callback && callback(error, null);
      }
    );
  };

  return (
    <div className="order-card-voucher">
      <a
        href={voucher?.campaign.url}
        onClick={() => trackEvent('Account', 'Voucher', 'Banner')}
        target="_blank"
      >
        <img src={voucher?.campaign.banner} />
      </a>
      <span className="order-card-voucher-actions">
        <FormattedMessageWrappedInSpan
          id="orderCard.voucherCode"
          defaultMessage="Voucher code: "
        />
        &nbsp;
        {voucher?.code}
        <span className="order-card-voucher-actions-buttons">
          <Tooltip
            title={
              voucherCodeCopied ? (
                <FormattedMessageWrappedInSpan
                  id="orderCard.voucherCopiedMessage"
                  defaultMessage="Copied"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="orderCard.voucherCopyMessage"
                  defaultMessage="Copy"
                />
              )
            }
            className="order-card-voucher-copy-tooltip"
          >
            <button
              onClick={() => copyVoucherCodeToClipboard(voucher.code)}
              className={`btn-voucher-actions-copy ${
                voucherCodeCopied ? 'circleAnimation' : ''
              }`}
            >
              {voucherCodeCopied ? <CheckRoundedIcon /> : <FileCopyRoundedIcon />}
            </button>
          </Tooltip>
          <Tooltip
            title={
              <FormattedMessageWrappedInSpan
                id="orderCard.voucherCopyAndGoMessage"
                defaultMessage="Copy and open website"
              />
            }
          >
            <button
              onClick={() => copyVoucherCodeGoToWeb(voucher.code, voucher.campaign.url)}
            >
              <OpenInNewRoundedIcon />
            </button>
          </Tooltip>
        </span>
      </span>
    </div>
  );
};

VoucherWithActions.propTypes = {
  voucher: PropTypes.object,
};

export default VoucherWithActions;
