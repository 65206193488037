import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation LoginRefreshMutation($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      auth {
        email
        status
        token {
          access
          refresh
        }
      }
    }
  }
`;

function commit(environment, refreshToken, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        refreshToken,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.refreshToken.auth);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
