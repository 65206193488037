import React from 'react';

export default function CartItemDvdIcon(props) {
  return (
    <div className="cart-item-dvd-icon-container ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
      >
        {/* Outer circle */}
        <circle cx="50" cy="50" r="20"   fill="none" stroke="currentColor" strokeWidth="58" />
      </svg>
    </div>
  );
}
