import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import isEmailProper from '../../../utils/isEmailProper';
import classNames from 'classnames';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

import GDPRHint from '../GDPRHint';
import { FormattedMessageWrappedInSpan } from '../../misc';
import EmailSuggestionTooltip from '../../misc/EmailSuggestionTooltip';

import { getFromLocalStorage } from '../../../utils/browser-storage';
import useEmailSpellChecking from '../../../hooks/useEmailSpellChecking';

import RaceletterForm from '../../misc/RaceletterForm';

const ENTER_KEY = 'Enter';

const EventReminderForm = (props) => {
  const { onRequestReminder, intl } = props;
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState(getFromLocalStorage('email') || '');
  const [startnumber, setStartnumber] = useState('');
  const [showMultipleNumbersError, setShowMultipleNumbersError] = useState(false);
  const [raceletterConfirmation, setRaceletterConfirmation] = useState(false);
  const [showRaceletterError, setShowRaceletterError] = useState(false);

  let suggestedEmail = useEmailSpellChecking(email);

  const handleRaceletterConfirmationChange = (value) => {
    setRaceletterConfirmation(value);
  };

  const handleEmailInputChanged = (event) => {
    setEmail(event.target.value.trim());
    if (showError) {
      setShowError(false);
    }
  };

  const handleStartnumberInputChanged = (event) => {
    const inputStartnumValue = event.target.value.trim();
    const restrictedChars = [',', ' '];
    if (restrictedChars.some((char) => inputStartnumValue.includes(char))) {
      event.preventDefault();
      setShowMultipleNumbersError(true);
    } else {
      setStartnumber(inputStartnumValue);
    }
  };

  const handleKeypress = (event) => {
    if (event.key === ENTER_KEY) {
      submitForm();
    }
  };

  const getErrorMessage = (type) => {
    if (showError) {
      if (!email) {
        return intl.formatMessage(translations.inputErrorText);
      }
      if (!isEmailProper(email)) {
        return intl.formatMessage(translations.invalidEmailText);
      }
    }
    if (showMultipleNumbersError && type === 'startnumber') {
      return intl.formatMessage(translations.restrictedCharactersErrorText);
    }
    return false;
  };

  const submitForm = () => {
    if (!isEmailProper(email)) {
      setShowError(true);
      return;
    }
    if (raceletterConfirmation === null) {
      setShowRaceletterError(true);
      return;
    }
    onRequestReminder(email, startnumber, raceletterConfirmation);
  };

  return (
    <div className="notification-container">
      <DialogContent>
        <div className="notification-body">
          <FormGroup>
            <EmailSuggestionTooltip
              emailSuggestion={suggestedEmail}
              onAcceptSuggestion={() => {
                setEmail(suggestedEmail);
              }}
            >
              <TextField
                autoFocus={email === ''}
                className="notification-input"
                error={showError}
                id="email"
                helperText={getErrorMessage()}
                label={intl.formatMessage(translations.emailTextFieldLabel)}
                onChange={handleEmailInputChanged}
                onKeyPress={handleKeypress}
                value={email}
              />
            </EmailSuggestionTooltip>
            <TextField
              autoComplete="off"
              error={showMultipleNumbersError}
              helperText={getErrorMessage('startnumber')}
              autoFocus={email !== ''}
              className="notification-input"
              id="startnumber"
              label={intl.formatMessage(translations.startNumberTextFieldLabel)}
              onChange={handleStartnumberInputChanged}
              onKeyPress={handleKeypress}
              placeholder={intl.formatMessage(
                translations.startNumberTextFieldPlaceholder
              )}
              value={startnumber}
            />
            <RaceletterForm
              labelClass={'notification-radio-label'}
              showError={showRaceletterError}
              removeShowError={() => setShowRaceletterError(false)}
              liftUpConfirmation={handleRaceletterConfirmationChange}
            />
          </FormGroup>
          <div className="mt-30 center-text">
            <GDPRHint />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="notification-action">
          <button
            className={classNames('accept', { disabled: !isEmailProper(email) })}
            onClick={submitForm}
          >
            <FormattedMessageWrappedInSpan
              id="eventReminderForm.acceptButton"
              defaultMessage="Notify me"
            />
          </button>
        </div>
      </DialogActions>
    </div>
  );
};

const translations = defineMessages({
  emailTextFieldLabel: {
    id: 'eventReminderForm.emailTextFieldLabel',
    defaultMessage: 'Email',
  },
  startNumberTextFieldLabel: {
    id: 'eventReminderForm.startNumberTextFieldLabel',
    defaultMessage: 'Bib Number (optional)',
  },
  startNumberTextFieldPlaceholder: {
    id: 'eventReminderForm.startNumberTextFieldPlaceholderNew',
    defaultMessage: 'Enter one bib number only',
  },
  raceletterCheckboxText: {
    id: 'eventReminderForm.raceletterCheckboxText',
    defaultMessage:
      'Subscribe to the Sportograf Raceletter. I can revoke this consent at any time with effect for the future by sending an email to support@sportograf.com.',
  },
  inputErrorText: {
    id: 'eventReminderForm.inputErrorText',
    defaultMessage: 'This field is required',
  },
  invalidEmailText: {
    id: 'eventReminderForm.invalidEmailText',
    defaultMessage: 'Please enter a valid email',
  },
  restrictedCharactersErrorText: {
    id: 'eventReminderForm.restrictedCharactersErrorText',
    defaultMessage:
      "You can enter a single bib number only. If you really want to receive multiple notifications as soon as we are online, you need to submit and accept the notification for each bib number separately. We don't recommend signing up with multiple bib numbers, as you risk those notifications ending up in your spam folder.",
  },
});

EventReminderForm.propTypes = {
  onRequestReminder: PropTypes.func,
  intl: PropTypes.object,
};

export { EventReminderForm };

export default injectIntl(EventReminderForm);
