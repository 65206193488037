import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { uniqBy, orderBy, filter } from 'lodash';
import classNames from 'classnames';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import EventListItem from '../components/content/lists/EventListItem';
import EventTwoStepsListItemQueryRenderer from '../components/content/lists/EventTwoStepsListItemQueryRenderer';
import OrderCard from '../components/content/OrderCard';
import AccountPageInformations from '../components/content/informations/AccountPageInformations';
import withNotificationDialogConsumer from '../components/hoc/withNotificationDialogConsumer';
import { FormattedMessageWrappedInSpan } from '../components/misc';
import { customScrollToComponent } from '../utils/common';

import { HelpdeskWidgetContext } from '../components/content/HelpdeskWidget';

import { PAYMENT_COMPLETED, COMPLETED } from '../utils/variables';

////////////////////////////////////////////
// TODO tests with refs and scrolling to component, OrderCard onClick
////////////////////////////////////////////

const EVENTS_TAB = 0;
const ORDERS_TAB = 1;
const RECHECKS_TAB = 2;

const AccountPage = (props) => {
  var expandedOrderCardRef = null;
  const recheckFaqUrl =
    'https://helpdesk.sportograf.com/en/support/solutions/articles/77000265399-how-do-i-find-and-mark-more-photos-of-myself-';

  const [expandedOrderCardId, setExpandedOrderCardId] = useState(null);
  const [scrollToOrderCard, setScrollToOrderCard] = useState(false);
  const [unpayedEvents, setUnpayedEvents] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(EVENTS_TAB);
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const [maxEventItemHeight, setMaxEventItemHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const pendingOrders = filter(
    props.viewer.account.orders?.edges,
    (edge) => edge.node.state !== PAYMENT_COMPLETED && edge.node.state !== COMPLETED
  );

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (isLoading) {
      return;
    }
    const calculateRowHeights = () => {
      if (!containerRef.current) {
        return;
      }

      const container = containerRef.current;
      const children = Array.from(container.children);
      const childHeights = [];

      children.forEach((child) => {
        const childRect = child.getBoundingClientRect();
        childHeights.push(childRect.height);
      });

      setMaxEventItemHeight(Math.max(...childHeights));
    };

    const timeoutId = setTimeout(calculateRowHeights, 1000);

    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  useEffect(() => {
    hlpDskDispatch({ type: 'name', payload: props.viewer.account.profile.name });
    hlpDskDispatch({ type: 'email', payload: props.viewer.account.profile.email });
  }, []);

  useEffect(() => {
    let newUnpayedEvents = unpayedEvents;
    pendingOrders.forEach((pendingOrder) => {
      newUnpayedEvents = [
        ...newUnpayedEvents,
        {
          ...pendingOrder.node.cart.lineItems[0].product.event,
          orderId: pendingOrder.node.orderid,
        },
      ];
    });
    setUnpayedEvents(newUnpayedEvents);

    const {
      location: { pathname: path },
    } = props;
    path.includes('/account/events') && setSelectedTabIndex(EVENTS_TAB);
    path.includes('/account/orders') && setSelectedTabIndex(ORDERS_TAB);
    path.includes('/account/rechecks') && setSelectedTabIndex(RECHECKS_TAB);
  }, []);

  useEffect(() => {
    const sectionFromUrl = props.match.params.section;
    switch (sectionFromUrl) {
      case 'events':
        setSelectedTabIndex(EVENTS_TAB);
        break;
      case 'orders':
        setSelectedTabIndex(ORDERS_TAB);
        break;
      case 'rechecks':
        setSelectedTabIndex(RECHECKS_TAB);
        break;
    }
  });

  useEffect(() => {
    if (scrollToOrderCard) {
      if (expandedOrderCardRef) {
        customScrollToComponent(expandedOrderCardRef);
        setScrollToOrderCard(false);
      }
    }
  });

  //since we're navigating to the same key, only the URL is changing
  const handleTabChange = (event, value) => {
    switch (value) {
      case EVENTS_TAB:
        setSelectedTabIndex(EVENTS_TAB);
        props.history.replace('/account/events');
        break;
      case ORDERS_TAB:
        setSelectedTabIndex(ORDERS_TAB);
        props.history.replace('/account/orders');
        break;
      case RECHECKS_TAB:
        setSelectedTabIndex(RECHECKS_TAB);
        props.history.replace('/account/rechecks');
        break;
    }
  };

  const handleNavigateHome = () => {
    props.history.push('/');
  };

  const handleUnpayedEventClick = (eventId, orderId) => {
    setExpandedOrderCardId(orderId);
    setScrollToOrderCard(true);
    handleTabChange(null, ORDERS_TAB);
  };

  const handleOrderCardExpandClick = (orderId) => {
    setExpandedOrderCardId(expandedOrderCardId === orderId ? null : orderId);
    setScrollToOrderCard(false);
  };

  const { intl, viewer, onCookiesChange, socialCookies, statsCookies } = props;
  const { events, orders, rechecks } = viewer.account;
  const orderedEvents = orderBy(events?.edges, ['node.beginsAt'], ['desc']);
  const orderedOrders = orderBy(orders?.edges, ['node.paymentReceivedAt'], ['desc']);
  const eventsWithRechecks = uniqBy(rechecks, 'event.sgId');

  const shouldShowCookiesUpdateBanner =
    localStorage.getItem('userAcceptsSportografWebCookies') === null &&
    (localStorage.getItem('userAcceptsStatsCookies') != null ||
      localStorage.getItem('userAcceptsSocialCookies') != null);
  //show call to action if customer has no previous orders
  if (orderedEvents.length === 0 && orderedOrders.length === 0) {
    return (
      <Fragment>
        <div className="container-960">
          <div className="row">
            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
              <div className="account-page-no-orders">
                <AccountPageInformations
                  email={viewer.account.profile.email}
                  shouldShowCookiesUpdateBanner={shouldShowCookiesUpdateBanner}
                  onCookiesChange={onCookiesChange}
                  socialCookies={socialCookies}
                  statsCookies={statsCookies}
                />
                <h1>
                  <FormattedMessageWrappedInSpan
                    id="accountPage.emptyOrders.main"
                    defaultMessage="You have not placed any orders yet"
                  />
                </h1>
                <div className="account-page-no-orders-cta">
                  <FormattedMessageWrappedInSpan
                    id="accountPage.emptyOrders.action"
                    defaultMessage="Feel free to browse the events and photos by clicking the button below"
                  />
                  <button
                    className="btn-default primary-new"
                    onClick={handleNavigateHome}
                  >
                    <FormattedMessageWrappedInSpan
                      id="accountPage.emptyOrders.actionButton"
                      defaultMessage="Browse events"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="account-page-container container-960 container-page mb-20">
      <div className="row">
        <div className="col-xs-16 col-sm-12 col-sm-offset-2">
          <AccountPageInformations
            email={viewer.account.profile.email}
            shouldShowCookiesUpdateBanner={shouldShowCookiesUpdateBanner}
            onCookiesChange={onCookiesChange}
            socialCookies={socialCookies}
            statsCookies={statsCookies}
          />
          <Tabs value={selectedTabIndex} onChange={handleTabChange} variant="fullWidth">
            <Tab
              classes={{ root: 'account-page-tab' }}
              label={intl.formatMessage(translations.tabOne)}
              value={EVENTS_TAB}
            />
            <Tab
              classes={{ root: 'account-page-tab' }}
              label={intl.formatMessage(translations.tabTwo)}
              value={ORDERS_TAB}
            />
            <Tab
              classes={{ root: 'account-page-tab' }}
              label={intl.formatMessage(translations.tabThree)}
              value={RECHECKS_TAB}
            />
          </Tabs>
          {selectedTabIndex === EVENTS_TAB && (
            <Fragment>
              {orderedEvents.length !== 0 && (
                <Fragment>
                  <h1>{intl.formatMessage(translations.tabOne)}</h1>
                  <div className="row" ref={containerRef}>
                    {orderedEvents.map((edge) => (
                      <EventTwoStepsListItemQueryRenderer
                        event={edge.node}
                        key={edge.node.id}
                        eventId={edge.node.sgId}
                        itemHeight={maxEventItemHeight}
                        setIsLoading={setIsLoading}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
              {unpayedEvents.length !== 0 && (
                <Fragment>
                  <h1 className={classNames({ separator: orderedEvents.length !== 0 })}>
                    <FormattedMessageWrappedInSpan
                      id="accountPage.outstandingPayments.title"
                      defaultMessage="Outstanding Payments"
                    />
                  </h1>
                  <div className="row">
                    {unpayedEvents.map((event, index) => (
                      <EventListItem
                        bannerHasGrayScale={true}
                        event={event}
                        info={
                          <div>
                            <div className="event-list-item-date">
                              <p>
                                <FormattedMessageWrappedInSpan
                                  id="accountPage.eventListItem.orderId"
                                  defaultMessage="Order ID:"
                                />
                              </p>
                              <p>{event.orderId}</p>
                            </div>
                          </div>
                        }
                        hasFooter={false}
                        key={index}
                        onClick={(eventId) =>
                          handleUnpayedEventClick(eventId, event.orderId)
                        }
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
          {selectedTabIndex === ORDERS_TAB && (
            <div className="order-container">
              <h1>{intl.formatMessage(translations.tabTwo)}</h1>
              {orderedOrders.map((order) => {
                const expanded = expandedOrderCardId === order.node.orderid;
                const eventName = order.node.cart.lineItems;
                return (
                  <div
                    key={`order-${order.node.orderid}`}
                    ref={(elem) => {
                      if (expanded) expandedOrderCardRef = elem;
                    }}
                  >
                    <OrderCard
                      expanded={expanded}
                      key={order.node.id}
                      order={order.node}
                      onExpandClick={handleOrderCardExpandClick}
                      eventName={eventName}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {selectedTabIndex === RECHECKS_TAB && (
            <Fragment>
              {eventsWithRechecks.length > 0 ? (
                <>
                  <h1>{intl.formatMessage(translations.tabThree)}</h1>
                  <div className="row">
                    {eventsWithRechecks.map((recheckData) => (
                      <EventListItem
                        event={recheckData.event}
                        hasFooter={false}
                        key={recheckData.event.sgId}
                        linkTo={`/account/recheck/${recheckData.event.sgId}`}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="account-empty-rechecks row">
                  <h3>
                    <FormattedMessageWrappedInSpan
                      id="accountPage.emptyRechecks.main"
                      defaultMessage="You have not requested any reviews yet."
                    />
                  </h3>
                  <button
                    className="btn-default primary-new"
                    onClick={() => {
                      window.open(recheckFaqUrl, '_blank');
                    }}
                  >
                    <FormattedMessageWrappedInSpan
                      id="accountPage.emptyRechecks.actionButton"
                      defaultMessage="What is this?"
                    />
                  </button>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const translations = defineMessages({
  tabOne: {
    id: 'accountPage.tabs.tabOne',
    defaultMessage: 'My photos',
  },
  tabTwo: {
    id: 'accountPage.tabs.tabTwo',
    defaultMessage: 'My invoices',
  },
  tabThree: {
    id: 'accountPage.tabs.tabThree',
    defaultMessage: 'My reviews',
  },
});

AccountPage.propTypes = {
  viewer: PropTypes.object,
  intl: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  onCookiesChange: PropTypes.func,
  socialCookies: PropTypes.bool,
  statsCookies: PropTypes.bool,
};

export { AccountPage };

export default createFragmentContainer(
  injectIntl(withRouter(withNotificationDialogConsumer(AccountPage))),
  {
    viewer: graphql`
      fragment AccountPage_viewer on Viewer {
        account {
          profile {
            name
            email
          }
          events(first: $count) {
            edges {
              node {
                id
                sgId
                ...EventListItem_event
              }
            }
          }
          rechecks {
            eventId
            event {
              sgId
              name
              banner
            }
          }
          orders(first: $count) {
            edges {
              node {
                id
                cart {
                  lineItems {
                    product {
                      event {
                        name
                        id
                        sgId
                        ...EventListItem_event
                      }
                      type
                    }
                  }
                }
                orderid
                state
                ...OrderCard_order
              }
            }
          }
        }
      }
    `,
  }
);
