import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import CustomDate from '../../misc/CustomDate';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { MAIN_SEARCH_BIB, MAIN_SEARCH_FACE } from '../../../utils/variables';

const EventListItem = (props) => {
  const {
    bannerHasGrayScale,
    event,
    hasFooter,
    hasPhotoCount,
    info,
    linkTo,
    onClick,
    photoCount,
    isParticipantFotoFlatPreview,
    participantPreviewPhoto,
    fotoFlatId,
    showLinkIcon,
    itemHeight,
    hasManyBibsToShow,
    numberOfBibs,
    isExpanded,
    isSinglePhotosItem,
  } = props;

  function _renderInnerCard() {
    return (
      <div
        className={classNames('event-list-item-container-inner', {
          'participant-item': isParticipantFotoFlatPreview,
        })}
        onClick={() => onClick && onClick(event.sgId)}
      >
        {isParticipantFotoFlatPreview && participantPreviewPhoto && (
          <div className="event-list-item_participant-logo">
            <img src={event.banner} />
          </div>
        )}
        {showLinkIcon && <div className="event-list-item-link-icon" />}
        <div
          className={classNames('event-list-item-banner', {
            'banner-gray-scale': bannerHasGrayScale,
          })}
        >
          {isParticipantFotoFlatPreview && participantPreviewPhoto ? (
            <img src={participantPreviewPhoto.url} />
          ) : (
            <img src={event.banner} />
          )}
        </div>
        <div className="event-list-item-body">
          <div className="event-list-item-date">
            {!isParticipantFotoFlatPreview && <CustomDate value={event.beginsAt} />}
          </div>
          <h3 className="event-list-item-name">{event.name}</h3>
          {fotoFlatId && !isSinglePhotosItem && (
            <span>
              {event.mainSearch === MAIN_SEARCH_BIB && (
                <FormattedMessageWrappedInSpan
                  id="eventListItem.bibNumber"
                  defaultMessage="Bib number {fotoFlatId}"
                  values={{ fotoFlatId: fotoFlatId }}
                />
              )}
              {event.mainSearch === MAIN_SEARCH_FACE && (
                <FormattedMessageWrappedInSpan
                  id="eventListItem.fotoFlatID"
                  defaultMessage="Foto-Flat ID {fotoFlatId}"
                  values={{ fotoFlatId: fotoFlatId }}
                />
              )}
            </span>
          )}
          {isSinglePhotosItem && (
            <FormattedMessageWrappedInSpan
              id="eventListItem.singlePhotos"
              defaultMessage="Single photos"
            />
          )}
          {info}
        </div>
        {hasFooter && (
          <div
            className={classNames(
              'event-list-item-footer',
              'event-list-item-footer-action'
            )}
          >
            {!hasPhotoCount && !hasManyBibsToShow && (
              <button className="redirect" onClick={() => onClick && onClick(event.sgId)}>
                <FormattedMessageWrappedInSpan
                  id="eventListItem.showAll"
                  defaultMessage="Show Photos"
                />
              </button>
            )}
            {hasPhotoCount && !hasManyBibsToShow && (
              <div className={'event-list-item-action'}>
                <FormattedMessageWrappedInSpan
                  id="eventListItem.showAll"
                  defaultMessage="Show Photos"
                />
              </div>
            )}
            {hasPhotoCount && !hasManyBibsToShow && (
              <span className="event-list-item-count">{photoCount}</span>
            )}
            {hasManyBibsToShow && (
              <button>
                {isExpanded ? (
                  <FormattedMessageWrappedInSpan
                    id="eventListItem.closeManyFotoFlats"
                    defaultMessage="Close"
                  />
                ) : (
                  <FormattedMessageWrappedInSpan
                    id="eventListItem.openManyPackages"
                    defaultMessage="{count} packages"
                    values={{ count: numberOfBibs }}
                  />
                )}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames('event-list-item-container', {
        'col-xs-8 col-sm-4': !isParticipantFotoFlatPreview,
        'event-list-item_participant event-list-item-container_no-margin':
          isParticipantFotoFlatPreview,
      })}
      style={{
        height: itemHeight
          ? `${itemHeight}px`
          : isParticipantFotoFlatPreview
          ? '90%'
          : 'auto',
      }}
    >
      {linkTo ? <Link to={linkTo}>{_renderInnerCard()}</Link> : _renderInnerCard()}
    </div>
  );
};

EventListItem.defaultProps = {
  bannerHasGrayScale: false,
  hasFooter: true,
  hasPhotoCount: false,
  info: null,
  linkTo: null,
  photoCount: null,
  isParticipantFotoFlatPreview: false,
  showLinkIcon: false,
  hasManyBibsToShow: false,
  isExpanded: false,
  isSinglePhotosItem: false,
};

EventListItem.propTypes = {
  bannerHasGrayScale: PropTypes.bool,
  event: PropTypes.object,
  hasFooter: PropTypes.bool,
  hasPhotoCount: PropTypes.bool,
  info: PropTypes.node,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  photoCount: PropTypes.number,
  isParticipantFotoFlatPreview: PropTypes.bool,
  participantPreviewPhoto: PropTypes.object,
  fotoFlatId: PropTypes.string,
  showLinkIcon: PropTypes.bool,
  itemHeight: PropTypes.number,
  hasManyBibsToShow: PropTypes.bool,
  numberOfBibs: PropTypes.number,
  isExpanded: PropTypes.bool,
  isSinglePhotosItem: PropTypes.bool,
};

export { EventListItem };

export default createFragmentContainer(injectIntl(EventListItem), {
  event: graphql`
    fragment EventListItem_event on Event {
      id
      sgId
      banner
      beginsAt
      name
      mainSearch
    }
  `,
});
