import React, { useState, useEffect, useContext, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { sendError } from '../appsignal';

import EventPendingView from '../components/content/event/EventPendingView';
import BestOf from '../components/content/event/BestOf';
import EventOnlineView from '../components/content/event/EventOnlineView';
import PaymentMethodView from '../components/content/PaymentMethodView';
import PastEventInformation from '../components/content/event/PastEventInformation';
import Pricelist from '../components/content/event/Pricelist';

import fetchBestofConfig from '../utils/fetchBestofConfig';
import { HelpdeskWidgetContext } from '../components/content/HelpdeskWidget';
import { UserContext } from '../components/context/UserContext';
import { FACTOR_EMAIL } from '../utils/variables';
import { includesCertificate, includesVideo } from '../utils/common';
import { customScrollToComponent } from '../utils/common';

const PENDING_STATE = 'reminder';

const EventPage = (props) => {
  const { event } = props.viewer;

  const priceOffer = useRef(null);

  const eventOnline = event.state !== PENDING_STATE;

  let initialSelectedTab = 1;

  let pastEventNotificationHorizon = new Date();
  pastEventNotificationHorizon.setDate(pastEventNotificationHorizon.getDate() - 90);
  let currentEventDate = new Date(event.beginsAt);

  const [showPastEventNotification, setShowPastEventNotification] = useState(
    currentEventDate < pastEventNotificationHorizon
  );
  const [shouldExpandBestOf, setShouldExpandBestOf] = useState(
    props.match.params.bestof ? true : false
  );
  const [extendedSearchSelectedTab, setExtendedSearchSelectedTab] =
    useState(initialSelectedTab);
  const [extendedSearchExpand, setExtendedSearchExpand] = useState(false);
  const [bestOfAvailable, setBestOfAvailable] = useState(false);
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const { onMarathonFotoChange } = useContext(UserContext);
  const [bestofRef, setBestofRef] = useState();
  const [searchformRef, setSearchFormRef] = useState();
  const [showHowToBuyHint, setShowHowToBuyHint] = useState(false);

  useEffect(() => {
    hlpDskDispatch({ type: 'eventName', payload: event.name });
    hlpDskDispatch({ type: 'eventId', payload: event.sgId });
    hlpDskDispatch({ type: 'status', payload: event.state });
  }, [event, hlpDskDispatch]);

  useEffect(() => {
    onMarathonFotoChange(event.marathonFoto);
  }, []);

  React.useEffect(() => {
    fetchBestofConfig(event.sgId, 'HEAD')
      .then((config) => {
        setBestOfAvailable(config.isAvailable);
      })
      .catch((error) => {
        sendError(error);
      });
  });

  const handleClosePastEventNotification = () => {
    setShowPastEventNotification(false);
  };

  const handleExtendedSearchTabChanged = (event, value) => {
    setExtendedSearchExpand(true);
    setExtendedSearchSelectedTab(value);
  };

  const handleExtendedSearchExpand = () => {
    setExtendedSearchExpand(!extendedSearchExpand);
  };

  const handleExpandBestof = () => {
    setShouldExpandBestOf(true);
  };

  const scrollToOffer = () => {
    if (priceOffer.current) {
      customScrollToComponent(priceOffer.current, 'center' );
    }
  };

  const scrollToBestof = () => {
    if (bestofRef) {
      customScrollToComponent(bestofRef);
    }
  };

  const scrollToSearchForm = () => {
    if (searchformRef) {
      const { current } = searchformRef;
      if (current) {
        customScrollToComponent(current);
      } else {
        customScrollToComponent(searchformRef);
      }
    }
  };

  const updateHowToBuyTooltipVisibility = (shouldShow) => {
    setShowHowToBuyHint(shouldShow);
  };

  // In case a user navigates to an old event, we make a redirect to the archive website
  if (event.legacy) {
    window.location.href = `https://archive.sportograf.com/shop/event/${event.sgId}`;
    return null;
  }

  // In case a user navigates directly over the url, it is necessary to check if the
  // event has subevents. If so, forward him to the appropriate page.
  if (event.subEvents.length > 0) {
    return <Redirect to={`/event/${event.sgId}/subevents`} />;
  }

  //support wish to redirect from this event
  if (event.sgId === 9417) {
    return <Redirect to={`/event/11693`} />;
  }

  return (
    <div className="container-960 container-page">
      {event.successorEvent && showPastEventNotification && (
        <PastEventInformation
          eventname={event.name}
          currentEventDate={event.beginsAt}
          successorEvent={event.successorEvent}
          onRequestClose={handleClosePastEventNotification}
        />
      )}
      {!eventOnline && (
        <EventPendingView
          pendingEvent={event}
          onExpandBestof={handleExpandBestof}
          bestOfAvailable={bestOfAvailable}
          onScrollToBestOf={scrollToBestof}
        />
      )}
      {eventOnline && (
        <EventOnlineView
          onlineEvent={event}
          onExpandBestof={handleExpandBestof}
          bestOfAvailable={bestOfAvailable}
          extendedSearchSelectedTab={extendedSearchSelectedTab}
          extendedSearchExpand={extendedSearchExpand}
          hasGpsSearchTab={event.gps}
          hasDualSearchTab={event.searchDual}
          hasSmartSearchTab={false}
          hasCategorySearchTab={event.searchCollections}
          hasExtendedSearch={
            !event.secondFactorType ||
            (event.secondFactorType.type == FACTOR_EMAIL && event.participantUser)
          }
          onExtendedSearchTabChange={handleExtendedSearchTabChanged}
          onExtendedSearchExpand={handleExtendedSearchExpand}
          handleScrollToOffer={scrollToOffer}
          shouldShowAdditionalOffer={
            event.voucherCampaigns.length ||
            includesCertificate(event.products) ||
            includesVideo(event.products)
          }
          setFormRef={setSearchFormRef}
          showHowToBuyHint={showHowToBuyHint}
          onShowHowToBuyHint={updateHowToBuyTooltipVisibility}
          eventSecondFactorType={event.secondFactorType && event.secondFactorType.type}
        />
      )}
      {bestOfAvailable && (
        <BestOf
          sgId={event.sgId}
          expanded={!eventOnline}
          liftUpBestofPosition={setBestofRef}
          shouldExpandBestOf={shouldExpandBestOf}
        />
      )}
      {eventOnline && (
        <div ref={priceOffer}>
          <Pricelist
            offer={event}
            onScrollToSearchForm={scrollToSearchForm}
            onShowHowToBuyHint={updateHowToBuyTooltipVisibility}
          />
        </div>
      )}
      <PaymentMethodView />
    </div>
  );
};

EventPage.propTypes = {
  match: PropTypes.object,
  viewer: PropTypes.object,
};

export { EventPage };

export default createFragmentContainer(EventPage, {
  viewer: graphql`
    fragment EventPage_viewer on Viewer {
      event(id: $eventId, language: $language) {
        id
        sgId
        beginsAt
        gps
        name
        legacy
        secondFactorType {
          type
        }
        participantUser
        state
        searchDual
        searchCollections
        subEvents {
          id
        }
        successorEvent {
          ...PastEventInformation_successorEvent
        }
        voucherCampaigns {
          id
        }
        products {
          type
        }
        marathonFoto
        ...EventPendingView_pendingEvent
        ...EventOnlineView_onlineEvent
        ...Pricelist_offer
      }
    }
  `,
});
