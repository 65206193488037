/**
 * @flow
 * @relayHash 45058bea59e9b5079dd8e673180e5a30
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SportografNavigationBarQueryVariables = {||};
export type SportografNavigationBarQueryResponse = {|
  +viewer: ?{|
    +account: ?{|
      +profile: ?{|
        +email: ?string
      |},
      +rechecks: ?$ReadOnlyArray<?{|
        +eventId: ?string
      |}>,
    |},
    +cart: ?{|
      +customer: ?{|
        +email: ?string
      |},
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
      +order: ?{|
        +orderid: ?string
      |},
      +id: string,
    |},
  |}
|};
export type SportografNavigationBarQuery = {|
  variables: SportografNavigationBarQueryVariables,
  response: SportografNavigationBarQueryResponse,
|};
*/


/*
query SportografNavigationBarQuery {
  viewer {
    account {
      profile {
        email
        id
      }
      rechecks {
        eventId
      }
      id
    }
    cart {
      customer {
        email
        id
      }
      lineItems {
        id
      }
      order {
        orderid
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "rechecks",
  "storageKey": null,
  "args": null,
  "concreteType": "Recheck",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventId",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lineItems",
  "storageKey": null,
  "args": null,
  "concreteType": "LineItem",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderid",
  "args": null,
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SportografNavigationBarQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "Profile",
                "plural": false,
                "selections": (v1/*: any*/)
              },
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "customer",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": (v1/*: any*/)
              },
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SportografNavigationBarQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "Profile",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "customer",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SportografNavigationBarQuery",
    "id": null,
    "text": "query SportografNavigationBarQuery {\n  viewer {\n    account {\n      profile {\n        email\n        id\n      }\n      rechecks {\n        eventId\n      }\n      id\n    }\n    cart {\n      customer {\n        email\n        id\n      }\n      lineItems {\n        id\n      }\n      order {\n        orderid\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39987aaf6422b8f294ec667a91526617';

module.exports = node;
