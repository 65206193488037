// Product and product features
export const FOTO_FLAT = 'FotoFlat';
export const SINGLE_PHOTO = 'SingleFoto';
export const PHOTO_DVD = 'FotoCD';
export const PHOTO_BOOK = 'FotoBook';
export const VIDEO = 'SingleVideo';
export const CERTIFICATE = 'EventCertificate';

export const VOUCHER = 'voucher';
export const ALL_PHOTOS = 'all_photos';
export const SINGLE_MEDIA_ACCOUNT_PAGE = 'singleMedia';

// Media List and Search Types
export const ALTERNATIVE = 'alt_search';
export const DUAL = 'dual';
export const IMPRESSION = 'impressions';
export const PARTICIPANT = 'participant';
export const CATEGORY = 'categories';
export const SUGGESTION = 'suggestions';
export const GPS = 'gps';
export const ACCOUNT = 'account';

// All types of photos
// It's recommended to add new types here as well
export const ORIGINAL = 'original';
export const PREVIEW = 'preview';
export const SOCIAL_MEDIA = 'socialmedia';
export const THUMBNAIL = 'thumbnail';

// Money transfer options (default payment type of payment method object)
export const PAYPAL = 'Braintree';
export const BANKTRANSFER = 'BankTransfer';
export const ADYEN = 'Adyen';
export const AMAZON = 'Amazon';

export const PAYMENT_COMPLETED = 'payment_completed';
export const COMPLETED = 'completed';
export const PAYMENT_FAILED = 'payment_failed';
export const PAYMENT_IN_PROGRESS = 'payment_in_progress';
export const IN_PAYMENT = 'in_payment';

export const REGISTER_STEP = 0;
export const ADDRESS_STEP = 1;
export const PAYMENT_STEP = 2;

export const MAGIC_LINK_SENT_MSG = 'magic link sent';
export const MAGIC_LINK_EXPLANATION_MSG = 'maigc link explanation';
export const MAGIC_LINK_EXPIRED_MSG = 'magic link is experied';
export const PHOTO_DVD_EXPLANATION_MSG = 'photo dvd explanation';
export const PHOTO_BOOK_MOBILE_MSG = 'photo book mobile';
export const PHOTO_BOOK_SHIPPING_CHARGES_MSG = 'photo book shipping charges overview';
export const FRESHDESK_PAYPAL_CANCELLED_MSG = 'freshdesk paypal cancelled';
export const VERIFY_EMAIL_NEXT_STEPS_MSG = 'verify email next steps';

export const ALL_PHOTOS_CATEGORY = 'allphotos';

export const PENDING = 'reminder';

// Search types
export const MAIN_SEARCH_FACE = 'face';
export const MAIN_SEARCH_BIB = 'startnumber';
export const MAIN_SEARCH_INACTIVE = 'none';

// Second factor types
export const FACTOR_DATE_OF_BIRTH = 'Birthday';
export const FACTOR_EMAIL = 'Email';
export const FACTOR_YEAR_OF_BIRTH = 'Year of birth';
export const FACTOR_CODE = 'Code';

// recheck
export const RECHECK = 'recheck';
export const RECHECK_APPROVED = 'approved';
export const RECHECK_REJECTED = 'rejected';
export const RECHECK_REQUESTED = 'requested';

// uploaded image for face search requirements
export const MAX_PHOTO_UPLOAD_DIMENSION = 800;
export const MAX_PHOTO_UPLOAD_SIZE = 10000;

// restart production requests
export const RESTART_PRODUCTION = 'restart_production'
export const RESTART_STATE_CAN_RESTART = 'can_restart'
export const RESTART_STATE_PROCESSING = 'processing';

// media queries
export const LAYOUT_BREAKPOINT = 768;

// alternative search tabs
export const TAB_SMART = 0;
export const TAB_CATEGORIES = 1;
export const TAB_GPS = 2;
export const TAB_DUAL = 3;

// TODO: Replace hardcoded values with backend response when available
export const BERLIN_MARATHON_EVENTID = 12236;