import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import AccountRecheckPage from './AccountRecheckPage';
import QueryHandler from '../components/misc/QueryHandler';
import withUserConsumer from '../components/hoc/withUserConsumer';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const AccountRecheckPageQueryRenderer = (props) => {
  const { isLoggedIn, location, match } = props;
  const searchParams = new URLSearchParams(location.search);
  const eventIdFromParams = searchParams.get('event_id');
  const startnumberFromParams = searchParams.get('startnumber');
  const eventSgId = match.params.id || eventIdFromParams;

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <QueryHandler
      query={graphql`
        query AccountRecheckPageQueryRendererQuery(
          $eventId: ID!
          $count: Int!
          $cursor: String
          $isRecheckPage: Boolean!
        ) {
          viewer {
            ...AccountRecheckPage_viewer
          }
        }
      `}
      variables={{ eventId: eventSgId, count: 100, isRecheckPage: true }}
      render={(props) => {
        return (
          <AccountRecheckPage
            viewer={props.viewer}
            eventSgId={eventSgId}
            startnumberFromParams={startnumberFromParams}
          />
        );
      }}
    />
  );
};

AccountRecheckPageQueryRenderer.propTypes = {
  match: PropTypes.object,
  viewer: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
};

export default withUserConsumer(AccountRecheckPageQueryRenderer);
