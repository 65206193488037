/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';

import MediaList from './MediaList';

class GpxMediaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
    };
  }

  handleLoadMore = () => {
    if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ isFetching: true });
    this.props.relay.loadMore(MEDIA_FETCH_COUNT, () => this.setState({ isFetching: false }));
  };

  render() {
    const { isFetching } = this.state;
    const {
      eventHasSearchByFace,
      eventHasSecondFactor,
      gpxFileMedia,
      relay,
      offer,
      participantMediaCount,
      impressionMediaCount,
      startnumber,
      goBackToMainSearchResults
    } = this.props;

    return (
      <MediaList
        eventHasSearchByFace={eventHasSearchByFace}
        eventHasSecondFactor={eventHasSecondFactor}
        media={gpxFileMedia.mediaList}
        hasMore={relay.hasMore()}
        isFetching={isFetching}
        onLoadMore={this.handleLoadMore}
        offer={offer}
        participantMediaCount={participantMediaCount}
        impressionMediaCount={impressionMediaCount}
        listType="gps"
        startnumber={startnumber}
        goBackToMainSearchResults={goBackToMainSearchResults}
      />
    );
  }
}

GpxMediaList.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  startnumber: PropTypes.string,
  gpxFileMedia: PropTypes.object,
  offer: PropTypes.object,
  relay: PropTypes.object,
  goBackToMainSearchResults: PropTypes.func
};

export { GpxMediaList };

export default createPaginationContainer(
  GpxMediaList,
  {
    gpxFileMedia: graphql`
      fragment GpxMediaList_gpxFileMedia on ExtendedMediaConnection {
        mediaList(first: $count, after: $cursor)
          @connection(key: "GpxMediaList_mediaList") {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          ...MediaList_media
        }
      }
    `,
    offer: graphql`
      fragment GpxMediaList_offer on Event {
        ...MediaList_offer
      }
    `,
  },
  {
    direction: 'forward',
    // Function that should indicate which connection to paginate over, given the fragment
    // props.
    getConnectionFromProps(props) {
      return props.gpxFileMedia && props.gpxFileMedia.mediaList;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        eventId: props.eventId,
        gpxFileId: props.gpxFileId,
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        isRecheckPage: false,
      };
    },
    query: graphql`
      query GpxMediaListPaginationQuery(
        $eventId: ID!
        $gpxFileId: ID!
        $count: Int!
        $cursor: String
        $isRecheckPage: Boolean!
      ) {
        viewer {
          event(id: $eventId) {
            searchMediaByGpx(gpxFileId: $gpxFileId) {
              ...GpxMediaList_gpxFileMedia
            }
          }
        }
      }
    `,
  },
);
