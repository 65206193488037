/**
 * @flow
 * @relayHash d614337e4f1951ac03b34b4540d3f1ae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClearCartMutationVariables = {||};
export type ClearCartMutationResponse = {|
  +clearCart: ?{|
    +cleared: ?boolean
  |}
|};
export type ClearCartMutation = {|
  variables: ClearCartMutationVariables,
  response: ClearCartMutationResponse,
|};
*/


/*
mutation ClearCartMutation {
  clearCart {
    cleared
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "clearCart",
    "storageKey": null,
    "args": null,
    "concreteType": "ClearCartPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cleared",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClearCartMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ClearCartMutation",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ClearCartMutation",
    "id": null,
    "text": "mutation ClearCartMutation {\n  clearCart {\n    cleared\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c10347826c26b4e7e26db1a11fcc8a1';

module.exports = node;
