/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventOnlineView_onlineEvent$ref = any;
type MediaList_media$ref = any;
type MediaList_offer$ref = any;
type ParticipantMediaList_offer$ref = any;
type ParticipantMediaList_participantMedia$ref = any;
type Pricelist_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventSnSearchPage_viewer$ref: FragmentReference;
declare export opaque type EventSnSearchPage_viewer$fragmentType: EventSnSearchPage_viewer$ref;
export type EventSnSearchPage_viewer = {|
  +account: ?{|
    +profile: ?{|
      +email: ?string
    |}
  |},
  +event: ?{|
    +id: string,
    +sgId: ?number,
    +pendingMediaProcessing: ?boolean,
    +gps: ?boolean,
    +legacy: ?boolean,
    +hostResultUrl: ?string,
    +impressionMedia: ?{|
      +mediaInfo: ?{|
        +count: ?number
      |},
      +mediaList: ?{|
        +$fragmentRefs: MediaList_media$ref
      |},
    |},
    +morePhotosButtonCount: ?number,
    +participant: ?{|
      +media: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |},
        +$fragmentRefs: ParticipantMediaList_participantMedia$ref,
      |},
      +startnumber: ?string,
    |},
    +products: ?$ReadOnlyArray<?{|
      +id: string,
      +mediaCollection: ?{|
        +name: ?string
      |},
      +price: ?any,
      +type: ?string,
      +configuration: ?{|
        +previewVideoUrl?: ?string,
        +previewImageUrl?: ?string,
      |},
    |}>,
    +secondFactorType: ?{|
      +type: ?string
    |},
    +mainSearch: ?string,
    +searchDual: ?boolean,
    +smartSearch: ?boolean,
    +searchCollections: ?boolean,
    +state: ?string,
    +marathonFoto: ?boolean,
    +voucherCampaigns: ?$ReadOnlyArray<?{|
      +id: string,
      +banner: ?string,
      +longText: ?string,
      +url: ?string,
    |}>,
    +warnText: ?string,
    +dialogAfterSearch: ?string,
    +$fragmentRefs: EventOnlineView_onlineEvent$ref & ParticipantMediaList_offer$ref & MediaList_offer$ref & Pricelist_offer$ref,
  |},
  +$refType: EventSnSearchPage_viewer$ref,
|};
export type EventSnSearchPage_viewer$data = EventSnSearchPage_viewer;
export type EventSnSearchPage_viewer$key = {
  +$data?: EventSnSearchPage_viewer$data,
  +$fragmentRefs: EventSnSearchPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "mediaInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "MediaInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventSnSearchPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "language",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "startnumber",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "secondFactor",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "profile",
          "storageKey": null,
          "args": null,
          "concreteType": "Profile",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "eventId"
        },
        {
          "kind": "Variable",
          "name": "language",
          "variableName": "language"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sgId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pendingMediaProcessing",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "gps",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "legacy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hostResultUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "impressionMedia",
          "storageKey": null,
          "args": null,
          "concreteType": "ExtendedMediaConnection",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "mediaList",
              "storageKey": "mediaList(first:100)",
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 100
                }
              ],
              "concreteType": "MediaConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "MediaList_media",
                  "args": null
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "morePhotosButtonCount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "participant",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "secondFactor",
              "variableName": "secondFactor"
            },
            {
              "kind": "Variable",
              "name": "startnumber",
              "variableName": "startnumber"
            }
          ],
          "concreteType": "Participant",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "media",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "ParticipantMediaList_participantMedia",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startnumber",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "products",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "mediaCollection",
              "storageKey": null,
              "args": null,
              "concreteType": "MediaCollection",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "configuration",
              "storageKey": null,
              "args": null,
              "concreteType": null,
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "type": "SingleVideoConfiguration",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previewVideoUrl",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "InlineFragment",
                  "type": "EventCertificateConfiguration",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previewImageUrl",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "secondFactorType",
          "storageKey": null,
          "args": null,
          "concreteType": "SecondFactorType",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mainSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchDual",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "smartSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchCollections",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "marathonFoto",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "voucherCampaigns",
          "storageKey": null,
          "args": null,
          "concreteType": "VoucherCampaign",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "banner",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "longText",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "warnText",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dialogAfterSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventOnlineView_onlineEvent",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ParticipantMediaList_offer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MediaList_offer",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Pricelist_offer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '978fcb7d79109e1fbfde8611dbfe7a7f';

module.exports = node;
