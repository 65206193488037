import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AdyenPaymentMethodsMutation($input: AdyenPaymentMethodsInput!) {
    adyenPaymentMethods(input: $input) {
      paymentMethods
      storedPaymentMethods
    }
  }
`;

// TODO Remove the order id and take it from the session in the backend?
function commit(environment, orderId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        orderId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.adyenPaymentMethods)
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
