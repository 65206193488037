import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { graphql } from 'react-relay';

import AccountEventPage from './AccountEventPage';
import BookPreviewPage from './BookPreviewPage';
import QueryHandler from '../components/misc/QueryHandler';
import withUserConsumer from '../components/hoc/withUserConsumer';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const AccountEventPageQueryRenderer = (props) => {
  const { match, isLoggedIn } = props;
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  return (
    <QueryHandler
      query={graphql`
        query AccountEventPageQueryRendererQuery(
          $eventId: ID!
          $isRecheckPage: Boolean!
        ) {
          viewer {
            ...AccountEventPage_viewer
            ...BookPreviewPage_viewer
          }
        }
      `}
      variables={{ isRecheckPage: false, eventId: match.params.id }}
      render={(props) => {
        return (
          <Switch>
            <Route
              exact={true}
              path="/:language/account/event/:id/:bibnumber"
              render={(routerProps) => (
                <AccountEventPage viewer={props.viewer} {...routerProps} />
              )}
            />
            <Route
              exact={true}
              path="/:language/account/event/:id/book"
              render={(routerProps) => (
                <BookPreviewPage viewer={props.viewer} {...routerProps} />
              )}
            />
            <Redirect to="/account/events" />
          </Switch>
        );
      }}
    />
  );
};

AccountEventPageQueryRenderer.propTypes = {
  isLoggedIn: PropTypes.bool,
  match: PropTypes.object,
};

export default withUserConsumer(AccountEventPageQueryRenderer);
