import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { injectIntl } from 'react-intl';
import { FormattedDate } from 'react-intl';

import { FormattedMessageWrappedInSpan } from '../components/misc';
import { MAIN_SEARCH_FACE, RECHECK } from '../utils/variables';
import FaqRecheck from '../components/content/FaqRecheck';
import CustomDialog from '../components/content/dialogs/CustomDialog';
import RecheckMediaList from '../components/content/lists/RecheckMediaList';
import { customScrollToComponent } from '../utils/common';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

////////////////////////////////////
// TODO missing test cases with useRef and useEffect
////////////////////////////////////

const AccountRecheckPage = (props) => {
  const { eventSgId, startnumberFromParams } = props;
  const { account, event } = props.viewer;

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isRecheckOfAllCompleted, setIsRecheckOfAllCompleted] = useState(true);

  const startnumberFromParamsMediaListRef = useRef(null);
  const faqRef = useRef(null);

  useEffect(() => {
    if (startnumberFromParamsMediaListRef) {
      customScrollToComponent(startnumberFromParamsMediaListRef.current);
    }
  }, [startnumberFromParamsMediaListRef]);

  const scrollToFaq = () => {
    if (faqRef.current) {
      customScrollToComponent(faqRef.current);
    }
  };

  return (
    <div className="container-960 container-page recheck-page">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="header-with-icon mb-10">
          <h1>
            <FormattedMessageWrappedInSpan
              id="accountRecheckPage.header"
              defaultMessage="Photos we are reviewing for you"
              className="header-1"
            />
          </h1>
          <HelpRoundedIcon onClick={() => setIsDetailsDialogOpen(true)} />
        </div>
        {!isRecheckOfAllCompleted && (
          <FormattedMessageWrappedInSpan
            id="accountRecheckPage.intro.pendingRechecks"
            defaultMessage="The review process can take up to one week. Your oldest pending request is from <b>{lastRecheckDate}</b>."
            values={{
              b: (chunks) => <b>{chunks}</b>,
              lastRecheckDate: (
                <FormattedDate
                  value={new Date(account.rechecks[0].requestedAt)}
                  day="numeric"
                  month="numeric"
                  year="numeric"
                  timeZone="UTC"
                />
              ),
            }}
            className="recheck-header-intro"
          />
        )}
        {isRecheckOfAllCompleted && (
          <FormattedMessageWrappedInSpan
            id="accountRecheckPage.intro.allRechecksChecked"
            defaultMessage="We already reviewed all your requests. Approved photos are now part of your Foto-Flat, and they will appear in the corresponding search results for the event."
            className="recheck-header-intro"
          />
        )}
      </div>
      <div className="col-xs-16">
        {account.rechecks.map((recheck) => {
          return (
            <>
              <div
                className="col-xs-16 col-sm-12 col-sm-offset-2 recheck-for-number"
                ref={(el) => {
                  if (recheck.startnumber === startnumberFromParams) {
                    startnumberFromParamsMediaListRef.current = el;
                  }
                }}
                key={recheck.startnumber}
              >
                <h1 className="separator">
                  {event.mainSearch === MAIN_SEARCH_FACE ? (
                    <FormattedMessageWrappedInSpan
                      id="accountRecheckPage.listFaceEventHeader"
                      defaultMessage="For Foto-Flat ID {bibNumber}"
                      values={{
                        bibNumber: recheck.startnumber,
                      }}
                    />
                  ) : (
                    <FormattedMessageWrappedInSpan
                      id="accountRecheckPage.listBibnumberEventHeader"
                      defaultMessage="For bib number {bibNumber}"
                      values={{
                        bibNumber: recheck.startnumber,
                      }}
                    />
                  )}
                </h1>
              </div>
              <RecheckMediaList
                eventSgId={eventSgId}
                startnumber={recheck.startnumber}
                offer={event}
                impressionMediaData={event}
                recheckMedia={recheck.media}
                account={account}
                listType={RECHECK}
                eventHasSearchByFace={event.mainSearch === MAIN_SEARCH_FACE}
                onOpenRecheckProcedureDialog={() => setIsDetailsDialogOpen(true)}
                setUpRecheckStillInProgress={() => setIsRecheckOfAllCompleted(false)}
              />
            </>
          );
        })}
      </div>
      <div ref={faqRef}>
        <FaqRecheck />
      </div>

      <CustomDialog open={isDetailsDialogOpen} onRequestClose={() => setIsDetailsDialogOpen(false)}>
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.title"
                  defaultMessage="What are review requests?"
                />
              </h1>
              <p>
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.text.whatIsRecheckProcedure"
                  defaultMessage="If our system overlooks a photo of yours, you can <span>send us a request</span> to add the image to your photos."
                  values={{
                    span: (chunks) => <span className="highlighted">{chunks}</span>,
                  }}
                />
              </p>
              <p>
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.text.whatIsRecheckReview"
                  defaultMessage="<span>Our team reviews your requests</span> and adds approved photos to your Foto-Flat. This process can take up to one week."
                  values={{
                    span: (chunks) => <span className="highlighted">{chunks}</span>,
                  }}
                />
              </p>
              <p>
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.text.howToAccessApprovedRechecks"
                  defaultMessage="<span>You do not need to purchase those photos</span>. If you've bought a Foto-Flat for the event, they will be added to your existing order. You will receive a notification when the high resolution images are available."
                  values={{
                    span: (chunks) => <span className="highlighted">{chunks}</span>,
                  }}
                />
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="notification-action">
              <button
                className="accept"
                onClick={() => {
                  setIsDetailsDialogOpen(false);
                  scrollToFaq();
                }}
              >
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.goToFaqButton"
                  defaultMessage="Go to FAQ"
                />
              </button>
              <button className="accept" onClick={() => setIsDetailsDialogOpen(false)}>
                <FormattedMessageWrappedInSpan
                  id="accountRecheckPage.dialog.closeButton"
                  defaultMessage="Close"
                />
              </button>
            </div>
          </DialogActions>
        </div>
      </CustomDialog>
    </div>
  );
};

AccountRecheckPage.propTypes = {
  viewer: PropTypes.object,
  eventSgId: PropTypes.string,
  startnumberFromParams: PropTypes.string,
};

export { AccountRecheckPage };
export default createFragmentContainer(injectIntl(AccountRecheckPage), {
  viewer: graphql`
    fragment AccountRecheckPage_viewer on Viewer {
      event(id: $eventId) {
        mainSearch
        ...RecheckMediaList_impressionMediaData
        ...RecheckMediaList_offer
      }
      account {
        rechecks(eventId: $eventId) {
          startnumber
          requestedAt
          media {
            ...RecheckMediaList_recheckMedia
          }
        }
        ...RecheckMediaList_account
      }
    }
  `,
});
