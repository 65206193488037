import { useWindowSize } from '../../hooks';

export default function MobileContainer(props) {
  const { windowWidth } = useWindowSize();

  if (windowWidth <= 768) {
    return props.children;
  }

  return null;
}
