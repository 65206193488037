import React from 'react';

const PaymentMethodView = () => {
  return (
    <div className="row">
      <div className="col-xs-16 col-sm-offset-2 col-sm-12 payment-method-view-container payment-method-view-primary">
        <div className="payment-method-view-amazon-pay"></div>
        <div className="payment-method-view-bank-transfer"></div>
        <div className="payment-method-view-visa"></div>
        <div className="payment-method-view-mastercard"></div>
      </div>
      <div className="col-xs-16 col-sm-offset-2 col-sm-12 payment-method-view-container payment-method-view-secondary">
        <div className="payment-method-view-paypal"></div>
        <div className="payment-method-view-sofort-uberweisung"></div>
        <div className="payment-method-view-ideal"></div>
        <div className="payment-method-view-bancontact"></div>
        <div className="payment-method-view-giropay"></div>
        <div className="payment-method-view-postepay"></div>
        <div className="payment-method-view-alipay"></div>
      </div>
    </div>
  );
}

export default PaymentMethodView;
