import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { customScrollToComponent } from '../../../utils/common';

////////////////////////////////////////////
// TODO MISSING TEST CASE: line 13 injectScript.onload
////////////////////////////////////////////

export const injectJuiceboxScripts = () => {
  const juiceBoxScript = injectScript('/bestof/juicebox/jbcore/juicebox.js');
  juiceBoxScript.onload = () => {
    injectScript('/bestof/bestof.js');
  };
};

export const injectScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

const BestOf = (props) => {
  const { expanded, sgId, shouldExpandBestOf, liftUpBestofPosition } = props;
  const outerContainerRef = useRef(null);

  useEffect(() => {
    injectJuiceboxScripts();
    if (liftUpBestofPosition) {
      liftUpBestofPosition(outerContainerRef.current);
    }
  }, []);

  useEffect(() => {
    window.sgId = sgId;
  }, [sgId]);

  useEffect(() => {
    if (shouldExpandBestOf) {
      customScrollToComponent(outerContainerRef.current);
    }
  }, [shouldExpandBestOf]);

  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div
          ref={outerContainerRef}
          className={
            'bestof-outer-container ' +
            ((expanded || shouldExpandBestOf) && 'bestof-expanded')
          }
        >
          <h1 className="separator">
            <FormattedMessageWrappedInSpan
              id="bestOf.title"
              defaultMessage="Bestof-Gallery"
            />
          </h1>
          <div className="bestof-gallery-container">
            <div className="juicebox-container" id="juicebox-container" />
          </div>
        </div>
      </div>
    </div>
  );
};

BestOf.propTypes = {
  expanded: PropTypes.bool,
  sgId: PropTypes.number,
  shouldExpandBestOf: PropTypes.bool,
  liftUpBestofPosition: PropTypes.func,
};

export default BestOf;
