import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import CircularProgress from '@material-ui/core/CircularProgress';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function LoadMoreButton(props) {
  const { showSpinner, onClick } = props;

  return (
    <div className="load-more-button container-960 container-full-width center-xs">
      {showSpinner && <CircularProgress />}
      {!showSpinner && (
        <button className="secondary" onClick={onClick}>
          <FormattedMessageWrappedInSpan
            id="loadMoreButton.label"
            defaultMessage="Load more"
            description="The button the user can use to load more content"
          />
        </button>
      )}
    </div>
  );
}

LoadMoreButton.propTypes = {
  showSpinner: PropTypes.bool,
  onClick: PropTypes.func,
};

export default LoadMoreButton;
