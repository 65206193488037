import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { injectIntl, defineMessages } from 'react-intl';
import sortBy from 'lodash.sortby';
import { getCountryNameByAlpha2, getLocalizedCountries } from '../../../intl/languages';
import isEmpty from 'validator/lib/isEmpty';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

import GDPRHint from '../GDPRHint';
import { FormattedMessageWrappedInSpan, MuiAutoComplete } from '../../misc';
import statesOfUsa from '../../misc/statesOfUsa';
import isEmailProper from '../../../utils/isEmailProper';

import { getFromLocalStorage, setToLocalStorage } from '../../../utils/browser-storage';

/////////////////////////////////////
// TODO missing test cases
/////////////////////////////////////

class CheckoutOrderingAddressStep extends Component {
  constructor(props) {
    super(props);

    let email = getFromLocalStorage('email') || '';
    let address = {
      firstName: '',
      lastName: '',
      line1: '',
      line2: '',
      postalCode: '',
      city: '',
      country: {
        alpha2: '',
        name: '',
      },
      state: '',
      phone: '',
    };

    const { viewer, cart } = props;
    if (viewer.account && viewer.account.profile && !cart.order) {
      // The address field on profile can be empty
      if (viewer.account.profile.addresses.edges.length >= 1) {
        address = viewer.account.profile.addresses.edges[0].node;
      }
      email = viewer.account.profile.email.trim();
    } else if (cart.order) {
      email = cart.customer.email.trim();
      if (cart.order.address) {
        address = cart.order.address;
      }
    }

    this.state = {
      showInputErrorMsg: {
        firstName: false,
        lastName: false,
        line1: false,
        postalCode: false,
        city: false,
        countryCode: false,
        state: false,
        email: false,
        confirmEmail: false,
      },
      emailsMatch: true,
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      line1: address.line1 || '',
      line2: address.line2 || '',
      postalCode: address.postalCode || '',
      city: address.city || '',
      country: {
        alpha2: address.country.alpha2 || '',
        name: address.country.name || '',
      },
      state: address.state || '',
      phone: address.phone || '',
      email: email,
      confirmEmail: email,
    };

    // Sort the list of countries in alphabetical order A-Z
    const sortedCountries = sortBy(
      getLocalizedCountries(viewer.countries),
      (country) => country.name
    );
    // Create array for `Select` component
    this.countryAutoCompleteOptions = sortedCountries.map((country) => ({
      value: country.alpha2,
      label: country.name,
    }));

    this.stateAutoCompleteOptions = statesOfUsa.map((state) => ({
      value: state,
      label: state,
    }));
  }

  componentDidMount() {
    this.props.setUpFormCheckCallback(this.handlePaymentButtonClicked);
  }

  componentWillUnmount() {
    // save the email for possible later use
    setToLocalStorage('email', this.state.email.trim());
  }

  handleInputChange = (event) => {
    this.props.onFormErrorStateChange(false);
    this.setState({
      showInputErrorMsg: {
        [event.target.name]: false,
      },
      emailsMatch: true,
      [event.target.name]: event.target.value,
    });
  };

  handleInputChangeEmail = (event) => {
    event.target.value = event.target.value.trim();
    this.handleInputChange(event);
  };

  handleCountryChanged = (selectedCountry) => {
    this.setState({
      showInputErrorMsg: {
        countryCode: false,
      },
      country: {
        alpha2: selectedCountry ? selectedCountry.value : '',
        name: selectedCountry ? selectedCountry.label : '',
      },
    });
  };

  handleStateChanged = (selectedState) => {
    this.setState({
      showInputErrorMsg: {
        state: false,
      },
      state: selectedState ? selectedState.value : '',
    });
  };

  handlePaymentButtonClicked = () => {
    
    if (!this._checkInputFields()) {
      this.setState({
        showInputErrorMsg: {
          firstName: this._isEmpty(this.state.firstName),
          lastName: this._isEmpty(this.state.lastName),
          line1: this._isEmpty(this.state.line1),
          postalCode: this._isEmpty(this.state.postalCode),
          city: this._isEmpty(this.state.city),
          countryCode: this._isEmpty(this.state.country.alpha2),
          email: this._isEmpty(this.state.email),
          state: this.state.country.alpha2 === 'US' && this._isEmpty(this.state.state),
          confirmEmail: this._isEmpty(this.state.confirmEmail),
        },
      });
      this.props.onFormErrorStateChange(true);
      return;
    }

    if (!isEmailProper(this.state.email) || !isEmailProper(this.state.confirmEmail)) {
      this.setState({
        showInputErrorMsg: {
          email: true,
          confirmEmail: true,
        },
      });
      this.props.onFormErrorStateChange(true);
      return;
    }

    if (!this._emailsMatch()) {
      this.setState({
        showInputErrorMsg: {
          email: true,
          confirmEmail: true,
        },
      });
      this.props.onFormErrorStateChange(true);
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const { showInputErrorMsg, ...details } = this.state;
    this.props.onNextStep(details);
  };

  handleOnPaste = (e) => {
    e.preventDefault();
  };

  _checkInputFields = () => {
    const {
      firstName,
      lastName,
      line1,
      postalCode,
      city,
      country,
      state,
      email,
      confirmEmail,
    } = this.state;

    if (
      this._isEmpty(firstName) ||
      this._isEmpty(lastName) ||
      this._isEmpty(line1) ||
      this._isEmpty(postalCode) ||
      this._isEmpty(city) ||
      this._isEmpty(country.alpha2) ||
      (country.alpha2 === 'US' && this._isEmpty(state)) ||
      this._isEmpty(email) ||
      this._isEmpty(confirmEmail)
    ) {
      return false;
    }

    return true;
  };

  _isEmpty(s) {
    return isEmpty(s, { ignore_whitespace: true });
  }

  _emailsMatch = () => {
    const { email, confirmEmail } = this.state;
    if (email !== confirmEmail) {
      return false;
    }
    return true;
  };

  getErrorText(inputValue, name, isEmailField) {
    const { intl } = this.props;

    if (!this.state.showInputErrorMsg[name]) {
      return null;
    }

    if (this._isEmpty(inputValue)) {
      return intl.formatMessage(translations.inputRequiredErrorText);
    }

    if (isEmailField) {
      if (!isEmailProper(inputValue)) {
        return intl.formatMessage(translations.invalidEmailErrorText);
      }

      if (!this._emailsMatch()) {
        return intl.formatMessage(translations.emailMatchErrorText);
      }
    }
  }

  getCountryAutoCompleteValue() {
    const { country } = this.state;
    if (country.alpha2 === '' && country.name === '') {
      return null;
    }
    return {
      label: getCountryNameByAlpha2(country.alpha2),
      value: country.alpha2,
    };
  }

  render() {
    const {
      firstName,
      lastName,
      line1,
      line2,
      postalCode,
      city,
      country,
      state,
      phone,
      email,
      confirmEmail,
      showInputErrorMsg,
    } = this.state;

    const { intl, cart, onPreviousStep, isLoggedInNow, goToRegisterStep } = this.props;

    return (
      <div className="order-address-form-container" ref={this.childRef}>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <TextField
              value={firstName}
              name="firstName"
              autoComplete="given-name"
              fullWidth
              label={intl.formatMessage(translations.firstName)}
              onChange={this.handleInputChange}
              error={showInputErrorMsg.firstName}
              helperText={this.getErrorText(firstName, 'firstName')}
            />
          </div>
          <div className="col-xs-16 col-sm-5">
            <TextField
              value={lastName}
              name="lastName"
              autoComplete="family-name"
              fullWidth
              label={intl.formatMessage(translations.lastName)}
              onChange={this.handleInputChange}
              helperText={this.getErrorText(lastName, 'lastName')}
              error={showInputErrorMsg.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <TextField
              value={line1}
              name="line1"
              autoComplete="address-line1"
              fullWidth
              label={intl.formatMessage(translations.line1)}
              onChange={this.handleInputChange}
              helperText={this.getErrorText(line1, 'line1')}
              error={showInputErrorMsg.line1}
            />
          </div>
          <div className="col-xs-16 col-sm-5">
            <TextField
              value={line2}
              name="line2"
              autoComplete="off"
              label={intl.formatMessage(translations.line2)}
              onChange={this.handleInputChange}
              fullWidth
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <TextField
              value={postalCode}
              name="postalCode"
              autoComplete="postal-code"
              fullWidth
              label={intl.formatMessage(translations.postalCode)}
              onChange={this.handleInputChange}
              helperText={this.getErrorText(postalCode, 'postalCode')}
              error={showInputErrorMsg.postalCode}
            />
          </div>
          <div className="col-xs-16 col-sm-5">
            <TextField
              value={city}
              name="city"
              autoComplete="address-level2"
              fullWidth
              label={intl.formatMessage(translations.city)}
              onChange={this.handleInputChange}
              helperText={this.getErrorText(city, 'city')}
              error={showInputErrorMsg.city}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <FormControl fullWidth error={showInputErrorMsg.countryCode}>
              <MuiAutoComplete
                label={intl.formatMessage(translations.countryCode)}
                placeholder={intl.formatMessage(translations.countryCode)}
                onChange={this.handleCountryChanged}
                options={this.countryAutoCompleteOptions}
                value={this.getCountryAutoCompleteValue()}
              />
              <FormHelperText>
                {this.getErrorText(country.alpha2, 'countryCode')}
              </FormHelperText>
            </FormControl>
          </div>

          {country.alpha2 === 'US' && (
            <div className="col-xs-16 col-sm-5 ">
              <FormControl fullWidth error={showInputErrorMsg.state}>
                <MuiAutoComplete
                  label={'State'}
                  placeholder={'State'}
                  onChange={this.handleStateChanged}
                  options={this.stateAutoCompleteOptions}
                  value={{ value: state, label: state }}
                />
                <FormHelperText>{this.getErrorText(state, 'state')}</FormHelperText>
              </FormControl>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <TextField
              value={email}
              name="email"
              autoComplete="email"
              fullWidth
              disabled={Boolean(cart.order)}
              label={intl.formatMessage(translations.email)}
              onChange={this.handleInputChangeEmail}
              helperText={this.getErrorText(email, 'email', true)}
              error={showInputErrorMsg.email}
            />
          </div>
          <div className="col-xs-16 col-sm-5">
            <TextField
              value={confirmEmail}
              name="confirmEmail"
              autoComplete="email"
              fullWidth
              label={intl.formatMessage(translations.confirmEmail)}
              onChange={this.handleInputChangeEmail}
              helperText={this.getErrorText(confirmEmail, 'confirmEmail', true)}
              onPaste={this.handleOnPaste}
              disabled={Boolean(cart.order)}
              error={showInputErrorMsg.confirmEmail}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-5 col-sm-offset-3">
            <TextField
              value={phone}
              name="phone"
              autoComplete="tel-national"
              fullWidth
              label={intl.formatMessage(translations.phone)}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="order-address-form-navigation col-xs-16 col-sm-5 last-sm">
            <div className={`cart-btn-next-step`}>
              <button
                className="btn-default primary-new full-width"
                onClick={this.handlePaymentButtonClicked}
              >
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingAddressStep.nextButton"
                  defaultMessage="Proceed to payment"
                />
              </button>
            </div>
          </div>
          <div className="order-address-form-navigation col-xs-16 col-sm-5 col-sm-offset-3">
            <div
              className="navigation-back"
              onClick={() => {
                if (isLoggedInNow) {
                  onPreviousStep();
                } else {
                  goToRegisterStep();
                }
              }}
            >
              <KeyboardBackspaceRoundedIcon />
              {isLoggedInNow ? (
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingAddressStep.backButtonCart"
                  defaultMessage="Back to cart"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingAddressStep.backButtonLogin"
                  defaultMessage="Back to login"
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-sm-10 col-sm-offset-3">
            <GDPRHint className="mt-30 center-text" />
          </div>
        </div>
      </div>
    );
  }
}

CheckoutOrderingAddressStep.propTypes = {
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  cart: PropTypes.object,
  viewer: PropTypes.object,
  intl: PropTypes.object,
  isLoggedInNow: PropTypes.bool,
  goToRegisterStep: PropTypes.func,
  onFormErrorStateChange: PropTypes.func,
  setUpFormCheckCallback: PropTypes.func,
};

const translations = defineMessages({
  firstName: {
    id: 'checkoutOrderingAddressStep.input.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'checkoutOrderingAddressStep.input.lastName',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'checkoutOrderingAddressStep.input.email',
    defaultMessage: 'Email',
  },
  confirmEmail: {
    id: 'checkoutOrderingAddressStep.input.confirmEmail',
    defaultMessage: 'Confirm email',
  },
  line1: {
    id: 'checkoutOrderingAddressStep.input.line1',
    defaultMessage: 'Address 1',
  },
  line2: {
    id: 'checkoutOrderingAddressStep.input.line2',
    defaultMessage: 'Address 2',
  },
  postalCode: {
    id: 'checkoutOrderingAddressStep.input.postalCode',
    defaultMessage: 'Postal code',
  },
  city: {
    id: 'checkoutOrderingAddressStep.input.city',
    defaultMessage: 'City',
  },
  countryCode: {
    id: 'checkoutOrderingAddressStep.input.countryCode',
    defaultMessage: 'Country code',
  },
  phone: {
    id: 'checkoutOrderingAddressStep.input.phone',
    defaultMessage: 'Telephone',
  },
  inputRequiredErrorText: {
    id: 'checkoutOrderingAddressStep.inputErrorText',
    defaultMessage: 'This field is required',
  },
  invalidEmailErrorText: {
    id: 'checkoutOrderingAddressStep.invalidEmailErrorText',
    defaultMessage: 'Please enter a valid email',
  },
  emailMatchErrorText: {
    id: 'checkoutOrderingAddressStep.emailMatchErrorText',
    defaultMessage: 'Both email addresses has to be the same',
  },
});

export { CheckoutOrderingAddressStep };

export default createFragmentContainer(injectIntl(CheckoutOrderingAddressStep), {
  cart: graphql`
    fragment CheckoutOrderingAddressStep_cart on Cart {
      id
      customer {
        email
      }
      order {
        id
        address {
          firstName
          lastName
          phone
          city
          postalCode
          line2
          line1
          country {
            alpha2
            name
          }
          state
        }
      }
    }
  `,
  viewer: graphql`
    fragment CheckoutOrderingAddressStep_viewer on Viewer {
      countries {
        name
        alpha2
      }
      account @include(if: $isLoggedIn) {
        profile {
          addresses(first: 10) {
            edges {
              node {
                firstName
                lastName
                phone
                city
                postalCode
                state
                line2
                line1
                country {
                  alpha2
                  name
                }
              }
            }
          }
          email
        }
      }
    }
  `,
});
