import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Slider from 'react-slick';
import shuffle from 'lodash.shuffle';

import fetchBestofConfig from '../../utils/fetchBestofConfig';

import { sendError } from '../../appsignal';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function BackgroundSlideshow({
  match: {
    params: { id },
  },
}) {
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (id) {
      fetchImages(id)
        .then(setImages)
        .catch(error => {
          sendError(error);
        });
    } else {
      setImages(null);
    }
  }, [id]);

  if (!images || !id || window.innerWidth < 768) {
    return null;
  }

  return (
    <div className="background-slideshow-container">
      <Slider
        autoplay={true}
        fade={true}
        swipe={false}
        draggable={false}
        arrows={false}
        dots={false}
        autoplaySpeed={20000}
        speed={2000}
        pauseOnHover={false}
        touchMove={false}
        lazyLoad={true}
      >
        {images.map((image, index) => (
          <div key={index} className="background-slideshow-slide">
            <span style={{ backgroundImage: `url(${image})` }} />
          </div>
        ))}
      </Slider>
      <div className="cover" />
    </div>
  );
}

function fetchImages(sgId) {
  return new Promise(function (resolve, reject) {
    fetchBestofConfig(sgId, 'GET')
      .then(config => {
        if (config.isAvailable) {
          resolve(
            shuffle(
              Array.prototype.slice.call(config.responseXML.getElementsByTagName('image'))
            )
              .slice(0, 20)
              .map(
                image =>
                  `/bestof/${sgId}/` + image.getAttribute('imageURL').replace('\\', '/')
              )
          );
        } else {
          resolve(null);
        }
      }).catch(error => {
        sendError(error);
      });
  });
}

BackgroundSlideshow.propTypes = {
  match: PropTypes.object,
};

export default withRouter(memo(BackgroundSlideshow));
