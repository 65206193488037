import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmailProper from '../../utils/isEmailProper';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PrimaryButton } from '../misc/Buttons';
import { FormattedMessageWrappedInSpan } from '../misc';

import { MAGIC_LINK_EXPLANATION_MSG } from '../../utils/variables';
import { getFromLocalStorage } from '../../utils/browser-storage';
import useEmailSpellChecking from '../../hooks/useEmailSpellChecking';
import EmailSuggestionTooltip from '../misc/EmailSuggestionTooltip';

const ENTER_KEY = 'Enter';

const LoginForm = (props) => {
  const {
    onRequestLogin,
    isLoginPage,
    onOpenNotificationDialog,
    isLinkSending,
    linkSentSuccess,
  } = props;
  const [email, setEmail] = useState(getFromLocalStorage('email') || '');
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);

  let suggestedEmail = useEmailSpellChecking(email);

  const handleEmailChange = (event) => {
    if (showEmailErrorMessage) {
      setShowEmailErrorMessage(false);
    }
    setEmail(event.target.value.trim());
  };

  const handleEmailKeypress = (event) => {
    if (event.key === ENTER_KEY) {
      handleSendMlClicked();
    }
  };

  const handleSendMlClicked = () => {
    if (!isEmailProper(email)) {
      setShowEmailErrorMessage(true);
      return;
    }
    onRequestLogin(email.trim());
  };

  const getEmailErrorMessage = () => {
    if (showEmailErrorMessage) {
      if (!email) {
        return (
          <FormattedMessageWrappedInSpan
            id="loginForm.magicLink.inputErrorText"
            defaultMessage="This field is required"
          />
        );
      }

      if (!isEmailProper(email)) {
        return (
          <FormattedMessageWrappedInSpan
            id="loginForm.magicLink.invalidEmailText"
            defaultMessage="Please enter a valid email"
          />
        );
      }
    }
    return false;
  };

  return (
    <div className="login-form-container">
      <EmailSuggestionTooltip
        emailSuggestion={suggestedEmail}
        onAcceptSuggestion={() => {
          setEmail(suggestedEmail);
        }}
      >
        <TextField
          autoFocus={isLoginPage && !email}
          classes={{ root: 'login-textfield' }}
          value={email || ''}
          name="email"
          fullWidth
          label={
            <FormattedMessageWrappedInSpan
              id="loginForm.enterEmailHint"
              defaultMessage="Enter your email"
            />
          }
          onChange={handleEmailChange}
          onKeyPress={handleEmailKeypress}
          error={showEmailErrorMessage}
          helperText={getEmailErrorMessage()}
          autoComplete="off"
        />
      </EmailSuggestionTooltip>
      <PrimaryButton
        leftLabel={
          linkSentSuccess ? (
            <FormattedMessageWrappedInSpan
              id="notificationDialogBody.verifyEmailNextSteps.title"
              defaultMessage="Link sent"
            />
          ) : isLinkSending ? (
            <CircularProgress color={'white'} size={20} thickness={3} />
          ) : (
            <FormattedMessageWrappedInSpan
              id="loginForm.login"
              defaultMessage="Send Magic Link"
            />
          )
        }
        onClick={handleSendMlClicked}
        disabled={linkSentSuccess || isLinkSending}
      />
      <div
        className="explanation"
        onClick={() => {
          onOpenNotificationDialog(MAGIC_LINK_EXPLANATION_MSG);
        }}
      >
        <FormattedMessageWrappedInSpan
          id="loginForm.magicLink.title"
          defaultMessage="What is a magic link?"
        />
      </div>
    </div>
  );
};

LoginForm.defaultProps = {
  isLoginPage: false,
};

LoginForm.propTypes = {
  intl: PropTypes.object,
  isLoginPage: PropTypes.bool,
  onRequestLogin: PropTypes.func,
  onOpenNotificationDialog: PropTypes.func,
  linkSentSuccess: PropTypes.bool,
  isLinkSending: PropTypes.bool,
};

export default LoginForm;
