/**
 * @flow
 * @relayHash 7001cbc7feab5fc53eeeb1254734f862
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RefreshTokenInput = {|
  refreshToken: string
|};
export type LoginRefreshMutationVariables = {|
  input: RefreshTokenInput
|};
export type LoginRefreshMutationResponse = {|
  +refreshToken: ?{|
    +auth: ?{|
      +email: ?string,
      +status: ?string,
      +token: ?{|
        +access: ?string,
        +refresh: ?string,
      |},
    |}
  |}
|};
export type LoginRefreshMutation = {|
  variables: LoginRefreshMutationVariables,
  response: LoginRefreshMutationResponse,
|};
*/


/*
mutation LoginRefreshMutation(
  $input: RefreshTokenInput!
) {
  refreshToken(input: $input) {
    auth {
      email
      status
      token {
        access
        refresh
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RefreshTokenInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "refreshToken",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshTokenPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "auth",
        "storageKey": null,
        "args": null,
        "concreteType": "Auth",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "Tokenset",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "access",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "refresh",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginRefreshMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginRefreshMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginRefreshMutation",
    "id": null,
    "text": "mutation LoginRefreshMutation(\n  $input: RefreshTokenInput!\n) {\n  refreshToken(input: $input) {\n    auth {\n      email\n      status\n      token {\n        access\n        refresh\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5101f58d0f727d28dc4cb7df19824ac';

module.exports = node;
