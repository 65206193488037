import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trackEvent } from '../../utils/ga-tracking';
import { useFlag } from '@unleash/proxy-client-react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';

import FormattedMessageWrappedInSpan from './FormattedMessageWrappedInSpan';

const RaceletterForm = ({
  labelClass,
  liftUpConfirmation,
  showError,
  removeShowError,
}) => {
  const isFeatureRadioBtnsEnabled = useFlag('radio-buttons-for-newsletter');

  const [raceletterConfirmation, setRaceletterConfirmation] = useState(
    isFeatureRadioBtnsEnabled ? null : false
  );

  useEffect(() => {
    trackEvent('Feature Flag', 'radio-buttons-for-newsletter', isFeatureRadioBtnsEnabled);
    if (isFeatureRadioBtnsEnabled) {
      liftUpConfirmation && liftUpConfirmation(null);
    }
  }, []);

  const handleChange = (event) => {
    setRaceletterConfirmation(event.target.value === 'true');
    liftUpConfirmation && liftUpConfirmation(event.target.value === 'true');
    if (showError) {
      removeShowError();
    }
  };

  const handleChangeCheckbox = (event) => {
    setRaceletterConfirmation((current) => !current);
    liftUpConfirmation && liftUpConfirmation(!raceletterConfirmation);
  };

  return (
    <>
      {!isFeatureRadioBtnsEnabled && (
        <FormControlLabel
          classes={{ root: 'notification-checkbox' }}
          control={
            <Checkbox checked={raceletterConfirmation} onChange={handleChangeCheckbox} />
          }
          label={
            <FormattedMessageWrappedInSpan
              id="eventReminderForm.raceletterCheckboxText"
              defaultMessage="Subscribe to the Sportograf Raceletter. I can revoke this consent at any time with effect for the future by sending an email to support@sportograf.com."
              className={labelClass}
            />
          }
        />
      )}
      {isFeatureRadioBtnsEnabled && (
        <>
          <FormLabel>
            <FormattedMessageWrappedInSpan
              id="eventReminderForm.raceletterCheckboxText"
              defaultMessage="Subscribe to the Sportograf Raceletter. I can revoke this consent at any time with effect for the future by sending an email to support@sportograf.com."
              className={labelClass}
            />
          </FormLabel>
          <RadioGroup
            aria-label="raceletter"
            name="raceletter"
            value={raceletterConfirmation}
            onChange={handleChange}
            className="raceletter-radio-group"
          >
            <FormControlLabel
              className="mt-10"
              classes={{ root: 'notification-radio ' }}
              value={true}
              control={<Radio />}
              label={
                <FormattedMessageWrappedInSpan
                  id="eventReminderForm.raceletterCheckbox.accept"
                  defaultMessage="Yes, sign me up"
                  className={classNames(labelClass, { error: showError })}
                />
              }
            />
            <FormControlLabel
              className="mb-10"
              classes={{ root: 'notification-radio ' }}
              value={false}
              control={<Radio />}
              label={
                <FormattedMessageWrappedInSpan
                  id="eventReminderForm.raceletterCheckbox.refuse"
                  defaultMessage="No, don’t sign me up"
                  className={classNames(labelClass, { error: showError })}
                />
              }
            />
            {showError && (
              <FormattedMessageWrappedInSpan
                id="eventReminderForm.raceletterCheckbox.error"
                defaultMessage="Please choose an option"
                className="raceletter-error-message"
              />
            )}
          </RadioGroup>
        </>
      )}
    </>
  );
};

RaceletterForm.propTypes = {
  labelClass: PropTypes.string,
  liftUpConfirmation: PropTypes.func,
  removeShowError: PropTypes.func,
  showError: PropTypes.bool,
};

export default RaceletterForm;
