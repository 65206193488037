import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import { PrimaryButton } from '../../misc/Buttons';

import { trackEvent } from '../../../utils/ga-tracking';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const imgName = {
  3858: 'packshot-4721',
  3848: 'packshot-5041',
  3601: 'packshot-5071',
  // events in prod
  4721: 'packshot-4721',
  5071: 'packshot-5071',
  5074: 'packshot-5071',
  5041: 'packshot-5041',
  5338: 'packshot-5338',
  5456: 'packshot-5456',
  5090: 'packshot-5090',
  7992: 'packshot-7992',
  8479: 'packshot-8479',
};

function PhotoBookInfoBanner({ eventId, eventName, onContinueClick }) {
  function onStartBtnClick() {
    onContinueClick();

    trackEvent('Account Event', 'Photo Book', 'Photo Book Info Banner');
  }

  return (
    <div className="photo-book-info-banner-container">
      <h1>
        <FormattedMessageWrappedInSpan
          id="photoBookInfoBanner.bigScreen.title"
          defaultMessage="Get your absolutely unique myRacebook now!"
        />
      </h1>
      <div className="row middle-sm">
        <div className="col-sm-8">
          <ul>
            <li>
              <FormattedMessageWrappedInSpan
                id="photoBookInfoBanner.bigScreen.description.lineOne"
                defaultMessage="Individualize your book easily!"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="photoBookInfoBanner.bigScreen.description.lineTwo"
                defaultMessage="Add your best personal photos!"
              />
            </li>
            <li>
              <FormattedMessageWrappedInSpan
                id="photoBookInfoBanner.bigScreen.description.lineThree"
                defaultMessage="Become part of the legendary {eventName} history!"
                values={{ eventName }}
              />
            </li>
          </ul>
        </div>
        <div className="col-sm-8">
          <div className="photo-book-info-banner-img-container">
            <img src={`/images/${imgName[eventId]}.png`} />
          </div>
        </div>
      </div>
      <div className="center-text">
        <PrimaryButton
          leftLabel={
            <FormattedMessageWrappedInSpan
              id="photoBookInfoBanner.button"
              defaultMessage="Start"
            />
          }
          onClick={onStartBtnClick}
        />
      </div>
    </div>
  );
}

PhotoBookInfoBanner.propTypes = {
  eventId: PropTypes.number,
  eventName: PropTypes.string,
  onContinueClick: PropTypes.func,
};

export default PhotoBookInfoBanner;
