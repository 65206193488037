import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import EventOnlineView from '../components/content/event/EventOnlineView';
import Faq from '../components/content/Faq';
import InvalidGpxFileInformation from '../components/content/informations/InvalidGpxFileInformation';
import LoadingView from '../components/content/LoadingView';
import GpxMediaList from '../components/content/lists/GpxMediaList';
import withRequestHandler from '../components/hoc/withRequestHandler';
import { UserContext } from '../components/context/UserContext'

import { getMainSearchResultUrl } from '../utils/common';
import { includesFotoFlat } from '../utils/common';
import { MAIN_SEARCH_FACE } from '../utils/variables';
import { customScrollToComponent } from '../utils/common';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const EventGpsSearchPage = ({
  viewer,
  gpxFileIdFromUrl,
  snFromUrl,
  relay,
  onRequestStart,
  onRequestStop,
  eventHasSecondFactor,
  isLoading,
  customerKey,
  history,
  codeSecondFactorForFaceEvent,
}) => {
  const { event } = viewer;
  const [extendedSearchSelectedTab, setExtendedSearchSelectedTab] = useState(2);
  const [extendedSearchExpand, setExtendedSearchExpand] = useState(true);
  const [showInvalidGpxFileInformation, setShowInvalidGpxFileInformation] =
    useState(false);
  const [searchFormPosition, setSearchFormPosition] = useState(null);
  const { onMarathonFotoChange } = useContext(UserContext);
  const eventOnlineView = useRef();
  const mediaSection = useRef();
  const participantMediaCount = event.participant
    ? event.participant.media.mediaInfo.count
    : 0;

  useEffect(() => {
    onMarathonFotoChange(event.marathonFoto);
    scrollToMediaSection();
  }, []);

  useEffect(() => {
    refetchMediaForNewGpxFile(gpxFileIdFromUrl);
    scrollToMediaSection();
  }, [gpxFileIdFromUrl]);

  const handleExtendedSearchTabChanged = (event, value) => {
    setExtendedSearchSelectedTab(value);
    setExtendedSearchExpand(true);
  };

  const handleExtendedSearchExpand = () => {
    setExtendedSearchExpand((prevExpand) => !prevExpand);
    // if the extended search changes from open to close it is important to reset
    // the selected tab to its default. Otherwise the media list will not be rendered.
    setExtendedSearchSelectedTab((prevSelectedTab) => (prevExpand ? 2 : prevSelectedTab));
  };

  const handleDeepSearchBtnClicked = () => {
    scrollToEventOnlineView(0);
  };

  const handleCategorySearchBtnClicked = () => {
    scrollToEventOnlineView(1);
  };

  const handleGpsSearchBtnClicked = () => {
    scrollToEventOnlineView(2);
  };

  const goBackToMainSearchResults = () => {
    const destinationUrl = getMainSearchResultUrl(
      event.sgId,
      snFromUrl,
      eventHasSecondFactor,
      customerKey
    );
    history.push(destinationUrl);
  };

  const scrollToForm = () => {
    customScrollToComponent(searchFormPosition);
  };

  const liftingUpFormPositionData = (formPositionRef) => {
    setSearchFormPosition(formPositionRef);
  };

  const scrollToEventOnlineView = (tabIndex) => {
    customScrollToComponent(eventOnlineView.current);
    setExtendedSearchSelectedTab(tabIndex);
    setExtendedSearchExpand(true);
  };

  const scrollToMediaSection = () => {
    if (mediaSection.current) {
      customScrollToComponent(mediaSection.current);
    }
  };

  const refetchMediaForNewGpxFile = (newGpxFileId) => {
    onRequestStart();
    setShowInvalidGpxFileInformation(false);
    relay.refetch({ gpxFileId: newGpxFileId }, null, (err) => {
      if (err) {
        onRequestStop();
        setShowInvalidGpxFileInformation(true);
      } else {
        onRequestStop();
      }
    });
  };

  if (event.state === 'reminder') {
    return <Redirect to={`/event/${event.sgId}`} />;
  }

  return (
    <div className="container-960 container-page">
      <EventOnlineView
        ref={eventOnlineView}
        onlineEvent={event}
        urlSn={snFromUrl}
        customerKey={customerKey}
        extendedSearchSelectedTab={extendedSearchSelectedTab}
        extendedSearchExpand={extendedSearchExpand}
        hasSmartSearchTab={event.smartSearch && participantMediaCount !== 0}
        hasGpsSearchTab={event.gps}
        hasDualSearchTab={event.searchDual}
        onExtendedSearchTabChange={handleExtendedSearchTabChanged}
        onExtendedSearchExpand={handleExtendedSearchExpand}
        setFormRef={liftingUpFormPositionData}
        codeSecondFactorForFaceEvent={codeSecondFactorForFaceEvent}
        showStillProcessingHint={event.pendingMediaProcessing}
        goBackToMainSearchResults={goBackToMainSearchResults}
      />
      <div ref={mediaSection}>
        {isLoading && <LoadingView />}
        {!isLoading && extendedSearchSelectedTab === 2 && (
          <>
            {!showInvalidGpxFileInformation &&
              event.searchMediaByGpx.mediaInfo.count !== 0 && (
                <GpxMediaList
                  eventHasSearchByFace={event.mainSearch === MAIN_SEARCH_FACE}
                  eventHasSecondFactor={eventHasSecondFactor}
                  eventId={event.sgId}
                  startnumber={snFromUrl}
                  gpxFileId={gpxFileIdFromUrl}
                  gpxFileMedia={event.searchMediaByGpx}
                  offer={event}
                  participantMediaCount={participantMediaCount}
                  goBackToMainSearchResults={goBackToMainSearchResults}
                  onScrollToMainSearch={scrollToForm}
                  eventHasFotoFlat={includesFotoFlat(event.products)}
                />
              )}
            {(showInvalidGpxFileInformation ||
              event.searchMediaByGpx.mediaInfo.count === 0) && (
              <InvalidGpxFileInformation />
            )}
          </>
        )}
      </div>
      <Faq onFindMore={() => scrollToEventOnlineView(0)} />
    </div>
  );
};

EventGpsSearchPage.propTypes = {
  customerKey: PropTypes.string,
  eventHasSecondFactor: PropTypes.bool,
  isLoading: PropTypes.bool,
  snFromUrl: PropTypes.string,
  gpxFileIdFromUrl: PropTypes.string,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  codeSecondFactorForFaceEvent: PropTypes.string,
  history: PropTypes.object,
};

export { EventGpsSearchPage };

export default createRefetchContainer(
  withRequestHandler(EventGpsSearchPage),
  {
    viewer: graphql`
      fragment EventGpsSearchPage_viewer on Viewer {
        event(id: $eventId, language: $language) {
          id
          sgId
          gps
          hostResultUrl
          products {
            type
          }
          pendingMediaProcessing

          participant(startnumber: $startnumber, secondFactor: $secondFactor)
            @skip(if: $noStartnumber) {
            media {
              mediaInfo {
                count
              }
            }
            startnumber
          }
          searchMediaByGpx(gpxFileId: $gpxFileId) {
            mediaInfo {
              count
            }
            ...GpxMediaList_gpxFileMedia
          }
          secondFactorType {
            type
          }
          mainSearch
          searchDual
          smartSearch
          state
          marathonFoto
          ...EventOnlineView_onlineEvent
          ...GpxMediaList_offer
        }
      }
    `,
  },
  graphql`
    query EventGpsSearchPageRefetchQuery(
      $eventId: ID!
      $language: String
      $startnumber: ID!
      $gpxFileId: ID!
      $count: Int!
      $cursor: String
      $secondFactor: String
      $individualPart: String
      $noStartnumber: Boolean
      $isRecheckPage: Boolean!
    ) {
      viewer {
        ...EventGpsSearchPage_viewer
      }
    }
  `
);
