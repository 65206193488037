import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../misc';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import CookieSettingsDialog from '../content/dialogs/CookieSettingsDialog';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const CookieBanner = (props) => {
  const {
    show,
    statsCookies,
    socialCookies,
    sportografWebCookies,
    onCookiesChange,
    onRequestClose,
  } = props;

  const [cookieSettingsDialogOpen, setCookieSettingsDialogOpen] = useState(false);

  return (
    <Fragment>
      {show && (
        <div className="cookie-banner-container">
          <div className="cookie-text-container">
            <p>
              <FormattedMessageWrappedInSpan
                id="cookieBanner.text"
                defaultMessage="We use cookies to offer you a better browsing experience."
              />
              &nbsp;
              <b>
                <Link to="/cookies-info">
                  <FormattedMessageWrappedInSpan
                    id="cookieBanner.moreInfoLink"
                    defaultMessage="Read more..."
                  />
                </Link>
              </b>
            </p>
            <p>
              <FormattedMessageWrappedInSpan
                id="cookieBanner.dgprText"
                defaultMessage="We have updated our privacy policy to be compliant with the GDPR."
              />
              &nbsp;
              <b>
                <Link to="/privacy">
                  <FormattedMessageWrappedInSpan
                    id="cookieBanner.linkToPrivacyPolicy"
                    defaultMessage="View privacy policy"
                  />
                </Link>
              </b>
            </p>
          </div>
          <div className="cookie-button-container">
            <button
              className={classNames('secondary', { disabled: cookieSettingsDialogOpen })}
              onClick={() => setCookieSettingsDialogOpen(true)}
            >
              <FormattedMessageWrappedInSpan
                id="cookieBanner.preferences"
                defaultMessage="Cookies preferences"
              />
            </button>
            <button className="secondary" onClick={() => onRequestClose(true)}>
              <FormattedMessageWrappedInSpan
                id="cookieBanner.acceptButton"
                defaultMessage="Accept all cookies"
              />
            </button>
          </div>
          <IconButton
            classes={{ root: 'cookie-banner-close-icon-btn' }}
            onClick={() => onRequestClose(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <CookieSettingsDialog
        statsCookies={statsCookies}
        socialCookies={socialCookies}
        sportografWebCookies={sportografWebCookies}
        onCookiesChange={onCookiesChange}
        onRequestClose={(needsRefresh) => {
          setCookieSettingsDialogOpen(false);
          if (needsRefresh) {
            refreshPage();
          }
        }}
        open={cookieSettingsDialogOpen}
      />
    </Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

CookieBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
  sportografWebCookies: PropTypes.bool,
  onCookiesChange: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default CookieBanner;
