/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CheckoutOrderingSteps_cart$ref = any;
type CheckoutOrderingSteps_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutPage_viewer$ref: FragmentReference;
declare export opaque type CheckoutPage_viewer$fragmentType: CheckoutPage_viewer$ref;
export type CheckoutPage_viewer = {|
  +cart: ?{|
    +lineItems: ?$ReadOnlyArray<?{|
      +id: string,
      +product: ?{|
        +id: string,
        +event: ?{|
          +id: string,
          +sgId: ?number,
          +name: ?string,
        |},
      |},
    |}>,
    +customer: ?{|
      +email: ?string
    |},
    +id: string,
    +order: ?{|
      +id: string,
      +orderid: ?string,
    |},
    +$fragmentRefs: CheckoutOrderingSteps_cart$ref,
  |},
  +adyenPaymentsDetails?: ?{|
    +merchantReference: ?string,
    +pspReference: ?string,
    +resultCode: ?string,
  |},
  +$fragmentRefs: CheckoutOrderingSteps_viewer$ref,
  +$refType: CheckoutPage_viewer$ref,
|};
export type CheckoutPage_viewer$data = CheckoutPage_viewer;
export type CheckoutPage_viewer$key = {
  +$data?: CheckoutPage_viewer$data,
  +$fragmentRefs: CheckoutPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CheckoutPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "adyenRedirectResult",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "noAdyenRedirectResult",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cart",
      "storageKey": null,
      "args": null,
      "concreteType": "Cart",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lineItems",
          "storageKey": null,
          "args": null,
          "concreteType": "LineItem",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "product",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "event",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Event",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "sgId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customer",
          "storageKey": null,
          "args": null,
          "concreteType": "Customer",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        },
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "order",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "orderid",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "CheckoutOrderingSteps_cart",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": false,
      "condition": "noAdyenRedirectResult",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "adyenPaymentsDetails",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "redirectResult",
              "variableName": "adyenRedirectResult"
            }
          ],
          "concreteType": "AdyenPaymentsDetails",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "merchantReference",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "pspReference",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "resultCode",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "CheckoutOrderingSteps_viewer",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e97d05e9aedd20de029c4f7d034ba841';

module.exports = node;
