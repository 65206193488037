import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, injectIntl } from 'react-intl';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const CustomDate = (props) => {
  if (props.value) {
    return (
      <FormattedDate value={props.value} timeZone='UTC'/>
    )
  } else {
    return null;
  }
}

CustomDate.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(CustomDate);
