import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AddFotoCdToCartMutation($input: AddFotoCdToCartInput!) {
    addFotoCdToCart(input: $input) {
      cart {
        additionalProducts {
          id
          price
          type
        }
        lineItems {
          id
          product {
            event {
              sgId
              name
            }
            price
            type
          }
          price
          productParams {
            ... on SingleFotoParams {
              foto {
                sgId
              }
            }
            ... on FotoFlatParams {
              startnumber
            }
          }
        }
        shipmentRequired
        totalPrice
      }
    }
  }
`;

function commit(environment, input, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: input,
    },
    onCompleted(response, errors) {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.addFotoCdToCart.cart);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
