import React, { useState, useEffect, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../../misc';

import { sendError } from '../../../../appsignal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CustomDialog from '../CustomDialog';
import Webcam from 'react-webcam';
import CircularProgress from '@material-ui/core/CircularProgress';
import AllowWebcamDialog from './AllowWebcamDialog';

////////////////////////////////////
//////// TODO missing test cases: interaction with react-webcam component
//////// line 52 starting react-webcam
//////// lines 58-61 error handling for react-webcam
//////// lines 71-73 capturing image
////////////////////////////////////

const WebcamDialog = (props) => {
  const { open, onRequestClose, onChange } = props;
  const [error, setError] = useState('');
  const [ready, setReady] = useState(false);
  const [showAllowWebcamDialog, setShowAcceptAllowWebcamDialog] = useState(false);
  const [acceptedAllowWebcamDialog, setAcceptedAllowWebcamDialog] = useState(false);
  const webcamRef = createRef(null);

  useEffect(() => {
    open && setShowAcceptAllowWebcamDialog(true);
  }, [open]);

  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: 'user', //maybe not exact because we have to test if external webcams can be used.
    //facingMode: 'environment', //maybe not exact because we have to test if external webcams can be used.
  };

  const handleAllowWebcamDialogClosed = () => {
    setShowAcceptAllowWebcamDialog(false);
    handleCloseModal();
  };

  const handleAllowWebcamDialogConfirmed = () => {
    setAcceptedAllowWebcamDialog(true);
  };

  const onUserMedia = () => {
    setReady(true);
  };

  const onUserMediaError = (ex) => {
    setReady(true);
    if (ex instanceof DOMException) {
      if (ex.name === 'NotAllowedError') {
        setError(ex.name);
      } else if (ex.name === 'NotFoundError') {
        setError(ex.name);
      } else {
        // handle unexpected DOMException
      }
    } else {
      sendError(ex);
    }
  };

  const handleCapture = useCallback(() => {
    onChange(webcamRef.current.getScreenshot({ width: 800, height: 800 }));
    setReady(false);
    onRequestClose();
  }, [webcamRef, onChange, onRequestClose]);

  const handleCloseModal = () => {
    setReady(false);
    onRequestClose();
    error && window.location.reload(false);
  };

  return (
    <>
      <AllowWebcamDialog
        open={!acceptedAllowWebcamDialog && showAllowWebcamDialog}
        onRequestClose={handleAllowWebcamDialogClosed}
        onRequestConfirm={handleAllowWebcamDialogConfirmed}
      />
      <CustomDialog
        modal={false}
        open={acceptedAllowWebcamDialog && open}
        onRequestClose={handleCloseModal}
        hasFixedWidth={false}
      >
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              {!ready && (
                <div className="cart-coupon-inner-container">
                  <div className="cart-coupon-spinner">
                    <CircularProgress />
                  </div>
                </div>
              )}
              {!error && (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  onUserMediaError={onUserMediaError}
                  onUserMedia={onUserMedia}
                  mirrored={true}
                />
              )}
              {error === 'NotAllowedError' && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="webcamDialog.errorNotAllowedError.title"
                      defaultMessage="Webcam access required"
                    />
                  </h1>
                  <h2>
                    <FormattedMessageWrappedInSpan
                      id="webcamDialog.errorNotAllowedError"
                      defaultMessage="You have not granted access to your webcam"
                    />
                  </h2>
                  <FormattedMessageWrappedInSpan
                    id="webcamDialog.howToEnableWebcam"
                    defaultMessage="Please enable and grant access to your webcam. More help for:"
                  />
                  <p>
                    <a
                      href={`https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&oco=1`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessageWrappedInSpan
                        id="webcamDialog.chromeHelp"
                        defaultMessage="Enable on Chrome"
                      />
                    </a>
                  </p>
                  <p>
                    <a
                      href={`https://www.google.com/search?q=enable+webcam+on+safari+ios&rlz=1C5CHFA_enDE811DE811&oq=enable+webcam+on+safari`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessageWrappedInSpan
                        id="webcamDialog.safariDesktopHelp"
                        defaultMessage="Enable on Safari (Desktop)"
                      />
                    </a>
                  </p>
                  <p>
                    <a
                      href={`https://www.google.com/search?q=enable+webcam+on+safari+ios&rlz=1C5CHFA_enDE811DE811&oq=enable+webcam+on+safari+ios`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessageWrappedInSpan
                        id="webcamDialog.safariIOSHelp"
                        defaultMessage="Enable on Safari (IOS)"
                      />
                    </a>
                  </p>
                  <p>
                    <a
                      href={`https://support.mozilla.org/kb/how-manage-your-camera-and-microphone-permissions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessageWrappedInSpan
                        id="webcamDialog.firefoxHelp"
                        defaultMessage="Enable on Firefox"
                      />
                    </a>
                  </p>
                </>
              )}
              {error === 'NotFoundError' && (
                <>
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="webcamDialog.errorNotFoundError.title"
                      defaultMessage="Camera unavailable. Email us"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="webcamDialog.errorNotFoundError"
                    defaultMessage="You have no camera available. Please contact us via email to help you out."
                  />
                  <p>
                    <a
                      href="mailto:support@sportograf.com?subject=Webcam not available"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessageWrappedInSpan
                        id="webcamDialog.sendEmailForHelp"
                        defaultMessage="Contact"
                      />
                    </a>
                  </p>
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions disableSpacing={true}>
            {ready && (
              <>
                <div className="notification-action">
                  <button className="accept" onClick={handleCloseModal}>
                    <FormattedMessageWrappedInSpan
                      id="webcamDialog.closeButton"
                      defaultMessage="Close"
                    />
                  </button>
                </div>
                {!error && (
                  <div className="notification-action">
                    <button className="accept" onClick={handleCapture}>
                      <CameraAltIcon />
                    </button>
                  </div>
                )}
              </>
            )}
          </DialogActions>
        </div>
      </CustomDialog>
    </>
  );
};

export default WebcamDialog;

WebcamDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onChange: PropTypes.func,
};
