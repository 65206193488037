import React from 'react';

import { UserConsumer } from '../context/UserContext';

import SportografFooter from './SportografFooter';
import MFFooter from '../../components_marathonFoto/MFFooter';

const Header = props => {
  return (
    <UserConsumer>
      {({ isMarathonFoto }) =>
        isMarathonFoto ? <MFFooter {...props} /> : <SportografFooter {...props} />
      }
    </UserConsumer>
  );
};

export default Header;