import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation AddReminderForEventWhenExpiredMutation($input: AddReminderForEventWhenExpiredInput!) {
    addReminderForEventWhenExpired(input: $input) {
      status
    }
  }
`;

function commit(environment, token, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        token,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.addReminderForEventWhenExpired.status);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
