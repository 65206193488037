import React from 'react'

const AboutPersonaldataSvg = props => (
  <svg width={244} height={220} viewBox="0 0 244 172" {...props}>
    <defs>
      <path id="path-1" d="M0 .4892h214.9526v142.7901H0z" />
      <path id="path-3" d="M0 172h243V1H0z" />
    </defs>
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g>
        <mask id="mask-2" fill="#fff">
          <use xlinkHref="#path-1" />
        </mask>
        <path
          d="M204.7705 143.2793H10.1825c-5.6243 0-10.1836-4.5324-10.1836-10.1221V10.6112C-.001 5.0215 4.5582.489 10.1826.489h194.588c5.6243 0 10.182 4.5325 10.182 10.1222v122.546c0 5.5897-4.5577 10.1221-10.182 10.1221"
          id="Fill-1"
          fill="#E7EBED"
          mask="url(#mask-2)"
        />
      </g>
      <g className="about-personaldata-animation-badge">
        <path
          d="M242 134.8192v-31.7676L211.5 97l-30.5 6.0516v31.7676S181 162.537 211.5 170c30.5-7.463 30.5-35.1808 30.5-35.1808z"
          id="Fill-10"
          fill="#FEFEFE"
        />
        <path
          d="M211.5 170c30.5-7.463 30.5-35.1808 30.5-35.1808v-31.7676L211.5 97l-30.5 6.0516v31.7676S181 162.537 211.5 170z"
          id="Stroke-12"
          stroke="#3084C6"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="mask-4" fill="#fff">
          <use xlinkHref="#path-3" />
        </mask>
        <g className="about-personaldata-animation-lock">
          <path
            id="Fill-14"
            fill="#FEFEFE"
            mask="url(#mask-4)"
            d="M196 149h31v-23h-31z"
          />
          <path
            id="Stroke-16"
            stroke="#3084C6"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            mask="url(#mask-4)"
            d="M196 149h31v-23h-31z"
          />
          <path
            d="M202 126v-8.2494c0-4.832 4.0288-8.7506 8.9985-8.7506 4.9712 0 9.0015 3.9186 9.0015 8.7506V126"
            id="Stroke-17"
            stroke="#3084C6"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            mask="url(#mask-4)"
          />
          <path
            id="Stroke-18"
            stroke="#3084C6"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            mask="url(#mask-4)"
            d="M205 137.1516L209.5514 142 218 133"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AboutPersonaldataSvg
