/**
 * @flow
 * @relayHash 4c31347bfefe8eac123a24bc69f06787
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventTwoStepsListItem_viewer$ref = any;
export type EventTwoStepsListItemQueryRendererQueryVariables = {|
  eventId: string
|};
export type EventTwoStepsListItemQueryRendererQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: EventTwoStepsListItem_viewer$ref
  |}
|};
export type EventTwoStepsListItemQueryRendererQuery = {|
  variables: EventTwoStepsListItemQueryRendererQueryVariables,
  response: EventTwoStepsListItemQueryRendererQueryResponse,
|};
*/


/*
query EventTwoStepsListItemQueryRendererQuery(
  $eventId: ID!
) {
  viewer {
    ...EventTwoStepsListItem_viewer
    id
  }
}

fragment EventTwoStepsListItem_viewer on Viewer {
  account {
    eventPurchases(byStartnumber: true, eventId: $eventId) {
      singleMedia {
        mediaInfo {
          count
        }
        mediaList(first: 1) {
          edges {
            node {
              id
              variants {
                name
                url
                id
              }
            }
          }
        }
      }
      participants {
        startnumber
        media {
          mediaList(first: 50) {
            edges {
              node {
                id
                variants {
                  name
                  url
                  id
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "MediaEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Media",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "variants",
            "storageKey": null,
            "args": null,
            "concreteType": "MediaFile",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EventTwoStepsListItemQueryRendererQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventTwoStepsListItem_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventTwoStepsListItemQueryRendererQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventPurchases",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "byStartnumber",
                    "value": true
                  },
                  {
                    "kind": "Variable",
                    "name": "eventId",
                    "variableName": "eventId"
                  }
                ],
                "concreteType": "EventPurchases",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "singleMedia",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExtendedMediaConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "mediaInfo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "MediaInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "count",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "mediaList",
                        "storageKey": "mediaList(first:1)",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "MediaConnection",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participants",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BoughtParticipant",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startnumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "media",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExtendedMediaConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mediaList",
                            "storageKey": "mediaList(first:50)",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 50
                              }
                            ],
                            "concreteType": "MediaConnection",
                            "plural": false,
                            "selections": (v2/*: any*/)
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v1/*: any*/)
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "EventTwoStepsListItemQueryRendererQuery",
    "id": null,
    "text": "query EventTwoStepsListItemQueryRendererQuery(\n  $eventId: ID!\n) {\n  viewer {\n    ...EventTwoStepsListItem_viewer\n    id\n  }\n}\n\nfragment EventTwoStepsListItem_viewer on Viewer {\n  account {\n    eventPurchases(byStartnumber: true, eventId: $eventId) {\n      singleMedia {\n        mediaInfo {\n          count\n        }\n        mediaList(first: 1) {\n          edges {\n            node {\n              id\n              variants {\n                name\n                url\n                id\n              }\n            }\n          }\n        }\n      }\n      participants {\n        startnumber\n        media {\n          mediaList(first: 50) {\n            edges {\n              node {\n                id\n                variants {\n                  name\n                  url\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5edd47e0034c9a33872cd39a10a0a4b';

module.exports = node;
