import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { withRouter } from 'react-router-dom';

import DialogContent from '@material-ui/core/DialogContent';

import CustomDialog from './CustomDialog';
import FormattedPrice from '../../misc/FormattedPrice';

import { trackEvent } from '../../../utils/ga-tracking';

class ItemAddedDialog extends Component {
  constructor(props) {
    super(props);
  }

  handleCheckout = () => {
    const { history, onRequestClose } = this.props;
    history.push('/cart');
    onRequestClose();
  };

  render() {
    const { open, lineItems, totalPrice, onRequestClose } = this.props;

    return (
      <CustomDialog open={open} onRequestClose={onRequestClose}>
        {lineItems && (
          <DialogContent>
            <div className="item-added-notification-container">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="itemAddedDialog.subtitle"
                  defaultMessage="Added to your cart"
                />
              </h1>
              {lineItems.map(item => (
                <div key={item.id} className="item-added-notification-item">
                  <span>{item.product.label}</span>
                  <span>
                    <FormattedPrice price={item.product.price} />
                  </span>
                </div>
              ))}
              <div className="item-added-notification-item item-added-notification-total">
                <FormattedMessageWrappedInSpan
                  id="itemAddedDialog.totalPrice"
                  defaultMessage="Total"
                  description="The text displayed in the last row of the table showing the total price"
                />
                <span>
                  <FormattedPrice price={totalPrice} />
                </span>
              </div>
              <button
                className="primary fullwidth"
                onClick={() => {
                  trackEvent('Navigation', 'Checkout', 'Cart items preview dialog');
                  this.handleCheckout();
                }}
              >
                <FormattedMessageWrappedInSpan
                  id="itemAddedDialog.checkoutButton"
                  defaultMessage="Checkout"
                />
              </button>
              <div
                className="item-added-notification-continue-shopping"
                onClick={onRequestClose}
              >
                <FormattedMessageWrappedInSpan
                  id="itemAddedDialog.continueButton"
                  defaultMessage="Continue shopping"
                />
              </div>
            </div>
          </DialogContent>
        )}
      </CustomDialog>
    );
  }
}

ItemAddedDialog.propTypes = {
  open: PropTypes.bool,
  lineItems: PropTypes.array,
  history: PropTypes.object,
  totalPrice: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export { ItemAddedDialog };

export default withRouter(ItemAddedDialog);
