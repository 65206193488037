import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import CartPage from './CartPage';
import CartEmptyPage from './CartEmptyPage';
import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartPageQueryRenderer(props) {
  return (
    <QueryHandler
      query={graphql`
        query CartPageQueryRendererQuery {
          viewer {
            cart {
              __typename
            }
            ...CartPage_viewer
          }
        }
      `}
      render={relayProps => {
        if (relayProps.viewer.cart) {
          return <CartPage viewer={relayProps.viewer} history={props.history} />;
        }
        return <CartEmptyPage history={props.history}/>;
      }}
    />
  );
}

CartPageQueryRenderer.propTypes = {
  // react-router props
  history: PropTypes.object,
};

export default CartPageQueryRenderer;
