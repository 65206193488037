import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation CreateOrderMutation($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        id
        cart {
          availablePaymentMethods {
            id
            name
          }
          customer {
            email
          }
          paymentRequired
        }
        orderid
        state
      }
    }
  }
`;

function commit(environment, email, language, cartId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        language,
      },
    },
    updater: store => {
      const payload = store.getRootField('createOrder');
      const order = payload.getLinkedRecord('order');
      const cart = store.get(cartId);
      cart.setLinkedRecord(order, 'order');
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.createOrder.order);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
