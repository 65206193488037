import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function ExpandButton({ expanded }) {
  return <IconButton>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>;
}

ExpandButton.propTypes = {
  expanded: PropTypes.bool,
};

export default ExpandButton;
