import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation PayWithPaypalMutation($input: PayWithPaypalInput!) {
    payWithPaypal(input: $input) {
      order {
        id
        orderid
        state
      }
    }
  }
`;

function commit(environment, orderId, nonce, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        orderId,
        nonce,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.payWithPaypal.order);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
