/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventOnlineView_onlineEvent$ref = any;
type MediaCollectionList_mediaCollectionMedia$ref = any;
type MediaCollectionList_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventCategorySearchPage_viewer$ref: FragmentReference;
declare export opaque type EventCategorySearchPage_viewer$fragmentType: EventCategorySearchPage_viewer$ref;
export type EventCategorySearchPage_viewer = {|
  +event: ?{|
    +id: string,
    +sgId: ?number,
    +gps: ?boolean,
    +hostResultUrl: ?string,
    +products: ?$ReadOnlyArray<?{|
      +type: ?string
    |}>,
    +pendingMediaProcessing: ?boolean,
    +media?: ?{|
      +mediaInfo: ?{|
        +shotDateMax: ?any,
        +shotDateMin: ?any,
      |},
      +$fragmentRefs: MediaCollectionList_mediaCollectionMedia$ref,
    |},
    +mediaCollection?: ?{|
      +id: string,
      +media: ?{|
        +mediaInfo: ?{|
          +shotDateMax: ?any,
          +shotDateMin: ?any,
        |},
        +$fragmentRefs: MediaCollectionList_mediaCollectionMedia$ref,
      |},
    |},
    +participant?: ?{|
      +media: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |}
      |},
      +startnumber: ?string,
    |},
    +searchAllPhotos: ?boolean,
    +secondFactorType: ?{|
      +type: ?string
    |},
    +mainSearch: ?string,
    +searchDual: ?boolean,
    +smartSearch: ?boolean,
    +searchCollections: ?boolean,
    +state: ?string,
    +marathonFoto: ?boolean,
    +$fragmentRefs: EventOnlineView_onlineEvent$ref & MediaCollectionList_offer$ref,
  |},
  +$refType: EventCategorySearchPage_viewer$ref,
|};
export type EventCategorySearchPage_viewer$data = EventCategorySearchPage_viewer;
export type EventCategorySearchPage_viewer$key = {
  +$data?: EventCategorySearchPage_viewer$data,
  +$fragmentRefs: EventCategorySearchPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "media",
  "storageKey": null,
  "args": null,
  "concreteType": "ExtendedMediaConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mediaInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shotDateMax",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shotDateMin",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaCollectionList_mediaCollectionMedia",
      "args": [
        {
          "kind": "Variable",
          "name": "fetchAllPhotos",
          "variableName": "fetchAllPhotos"
        },
        {
          "kind": "Variable",
          "name": "fetchCategory",
          "variableName": "fetchCategory"
        }
      ]
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "EventCategorySearchPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "language",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "fetchAllPhotos",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "fetchCategory",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "categoryId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "startnumber",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "secondFactor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "noStartnumber",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "eventId"
        },
        {
          "kind": "Variable",
          "name": "language",
          "variableName": "language"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sgId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "gps",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hostResultUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "products",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": true,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pendingMediaProcessing",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchAllPhotos",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "secondFactorType",
          "storageKey": null,
          "args": null,
          "concreteType": "SecondFactorType",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mainSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchDual",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "smartSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchCollections",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "marathonFoto",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "fetchAllPhotos",
          "selections": [
            (v2/*: any*/)
          ]
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "fetchCategory",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "mediaCollection",
              "storageKey": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "categoryId"
                }
              ],
              "concreteType": "MediaCollection",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "Condition",
          "passingValue": false,
          "condition": "noStartnumber",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "participant",
              "storageKey": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "secondFactor",
                  "variableName": "secondFactor"
                },
                {
                  "kind": "Variable",
                  "name": "startnumber",
                  "variableName": "startnumber"
                }
              ],
              "concreteType": "Participant",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "media",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExtendedMediaConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "mediaInfo",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MediaInfo",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "count",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startnumber",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "EventOnlineView_onlineEvent",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MediaCollectionList_offer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '656915193db463a07e4cd8d5166329b8';

module.exports = node;
