import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CustomDialog from './CustomDialog';
import { FormattedMessageWrappedInSpan } from '../../misc';

const PENDING = 'Pending';
const CANCEL = 'Cancel';
const FAILURE = 'Failure';
const ERROR = 'Error';
const CHECKOUT_ERROR = 'Checkout';

class PaymentNotificationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxIsSelected: false,
    };

    this.handleCheckboxChanged = this.handleCheckboxChanged.bind(this);
  }

  handleCheckboxChanged() {
    this.setState(prevState => ({ checkboxIsSelected: !prevState.checkboxIsSelected }));
  }

  render() {
    const { open, onRequestClose, path, intl } = this.props;
    const { checkboxIsSelected } = this.state;
    const acceptButtonDisabled = ((path === PENDING || path === CHECKOUT_ERROR) ? false : !checkboxIsSelected);

    return (
      <CustomDialog
        open={open}
        onRequestClose={onRequestClose}
        modal={!checkboxIsSelected}
      >
        <div className="notification-container">
          <div className="notification-body">
            <h1>
              {path === CANCEL && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.canceltitle"
                  defaultMessage="Payment canceled"
                />
              )}
              {path === FAILURE && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.failuretitle"
                  defaultMessage="Declined payment"
                />
              )}
              {path === ERROR && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.errortitle"
                  defaultMessage="Payment error"
                />
              )}
              {path === CHECKOUT_ERROR && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.checkouterrortitle"
                  defaultMessage="Checkout error"
                />
              )}
            </h1>
            <div className="mb-30">
              {path === PENDING && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.pendingMessage"
                  defaultMessage="Thank you for your order. Your data will be checked. As soon as the payment has been registered, your photos will be available for download and we will inform you by email.{br}Have fun with your photos!"
                  values={{ br: <br /> }}
                />
              )}
              {path === CANCEL && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.cancelMessage"
                  defaultMessage="You have canceled the payment process. Please choose another payment method or try again."
                />
              )}
              {path === FAILURE && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.failureMessage"
                  defaultMessage="Unfortunately, your payment was declined.{br}Please choose a different credit card or payment method."
                  values={{ br: <br /> }}
                />
              )}
              {path === ERROR && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.errorMessage"
                  defaultMessage="An error occured while trying to submit your payment. No payment has been made. Please choose another payment method."
                />
              )}
              {path === CHECKOUT_ERROR && (
                <FormattedMessageWrappedInSpan
                  id="paymentNotificationDialog.checkouterrorMessage"
                  defaultMessage="An error occurred while trying to apply your payment. Please try again. If the same error still occurs, contact our support."
                />
              )}
            </div>
            {(path === ERROR || path === FAILURE || path === CANCEL) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxIsSelected}
                    onChange={this.handleCheckboxChanged}
                  />
                }
                label={intl.formatMessage(translations.checkboxLabel)}
              />
            )}
          </div>
          <div className="notification-action">
            <button
              className={`accept${acceptButtonDisabled ? ' disabled' : ''}`}
              onClick={onRequestClose}
              disabled={acceptButtonDisabled}
            >
              <FormattedMessageWrappedInSpan
                id="paymentNotificationDialog.acceptButton"
                defaultMessage="OK"
              />
            </button>
          </div>
        </div>
      </CustomDialog>
    );
  }
}

const translations = defineMessages({
  checkboxLabel: {
    id: 'paymentNotificationDialog.checkboxLabel',
    defaultMessage: 'I have read the informations',
  },
});

PaymentNotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  intl: PropTypes.object,
  onRequestClose: PropTypes.func,
  path: PropTypes.oneOf([CANCEL, FAILURE, ERROR, PENDING, CHECKOUT_ERROR]),
};

export default injectIntl(PaymentNotificationDialog);
export { PaymentNotificationDialog };
