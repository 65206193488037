/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventList_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HomePage_viewer$ref: FragmentReference;
declare export opaque type HomePage_viewer$fragmentType: HomePage_viewer$ref;
export type HomePage_viewer = {|
  +config: ?{|
    +frontendCarousel: ?$ReadOnlyArray<?string>
  |},
  +landingpage?: ?{|
    +css: ?string
  |},
  +$fragmentRefs: EventList_viewer$ref,
  +$refType: HomePage_viewer$ref,
|};
export type HomePage_viewer$data = HomePage_viewer;
export type HomePage_viewer$key = {
  +$data?: HomePage_viewer$data,
  +$fragmentRefs: HomePage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "HomePage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "noLandingpage",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "config",
      "storageKey": null,
      "args": null,
      "concreteType": "Config",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "frontendCarousel",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": false,
      "condition": "noLandingpage",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "landingpage",
          "storageKey": null,
          "args": null,
          "concreteType": "Landingpage",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "css",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EventList_viewer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ffe1a8b4f2662a4d5294ad558069dfc6';

module.exports = node;
