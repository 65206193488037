import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessageWrappedInSpan } from '../../misc';

import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';

const RecheckRequestSuccessfulInfo = ({
  eventHasSearchByFace,
  numberOfRechecksSent,
  isDialogVersion,
}) => {
  return (
    <>
      <h1>
        <FormattedMessageWrappedInSpan
          id="recheckRequestSuccessfulInfo.title"
          defaultMessage="Thank You!"
        />
      </h1>
      {eventHasSearchByFace && (
        <FormattedMessageWrappedInSpan
          id="recheckRequestSuccessfulInfo.searchByFace.description.line1"
          defaultMessage="You've tagged {numberOfRechecksSent, plural, =1 {the photo} other {# photos}}."
          values={{ numberOfRechecksSent: numberOfRechecksSent }}
        />
      )}
      {!eventHasSearchByFace && (
        <FormattedMessageWrappedInSpan
          id="recheckRequestSuccessfulInfo.bibSearch.description.line1"
          defaultMessage="You've tagged {numberOfRechecksSent, plural, =1 {the photo} other {# photos}} with your bib number."
          values={{ numberOfRechecksSent: numberOfRechecksSent }}
        />
      )}
      <div>
        <FormattedMessageWrappedInSpan
          id="recheckRequestSuccessfulInfo.bibSearch.description.line3"
          defaultMessage="You can also add more images to review."
          className="mt-10"
        />
      </div>
      <div className={classNames('notification-info-box', { 'mt-20': isDialogVersion })}>
        {isDialogVersion && <MailOutlineRoundedIcon />}
        <FormattedMessageWrappedInSpan
          id="recheckRequestSuccessfulInfo.bibSearch.description.line2"
          defaultMessage="After we check your request, we will send you an email notification. It can take up to a week."
        />
      </div>
    </>
  );
};

RecheckRequestSuccessfulInfo.defaultProps = {
  numberOfRechecksSent: 1,
  isDialogVersion: true,
};

RecheckRequestSuccessfulInfo.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  numberOfRechecksSent: PropTypes.number,
  isDialogVersion: PropTypes.bool,
};

export default RecheckRequestSuccessfulInfo;
