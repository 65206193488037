import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';

import ExpandButton from '../misc/ExpandButton';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function FaqCard({ children, expanded, onExpandClick, title, ...other }) {
  return (
    <Card classes={{ root: 'faq-card' }} {...other}>
      <CardHeader
        action={<ExpandButton expanded={expanded} />}
        classes={{ root: 'faq-card-header' }}
        onClick={onExpandClick}
        title={title}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent classes={{ root: 'faq-card-text' }}>{children}</CardContent>
      </Collapse>
    </Card>
  );
}

FaqCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  expanded: PropTypes.bool,
  onExpandClick: PropTypes.func,
  title: PropTypes.node,
};

export default FaqCard;
