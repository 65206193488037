import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const LoadingPlaceholder = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="center-text">
        <CircularProgress />
      </div>
    );
  }
  return children;
};

LoadingPlaceholder.defaultProps = {
  isLoading: false,
};

LoadingPlaceholder.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default LoadingPlaceholder;
