import React, { useState, useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { trackEvent } from '../../../utils/ga-tracking';

import EventInfoCard from './EventInfoCard';
import EventReminder from './EventReminder';
import EventReminderAccepted from './EventReminderAccepted';

// mutations
import AddReminderFoEventMutation from '../../../mutations/AddReminderForEventMutation';
import SubscribeToNewsletterMutation from '../../../mutations/SubscribeToNewsletterMutation';
import { HelpdeskWidgetContext } from '../HelpdeskWidget';
import { getAppLanguage } from '../../../intl/languages';

const EventPendingView = (props) => {
  const history = useHistory();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const reminderToken = urlSearchParams.get("reminder_accepted");
  const reminderTokenIsInPath = Boolean(reminderToken);

  const [reminderResultSuccessful, setReminderResultSuccessful] = useState(false);
  const [showReminderResult, setShowReminderResult] = useState(false);
  const [newsletterResultStatus, setNewsletterResultStatus] = useState(null);
  const [showNewsletterResult, setShowNewsletterResult] = useState(false);
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const [showDialog, setShowDialog] = useState(reminderTokenIsInPath);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isUserEmailUnknown, setIsUserEmailUnknown] = useState(false);

  const handleRequestReminder = (email, startnumber, raceletter) => {
    hlpDskDispatch({
      type: 'addToHistory',
      payload: `add to reminder SN: ${startnumber}`,
    });
    const { pendingEvent, relay } = props;
    AddReminderFoEventMutation.commit(
      relay.environment,
      email,
      pendingEvent.id,
      getAppLanguage(),
      startnumber,
      (err, status) => {
        if (err) {
          return;
        }
        if (status === 'ok') {
          setReminderResultSuccessful(true);
          setShowReminderResult(true);
          trackEvent('Marketing', 'Event Reminder', 'sign up for reminder');
        } 
      }
    );
    if (raceletter) {
      handleSubscribeToRaceletter(email);
    }
  };

  const handleSubscribeToRaceletter = (email) => {
    const { pendingEvent, relay } = props;
    SubscribeToNewsletterMutation.commit(
      relay.environment,
      email,
      getAppLanguage(),
      pendingEvent.sports[0]?.name,
      (err, status) => {
        if (err) {
          return;
        }
        setNewsletterResultStatus(status);
        setShowNewsletterResult(true);
        trackEvent('Marketing', 'Event Reminder', 'sign up for newsletter');
      }
    );
  };

  const handleTokenReceived = () => {
    setShowDialog(false);
    history.replace(location.pathname);
  };

  const { pendingEvent, ...rest } = props;

  const allowToSubscribeAgain = () => {
    setShowReminderResult(false);
  };

  return (
    <div className="row event-pending-container">
      <EventInfoCard eventInfo={pendingEvent} displayBestof={true} {...rest} />
      {(!reminderTokenIsInPath || !isTokenValid) && (
        <EventReminder
          showReminderResult={showReminderResult}
          reminderResultSuccessful={reminderResultSuccessful}
          showNewsletterResult={showNewsletterResult}
          newsletterResultStatus={newsletterResultStatus}
          onRequestReminder={handleRequestReminder}
          showDialog={showDialog}
          onScrollToBestOf={props.onScrollToBestOf}
          allowToSubscribeAgain={allowToSubscribeAgain}
          reminderTokenIsInPath={reminderTokenIsInPath}
          reminderToken={reminderToken}
          isTokenValid={isTokenValid}
          isUserEmailUnknown={isUserEmailUnknown}
        />
      )}
      {reminderTokenIsInPath && isTokenValid && (
        <EventReminderAccepted
          onTokenReceived={handleTokenReceived}
          rerequestEmailCallback={() => setIsUserEmailUnknown(true)}
          invalidTokenCallback={() => {
            setIsTokenValid(false);
            allowToSubscribeAgain();
            setShowDialog(true);
          }}
        />
      )}
    </div>
  );
};

EventPendingView.propTypes = {
  bestOfAvailable: PropTypes.bool,
  pendingEvent: PropTypes.object,
  intl: PropTypes.object,
  relay: PropTypes.object,
  onExpandBestof: PropTypes.func,
  onScrollToBestOf: PropTypes.func,
};

export { EventPendingView };

export default createFragmentContainer(injectIntl(EventPendingView), {
  pendingEvent: graphql`
    fragment EventPendingView_pendingEvent on Event {
      id
      sgId
      name
      sports {
        name
      }
      ...EventInfoCard_eventInfo
    }
  `,
});
