import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import EventPage from './EventPage';
import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const EventPageQueryRenderer = (props) => {

  const { match } = props;

  return (
    <QueryHandler
      query={graphql`
        query EventPageQueryRendererQuery(
          $eventId: ID!
          $language: String
          $individualPart: String
        ) {
          viewer {
            ...EventPage_viewer
          }
        }
      `}
      variables={{
        eventId: props.match.params.id,
        individualPart: '',
        language: match.params.language,
      }}
      render={props => {
        return <EventPage match={match} viewer={props.viewer} />;
      }}
    />
  );

}

EventPageQueryRenderer.propTypes = {
  match: PropTypes.object,
};

export default EventPageQueryRenderer;
