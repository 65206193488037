import React from 'react';
import PropTypes from 'prop-types';

const ImageInfo = props => {
  return <span className="img-info">{props.label}</span>;
};

ImageInfo.propTypes = {
  label: PropTypes.string,
};

export default ImageInfo;
