import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import GDPRHint from '../GDPRHint';
import LoginForm from '../LoginForm';
import { PayPalExpressButton } from '../../misc/Buttons';
import { UserConsumer } from '../../context/UserContext';

import { trackEvent } from '../../../utils/ga-tracking';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';


/////////////////////////////////////
// TODO missing test
/////////////////////////////////////

const CheckoutOrderingRegisterStep = (props) => {
  const {
    hasPayPal,
    hasPayPalCheckoutBtn,
    isLoadingPayPalCheckout,
    onContinueAsGuest,
    onContinueWithPayPalExpress,
    onRequestLogin,
    onOpenNotificationDialog,
    onPreviousStep
  } = props;

  return (
    <UserConsumer>
      {({ onCookiesChange }) => (
        <Fragment>
          <div className="row ordering-steps-checkout-type">
            <div className="ordering-steps-guest-checkout col-xs-16 col-sm-5 col-sm-offset-3">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingRegisterStep.guestCheckout.title"
                  defaultMessage="Guest Checkout"
                />
              </h1>
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingRegisterStep.guestCheckout.subtitle"
                defaultMessage="Proceed to checkout and we create an account for you at the end"
              />
              <div>
                <button
                  className="btn-default primary-new active"
                  onClick={() => {
                    onContinueAsGuest();
                    trackEvent('Checkout', 'Register', 'Continue as guest');
                  }}
                >
                  <FormattedMessageWrappedInSpan
                    id="checkoutOrderingRegisterStep.guestCheckout.continueButton"
                    defaultMessage="Continue as guest"
                  />
                </button>
              </div>
              {false && hasPayPal && (
                // PaypalExpress deactivated for now (2020-06-15)
                <div className="paypal-express-btn-container">
                  {!hasPayPalCheckoutBtn && (
                    <PayPalExpressButton
                      hasSpinner={isLoadingPayPalCheckout}
                      leftLabel={
                        <FormattedMessageWrappedInSpan
                          id="checkoutOrderingRegisterStep.guestCheckout.paypalExpress.continueButton"
                          defaultMessage="PayPal Express"
                        />
                      }
                      onClick={onContinueWithPayPalExpress}
                      spinnerColor="#4C555C"
                    />
                  )}
                  <div>
                    {hasPayPalCheckoutBtn && (
                      <span className="explanation">
                        <FormattedMessageWrappedInSpan
                          id="checkoutOrderingRegisterStep.guestCheckout.paypalExpress.checkoutTitle"
                          defaultMessage="Please click here"
                        />
                      </span>
                    )}
                    <div
                      className={isLoadingPayPalCheckout ? 'paypal-checkout-hidden' : ''}
                      id="paypal-express-checkout-btn"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="ordering-steps-login col-xs-16 col-sm-5">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingRegisterStep.returningCustomer.title"
                  defaultMessage="Returning Customer"
                />
              </h1>
              <LoginForm
                onCookiesChange={onCookiesChange}
                onRequestLogin={onRequestLogin}
                onOpenNotificationDialog={onOpenNotificationDialog}
              />
            </div>
            <div className="navigation-back mt-15" onClick={onPreviousStep}>
            <KeyboardBackspaceRoundedIcon />
            <FormattedMessageWrappedInSpan
              id="checkoutOrderingPaymentStep.backButtonItems"
              defaultMessage="Back to cart"
            />
          </div>
          </div>
          <div className="row">
            <div className="col-xs-16 col-sm-10 col-sm-offset-3">
              <GDPRHint className="ordering-steps-register-gdpr center-text" />
            </div>

          </div> 
         
        </Fragment>
      )}
    </UserConsumer>
  );
};

CheckoutOrderingRegisterStep.propTypes = {
  hasPayPal: PropTypes.bool,
  hasPayPalCheckoutBtn: PropTypes.bool,
  isLoadingPayPalCheckout: PropTypes.bool,
  onContinueAsGuest: PropTypes.func,
  onContinueWithPayPalExpress: PropTypes.func,
  onRequestLogin: PropTypes.func,
  onOpenNotificationDialog: PropTypes.func,
  onPreviousStep: PropTypes.func
};

export default CheckoutOrderingRegisterStep;
