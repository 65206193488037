/**
 * @flow
 * @relayHash 0fccf26704dbf76fc9a6e32b64760e2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RecheckMediaList_recheckMedia$ref = any;
export type RecheckMediaListQueryVariables = {|
  eventId: string,
  count: number,
  cursor?: ?string,
  isRecheckPage: boolean,
|};
export type RecheckMediaListQueryResponse = {|
  +viewer: ?{|
    +account: ?{|
      +rechecks: ?$ReadOnlyArray<?{|
        +media: ?{|
          +$fragmentRefs: RecheckMediaList_recheckMedia$ref
        |}
      |}>
    |}
  |}
|};
export type RecheckMediaListQuery = {|
  variables: RecheckMediaListQueryVariables,
  response: RecheckMediaListQueryResponse,
|};
*/


/*
query RecheckMediaListQuery(
  $eventId: ID!
  $count: Int!
  $cursor: String
  $isRecheckPage: Boolean!
) {
  viewer {
    account {
      rechecks(eventId: $eventId) {
        media {
          ...RecheckMediaList_recheckMedia
        }
      }
      id
    }
    id
  }
}

fragment MediaListItem_item on Media {
  id
  sgId
  name
  shotDate
  recheckStatus
  variants {
    name
    url
    id
  }
}

fragment MediaList_media on MediaConnection {
  edges {
    node {
      id
      sgId
      shotDate
      variants {
        name
        url
        id
      }
      recheckStatus
      recheckRequestedAt @include(if: $isRecheckPage)
      ...MediaListItem_item
    }
  }
}

fragment RecheckMediaList_recheckMedia on ExtendedMediaConnection {
  mediaList(first: $count, after: $cursor) {
    edges {
      node {
        recheckStatus
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    ...MediaList_media
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isRecheckPage",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RecheckMediaListQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rechecks",
                "storageKey": null,
                "args": (v1/*: any*/),
                "concreteType": "Recheck",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "media",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExtendedMediaConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "RecheckMediaList_recheckMedia",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RecheckMediaListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "Account",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rechecks",
                "storageKey": null,
                "args": (v1/*: any*/),
                "concreteType": "Recheck",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "media",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExtendedMediaConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "mediaList",
                        "storageKey": null,
                        "args": (v2/*: any*/),
                        "concreteType": "MediaConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MediaEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Media",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "recheckStatus",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "sgId",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "shotDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "variants",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "MediaFile",
                                    "plural": true,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "url",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ]
                                  },
                                  (v4/*: any*/),
                                  {
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "condition": "isRecheckPage",
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "recheckRequestedAt",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "cursor",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "hasNextPage",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endCursor",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedHandle",
                        "alias": null,
                        "name": "mediaList",
                        "args": (v2/*: any*/),
                        "handle": "connection",
                        "key": "RecheckMediaList_mediaList",
                        "filters": null
                      }
                    ]
                  }
                ]
              },
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RecheckMediaListQuery",
    "id": null,
    "text": "query RecheckMediaListQuery(\n  $eventId: ID!\n  $count: Int!\n  $cursor: String\n  $isRecheckPage: Boolean!\n) {\n  viewer {\n    account {\n      rechecks(eventId: $eventId) {\n        media {\n          ...RecheckMediaList_recheckMedia\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment MediaListItem_item on Media {\n  id\n  sgId\n  name\n  shotDate\n  recheckStatus\n  variants {\n    name\n    url\n    id\n  }\n}\n\nfragment MediaList_media on MediaConnection {\n  edges {\n    node {\n      id\n      sgId\n      shotDate\n      variants {\n        name\n        url\n        id\n      }\n      recheckStatus\n      recheckRequestedAt @include(if: $isRecheckPage)\n      ...MediaListItem_item\n    }\n  }\n}\n\nfragment RecheckMediaList_recheckMedia on ExtendedMediaConnection {\n  mediaList(first: $count, after: $cursor) {\n    edges {\n      node {\n        recheckStatus\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    ...MediaList_media\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '717e43736b9993666b6f12a2cc9cabe4';

module.exports = node;
