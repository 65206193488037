/**
 * @flow
 * @relayHash 3702f81d0c8c4b37d9ebc2e69ea0b828
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetOrderPaymentMethodInput = {|
  orderId: string,
  paymentMethodId: string,
|};
export type SetOrderPaymentMethodMutationVariables = {|
  input: SetOrderPaymentMethodInput
|};
export type SetOrderPaymentMethodMutationResponse = {|
  +setOrderPaymentMethod: ?{|
    +order: ?{|
      +id: string,
      +orderid: ?string,
      +state: ?string,
      +payment: ?{|
        +paymentMethod: ?{|
          +id: string,
          +name: ?string,
        |},
        +state: ?string,
      |},
    |}
  |}
|};
export type SetOrderPaymentMethodMutation = {|
  variables: SetOrderPaymentMethodMutationVariables,
  response: SetOrderPaymentMethodMutationResponse,
|};
*/


/*
mutation SetOrderPaymentMethodMutation(
  $input: SetOrderPaymentMethodInput!
) {
  setOrderPaymentMethod(input: $input) {
    order {
      id
      orderid
      state
      payment {
        paymentMethod {
          id
          name
        }
        state
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetOrderPaymentMethodInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "paymentMethod",
  "storageKey": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SetOrderPaymentMethodMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setOrderPaymentMethod",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrderPaymentMethodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payment",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SetOrderPaymentMethodMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setOrderPaymentMethod",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrderPaymentMethodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payment",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "SetOrderPaymentMethodMutation",
    "id": null,
    "text": "mutation SetOrderPaymentMethodMutation(\n  $input: SetOrderPaymentMethodInput!\n) {\n  setOrderPaymentMethod(input: $input) {\n    order {\n      id\n      orderid\n      state\n      payment {\n        paymentMethod {\n          id\n          name\n        }\n        state\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b8826fc23c223e360276d0a54849bb4';

module.exports = node;
