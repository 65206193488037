/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventOnlineView_onlineEvent$ref = any;
type EventPendingView_pendingEvent$ref = any;
type PastEventInformation_successorEvent$ref = any;
type Pricelist_offer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventPage_viewer$ref: FragmentReference;
declare export opaque type EventPage_viewer$fragmentType: EventPage_viewer$ref;
export type EventPage_viewer = {|
  +event: ?{|
    +id: string,
    +sgId: ?number,
    +beginsAt: ?any,
    +gps: ?boolean,
    +name: ?string,
    +legacy: ?boolean,
    +secondFactorType: ?{|
      +type: ?string
    |},
    +participantUser: ?boolean,
    +state: ?string,
    +searchDual: ?boolean,
    +searchCollections: ?boolean,
    +subEvents: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
    +successorEvent: ?{|
      +$fragmentRefs: PastEventInformation_successorEvent$ref
    |},
    +voucherCampaigns: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
    +products: ?$ReadOnlyArray<?{|
      +type: ?string
    |}>,
    +marathonFoto: ?boolean,
    +$fragmentRefs: EventPendingView_pendingEvent$ref & EventOnlineView_onlineEvent$ref & Pricelist_offer$ref,
  |},
  +$refType: EventPage_viewer$ref,
|};
export type EventPage_viewer$data = EventPage_viewer;
export type EventPage_viewer$key = {
  +$data?: EventPage_viewer$data,
  +$fragmentRefs: EventPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "EventPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "language",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "eventId"
        },
        {
          "kind": "Variable",
          "name": "language",
          "variableName": "language"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sgId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "beginsAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "gps",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "legacy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "secondFactorType",
          "storageKey": null,
          "args": null,
          "concreteType": "SecondFactorType",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "participantUser",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchDual",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "searchCollections",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "subEvents",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": true,
          "selections": (v2/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "successorEvent",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "PastEventInformation_successorEvent",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "voucherCampaigns",
          "storageKey": null,
          "args": null,
          "concreteType": "VoucherCampaign",
          "plural": true,
          "selections": (v2/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "products",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": true,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "marathonFoto",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventPendingView_pendingEvent",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventOnlineView_onlineEvent",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Pricelist_offer",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '33dd490c615f0cb3c0863f2ce5b9dd2b';

module.exports = node;
