import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../../misc';
import { FRESHDESK_PAYPAL_CANCELLED_MSG } from '../../../../utils/variables';

import { HelpdeskWidgetContext } from '../../HelpdeskWidget';

const NotificationDialogAction = ({ msgType, onClick, ...props }) => {
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);

  const handleOpenFreshdesk = () => {
    if (localStorage.getItem('userAcceptsStatsCookies') == 'true') {
      hlpDskDispatch({ type: 'addToHistory', payload: "Paypal cancelled" });
      hlpDskDispatch({ type: 'open', payload: true });
    } else {
      window.open("mailto:support@sportograf.com?subject=PayPal");
    }
    onClick();
  }

  const _renderFreshdeskActions = () => {
    return (
      <>
        <button className="accept" onClick={onClick}>
          <FormattedMessageWrappedInSpan id="notificationDialogAction.close" defaultMessage="Close" />
        </button>
        <button className="accept" onClick={handleOpenFreshdesk}>
          <FormattedMessageWrappedInSpan id="notificationDialogAction.freshdesk" defaultMessage="Contact Support" />
        </button>
      </>
    );
  }

  const _renderDefaultActions = () => {
    return (
      <button className="accept" onClick={onClick}>
        <FormattedMessageWrappedInSpan id="notificationDialogAction.default" defaultMessage="Ok" />
      </button>
    );
  }

  switch (msgType) {
    case FRESHDESK_PAYPAL_CANCELLED_MSG:
      return _renderFreshdeskActions(onClick);
  }

  return _renderDefaultActions(onClick);
}

NotificationDialogAction.propTypes = {
  msgType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationDialogAction;
