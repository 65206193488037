import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import classNames from 'classnames';

import FormattedPrice from '../../misc/FormattedPrice';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const PhotoBookOffer = props => {
  return (
    <div className="row book-offer-spacer">
      <div className="col-xs-16 col-sm-8">
        <div className="book-offer-warning-card">
          <div className="book-offer-card-height flex-center">
            <div className="center-text">
              <p className="bold">
                <FormattedMessageWrappedInSpan
                  id="photoBookOffer.warning.title"
                  defaultMessage="Please note:"
                />
              </p>
              <p>
                <FormattedMessageWrappedInSpan
                  id="photoBookOffer.warning.description"
                  defaultMessage="When leaving or reloading the page, your changes will be lost. After the myRacebook has been added to the shopping cart via 'Buy now', no more changes can be made!"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-16 col-sm-4">
        <div className="book-offer-card">
          <div className="book-offer-card-height flex-center">
            <div className="book-offer-selection">
              <FormattedMessageWrappedInSpan
                id="photoBookOffer.counterText.top"
                defaultMessage="You have selected"
              />
              <p className="counter">
                {props.count}/{props.countMax}
              </p>
              <FormattedMessageWrappedInSpan
                id="photoBookOffer.counterText.bottom"
                defaultMessage="personal photos"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-16 col-sm-4">
        <div className="book-offer-card">
          <div className="book-offer-card-height">
            <div className="book-offer-price">
              <FormattedPrice price={props.price} />
            </div>
            <button
              className={classNames('book-offer-buy-btn', {
                disabled: props.isBuyButtonDisabled,
              })}
              disabled={props.isBuyButtonDisabled}
              onClick={props.onBuy}
            >
              <FormattedMessageWrappedInSpan
                id="photoBookOffer.buyBookButton"
                defaultMessage="Buy now"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PhotoBookOffer.propTypes = {
  count: PropTypes.number,
  countMax: PropTypes.number,
  isBuyButtonDisabled: PropTypes.bool,
  price: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  onBuy: PropTypes.func,
};

export default PhotoBookOffer;
