import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation SetOrderShipmentAddressWithNewAddressMutation(
    $input: SetOrderShipmentAddressWithNewAddressInput!
  ) {
    setOrderShipmentAddressWithNewAddress(input: $input) {
      order {
        id
        address {
          firstName
          lastName
          phone
          city
          postalCode
          line1
          line2
          country {
            alpha2
          }
          state
        }
        availablePaymentMethods {
          id
          name
          defaultPaymentType
        }
        bill {
          ...CheckoutBillTable_bill
        }
        state
      }
    }
  }
`;

function commit(environment, orderId, details, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        orderId: orderId,
        firstName: details.firstName,
        lastName: details.lastName,
        line1: details.line1,
        line2: details.line2,
        postalCode: details.postalCode,
        city: details.city,
        countryCode: details.country.alpha2,
        state: details.state,
        phone: details.phone,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.setOrderShipmentAddressWithNewAddress.order);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
