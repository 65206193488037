import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation RequestEventAccessMutation($input: RequestEventAccessInput!) {
    requestEventAccess(input: $input) {
      email
      status
    }
  }
`;

function commit(environment, email, language, eventID, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        language,
        eventID
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.requestEventAccess.email);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
