/* global APPSIGNAL_API_KEY: true */
/* global GIT_REVISION: true */
/* global BACKEND: true */

import Appsignal from "@appsignal/javascript";
import * as plugin_path from "@appsignal/plugin-path-decorator";

const appsignal = new Appsignal({
  key: APPSIGNAL_API_KEY,
  ignoreErrors: [
    /trySetContainerSize/i, // Juicebox
    /sizing is null/i, // Juicebox
    /Cannot read property 'css' of undefined/i, // Juicebox
    /Cannot read property 'product' of undefined/i, // Juicebox
    /Network request failed/i, // We can't change network problems
  ],
});
window.appsignal = appsignal;
appsignal.use(plugin_path.plugin({}))

appsignal.addDecorator((span) => {
  return span.setTags({ frontendRevision: GIT_REVISION })
})

function _handleSendError(error, info) {
  if (error) {
    if (error.name == "RRNLRequestError") {
      let mutation = error.message.match(/Relay request for `(.*)` failed by the following reason/)[1];
      error["name"] = "RRNLRequestError "+mutation;
    }
    appsignal.sendError(error);
  } else {
    appsignal.sendError(new Error('Null error raised, no exception message available'));
  }
}

function _handleSendAction(action, params, tags) {
  const span = appsignal.createSpan((span) => {
    return span
      .setAction("NO ERROR "+action)
      .setParams(params)
      .setTags(tags);
  });

  appsignal.send(span);
}

export function sendAction(action, params, tags) {
  _handleSendAction(action, params, tags);
}

export function sendError(error, info) {
  _handleSendError(error, info);
}
