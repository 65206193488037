import { commitMutation, graphql } from 'react-relay';

import { sendError } from '../appsignal';

const mutation = graphql`
  mutation ApplyOrderCouponMutation($input: ApplyOrderCouponInput!) {
    applyOrderCoupon(input: $input) {
      cart {
        paymentRequired,
        order {
          bill {
            coupon {
              vat
              brutto
            }
            totalBruttoPriceUnpayed
          }
        }
      }
    }
  }
`;

function commit(environment, code, orderId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        code,
        orderId
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        sendError(errors[0]);
        callback && callback(errors[0], null);
      } else {
        callback && callback(null, response.applyOrderCoupon.cart);
      }
    },
    onError: err => {
      sendError(err);
      callback && callback(err, null);
    },
  });
}

export default { commit };
