import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { PAYMENT_FAILED, PAYMENT_IN_PROGRESS } from '../utils/variables';

import LoadingView from '../components/content/LoadingView';
import CheckoutOrderingSteps from '../components/content/checkout/CheckoutOrderingSteps';
import CheckoutCompletedView from '../components/content/checkout/CheckoutCompletedView';
import FeedbackDialog from '../components/content/dialogs/FeedbackDialog';
import PaymentNotificationDialog from '../components/content/dialogs/PaymentNotificationDialog';
import { NotificationDialogContext } from '../components/context/NotificationDialogContext';

import withRequestHandler from '../components/hoc/withRequestHandler';
import { PayWithAmazonMutation, ClearCartMutation } from '../mutations';

import { pathHasLoginToken } from '../utils/common';
import { trackEvent } from '../utils/ga-tracking';
import { HelpdeskWidgetContext } from '../components/content/HelpdeskWidget';

//////////////////////////////////////////////////
// TODO: tests for Amazon pay and related to useEffect
//////////////////////////////////////////////////

const CheckoutPage = (props) => {
  const { isLoading, viewer, apiRedirectToPayment } = props;

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);

  const [email, setEmail] = useState(viewer.cart?.customer?.email || null);
  const [completed, setCompleted] = useState(false);
  const [sgOrderId, setSgOrderId] = useState(null);
  const [paymentCallbackPath, setPaymentCallbackPath] = useState(null);
  const [showPaymentNotificationDialog, setShowPaymentNotificationDialog] =
    useState(false);
  const [showCouponRemovedDuringPaymentDialog, setShowCouponRemovedDuringPaymentDialog] =
    useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const [couponShouldBeRemoved, setCouponShouldBeRemoved] = useState(false);
  const [sgIdFromOneEvent, setSgIdFromOneEvent] = useState(null);

  const { onOpenNotificationDialog } = React.useContext(NotificationDialogContext);

  useEffect(() => {
    if (viewer.cart) {
      const firstSgId = viewer.cart.lineItems[0].product.event.sgId;
      const isConsistent = viewer.cart.lineItems.every(
        (item) => item.product.event.sgId === firstSgId
      );
      if (isConsistent) {
        setSgIdFromOneEvent(firstSgId);
      }
    }
  }, []);

  const handleCheckoutCompleted = (orderId, email) => {
    handleClearCart();
    setCompleted(true);
    setSgOrderId(orderId);
    setEmail(email);
    trackEvent('Checkout', 'Completed checkout', 'Checkout successfull', {
      nonInteraction: true,
    });
  };

  const handleCheckoutError = () => {
    setCouponShouldBeRemoved(true);
  };

  const handleAdyenResultCode = (orderId, resultCode) => {
    // <https://docs.adyen.com/online-payments/web-components/advanced-use-cases/redirect-result>
    switch (resultCode) {
      case 'Authorised':
        handleClearCart();
        setCompleted(true);
        setSgOrderId(orderId);
        return;
      case 'Error':
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Error');
        return;
      case 'Pending':
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Pending');
        return;
      case 'PresentToShopper':
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Pending');
        return;
      case 'Refused':
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Failure');
        return;
      case 'Received':
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Pending');
        return;
      default:
        setShowPaymentNotificationDialog(true);
        setPaymentCallbackPath('Error');
        return;
    }
  };

  const handleAmazonCompleteSession = () => {
    const order = props.viewer.cart.order;
    const sessionId = searchParams.get('amazonCheckoutSessionId');
    props.onRequestStart();
    PayWithAmazonMutation.commit(
      props.relay.environment,
      order.id,
      sessionId,
      (error, updatedOrder) => {
        if (!error) {
          props.onRequestStop();
          switch (updatedOrder.state) {
            case PAYMENT_FAILED:
              setShowPaymentNotificationDialog(true);
              setPaymentCallbackPath('Failure');
              trackEvent('Checkout', 'Failed payment', 'Amazon failed', {
                nonInteraction: true,
              });
              return;
            case PAYMENT_IN_PROGRESS:
              setShowPaymentNotificationDialog(true);
              setPaymentCallbackPath('Pending');
              return;
            default:
              handleClearCart();
              setCompleted(true);
              setSgOrderId(order.orderid);
          }
        } else {
          props.onRequestError(error);
        }
      }
    );
  };

  const handleOpenPaymentNotificationDialog = (callbackPath) => {
    setShowPaymentNotificationDialog(true);
    setPaymentCallbackPath(callbackPath);
  };

  const handleClosePaymentNotificationDialog = () => {
    if (paymentCallbackPath === 'Pending') {
      handleClearCart();
    } else {
      history.replace('/checkout');
    }
    setShowPaymentNotificationDialog(false);
    setPaymentCallbackPath(null);
  };

  const handleCloseFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };

  const handleClearCart = () => {
    const { viewer, relay } = props;
    ClearCartMutation.commit(relay.environment, viewer.cart.id);
  };

  const handleOpenFeedbackDialog = () => {
    setShowFeedbackDialog(true);
  };

  const handleShowCart = () => {
    history.push('/cart');
  };

  const { isLoggedIn } = props;

  useEffect(() => {
    sgOrderId &&
      hlpDskDispatch({ type: 'addToHistory', payload: `Order ID: ${sgOrderId}` });
  }, [sgOrderId]);

  React.useEffect(() => {
    const { viewer } = props;

    if (pathHasLoginToken(location.search)) {
      history.replace(location.pathname);
    }

    if (params.callbackUrl) {
      if (viewer.cart && viewer.cart.order && viewer.cart.order.orderid) {
        switch (params.callbackUrl) {
          case 'adyen-redirect':
            if (viewer.adyenPaymentsDetails != undefined) {
              handleAdyenResultCode(
                viewer.adyenPaymentsDetails.merchantReference,
                viewer.adyenPaymentsDetails.resultCode
              );
            } else {
              handleAdyenResultCode(null, 'Error');
            }
            break;
          case 'amazon-result':
            handleAmazonCompleteSession();
            break;
          case 'amazon-cancel':
            handleOpenPaymentNotificationDialog('Cancel');
            trackEvent('Checkout', 'Canceled payment', 'Amazon payment canceled');
            break;
          default:
            handleCheckoutCompleted(
              viewer.cart.order.orderid,
              viewer.cart.customer.email
            );
            return;
        }
      }
    }
  }, [location]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!completed) {
    if (!viewer.cart) {
      return <Redirect to="/" />;
    }
    if (params.callbackUrl) {
      if (!viewer.cart.order || (viewer.cart.order && !viewer.cart.order.orderid)) {
        return <Redirect to="/checkout" />;
      }
    }
  }

  return (
    <>
      {onOpenNotificationDialog && (
        <div>
          <div className="checkout-page-background" />
          <div className="container-960 container-page checkout-page">
            <div className="checkout-container">
              {!completed && (
                <CheckoutOrderingSteps
                  cart={viewer.cart}
                  viewer={viewer}
                  isLoggedIn={isLoggedIn}
                  onCheckoutCompleted={handleCheckoutCompleted}
                  onShowCart={handleShowCart}
                  onOpenNotificationDialog={onOpenNotificationDialog}
                  onAdyenResult={handleAdyenResultCode}
                  onCheckoutFail={handleCheckoutError}
                  couponShouldBeRemoved={couponShouldBeRemoved}
                  history={history}
                  apiRedirectToPayment={apiRedirectToPayment}
                />
              )}
              {completed && (
                <CheckoutCompletedView
                  email={email}
                  orderId={sgOrderId}
                  onOpenFeedbackClick={handleOpenFeedbackDialog}
                  isLoggedIn={isLoggedIn}
                  //null if user does not ordered from only one event
                  sgIdForOneEvent={sgIdFromOneEvent}
                />
              )}
            </div>
          </div>
          <PaymentNotificationDialog
            open={showPaymentNotificationDialog}
            onRequestClose={handleClosePaymentNotificationDialog}
            path={paymentCallbackPath}
          />
          <FeedbackDialog
            open={showFeedbackDialog}
            showRating={true}
            onRequestClose={handleCloseFeedbackDialog}
          />
        </div>
      )}
    </>
  );
};

CheckoutPage.propTypes = {
  isLoggedIn: PropTypes.bool,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  isLoading: PropTypes.bool,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  apiRedirectToPayment: PropTypes.bool,
};

export { CheckoutPage };

export default createFragmentContainer(withRequestHandler(CheckoutPage), {
  viewer: graphql`
    fragment CheckoutPage_viewer on Viewer {
      cart {
        lineItems {
          id
          product {
            id
            event {
              id
              sgId
              name
            }
          }
        }
        customer {
          email
        }
        id
        order {
          id
          orderid
        }
        ...CheckoutOrderingSteps_cart
      }
      adyenPaymentsDetails(redirectResult: $adyenRedirectResult)
        @skip(if: $noAdyenRedirectResult) {
        merchantReference
        pspReference
        resultCode
      }
      ...CheckoutOrderingSteps_viewer
    }
  `,
});
