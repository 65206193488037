/**
 * @flow
 * @relayHash d4e1794876e95a3c63bd969bc9a210fe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequestLoginInput = {|
  email: string,
  inCheckout?: ?boolean,
  language?: ?string,
  provider: string,
|};
export type RequestLoginMutationVariables = {|
  input: RequestLoginInput
|};
export type RequestLoginMutationResponse = {|
  +requestLogin: ?{|
    +email: ?string,
    +status: ?string,
  |}
|};
export type RequestLoginMutation = {|
  variables: RequestLoginMutationVariables,
  response: RequestLoginMutationResponse,
|};
*/


/*
mutation RequestLoginMutation(
  $input: RequestLoginInput!
) {
  requestLogin(input: $input) {
    email
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestLoginInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "requestLogin",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestLoginPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RequestLoginMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RequestLoginMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RequestLoginMutation",
    "id": null,
    "text": "mutation RequestLoginMutation(\n  $input: RequestLoginInput!\n) {\n  requestLogin(input: $input) {\n    email\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fa4314e9dcf9dc7d8c6ced4d239e2da';

module.exports = node;
