/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoCard_eventInfo$ref: FragmentReference;
declare export opaque type EventInfoCard_eventInfo$fragmentType: EventInfoCard_eventInfo$ref;
export type EventInfoCard_eventInfo = {|
  +id: string,
  +sgId: ?number,
  +beginsAt: ?any,
  +banner: ?string,
  +hostHomepageName: ?string,
  +hostHomepageUrl: ?string,
  +location: ?string,
  +name: ?string,
  +predecessorEvent: ?{|
    +name: ?string
  |},
  +$refType: EventInfoCard_eventInfo$ref,
|};
export type EventInfoCard_eventInfo$data = EventInfoCard_eventInfo;
export type EventInfoCard_eventInfo$key = {
  +$data?: EventInfoCard_eventInfo$data,
  +$fragmentRefs: EventInfoCard_eventInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventInfoCard_eventInfo",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beginsAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "banner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hostHomepageName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hostHomepageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "location",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "predecessorEvent",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca4d38dbb7c935bf3e70323012094baa';

module.exports = node;
