import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';

import CustomDialog from './CustomDialog';

////////////////////////////////////////////
// TODO - TEST CASE for line 108, condition (allCookiesSwitch === (statsCookies && socialCookies) should refresh page)
////////////////////////////////////////////

function CookieSettingsDialog(props) {
  const {
    statsCookies,
    socialCookies,
    sportografWebCookies,
    open,
    onCookiesChange,
    onRequestClose,
  } = props;

  const [allCookiesSwitch, setAllCookiesSwitch] = useState(false);
  const [statsCookiesSwitch, setStatsCookiesSwitch] = useState(false);
  const [socialCookiesSwitch, setSocialCookiesSwitch] = useState(false);
  const [sportografWebCookiesSwitch, setSportografWebCookiesSwitch] = useState(false);

  return (
    <CustomDialog open={open} onRequestClose={onRequestClose} fullWidth={true}>
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="cookieSettingsDialog.title"
                defaultMessage="Cookie settings"
              />
            </h1>
            <div className="align-text-left">
              <div className="cookies-dialog-description">
                <FormattedMessageWrappedInSpan
                  id="cookieSettingsDialog.info"
                  defaultMessage="In order to make our website attractive and to enable the use of certain functions, we use so-called cookies on different pages. These are small text files which are stored on your end device. Some of the cookies that we use are erased after the end of the browser session, i.e. after you close your browser (so-called session cookies). Other cookies remain on your end device and enable us and our partner companies to recognise your browser the next time you visit us (persistent cookies). You can adjust your browser settings so that you are informed of the installation of cookies and can decide whether to accept them on a case-by-case basis or block the acceptance of cookies either for particular cases or in general. You can block and also delete cookies through your browser. For information on the deletion and management of cookies, go to the help function within your browser. If you do not accept cookies, the functionality of our website may be restricted."
                />
              </div>
              <div className="mt-30 custom-dialog-toggle-group">
                <div>
                  <FormattedMessageWrappedInSpan
                    id="cookieSettingsDialog.allowAllCookies"
                    defaultMessage="Allow all cookies"
                  />
                  <Switch
                    checked={statsCookies && socialCookies && sportografWebCookies}
                    color="primary"
                    onChange={(event, checked) => {
                      setAllCookiesSwitch(checked);
                      onCookiesChange({
                        stats: checked,
                        social: checked,
                        sportografWeb: checked,
                      });
                    }}
                  />
                </div>
                <div className="custom-dialog-toggle-item">
                  <div>
                    <FormattedMessageWrappedInSpan
                      id="cookieSettingsDialog.allowStatistics.title"
                      defaultMessage="Statistics"
                    />
                    <Switch
                      checked={statsCookies}
                      color="primary"
                      onChange={(event, checked) => {
                        setStatsCookiesSwitch(checked);
                        onCookiesChange({
                          stats: checked,
                          social: socialCookies,
                          sportografWeb: sportografWebCookies,
                        });
                      }}
                    />
                  </div>
                  <div className="cookies-dialog-description">
                    <FormattedMessageWrappedInSpan
                      id="cookieSettingsDialog.allowStatistics.description"
                      defaultMessage="Statistics cookies collect information anonymously. This information helps us to understand how our visitors use our website."
                    />
                  </div>
                </div>
                <div className="custom-dialog-toggle-item">
                  <div>
                    <FormattedMessageWrappedInSpan
                      id="cookieSettingsDialog.allowExternalMedia.title"
                      defaultMessage="External Media"
                    />
                    <Switch
                      checked={socialCookies}
                      color="primary"
                      onChange={(event, checked) => {
                        setSocialCookiesSwitch(checked);
                        onCookiesChange({
                          stats: statsCookies,
                          social: checked,
                          sportografWeb: sportografWebCookies,
                        });
                      }}
                    />
                  </div>
                  <div className="cookies-dialog-description">
                    <FormattedMessageWrappedInSpan
                      id="cookieSettingsDialog.allowExternalMedia.description"
                      defaultMessage="Content from video platforms and social media platforms are blocked by default. If cookies are accepted by external media, access to this content no longer requires manual approval."
                    />
                  </div>
                </div>
              </div>

              <div className="custom-dialog-toggle-item">
                <div>
                  <FormattedMessageWrappedInSpan
                    id="cookieSettingsDialog.allowSportografWeb.title"
                    defaultMessage="Sportograf searching data"
                  />
                  <Switch
                    checked={sportografWebCookies}
                    color="primary"
                    onChange={(event, checked) => {
                      setSportografWebCookiesSwitch(checked);
                      onCookiesChange({
                        stats: statsCookies,
                        social: socialCookies,
                        sportografWeb: checked,
                      });
                    }}
                  />
                </div>
                <div className="cookies-dialog-description">
                  <FormattedMessageWrappedInSpan
                    id="cookieSettingsDialog.allowSportografWeb.description"
                    defaultMessage="To simplify searching for photos, we save IDs of face search results and other searching data in your browser. "
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button
              className="accept"
              onClick={() => {
                let refreshPage = false;
                if (
                  allCookiesSwitch ===
                  (statsCookies && socialCookies && sportografWebCookies)
                ) {
                  refreshPage = true;
                }
                if (statsCookies || socialCookies || sportografWebCookies) {
                  refreshPage = true;
                }
                onRequestClose(refreshPage);
              }}
            >
              <FormattedMessageWrappedInSpan
                id="cookieSettingsDialog.closeButton"
                defaultMessage="Okay"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    </CustomDialog>
  );
}

CookieSettingsDialog.propTypes = {
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
  sportografWebCookies: PropTypes.bool,
  onCookiesChange: PropTypes.func,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default CookieSettingsDialog;
export { CookieSettingsDialog };
