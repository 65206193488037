import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan } from '../../misc';
import CustomDialog from './CustomDialog';

const PhotoBookShippingInformationDialog = (props) => {
  const { open, onRequestClose } = props;

  return (
    <CustomDialog open={open} onRequestClose={onRequestClose}>
      <div className="notification-container">
        <div className="notification-body">
          <h1>
            <FormattedMessageWrappedInSpan
              id="cartPage.paymentDialogInformation.title"
              defaultMessage="Country-based shipping costs"
            />
          </h1>
          <FormattedMessageWrappedInSpan
            id="cartPage.paymentDialogInformation"
            defaultMessage="Shipping costs are determined based on the country to which you want us to send the delivery. Provide this information in the address step and we will display the actual shipping cost specific to your country in the payment step."
          />
        </div>
      </div>
      <div className="notification-action">
        <button className="accept" onClick={onRequestClose}>
          <FormattedMessageWrappedInSpan id="errorDialog.okButton" defaultMessage="OK" />
        </button>
      </div>
    </CustomDialog>
  );
};

PhotoBookShippingInformationDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default PhotoBookShippingInformationDialog;
