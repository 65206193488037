/* global BACKEND: true */

import { Environment, RecordSource, Store } from 'relay-runtime';
import 'regenerator-runtime/runtime';
import { RelayNetworkLayer, authMiddleware } from 'react-relay-network-modern';

import LoginRefreshMutation from './mutations/LoginRefreshMutation';

const source = new RecordSource();
const store = new Store(source);

import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from './utils/browser-storage';

const network = new RelayNetworkLayer([
  authMiddleware({
    token: () => getFromLocalStorage('accessToken'),
    prefix: 'Bearer ',
    header: 'Authorization',
    allowEmptyToken: true,
    tokenRefreshPromise: (req) => {
      return new Promise(
        function(resolve, reject) {
          removeFromLocalStorage('accessToken');
          LoginRefreshMutation.commit(environment, getFromLocalStorage('refreshToken'), (error, auth) => {
            if (!error) {
              setToLocalStorage('refreshToken', auth.token.refresh);
              setToLocalStorage('accessToken', auth.token.access);
              resolve(auth.token.access)
            } else {
              reject(error);
            }
          });
      });
    }
  }),
  next => req => {
    req.fetchOpts.url = BACKEND + '/api/graphql';
    req.fetchOpts.credentials = 'include';
    return next(req);
  },
]);

const environment = new Environment({
  network,
  store,
});

export default environment;
