import React, { Component } from 'react';

import { UserConsumer } from '../context/UserContext';

import { getDisplayName } from './common';

function withUserConsumer(WrappedComponent) {
  class WithUserConsumer extends Component {
    render() {
      return (
        <UserConsumer>
          {consumerValues => <WrappedComponent {...consumerValues} {...this.props} />}
        </UserConsumer>
      );
    }
  }

  WithUserConsumer.displayName = `WithUserConsumer(${getDisplayName(WrappedComponent)})`;

  return WithUserConsumer;
}

export default withUserConsumer;
