import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { ALTERNATIVE, PENDING } from '../../../utils/variables';
import { HelpdeskWidgetContext } from '../HelpdeskWidget';
import { getCustomerKey } from '../../../utils/browser-storage';

const NoPhotosForOrderActions = (props) => {
  const { eventInfo, startNumber, userInfo } = props;
  const eventSgId = eventInfo.sgId;
  const customerKey = getCustomerKey(eventSgId, startNumber);

  const isEventInReminderState = eventInfo.state === PENDING;
  const hasEventStillProcessingPhotos = eventInfo.pendingMediaProcessing;
  const hasUserNoPhotosForProducedEvent =
    !isEventInReminderState && !hasEventStillProcessingPhotos;
  const hasEventAltSerachOptionsForNoPhotoResult =
    eventInfo.gps || eventInfo.searchCollections;
  const shouldShowActions =
    !isEventInReminderState &&
    (hasUserNoPhotosForProducedEvent ||
      (hasEventStillProcessingPhotos && hasEventAltSerachOptionsForNoPhotoResult));
  const eventHasSecondFactor = Boolean(eventInfo.secondFactorType?.type);
  const shouldShowAlternativeSearch =
    !eventHasSecondFactor || (eventHasSecondFactor && customerKey);

  const history = useHistory();
  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);

  const onGoToSearchResults = () => {
    let altSearchCustomerKey = '';
    if (eventHasSecondFactor && customerKey) {
      altSearchCustomerKey = `/${customerKey}`;
    }
    history.push(
      `/event/${eventSgId}/search/${startNumber}${altSearchCustomerKey}?${ALTERNATIVE}=true`
    );
  };

  const onContactSupport = () => {
    const userAcceptsCookies = localStorage.getItem('userAcceptsStatsCookies') === 'true';

    if (userAcceptsCookies) {
      hlpDskDispatch({
        type: 'addToHistory',
        payload: `No photos for the order, eventId: ${eventSgId}, startnumber: ${startNumber}`,
      });
      hlpDskDispatch({ type: 'eventId', payload: eventSgId });
      hlpDskDispatch({ type: 'eventName', payload: eventInfo.name });
      hlpDskDispatch({ type: 'email', payload: userInfo?.profile.email });
      hlpDskDispatch({ type: 'open', payload: true });
    } else {
      history.push(`/contact`);
    }
  };

  const chooseHeaderToRender = () => {
    if (isEventInReminderState) {
      return (
        <FormattedMessageWrappedInSpan
          id="noPhotosForOrderActions.reminderStateEvent.title"
          defaultMessage="Photos Coming Soon"
        />
      );
    }
    if (hasEventStillProcessingPhotos) {
      return (
        <FormattedMessageWrappedInSpan
          id="noPhotosForOrderActions.processingStateEvent.title"
          defaultMessage="We are on it!"
        />
      );
    }
    return (
      <FormattedMessageWrappedInSpan
        id="noPhotosForOrderActions.producedEvent.title"
        defaultMessage="No photos yet"
      />
    );
  };

  const choseTextToRender = () => {
    if (isEventInReminderState) {
      return (
        <FormattedMessageWrappedInSpan
          id="noPhotosForOrderActions.reminderStateEvent.message"
          defaultMessage="There are no photos to download just yet, but we'll notify you via email as soon as they're ready."
        />
      );
    }
    if (hasEventStillProcessingPhotos) {
      return (
        <FormattedMessageWrappedInSpan
          id="noPhotosForOrderActions.processingStateEvent.message.processing"
          defaultMessage="There are no photos in your order yet but we're still processing images for this event so more might be added soon."
        />
      );
    }
    if (
      hasUserNoPhotosForProducedEvent &&
      hasEventAltSerachOptionsForNoPhotoResult &&
      shouldShowAlternativeSearch
    ) {
      return (
        <FormattedMessageWrappedInSpan
          id="noPhotosForOrderActions.producedEventAltAvailable.message"
          defaultMessage="There are no photos for this order. You can add them through an alternative search or reach out to our support team for help."
        />
      );
    }
    return (
      <FormattedMessageWrappedInSpan
        id="noPhotosForOrderActions.producedEventAltNotAvailable.message"
        defaultMessage="There are no photos for this order. You can reach out to our support team for help."
      />
    );
  };

  return (
    <div className="event-account-no-photos-info">
      <div className="container-label ml-15 mr-15 mb-15">{chooseHeaderToRender()}</div>
      <div
        className={classNames('event-account-no-photos-info_texts', {
          'no-action-texts': !shouldShowActions,
        })}
      >
        <div className='event-account-no-photos-icon' />
        {choseTextToRender()}
      </div>
      {shouldShowActions && (
        <div className="event-account-no-photos-info_actions mt-30">
          {hasEventAltSerachOptionsForNoPhotoResult && shouldShowAlternativeSearch && (
            <button
              onClick={onGoToSearchResults}
              className={classNames('btn btn-default info-btn full-width', {
                'main-action': !hasUserNoPhotosForProducedEvent,
              })}
            >
              <FormattedMessageWrappedInSpan
                id="noPhotosForOrderActions.actions.goToAlternativeSearch"
                defaultMessage="Alternative search"
              />
            </button>
          )}
          {hasUserNoPhotosForProducedEvent && (
            <button
              onClick={onContactSupport}
              className="btn btn-default info-btn main-action full-width"
            >
              <FormattedMessageWrappedInSpan
                id="noPhotosForOrderActions.actions.contactSupport"
                defaultMessage="Contact support"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

NoPhotosForOrderActions.propTypes = {
  eventInfo: PropTypes.object,
  startNumber: PropTypes.string,
  userInfo: PropTypes.object,
};

export { NoPhotosForOrderActions };

export default createFragmentContainer(NoPhotosForOrderActions, {
  eventInfo: graphql`
    fragment NoPhotosForOrderActions_eventInfo on Event {
      name
      state
      sgId
      searchCollections
      gps
      pendingMediaProcessing
      secondFactorType {
        type
      }
    }
  `,
  userInfo: graphql`
    fragment NoPhotosForOrderActions_userInfo on Account {
      profile {
        email
      }
    }
  `,
});
