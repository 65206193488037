/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExtendedSearchCategorySearchTab_categories$ref: FragmentReference;
declare export opaque type ExtendedSearchCategorySearchTab_categories$fragmentType: ExtendedSearchCategorySearchTab_categories$ref;
export type ExtendedSearchCategorySearchTab_categories = {|
  +mediaCollections: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
  |}>,
  +searchAllPhotos: ?boolean,
  +$refType: ExtendedSearchCategorySearchTab_categories$ref,
|};
export type ExtendedSearchCategorySearchTab_categories$data = ExtendedSearchCategorySearchTab_categories;
export type ExtendedSearchCategorySearchTab_categories$key = {
  +$data?: ExtendedSearchCategorySearchTab_categories$data,
  +$fragmentRefs: ExtendedSearchCategorySearchTab_categories$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ExtendedSearchCategorySearchTab_categories",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mediaCollections",
      "storageKey": null,
      "args": null,
      "concreteType": "MediaCollection",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "searchAllPhotos",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f5f1408b67ac6457ed08c8e0268c2eb2';

module.exports = node;
