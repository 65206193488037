import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const FeedbackResult = props => {
  const { onCloseClick, status } = props;

  if (status === 'ok') {
    return (
      <div className="notification-container">
        <DialogContent>
          <div className="notification-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="feedbackResult.success.title"
                defaultMessage="Thank you!"
              />
            </h1>
            <FormattedMessageWrappedInSpan
              id="feedbackResult.success.message"
              defaultMessage="Your feedback has been send"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button onClick={onCloseClick}>
              <FormattedMessageWrappedInSpan id="feedbackResult.closeButton" defaultMessage="Close" />
            </button>
          </div>
        </DialogActions>
      </div>
    );
  }

  return (
    <div className="notification-container">
      <div className="notification-body">
        <h1>
          <FormattedMessageWrappedInSpan
            id="feedbackResult.fail.title"
            defaultMessage="We're sorry"
          />
        </h1>
        <FormattedMessageWrappedInSpan
          id="feedbackResult.fail.message"
          defaultMessage="FAILED"
        />
      </div>
      <div className="notification-action">
        <button onClick={onCloseClick}>
          <FormattedMessageWrappedInSpan id="feedbackResult.closeButton" defaultMessage="Close" />
        </button>
      </div>
    </div>
  );
};

FeedbackResult.propTypes = {
  status: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default FeedbackResult;
