import React, { Component, Fragment, createRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CheckoutOrderingRegisterStep from './CheckoutOrderingRegisterStep';
import CheckoutOrderingAddressStep from './CheckoutOrderingAddressStep';
import CheckoutOrderingPaymentStep from './CheckoutOrderingPaymentStep';
import LoadingView from '../LoadingView';
import withRequestHandler from '../../hoc/withRequestHandler';
import CartNavigationSteps from '../cart-items/CartNavigationSteps';
import MobileContainer from '../../../components/misc/MobileContainer';
import StickyStayInPlaceContainer from '../../../components/misc/StickyStayInPlaceContainer';
import { CartQuickCheckout } from '../../../components/content/cart-quick-checkout';
import DvdInforationDialog from '../dialogs/DvdInforationDialog';

import {
  BraintreeClientTokenMutation,
  ConfirmCheckoutMutation,
  CreateOrderMutation,
  CreateOrUpdateOrderWithPaypalExpressMutation,
  FinishPaypalExpressPaymentMutation,
  PayWithBankTransferMutation,
  PayWithPaypalMutation,
  RequestLoginMutation,
  SetOrderAddressMutation,
  SetOrderShipmentAddressWithNewAddressMutation,
} from '../../../mutations';

import { initPaypalButton } from '../../../utils/initPaypalButton';
import { includesPayPal, getRealAmount } from '../../../utils/common';
import { setToLocalStorage } from '../../../utils/browser-storage';
import { trackEvent } from '../../../utils/ga-tracking';

import {
  REGISTER_STEP,
  ADDRESS_STEP,
  PAYMENT_STEP,
  MAGIC_LINK_SENT_MSG,
  PAYPAL,
  BANKTRANSFER,
  ADYEN,
} from '../../../utils/variables';

//////////////////////////////////////////////////
// TODO: tests for related to ref and to most of payment methods
//////////////////////////////////////////////////

class CheckoutOrderingSteps extends Component {
  constructor(props) {
    super(props);

    this.addressStepRef = createRef();

    const { cart, isLoggedIn } = props;

    const hasPayPal = includesPayPal(cart.availablePaymentMethods);

    this.state = {
      hasPayPal: hasPayPal,
      hasPayPalCheckoutBtn: false,
      isLoadingPayPalCheckout: false,
      isPaypalExpress: false,
      stepIndex: 0,
      paymentRequired: cart.paymentRequired,
      availablePaymentMethods: cart.availablePaymentMethods,
      couponRemovedDuringPayment: false,
      isFormRendered: false,
      formCheckCallback: null,
      isFormError: false,
      showDvdNotification: false,
    };

    this.handleContinueWithPayPalExpress =
      this.handleContinueWithPayPalExpress.bind(this);
    this.handleConfirmCheckout = this.handleConfirmCheckout.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.stepIndex === REGISTER_STEP && nextProps.isLoggedIn) {
      return {
        stepIndex: ADDRESS_STEP,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.someisFormRenderedProp !== this.props.isFormRendered) {
      const childInstance = this.addressStepRef.current;
    }
  }

  componentDidMount() {
    this.setState({ stepIndex: this.determineFirstStep() });
  }

  determineFirstStep = () => {
    const { apiRedirectToPayment, cart, isLoggedIn } = this.props;

    if (apiRedirectToPayment && isLoggedIn && cart.order) {
      return PAYMENT_STEP;
    } else if (cart.order || isLoggedIn) {
      return ADDRESS_STEP;
    } else {
      return REGISTER_STEP;
    }
  };

  handleRequestLogin = (email) => {
    this.props.onRequestStart();
    RequestLoginMutation.commit(
      this.props.relay.environment,
      email,
      true,
      this.props.match.params.language,
      (error, res) => {
        setToLocalStorage('email', email);
        if (!error) {
          this.props.onOpenNotificationDialog(MAGIC_LINK_SENT_MSG);
        } else {
          this.props.onRequestError(error);
        }
        this.props.onRequestStop();
      }
    );
    trackEvent('Checkout', 'Register', 'Magic Link');
  };

  handleGoToAddressStep = () => {
    this._goToAddressStep();
  };

  handleGoToRegisterStep = () => {
    this._goToRegisterStep();
  };

  handleGoToPaymentStep = (details) => {
    this.props.onRequestStart();
    const { order } = this.props.cart;
    if (
      order &&
      (order.state === 'checkout' ||
        order.state === 'payment_in_progress' ||
        order.state === 'payment_failed')
    ) {
      this._setOrderAddress(order.id, details);
    } else {
      this._createOrder(details);
    }

    trackEvent('Checkout', 'Address', 'Continue to payment step');
  };

  handleCompleteCheckout = (paymentMethod, data, resultCode) => {
    // Check if the user decided to use paypal express checkout
    if (this.state.isPaypalExpress) {
      this._finishPaypalExpressPayment();
    } else {
      switch (paymentMethod.defaultPaymentType) {
        case BANKTRANSFER:
          this._payWithBankTransfer();
          return;
        case PAYPAL:
          this._payWithPaypal(data);
          return;
        case ADYEN:
          this.props.onAdyenResult(data, resultCode);
          return;
      }
    }

    trackEvent('Checkout', 'Payment', 'Buy cart items');
  };

  handleContinueWithPayPalExpress() {
    const { cart, match, relay } = this.props;

    this.setState({ isLoadingPayPalCheckout: true });
    BraintreeClientTokenMutation.commit(relay.environment, (error, token) => {
      if (!error) {
        initPaypalButton('paypal-express-checkout-btn', token, {
          checkoutStep: REGISTER_STEP,
          variables: {
            amount: getRealAmount(cart.totalPrice),
            currency: cart.totalPrice.currency,
          },
          language: match.params.language,
          onAuthorize: (nonce) => this._handlePaypalExpressCheckout(nonce),
          onCancel: () =>
            trackEvent('Checkout', 'Canceled payment', 'PayPal Express payment canceled'),
          onReady: () =>
            this.setState({
              hasPayPalCheckoutBtn: true,
              isLoadingPayPalCheckout: false,
            }),
        });
      }
    });

    trackEvent('Checkout', 'Register', 'PayPal Express');
  }

  handleConfirmCheckout() {
    const {
      relay,
      cart,
      onRequestStart,
      onRequestStop,
      onCheckoutCompleted,
      onCheckoutFail,
    } = this.props;
    onRequestStart();
    ConfirmCheckoutMutation.commit(
      relay.environment,
      cart.order.id,
      (error, updatedOrder) => {
        if (!error) {
          if (updatedOrder.state === 'checkout') {
            onCheckoutFail();
            this.setState({ couponRemovedDuringPayment: true });
            onRequestStop();
          } else {
            onCheckoutCompleted(updatedOrder.orderid, cart.customer.email);
          }
        } else {
          onRequestStop();
        }
      }
    );
  }

  _goToRegisterStep = () => {
    this.setState({ stepIndex: REGISTER_STEP });
  };

  _goToAddressStep = () => {
    this.setState({ stepIndex: ADDRESS_STEP, isFormRendered: true });
  };

  _goToPaymentStep = () => {
    this.setState({ stepIndex: PAYMENT_STEP });
  };

  _handlePaypalExpressCheckout = (nonce) => {
    const { relay, match, cart, onRequestStart, onRequestStop } = this.props;
    onRequestStart();
    CreateOrUpdateOrderWithPaypalExpressMutation.commit(
      relay.environment,
      nonce,
      match.params.language,
      cart.id,
      (error, updatedOrder) => {
        if (!error) {
          this.setState({ isPaypalExpress: true });
          this._goToPaymentStep();
        }
        onRequestStop();
      }
    );
  };

  _createOrder = (details) => {
    const { relay, match, cart } = this.props;
    CreateOrderMutation.commit(
      relay.environment,
      details.email,
      match.params.language,
      cart.id,
      (error, updatedOrder) => {
        if (!error) {
          this._setOrderAddress(updatedOrder.id, details);
        }
      }
    );
  };

  _setOrderAddress = (orderId, details) => {
    const { cart, relay } = this.props;
    SetOrderAddressMutation.commit(
      relay.environment,
      orderId,
      details,
      (error, updatedOrder) => {
        if (!error) {
          if (cart.shipmentRequired) {
            SetOrderShipmentAddressWithNewAddressMutation.commit(
              relay.environment,
              orderId,
              details,
              (error, _updatedOrder) => {
                if (!error) {
                  this.props.onRequestStop();
                  this.setState({
                    availablePaymentMethods: updatedOrder.availablePaymentMethods,
                    paymentRequired: updatedOrder.cart.paymentRequired,
                    stepIndex: PAYMENT_STEP,
                  });
                }
              }
            );
          } else {
            this.props.onRequestStop();
            this.setState({
              availablePaymentMethods: updatedOrder.availablePaymentMethods,
              paymentRequired: updatedOrder.cart.paymentRequired,
              stepIndex: PAYMENT_STEP,
            });
          }
        }
      }
    );
  };

  _finishPaypalExpressPayment = () => {
    this.props.onRequestStart();
    const { cart, relay } = this.props;
    FinishPaypalExpressPaymentMutation.commit(
      relay.environment,
      cart.order.id,
      (error, updatedOrder) => {
        if (!error) {
          this.props.onRequestStop();
          this.props.onCheckoutCompleted(updatedOrder.orderid, cart.customer.email);
        } else {
          this.props.onRequestError(error);
        }
      }
    );
  };

  _payWithBankTransfer = () => {
    this.props.onRequestStart();
    const { cart, relay } = this.props;
    PayWithBankTransferMutation.commit(
      relay.environment,
      cart.order.id,
      (error, updatedOrder) => {
        if (!error) {
          this.props.onRequestStop();
          this.props.onCheckoutCompleted(updatedOrder.orderid, cart.customer.email);
        } else {
          this.props.onRequestError(error);
        }
      }
    );
  };

  _payWithPaypal = (nonce) => {
    this.props.onRequestStart();
    const { cart, relay } = this.props;
    PayWithPaypalMutation.commit(
      relay.environment,
      cart.order.id,
      nonce,
      (error, updatedOrder) => {
        if (!error) {
          this.props.onRequestStop();
          this.props.onCheckoutCompleted(updatedOrder.orderid, cart.customer.email);
        } else {
          this.props.onRequestError(error);
        }
      }
    );
  };

  setUpFormCheckCallback = (callback) => {
    this.setState({
      formCheckCallback: callback,
    });
  };

  onFormErrorStateChange = (isError) => {
    this.setState({
      isFormError: isError,
    });
  };

  onFormCheck = () => {
    if (this.state.formCheckCallback) {
      this.state.formCheckCallback();
    }
  };

  goToCart = () => {
    this.props.history.push('/cart');
  };

  toggleDvdNotification = () => {
    this.setState((prevState) => ({
      showDvdNotification: !prevState.showDvdNotification,
    }));
  };

  render() {
    const { cart, viewer, onShowCart, onOpenNotificationDialog, isLoading, isLoggedIn } =
      this.props;

    const {
      hasPayPal,
      hasPayPalCheckoutBtn,
      isLoadingPayPalCheckout,
      isPaypalExpress,
      stepIndex,
      paymentRequired,
      availablePaymentMethods,
    } = this.state;

    return (
      <div className="ordering-steps-container">
        <CartNavigationSteps
          currentStep={stepIndex + 2}
          history={this.props.history}
          goToAddressStep={this.handleGoToAddressStep}
          goToPaymentStep={this.onFormCheck}
          goToRegisterStep={this.handleGoToRegisterStep}
          isFormError={this.state.isFormError}
          isLoggedInNow={isLoggedIn}
        />
        {isLoading && <LoadingView />}
        {!isLoading && (
          <Fragment>
            {stepIndex === REGISTER_STEP && (
              <CheckoutOrderingRegisterStep
                hasPayPal={hasPayPal}
                hasPayPalCheckoutBtn={hasPayPalCheckoutBtn}
                isLoadingPayPalCheckout={isLoadingPayPalCheckout}
                onContinueAsGuest={this.handleGoToAddressStep}
                onContinueWithPayPalExpress={this.handleContinueWithPayPalExpress}
                onRequestLogin={this.handleRequestLogin}
                onOpenNotificationDialog={onOpenNotificationDialog}
                onPreviousStep={this.goToCart}
              />
            )}
            {stepIndex === ADDRESS_STEP && (
              <CheckoutOrderingAddressStep
                cart={cart}
                viewer={viewer}
                onNextStep={this.handleGoToPaymentStep}
                onPreviousStep={onShowCart}
                goToRegisterStep={this.handleGoToRegisterStep}
                isLoggedInNow={isLoggedIn}
                setUpFormCheckCallback={this.setUpFormCheckCallback}
                onFormErrorStateChange={this.onFormErrorStateChange}
              />
            )}
            {stepIndex === PAYMENT_STEP && (
              <CheckoutOrderingPaymentStep
                cart={cart}
                availablePaymentMethods={availablePaymentMethods}
                paymentRequired={paymentRequired}
                isPaypalExpress={isPaypalExpress}
                onChangeAddress={this.handleGoToAddressStep}
                onNextStep={this.handleCompleteCheckout}
                onPreviousStep={this.handleGoToAddressStep}
                onConfirmCheckout={this.handleConfirmCheckout}
                couponRemovedDuringPayment={this.state.couponRemovedDuringPayment}
                couponShouldBeRemoved={this.props.couponShouldBeRemoved}
                onToggleDvdDialog={this.toggleDvdNotification}
              />
            )}
            <MobileContainer>
              <StickyStayInPlaceContainer
                changingDataShouldUpdateCoords={stepIndex}
                marginTop={30}
              >
                <CartQuickCheckout
                  stepNumber={stepIndex}
                  onContinueAsGuest={this.handleGoToAddressStep}
                  onGoToPaymentStep={this.onFormCheck}
                  onCompleteCheckout={this.handleCompleteCheckout}
                  isFormError={this.state.isFormError}
                  isLoggedIn={isLoggedIn}
                />
              </StickyStayInPlaceContainer>
            </MobileContainer>
          </Fragment>
        )}
        <DvdInforationDialog
          open={this.state.showDvdNotification}
          onRequestClose={this.toggleDvdNotification}
          onRequestRedirect={this.goToCart}
        />
      </div>
    );
  }
}

CheckoutOrderingSteps.propTypes = {
  isLoggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  cart: PropTypes.object,
  viewer: PropTypes.object,
  relay: PropTypes.object,
  match: PropTypes.object,
  onCheckoutCompleted: PropTypes.func,
  onShowCart: PropTypes.func,
  onOpenNotificationDialog: PropTypes.func,
  onAdyenResult: PropTypes.func,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  onCheckoutFail: PropTypes.func,
  history: PropTypes.object,
  couponShouldBeRemoved: PropTypes.bool,
  isFormRendered: PropTypes.bool,
  apiRedirectToPayment: PropTypes.bool,
};

export { CheckoutOrderingSteps };

export default createFragmentContainer(
  withRouter(withRequestHandler(CheckoutOrderingSteps)),
  {
    cart: graphql`
      fragment CheckoutOrderingSteps_cart on Cart {
        id
        customer {
          email
        }
        lineItems {
          id
          product {
            eventId
          }
        }
        order {
          id
          state
          orderid
        }
        shipmentRequired
        paymentRequired
        totalPrice
        availablePaymentMethods {
          id
          name
          defaultPaymentType
        }

        ...CheckoutOrderingAddressStep_cart
        ...CheckoutOrderingPaymentStep_cart
      }
    `,
    viewer: graphql`
      fragment CheckoutOrderingSteps_viewer on Viewer {
        ...CheckoutOrderingAddressStep_viewer
      }
    `,
  }
);
