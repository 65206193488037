import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { useLocation } from 'react-router-dom';

import CheckoutPage from './CheckoutPage';
import QueryHandler from '../components/misc/QueryHandler';
import { UserConsumer } from '../components/context/UserContext';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const CheckoutPageQueryRenderer = (props) => {
  const search = useLocation().search;
  const adyenRedirectResult = new URLSearchParams(search).get('redirectResult');
  const apiRedirectToPayment = new URLSearchParams(search).get('already_filled');

  return (
    <UserConsumer>
      {({ isLoggedIn }) => (
        <QueryHandler
          query={graphql`
            query CheckoutPageQueryRendererQuery($isLoggedIn: Boolean, $noAdyenRedirectResult: Boolean, $adyenRedirectResult: String) {
              viewer {
                ...CheckoutPage_viewer
              }
            }
          `}
          variables={{
            isLoggedIn: isLoggedIn,
            noAdyenRedirectResult: adyenRedirectResult === undefined,
            adyenRedirectResult: adyenRedirectResult,
          }}
          render={props => {
            return <CheckoutPage isLoggedIn={isLoggedIn} apiRedirectToPayment={JSON.parse(apiRedirectToPayment)} viewer={props.viewer} />;
          }}
        />
      )}
    </UserConsumer>
  );
}

CheckoutPageQueryRenderer.propTypes = {
  viewer: PropTypes.object
}

export default CheckoutPageQueryRenderer;
