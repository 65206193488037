/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StartNumberSearch_info$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExtendedSearchDualSearchTab_event$ref: FragmentReference;
declare export opaque type ExtendedSearchDualSearchTab_event$fragmentType: ExtendedSearchDualSearchTab_event$ref;
export type ExtendedSearchDualSearchTab_event = {|
  +id: string,
  +sgId: ?number,
  +mainSearch: ?string,
  +$fragmentRefs: StartNumberSearch_info$ref,
  +$refType: ExtendedSearchDualSearchTab_event$ref,
|};
export type ExtendedSearchDualSearchTab_event$data = ExtendedSearchDualSearchTab_event;
export type ExtendedSearchDualSearchTab_event$key = {
  +$data?: ExtendedSearchDualSearchTab_event$data,
  +$fragmentRefs: ExtendedSearchDualSearchTab_event$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ExtendedSearchDualSearchTab_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainSearch",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "StartNumberSearch_info",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e0281216df83aaef90c5ae1546135841';

module.exports = node;
