import React from 'react'

const AboutPreparationSvg = () => (
  <svg viewBox="0 0 344 353">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path className="about-preparation-animation-path"
        d="M172.8,36 L260.4,36 C282.1,36 299.7,53.6 299.7,75.3 C299.7,97 282.1,114.6 260.4,114.6 L39.3,114.6 C17.6,114.6 0,132.2 0,153.9 C0,175.6 17.6,193.2 39.3,193.2 L300.4,193.2 C322.1,193.2 339.7,210.8 339.7,232.5 C339.7,254.2 322.1,271.8 300.4,271.8 L69.3,271.8 C47.6,271.8 30,289.4 30,311.1 C30,332.8 47.6,350.4 69.3,350.4 L120.1,350.4"
        fill="none"
        stroke="#3C5465"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <path className="about-preparation-animation-dash"
        d="M172.8,36 L260.4,36 C282.1,36 299.7,53.6 299.7,75.3 C299.7,97 282.1,114.6 260.4,114.6 L39.3,114.6 C17.6,114.6 0,132.2 0,153.9 C0,175.6 17.6,193.2 39.3,193.2 L300.4,193.2 C322.1,193.2 339.7,210.8 339.7,232.5 C339.7,254.2 322.1,271.8 300.4,271.8 L69.3,271.8 C47.6,271.8 30,289.4 30,311.1 C30,332.8 47.6,350.4 69.3,350.4 L120.1,350.4"
        fill="none"
        stroke="#f6f8f9"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <g className="about-preparation-animation-pin">
        <path
          d="M133.1982,321.3889 C136.8672,321.3889 139.9232,324.4669 139.9232,328.2639 C139.9232,331.9589 136.8672,335.0389 133.1982,335.0389 C129.4272,335.0389 126.3712,331.9589 126.3712,328.2639 C126.3712,324.4669 129.4272,321.3889 133.1982,321.3889 M133.1982,314.6159 C140.6362,314.6159 146.6472,320.6699 146.6472,328.2639 C146.6472,334.7309 137.2742,348.1749 133.1982,353.6159 C129.0212,348.1749 119.6472,334.7309 119.6472,328.2639 C119.6472,320.6699 125.6592,314.6159 133.1982,314.6159"
          id="Fill-4"
          fill="#3084C6"
        />
      </g>
      <g className="about-preparation-animation-pin">
          <rect fill="#F6F8F9" x="235" y="171" width="27" height="26"></rect>
          <path d="M247.9775,164.8201 C251.6455,164.8201 254.7025,167.8981 254.7025,171.6951 C254.7025,175.3911 251.6455,178.4711 247.9775,178.4711 C244.2065,178.4711 241.1505,175.3911 241.1505,171.6951 C241.1505,167.8981 244.2065,164.8201 247.9775,164.8201 M248.552,158 C255.989,158 262,164.054 262,171.648 C262,178.115 252.627,191.56 248.552,196.999 C244.374,191.56 235,178.115 235,171.648 C235,164.054 241.013,158 248.552,158" id="Fill-7" fill="#3084C6"></path>
      </g>
      <g className="about-preparation-animation-pin">
        <path
          d="M157.6982,7.7732 C161.3672,7.7732 164.4232,10.8512 164.4232,14.6482 C164.4232,18.3442 161.3672,21.4232 157.6982,21.4232 C153.9272,21.4232 150.8712,18.3442 150.8712,14.6482 C150.8712,10.8512 153.9272,7.7732 157.6982,7.7732 M157.6982,0.9992 C165.1362,0.9992 171.1472,7.0542 171.1472,14.6482 C171.1472,21.1152 161.7742,34.5602 157.6982,39.9992 C153.5212,34.5602 144.1472,21.1152 144.1472,14.6482 C144.1472,7.0542 150.1592,0.9992 157.6982,0.9992"
          id="Fill-9"
          fill="#3084C6"
        />
      </g>
    </g>
  </svg>
)

export default AboutPreparationSvg
