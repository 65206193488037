import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { trackEvent } from '../../../utils/ga-tracking';
import classNames from 'classnames';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import environment from '../../../environment';
import AddReminderForEventWhenExpired from '../../../mutations/AddReminderForEventWhenExpiredMutation';
import CustomDialog from '../dialogs/CustomDialog';
import EventReminderForm from './EventReminderForm';
import EventReminderResult from './EventReminderResult';

const EventReminder = (props) => {
  const {
    onRequestReminder,
    showReminderResult,
    reminderResultSuccessful,
    showNewsletterResult,
    newsletterResultStatus,
    showDialog,
    onScrollToBestOf,
    allowToSubscribeAgain,
    isTokenValid,
    reminderTokenIsInPath,
    reminderToken,
    isUserEmailUnknown,
  } = props;

  const [formVisible, setFormVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(showDialog);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [showResendingResult, setShowResendingResult] = useState(false);
  const [resendReminderSuccessful, setResendReminderSuccessful] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
    setFormVisible(true);
    trackEvent('Marketing', 'Event Reminder', 'dialog open');
  };

  const closeDialog = () => {
    if (onScrollToBestOf) {
      onScrollToBestOf();
    }
    setDialogOpen(false);
  };

  const closeDialogandAllowToSubmitAgain = () => {
    if (onScrollToBestOf) {
      onScrollToBestOf();
    }
    setDialogOpen(false);
    allowToSubscribeAgain();
  };

  const resendReminder = () => {
    setIsRequestPending(true);
    AddReminderForEventWhenExpired.commit(environment, reminderToken, (err, status) => {
      if (status === 'ok') {
        setResendReminderSuccessful(true);
      }
      setIsRequestPending(false);
      setShowResendingResult(true);
    });
  };

  const eventReminderBody = (
    <div className="notification-body">
      <div className="event-reminder-icon" />
      <h1>
        <FormattedMessageWrappedInSpan
          id="eventReminder.title"
          defaultMessage="We are on it!"
        />
      </h1>

      <FormattedMessageWrappedInSpan
        id="eventReminder.noPhotosText"
        defaultMessage="The photos are not online yet."
        description="The text that tells the user that the fotos are not online yet."
      />
      <FormattedMessageWrappedInSpan
        id="eventReminder.requestReminderText"
        defaultMessage="Get a notification as soon as your pictures are online."
        description="The text that tells the user that he can get a reminder"
      />
    </div>
  );

  return (
    <div className="col-xs-16 col-sm-6 event-reminder-container">
      <div className="notification-container event-reminder-body">
        {eventReminderBody}
        <button className="primary" onClick={openDialog}>
          <FormattedMessageWrappedInSpan
            id="eventReminder.action.accept"
            defaultMessage="Notify me"
          />
        </button>
      </div>

      <CustomDialog open={dialogOpen} onRequestClose={closeDialog}>
        {!showReminderResult && !showResendingResult && formVisible && (
          <EventReminderForm onRequestReminder={onRequestReminder} />
        )}
        {!formVisible && !showResendingResult && (
          <div className="notification-container">
            <DialogContent>
              {!isTokenValid && reminderTokenIsInPath && !isRequestPending && (
                <div className="notification-body">
                  <div className="event-reminder-icon" />
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="eventReminder.error.title"
                      defaultMessage="Reminder link is invalid or has expired"
                    />
                  </h1>
                  <FormattedMessageWrappedInSpan
                    id="eventReminder.error.text"
                    defaultMessage="Your link is invalid or has expired. Try subscribing for reminder again."
                  />
                </div>
              )}
              {isRequestPending && (
                <div className="notification-body">
                  <CircularProgress />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <div className="notification-action">
                <button onClick={closeDialog}>
                  <FormattedMessageWrappedInSpan
                    id="eventReminder.cancelButton"
                    defaultMessage="Cancel"
                  />
                </button>
                <button
                  className={classNames('accept', { disabled: isRequestPending })}
                  disabled={isRequestPending}
                  onClick={
                    !isTokenValid && reminderTokenIsInPath && !isUserEmailUnknown ? resendReminder : openDialog
                  }
                >
                  {!isTokenValid && reminderTokenIsInPath ? (
                    <FormattedMessageWrappedInSpan
                      id="eventReminder.acceptAgainButton"
                      defaultMessage="Try again"
                    />
                  ) : (
                    <FormattedMessageWrappedInSpan
                      id="eventReminder.acceptButton"
                      defaultMessage="Notify me"
                    />
                  )}
                </button>
              </div>
            </DialogActions>
          </div>
        )}
        <EventReminderResult
          showReminderResult={showReminderResult || showResendingResult}
          reminderResultSuccessful={reminderResultSuccessful || resendReminderSuccessful}
          showNewsletterResult={showNewsletterResult}
          newsletterResultStatus={newsletterResultStatus}
          closeDialog={closeDialogandAllowToSubmitAgain}
        />
      </CustomDialog>
    </div>
  );
};

EventReminder.propTypes = {
  onRequestReminder: PropTypes.func,
  showReminderResult: PropTypes.bool,
  reminderResultSuccessful: PropTypes.bool,
  showNewsletterResult: PropTypes.bool,
  newsletterResultStatus: PropTypes.string,
  showDialog: PropTypes.bool,
  onScrollToBestOf: PropTypes.func,
  allowToSubscribeAgain: PropTypes.func,
  isTokenValid: PropTypes.bool,
  reminderTokenIsInPath: PropTypes.bool,
  reminderToken: PropTypes.string,
  isUserEmailUnknown: PropTypes.bool,
};

export default EventReminder;
