import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import { FormattedMessageWrappedInSpan } from '../../misc';
import CustomDialog from './CustomDialog';

const DvdLabelDialog = props => {
  const { open, dvdText, intl, onTextChange, onRequestClose, onAcceptClick } = props;

  return (
    <CustomDialog
      hasFixedWidth={false}
      open={open}
      onRequestClose={onRequestClose}
      className="dvd-label-dialog"
    >
      <div className="notification-container">
        <div className="dvd-label-dialog-body">
          <div className="dvd-label-dialog-dvd">
            <div className="dvd-label-dialog-dvd-inner-circle">
              <div className="dvd-label-dialog-dvd-hole" />
            </div>
            <div className="dvd-label-dialog-input-container">
              <TextField
                autoComplete="off"
                classes={{ root: 'dvd-label-dialog-input' }}
                id="lineOne"
                inputProps={{ maxLength: 30 }}
                label={intl.formatMessage(translations.nameHintText)}
                name="lineOne"
                onChange={onTextChange}
                value={dvdText.lineOne}
              />
              <TextField
                autoComplete="off"
                classes={{ root: 'dvd-label-dialog-input' }}
                id="lineTwo"
                inputProps={{ maxLength: 30 }}
                label={intl.formatMessage(translations.eventNameHintText)}
                name="lineTwo"
                onChange={onTextChange}
                value={dvdText.lineTwo}
              />
              <TextField
                autoComplete="off"
                classes={{ root: 'dvd-label-dialog-input' }}
                id="lineThree"
                inputProps={{ maxLength: 30 }}
                label={intl.formatMessage(translations.firstAdditionalHintText)}
                name="lineThree"
                onChange={onTextChange}
                value={dvdText.lineThree}
              />
              <TextField
                autoComplete="off"
                classes={{ root: 'dvd-label-dialog-input' }}
                id="lineFour"
                inputProps={{ maxLength: 30 }}
                label={intl.formatMessage(translations.secondAdditionalHintText)}
                name="lineFour"
                onChange={onTextChange}
                value={dvdText.lineFour}
              />
            </div>
          </div>
        </div>
        <div className="notification-action">
          <button onClick={onRequestClose}>
            <FormattedMessageWrappedInSpan id="dvdLabelDialog.cancelButton" defaultMessage="Cancel" />
          </button>
          <button className="accept" onClick={onAcceptClick}>
            <FormattedMessageWrappedInSpan id="dvdLabelDialog.acceptButton" defaultMessage="Done" />
          </button>
        </div>
      </div>
    </CustomDialog>
  );
};

const translations = defineMessages({
  nameHintText: {
    id: 'dvdLabelDialog.textFieldHintText.name',
    defaultMessage: 'Your name',
  },
  eventNameHintText: {
    id: 'dvdLabelDialog.textFieldHintText.eventName',
    defaultMessage: 'Event name',
  },
  firstAdditionalHintText: {
    id: 'dvdLabelDialog.textFieldHintText.additionalOne',
    defaultMessage: 'Additional text 1',
  },
  secondAdditionalHintText: {
    id: 'dvdLabelDialog.textFieldHintText.additionalTwo',
    defaultMessage: 'Additional text 2',
  },
});

DvdLabelDialog.propTypes = {
  open: PropTypes.bool,
  dvdText: PropTypes.shape({
    lineOne: PropTypes.string,
    lineTwo: PropTypes.string,
    lineThree: PropTypes.string,
    lineFour: PropTypes.string,
  }),
  intl: PropTypes.object,
  onRequestClose: PropTypes.func,
  onAcceptClick: PropTypes.func,
  onTextChange: PropTypes.func,
};

export default injectIntl(DvdLabelDialog);
