// eslint ignore
/* global EVENT_FETCH_COUNT: true */
/* global BETA_MODE: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import HomePage from './HomePage';
import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class HomePageQueryRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // For various reasons, the home page of the new website
    // will not be shown. Everytime the user should see the "new"
    // home page, he will be redirected to the SG legacy page.
    // `BETA_MODE` is defined in `webpack.common.js`
    if (BETA_MODE) {
      if (window.location.href.includes('beta.sportograf.com')) {
        window.location.replace(
          `https://www.sportograf.com/${this.props.match.params.language}/shop`,
        );
        return null;
      }
    }
    
    return (
      <div className="main-page-container">
        <QueryHandler
          hasLoadingBackground={true}
          query={graphql`
            query HomePageQueryRendererQuery(
              $count: Int!
              $cursor: String
              $query: String
              $landingpage: String
              $noLandingpage: Boolean
            ) {
              viewer(landingpage: $landingpage) {
                ...HomePage_viewer
              }
            }
          `}
          // `EVENT_FETCH_COUNT` is defined in `webpack.common.js`
          variables={{
            count: EVENT_FETCH_COUNT,
            landingpage: this.props.match.params.landingpage,
            noLandingpage: this.props.match.params.landingpage === undefined
          }}
          render={props => {
            return <HomePage viewer={props.viewer} />;
          }}
        />
      </div>
    );
  }
}

HomePageQueryRenderer.propTypes = {
  match: PropTypes.object,
};

export default HomePageQueryRenderer;
