import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SINGLE_MEDIA_ACCOUNT_PAGE } from '../../../utils/variables';

import environment from '../../../environment';
import GenerateFotoFlatZipInput from '../../../mutations/GenerateFotoFlatZip';
import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountDownloadTileZipDownload = ({
  icon,
  textInSpan,
  eventSgId,
  type,
  notActive,
  isWrappedInLink,
  bibNumber,
}) => {
  const [error, setError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [valueForAvailableZip, setValueForAvailableZip] = useState({
    id: type,
    url: null,
  });

  const bibToGenerateZip = bibNumber === SINGLE_MEDIA_ACCOUNT_PAGE ? null : bibNumber;

  const download = (url) => {
    window.location.href = url;
  };

  const updateState = (newUrl) => {
    setValueForAvailableZip((prevState) => ({
      ...prevState,
      url: newUrl,
    }));
  };

  const generateZipDownloadLink = (zipName) => {
    setIsPending(true);
    GenerateFotoFlatZipInput.commit(environment, eventSgId, bibToGenerateZip, zipName, (errorMsg, url) => {
      if (!errorMsg) {
        updateState(zipName, url);
        download(url);
      } else {
        setError(true);
      }
      setIsPending(false);
    });
  };

  const handleButtonClicked = () => {
    if (isWrappedInLink) {
      return;
    }
    if (valueForAvailableZip.url) {
      download(valueForAvailableZip.url);
    } else {
      generateZipDownloadLink(valueForAvailableZip.id);
    }
  };

  return (
    <AccountDownloadTileButton
      icon={icon}
      textInSpan={textInSpan}
      onClick={() => {
        handleButtonClicked();
      }}
      isPending={isPending}
      error={error}
      notActive={notActive}
    />
  );
};

AccountDownloadTileZipDownload.propTypes = {
  icon: PropTypes.object,
  textInSpan: PropTypes.object,
  notActive: PropTypes.bool,
  eventSgId: PropTypes.number,
  type: PropTypes.string,
  isFaceSearchEvent: PropTypes.bool,
  isWrappedInLink: PropTypes.bool,
  bibNumber: PropTypes.string,
};

export default AccountDownloadTileZipDownload;
