import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation RequestRechecksMutation($input: RequestRechecksInput!) {
    requestRechecks(input: $input) {
      status
    }
  }
`;

function commit(environment, email, locale, mediaIds, startnumber, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        locale,
        mediaIds,
        startnumber,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.requestRechecks.status);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
