/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExtendedSearchCategorySearchTab_categories$ref = any;
type ExtendedSearchDualSearchTab_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExtendedSearch_event$ref: FragmentReference;
declare export opaque type ExtendedSearch_event$fragmentType: ExtendedSearch_event$ref;
export type ExtendedSearch_event = {|
  +id: string,
  +sgId: ?number,
  +mainSearch: ?string,
  +searchCollections: ?boolean,
  +$fragmentRefs: ExtendedSearchCategorySearchTab_categories$ref & ExtendedSearchDualSearchTab_event$ref,
  +$refType: ExtendedSearch_event$ref,
|};
export type ExtendedSearch_event$data = ExtendedSearch_event;
export type ExtendedSearch_event$key = {
  +$data?: ExtendedSearch_event$data,
  +$fragmentRefs: ExtendedSearch_event$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ExtendedSearch_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainSearch",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "searchCollections",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExtendedSearchCategorySearchTab_categories",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExtendedSearchDualSearchTab_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a458c6d4d9d9b28741601da4af65143d';

module.exports = node;
