import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessageWrappedInSpan} from '../misc';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import {Checkbox, FormControl, FormControlLabel} from '@material-ui/core';
import { getAppLanguage } from '../../intl/languages';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const GDPRHint = ({className, faceNote, secondFactorNote, onChangePrivacyPolicy, onChangeIdentityConfirmation, intl}) => {

  const linkToPrivacy = (
    <a href="/privacy" target="_blank" rel="noopener noreferrer">
      <FormattedMessageWrappedInSpan id="gdprHint.link" defaultMessage="privacy policy" />
    </a>
  );

  const linkToSecondFactorFAQ = (
    <a href={`https://helpdesk.sportograf.com/${getAppLanguage()}/support/solutions/articles/77000530756`} target="_blank" rel="noopener noreferrer">
      <FormattedMessageWrappedInSpan id="gdprHint.secondFactorHint.link" defaultMessage="our second factor authentication" />
    </a>
  )

  return (
    <>
      {faceNote &&
        <div className={classNames('gdpr-hint-consent', className)}>
          <FormControl component="fieldset">
            <FormControlLabel
              control={<Checkbox onChange={onChangePrivacyPolicy}/>}
              label={
                <FormattedMessageWrappedInSpan
                  id="gdprHint.faceHint"
                  defaultMessage="I consent that the biometric characteristics of the uploaded selfie depicting me will be stored and compared with the database of the event. The consent can be revoked at any time with effect for the future by notifying Sportograf."
                />
              }
              labelPlacement="end"
            />
            <FormControlLabel
              control={<Checkbox onChange={onChangeIdentityConfirmation}/>}
              label={
                <FormattedMessageWrappedInSpan
                  id="gdprHint.faceIdentityHint"
                  defaultMessage="I confirm that I am pictured in the selfie and that I participated in the event."
                />
              }
              labelPlacement="end"
            />
          </FormControl>
        </div>
      }
      {secondFactorNote &&
        <div className={classNames('gdpr-hint-text', className)}>
          <FormattedMessageWrappedInSpan
            id="gdprHint.secondFactorHint.text"
            defaultMessage="Why do we ask for this? Learn more about {link} and how we process your data."
            values={{link: linkToSecondFactorFAQ}}
          />
        </div>
      }
      {!faceNote && !secondFactorNote &&
        <div className={classNames('gdpr-hint-text', className)}>
          <FormattedMessageWrappedInSpan
            id="gdprHint.text"
            defaultMessage="For more information on data protection, please see our {link}."
            values={{link: linkToPrivacy}}
          />
        </div>
      }
    </>
  );
};

GDPRHint.defaultProps = {
  faceNote: false,
  secondFactorNote: false
};

GDPRHint.propTypes = {
  intl: PropTypes.object,
  className: PropTypes.string,
  faceNote: PropTypes.bool,
  secondFactorNote: PropTypes.bool,
  onChangePrivacyPolicy: PropTypes.func,
  onChangeIdentityConfirmation: PropTypes.func
};

export default injectIntl(GDPRHint);
