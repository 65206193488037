import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import classNames from 'classnames';

const PAYPAL_VISA = 'VisaViaPayPal';
const PAYPAL_MC = 'MastercardViaPayPal';

import { BANKTRANSFER, PAYPAL, ADYEN, AMAZON } from '../../../utils/variables';

/////////////////////////////////////
// TODO missing test cases
// lines 26, 124-140
/////////////////////////////////////

class CheckoutOrderingPaymentStepPaymentMethods extends Component {
  constructor(props) {
    super(props);
  }

  _getPaymentMethodDescription = (name, type) => {
    switch (true) {
      case name == PAYPAL_VISA:
      case name == PAYPAL_MC:
        return (
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepPaymentMethods.paypalCreditDescription"
            defaultMessage="No PayPal account required. Please click on 'Buy now' to proceed with your payment with credit card via PayPal. After finishing your payment you will be redirected to www.sportograf.com."
          />
        );
      case type == BANKTRANSFER:
        return (
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepPaymentMethods.bankTransferDescription"
            defaultMessage="Please click on 'Pay now' to complete your order. After completing your order, we will send you the bank transfer details by email. Please use your order number and name as type of use when making the transfer. Please note that it may take up to 5 days for the payment to be collected."
          />
        );
      case type == PAYPAL:
        return (
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepPaymentMethods.paypalDescription"
            defaultMessage="Please click on 'Buy now' to proceed with your payment with PayPal. After finishing your payment you will be redirected to www.sportograf.com."
          />
        );
      case type == ADYEN:
        return (
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepPaymentMethods.adyenDescription"
            defaultMessage="Please enter all required payment information below and click on 'Pay now' to proceed with your payment. After finishing your payment you will be redirected to www.sportograf.com."
          />
        );
      case type == AMAZON:
        return (
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepPaymentMethods.amazonDescription"
            defaultMessage="Please click on 'Pay now' to proceed with your payment with Amazon Pay. After finishing your payment you will be redirected to www.sportograf.com."
          />
        );
    }
  };

  _renderPaymentMethodCard = (pm) => {
    const { selectedPaymentMethod } = this.props;
    const isSelected = pm.id === (selectedPaymentMethod && selectedPaymentMethod.id);

    return (
      <div
        key={pm.id}
        className={classNames('order-payment-form-payment-method', pm.name, {
          'payment-method-selected': isSelected,
        })}
      />
    );
  };

  render() {
    const {
      isPaypalExpress,
      availablePaymentMethods,
      selectedPaymentMethod,
      onPaymentMethodChanged,
    } = this.props;

    if (isPaypalExpress) {
      return (
        <div className="row">
          <div className="order-payment-form-paypal-express col-xs-16 col-sm-10 col-sm-offset-3">
            <h2>
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingPaymentStepPaymentMethods.paypalExpressCheckoutMessage"
                defaultMessage="You have decided to use Paypal Express"
              />
            </h2>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-offset-3">
            <h2 className="order-payment-form-info">
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingPaymentStepPaymentMethods.selectPaymentMehtodInfo"
                defaultMessage="Select your payment method"
              />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="order-payment-form-payment-method-container col-xs-16 col-sm-10 col-sm-offset-3">
            {availablePaymentMethods.map((pm) => {
              if (pm.name !== 'Alipay') {
                return (
                  <div key={pm.id} onClick={() => onPaymentMethodChanged(pm)}>
                    {this._renderPaymentMethodCard(pm)}
                  </div>
                );
              }
            })}
          </div>
        </div>
        {selectedPaymentMethod && (
          <div className="row">
            {this.props.klarnaWarning && (
              <div className="order-payment-form-payment-method-warning col-xs-16 col-sm-6 col-sm-offset-5">
                <FormattedMessageWrappedInSpan
                  id="checkoutOrderingPaymentStepPaymentMethods.klarnaWarning"
                  defaultMessage="<b>Please note:</b> We're currently seeing some banks from Austria charging for payments with this payment method because the provider is seated in the UK."
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </div>
            )}
            <div className="order-payment-form-payment-method-description col-xs-16 col-sm-6 col-sm-offset-5">
              {this._getPaymentMethodDescription(
                selectedPaymentMethod.name,
                selectedPaymentMethod.defaultPaymentType
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

CheckoutOrderingPaymentStepPaymentMethods.defaultProps = {
  isPaypalExpress: false,
  klarnaWarning: false,
};

CheckoutOrderingPaymentStepPaymentMethods.propTypes = {
  isPaypalExpress: PropTypes.bool,
  availablePaymentMethods: PropTypes.array,
  onPaymentMethodChanged: PropTypes.func,
  selectedPaymentMethod: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  klarnaWarning: PropTypes.bool,
};

export default CheckoutOrderingPaymentStepPaymentMethods;
