import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import CartLineItem from './CartLineItem';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartOverviewItem(props) {
  const { lineItem, eventNames, ...other } = props;
  return (
    <CartLineItem
      finalPrice={lineItem.price}
      lineItem={lineItem}
      eventNames={eventNames}
      {...other}
    />
  );
}

CartOverviewItem.propTypes = {
  lineItem: PropTypes.object,
  eventNames: PropTypes.object,
};

export { CartOverviewItem };
export default createFragmentContainer(CartOverviewItem, {
  lineItem: graphql`
    fragment CartOverviewItem_lineItem on LineItem {
      price
      ...CartLineItem_lineItem
    }
  `,
});
