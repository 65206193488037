/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NoPhotosForOrderActions_userInfo$ref: FragmentReference;
declare export opaque type NoPhotosForOrderActions_userInfo$fragmentType: NoPhotosForOrderActions_userInfo$ref;
export type NoPhotosForOrderActions_userInfo = {|
  +profile: ?{|
    +email: ?string
  |},
  +$refType: NoPhotosForOrderActions_userInfo$ref,
|};
export type NoPhotosForOrderActions_userInfo$data = NoPhotosForOrderActions_userInfo;
export type NoPhotosForOrderActions_userInfo$key = {
  +$data?: NoPhotosForOrderActions_userInfo$data,
  +$fragmentRefs: NoPhotosForOrderActions_userInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "NoPhotosForOrderActions_userInfo",
  "type": "Account",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "profile",
      "storageKey": null,
      "args": null,
      "concreteType": "Profile",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '22a983568b31e3c86a55df666fbf9dcb';

module.exports = node;
