import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { Link } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';

const CheckoutOrderingPaymentStepCheckbox = props => {
  const {
    acceptsPrivacy,
    acceptsSgContract,
    onPrivacyCheckboxChanged,
    onSgContractCheckboxChanged,
  } = props;

  return (
    <Fragment>
      <div className="order-payment-form-checkbox-container row">
        <div className="col-xs-2 col-sm-1 col-sm-offset-3">
          <Checkbox
            classes={{root: 'order-payment-form-checkbox'}}
            checked={acceptsPrivacy}
            onChange={onPrivacyCheckboxChanged}
          />
        </div>
        <div className="col-xs-14 col-sm-9">
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepCheckbox.privacy.text"
            defaultMessage="I agree to Sportograf's {termsAndConditions} and have read the {cancellation} and the {privacyPolicy}."
            values={{
              termsAndConditions: (
                <Link className="checkbox-router-link" to="/agb" target="_blank">
                  <FormattedMessageWrappedInSpan
                    id="checkoutOrderingPaymentStepCheckbox.privacy.termsAndConditionsLink"
                    defaultMessage="terms and conditions"
                  />
                </Link>
              ),
              cancellation: (
                <Link className="checkbox-router-link" to="/agb" target="_blank">
                  <FormattedMessageWrappedInSpan
                    id="checkoutOrderingPaymentStepCheckbox.privacy.cancellationLink"
                    defaultMessage="cancellation/revocation"
                  />
                </Link>
              ),
              privacyPolicy: (
                <Link className="checkbox-router-link" to="/privacy" target="_blank">
                  <FormattedMessageWrappedInSpan
                    id="checkoutOrderingPaymentStepCheckbox.privacy.privacyPolicyLink"
                    defaultMessage="privacy policy"
                  />
                </Link>
              ),
            }}
          />
        </div>
      </div>
      <div className="order-payment-form-checkbox-container row">
        <div className="col-xs-2 col-sm-1 col-sm-offset-3">
          <Checkbox
            classes={{root: 'order-payment-form-checkbox'}}
            checked={acceptsSgContract}
            onChange={onSgContractCheckboxChanged}
          />
        </div>
        <div className="col-xs-14 col-sm-9">
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepCheckbox.sgContract"
            defaultMessage="I agree with the execution of the agreement in terms of digital content (e.g. the provision of the download links for images / videos) explicitly before the end of the withdrawal period. I am aware that my legal right of withdrawal regarding the digital content expires with the beginning of the execution of the contract."
          />
        </div>
      </div>
    </Fragment>
  );
};

CheckoutOrderingPaymentStepCheckbox.propTypes = {
  acceptsPrivacy: PropTypes.bool,
  acceptsSgContract: PropTypes.bool,
  onPrivacyCheckboxChanged: PropTypes.func,
  onSgContractCheckboxChanged: PropTypes.func,
};

export default CheckoutOrderingPaymentStepCheckbox;
