import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation SetOrderPaymentMethodMutation($input: SetOrderPaymentMethodInput!) {
    setOrderPaymentMethod(input: $input) {
      order {
        id
        orderid
        state
        payment {
          paymentMethod {
            id
            name
          }
          state
        }
      }
    }
  }
`;

function commit(environment, orderId, paymentMethodId, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        orderId,
        paymentMethodId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.setOrderPaymentMethod.order);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
