/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type EventInfoCard_eventInfo$ref = any;
type MediaList_media$ref = any;
type NoPhotosForOrderActions_eventInfo$ref = any;
type NoPhotosForOrderActions_userInfo$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountEventPage_viewer$ref: FragmentReference;
declare export opaque type AccountEventPage_viewer$fragmentType: AccountEventPage_viewer$ref;
export type AccountEventPage_viewer = {|
  +account: ?{|
    +orders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +cart: ?{|
            +lineItems: ?$ReadOnlyArray<?{|
              +product: ?{|
                +event: ?{|
                  +name: ?string,
                  +sgId: ?number,
                |}
              |}
            |}>
          |},
          +orderid: ?string,
          +vouchers: ?$ReadOnlyArray<?{|
            +id: string,
            +code: ?string,
            +campaign: ?{|
              +id: string,
              +banner: ?string,
              +url: ?string,
            |},
          |}>,
        |}
      |}>
    |},
    +boughtEvent: ?{|
      +name: ?string,
      +products: ?$ReadOnlyArray<?{|
        +type: ?string,
        +price: ?any,
      |}>,
      +sgId: ?number,
      +mainSearch: ?string,
      +pendingMediaProcessing: ?boolean,
      +$fragmentRefs: EventInfoCard_eventInfo$ref & NoPhotosForOrderActions_eventInfo$ref,
    |},
    +eventPurchases: ?{|
      +availableCertificates: ?$ReadOnlyArray<?string>,
      +availableVideos: ?$ReadOnlyArray<?string>,
      +availableZips: ?$ReadOnlyArray<?string>,
      +bonusMedia: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |},
        +mediaList: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +sgId: ?number,
              +variants: ?$ReadOnlyArray<?{|
                +format: ?string,
                +name: ?string,
                +url: ?string,
              |}>,
            |}
          |}>,
          +$fragmentRefs: MediaList_media$ref,
        |},
      |},
      +canRestart: ?boolean,
      +restartState: ?string,
      +participants: ?$ReadOnlyArray<?{|
        +media: ?{|
          +mediaInfo: ?{|
            +count: ?number
          |},
          +mediaList: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +sgId: ?number,
                +variants: ?$ReadOnlyArray<?{|
                  +format: ?string,
                  +name: ?string,
                  +url: ?string,
                |}>,
              |}
            |}>,
            +$fragmentRefs: MediaList_media$ref,
          |},
        |},
        +startnumber: ?string,
      |}>,
      +singleMedia: ?{|
        +mediaInfo: ?{|
          +count: ?number
        |},
        +mediaList: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +sgId: ?number,
              +variants: ?$ReadOnlyArray<?{|
                +format: ?string,
                +name: ?string,
                +url: ?string,
              |}>,
            |}
          |}>,
          +$fragmentRefs: MediaList_media$ref,
        |},
      |},
    |},
    +$fragmentRefs: NoPhotosForOrderActions_userInfo$ref,
  |},
  +$refType: AccountEventPage_viewer$ref,
|};
export type AccountEventPage_viewer$data = AccountEventPage_viewer;
export type AccountEventPage_viewer$key = {
  +$data?: AccountEventPage_viewer$data,
  +$fragmentRefs: AccountEventPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "MediaInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mediaList",
    "storageKey": "mediaList(first:1000)",
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1000
      }
    ],
    "concreteType": "MediaConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "MediaEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Media",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "variants",
                "storageKey": null,
                "args": null,
                "concreteType": "MediaFile",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "format",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "MediaList_media",
        "args": null
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "AccountEventPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orders",
          "storageKey": "orders(first:100)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100
            }
          ],
          "concreteType": "OrderConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Order",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "cart",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Cart",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "lineItems",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "LineItem",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "product",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "Product",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "event",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "Event",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "orderid",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "vouchers",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Voucher",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "code",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "campaign",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "VoucherCampaign",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "banner",
                              "args": null,
                              "storageKey": null
                            },
                            (v3/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "boughtEvent",
          "storageKey": null,
          "args": (v4/*: any*/),
          "concreteType": "Event",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "products",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "price",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "mainSearch",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "pendingMediaProcessing",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "EventInfoCard_eventInfo",
              "args": null
            },
            {
              "kind": "FragmentSpread",
              "name": "NoPhotosForOrderActions_eventInfo",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "eventPurchases",
          "storageKey": null,
          "args": (v4/*: any*/),
          "concreteType": "EventPurchases",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "availableCertificates",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "availableVideos",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "availableZips",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "bonusMedia",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": (v5/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "canRestart",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "restartState",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "participants",
              "storageKey": null,
              "args": null,
              "concreteType": "BoughtParticipant",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "media",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExtendedMediaConnection",
                  "plural": false,
                  "selections": (v5/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startnumber",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "singleMedia",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": (v5/*: any*/)
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "NoPhotosForOrderActions_userInfo",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fcceee1d2f2232198fb04ca7a417dca1';

module.exports = node;
