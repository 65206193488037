/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecheckMediaList_impressionMediaData$ref: FragmentReference;
declare export opaque type RecheckMediaList_impressionMediaData$fragmentType: RecheckMediaList_impressionMediaData$ref;
export type RecheckMediaList_impressionMediaData = {|
  +impressionMedia: ?{|
    +mediaInfo: ?{|
      +count: ?number
    |}
  |},
  +$refType: RecheckMediaList_impressionMediaData$ref,
|};
export type RecheckMediaList_impressionMediaData$data = RecheckMediaList_impressionMediaData;
export type RecheckMediaList_impressionMediaData$key = {
  +$data?: RecheckMediaList_impressionMediaData$data,
  +$fragmentRefs: RecheckMediaList_impressionMediaData$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "RecheckMediaList_impressionMediaData",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "impressionMedia",
      "storageKey": null,
      "args": null,
      "concreteType": "ExtendedMediaConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "mediaInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "MediaInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "count",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3d34bdcd3a461ee5d6be204062ef7a51';

module.exports = node;
