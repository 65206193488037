import React, { useState } from 'react';
import { FormattedMessageWrappedInSpan } from '../misc';

import FaqCard from './FaqCard';

const FaqRecheck = () => {
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleExpandClick = (id) => {
    setSelectedCardId((prevState) => (prevState != id ? id : null));
  };

  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="faq-container">
          <h2>
            <FormattedMessageWrappedInSpan id="faq.title" defaultMessage="Help" />
          </h2>
          <FaqCard
            expanded={selectedCardId === 0}
            onExpandClick={() => handleExpandClick(0)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.howLong.title"
                defaultMessage="How long do I have to wait?"
              />
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.howLong.description"
                defaultMessage="We review your request manually. As soon as we checked all pictures, we'll notify you by email, and you can see the status in your account area. Usually you should get notified within 24 hours. It can take up to one week."
              />
            </p>
          </FaqCard>

          <FaqCard
            expanded={selectedCardId === 1}
            onExpandClick={() => handleExpandClick(1)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.howToDownloadApproved.title"
                defaultMessage="How can I download the approved pictures?"
              />
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.howToDownloadApproved.description"
                defaultMessage="As soon as we reviewed your request, you'll get notified by email. In case you already placed an order, you will then be able to download your pictures from your account area.{br}If you didn't place an order yet, you'll see that accepted photos have been added to our offer."
                values={{
                  br: <br />,
                }}
              />
            </p>
          </FaqCard>

          <FaqCard
            expanded={selectedCardId === 2}
            onExpandClick={() => handleExpandClick(2)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.canIAddLater.title"
                defaultMessage="Can I buy my Foto-Flat and look for further pictures later?"
              />
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.canIAddLater.description"
                defaultMessage="Yes, you can search for and add more pictures of yourself at no extra cost. You can do this before or after you ordered your Foto-Flat."
              />
            </p>
          </FaqCard>

          <FaqCard
            expanded={selectedCardId === 3}
            onExpandClick={() => handleExpandClick(3)}
            title={
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.whyRejected.title"
                defaultMessage="Why was my request rejected?"
              />
            }
          >
            <p>
              <FormattedMessageWrappedInSpan
                id="faq.recheckPage.whyRejected.description"
                defaultMessage="We offer each participant their own personalized Foto-Flat. You can add more pictures of yourself for free. Adding photos not showing yourself isn't part of the Foto-Flat. We're sorry if you've been falsely declined.{br}If your photos have been rejected, but you are visible in them, please send us the <a>picture numbers</a> and we will review them again."
                values={{
                  br: <br />,
                  a: (chunks) => (
                    <a
                      href="https://sportograf.freshdesk.com/a/solutions/articles/77000429786?lang=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-blue"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          </FaqCard>
        </div>
      </div>
    </div>
  );
};

export default FaqRecheck;
