/**
 * @flow
 * @relayHash dc300d5cc83ffc1391f420187a166303
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PayWithBankTransferInput = {|
  orderId: string
|};
export type PayWithBankTransferMutationVariables = {|
  input: PayWithBankTransferInput
|};
export type PayWithBankTransferMutationResponse = {|
  +payWithBankTransfer: ?{|
    +order: ?{|
      +id: string,
      +orderid: ?string,
      +state: ?string,
      +payment: ?{|
        +state: ?string
      |},
    |}
  |}
|};
export type PayWithBankTransferMutation = {|
  variables: PayWithBankTransferMutationVariables,
  response: PayWithBankTransferMutationResponse,
|};
*/


/*
mutation PayWithBankTransferMutation(
  $input: PayWithBankTransferInput!
) {
  payWithBankTransfer(input: $input) {
    order {
      id
      orderid
      state
      payment {
        state
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PayWithBankTransferInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PayWithBankTransferMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "payWithBankTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PayWithBankTransferPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payment",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PayWithBankTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "payWithBankTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PayWithBankTransferPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "order",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payment",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "PayWithBankTransferMutation",
    "id": null,
    "text": "mutation PayWithBankTransferMutation(\n  $input: PayWithBankTransferInput!\n) {\n  payWithBankTransfer(input: $input) {\n    order {\n      id\n      orderid\n      state\n      payment {\n        state\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d37842dcf3c93be3166c95f79305cfa';

module.exports = node;
