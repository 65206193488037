/**
 * @flow
 * @relayHash d039fee7194f0bbdecf75e3aa3514d26
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFotoCdToCartInput = {|
  count?: ?number,
  productId: string,
  textLine1?: ?string,
  textLine2?: ?string,
  textLine3?: ?string,
  textLine4?: ?string,
|};
export type AddFotoCdToCartMutationVariables = {|
  input: AddFotoCdToCartInput
|};
export type AddFotoCdToCartMutationResponse = {|
  +addFotoCdToCart: ?{|
    +cart: ?{|
      +additionalProducts: ?$ReadOnlyArray<?{|
        +id: string,
        +price: ?any,
        +type: ?string,
      |}>,
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string,
        +product: ?{|
          +event: ?{|
            +sgId: ?number,
            +name: ?string,
          |},
          +price: ?any,
          +type: ?string,
        |},
        +price: ?any,
        +productParams: ?{|
          +foto?: ?{|
            +sgId: ?number
          |},
          +startnumber?: ?string,
        |},
      |}>,
      +shipmentRequired: ?boolean,
      +totalPrice: ?any,
    |}
  |}
|};
export type AddFotoCdToCartMutation = {|
  variables: AddFotoCdToCartMutationVariables,
  response: AddFotoCdToCartMutationResponse,
|};
*/


/*
mutation AddFotoCdToCartMutation(
  $input: AddFotoCdToCartInput!
) {
  addFotoCdToCart(input: $input) {
    cart {
      additionalProducts {
        id
        price
        type
      }
      lineItems {
        id
        product {
          event {
            sgId
            name
            id
          }
          price
          type
          id
        }
        price
        productParams {
          __typename
          ... on SingleFotoParams {
            foto {
              sgId
              id
            }
          }
          ... on FotoFlatParams {
            startnumber
          }
        }
      }
      shipmentRequired
      totalPrice
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddFotoCdToCartInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "additionalProducts",
  "storageKey": null,
  "args": null,
  "concreteType": "Product",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/)
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sgId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "type": "FotoFlatParams",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startnumber",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shipmentRequired",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPrice",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddFotoCdToCartMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addFotoCdToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddFotoCdToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "event",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "productParams",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "type": "SingleFotoParams",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "foto",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Media",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              },
              (v9/*: any*/),
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddFotoCdToCartMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addFotoCdToCart",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddFotoCdToCartPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lineItems",
                "storageKey": null,
                "args": null,
                "concreteType": "LineItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "product",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "event",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "productParams",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "SingleFotoParams",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "foto",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Media",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  }
                ]
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddFotoCdToCartMutation",
    "id": null,
    "text": "mutation AddFotoCdToCartMutation(\n  $input: AddFotoCdToCartInput!\n) {\n  addFotoCdToCart(input: $input) {\n    cart {\n      additionalProducts {\n        id\n        price\n        type\n      }\n      lineItems {\n        id\n        product {\n          event {\n            sgId\n            name\n            id\n          }\n          price\n          type\n          id\n        }\n        price\n        productParams {\n          __typename\n          ... on SingleFotoParams {\n            foto {\n              sgId\n              id\n            }\n          }\n          ... on FotoFlatParams {\n            startnumber\n          }\n        }\n      }\n      shipmentRequired\n      totalPrice\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a288fe6664f9af7c879e378fba26185b';

module.exports = node;
