import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';

import FotoFlatOffer from '../event/FotoFlatOffer';
import { PARTICIPANT, RECHECK } from '../../../utils/variables';
import MediaListInfoTile from './MediaListInfoTile';
import MediaListAdditionalPhotoTile from './MediaListAdditionalPhotoTile';
import classNames from 'classnames';

const MediaListMasonry = (props) => {
  const {
    listType,
    onImagesLoaded,
    fotoFlatOffer,
    participantMediaCount,
    onBuyFotoFlatClick,
    impressionMediaCount,
    onScrollToImpressions,
    onScrollToVoucher,
    onScrollToExtendedSearch,
    onScrollToCertificate,
    showMorePhotosBox,
    onScrollToVideo,
    setFotoFlatRef,
    hasVideo,
    hasVideoUrl,
    hasCertificates,
    hasCertificateUrl,
    recheckHasOrder,
    onGoToSearchResults,
    onGoToAccountEventPage,
    hasApprovedRechecks,
    hasOnlyRejectedRechecks,
    onOpenRecheckProcedureDialog,
    goBackToMainSearchResults,
    eventHasFotoFlat,
    showOfferHintForStillProcessingMedia,
  } = props;

  const [mobile, setMobile] = useState(false);

  const containerElementRef = useRef();
  const fotoFlatIndex = mobile ? 4 : 3;
  const isRecheckHintVisible =
    listType === RECHECK && recheckHasOrder && !hasOnlyRejectedRechecks;

  useEffect(() => {
    window.addEventListener('resize', getSizes);
    getSizes();

    return () => {
      window.removeEventListener('resize', getSizes);
    };
  }, []);

  const getSizes = () => {
    const windowWidth = window.innerWidth;

    //temporary fix for case when resizing happens when component is not yet mounted
    // revisit eventually
    // if (!this.state) {
    //   return;
    // }

    if (windowWidth >= 768) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  const onImagesLoadedInner = (imagesLoaded) => {
    //we manually add classes to html elements to avoid a strange behavior with
    //this library which caused a memory leak when updating state in this method
    imagesLoaded.images.forEach((image) => {
      if (!image.loaded && image.img && image.img.classList) {
        image.loaded = true;
        image.img.classList.add('image-loaded');
      }
    });
    containerElementRef.current.classList.add('show-fotoflat-offer');

    if (listType === PARTICIPANT && onImagesLoaded) {
      onImagesLoaded();
    }
  };

  return (
    <div className="medialist-container" ref={containerElementRef}>
      <div>
        <Masonry
          className={classNames("masonry-container",  {'show-fotoflat-offer': showOfferHintForStillProcessingMedia})}
          options={{
            columnWidth: '.medialist-item-container',
            gutter: 0,
            itemSelector: '.medialist-item-container',
            percentPosition: true,
          }}
          disableImagesLoaded={false}
          updateOnEachImageLoad={true}
          onImagesLoaded={onImagesLoadedInner}
        >
          {props.children.slice(0, fotoFlatIndex)}
          {fotoFlatOffer && (
            <FotoFlatOffer
              key="FotoFlatOffer"
              type={listType === RECHECK ? RECHECK : 'main'}
              offer={fotoFlatOffer}
              mobile={mobile}
              participantMediaCount={participantMediaCount}
              impressionMediaCount={impressionMediaCount}
              onBuyFotoFlatClick={onBuyFotoFlatClick}
              onScrollToImpressions={onScrollToImpressions}
              onScrollToVoucher={onScrollToVoucher}
              onScrollToCertificate={onScrollToCertificate}
              setFotoFlatRef={setFotoFlatRef}
              hasVideo={hasVideo}
              onScrollToVideo={onScrollToVideo}
              hasVideoUrl={hasVideoUrl}
              hasCertificates={hasCertificates}
              hasCertificateUrl={hasCertificateUrl}
              goToSearchResults={() => onGoToSearchResults()}
              showOfferHintForStillProcessingMedia={showOfferHintForStillProcessingMedia}
            />
          )}
          {eventHasFotoFlat &&
            (listType === 'categories' ||
              listType === 'dual' ||
              listType === 'gps' ||
              listType === 'suggestions') && (
              <div className="medialist-item-container more-info-tile-container">
                <MediaListInfoTile
                  key="MediaListInfoTile-alternativeSearch"
                  type="alternativeSearchHint"
                  onClick={() => goBackToMainSearchResults()}
                />
              </div>
            )}
          {props.children.slice(fotoFlatIndex)}
          {showOfferHintForStillProcessingMedia && participantMediaCount > 0 && <MediaListAdditionalPhotoTile />}
          {showMorePhotosBox && (
            <div className="medialist-item-container medialist-infotile-container">
              <MediaListInfoTile
                type="morePhotos"
                key="MediaListMorePhotos"
                participantMediaCount={participantMediaCount}
                onClick={() => onScrollToExtendedSearch()}
              />
            </div>
          )}
          {isRecheckHintVisible && (
            <div className="medialist-item-container medialist-infotile-container">
              {hasApprovedRechecks ? (
                <MediaListInfoTile
                  type="recheckShowOrder"
                  key="recheckShowOrder-order"
                  onClick={() => onGoToAccountEventPage()}
                />
              ) : (
                <MediaListInfoTile
                  type="recheckPending"
                  key="recheckPending"
                  onClick={() => onOpenRecheckProcedureDialog()}
                />
              )}
            </div>
          )}
        </Masonry>
      </div>
    </div>
  );
};

MediaListMasonry.defaultProps = {
  showOfferHintForStillProcessingMedia: false,
};

MediaListMasonry.propTypes = {
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  fotoFlatOffer: PropTypes.object,
  children: PropTypes.node,
  onBuyFotoFlatClick: PropTypes.func,
  onScrollToImpressions: PropTypes.func,
  onScrollToVoucher: PropTypes.func,
  onScrollToVideo: PropTypes.func,
  onScrollToCertificate: PropTypes.func,
  onImagesLoaded: PropTypes.func,
  listType: PropTypes.string,
  onScrollToExtendedSearch: PropTypes.func,
  showMorePhotosBox: PropTypes.bool,
  setFotoFlatRef: PropTypes.func,
  hasVideo: PropTypes.bool,
  hasVideoUrl: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  hasCertificateUrl: PropTypes.bool,
  goBackToMainSearchResults: PropTypes.func,
  eventHasFotoFlat: PropTypes.bool,
  recheckHasOrder: PropTypes.bool,
  onGoToSearchResults: PropTypes.func,
  onGoToAccountEventPage: PropTypes.func,
  hasApprovedRechecks: PropTypes.bool,
  onOpenRecheckProcedureDialog: PropTypes.func,
  hasOnlyRejectedRechecks: PropTypes.bool,
  showOfferHintForStillProcessingMedia: PropTypes.bool,
};

export default MediaListMasonry;
