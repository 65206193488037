/* global GA_TRACKING_ID: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import LoadingView from '../content/LoadingView';
import withRequestHandler from '../hoc/withRequestHandler';

import LoginMutation from '../../mutations/LoginMutation';
import SetCookieSettingsMutation from '../../mutations/SetCookieSettingsMutation';

import { initGA, disableGA, trackPageview, setPage } from '../../utils/ga-tracking';
import { isLanguageSupported } from '../../intl/languages';
import environment from '../../environment';
import {
  pathHasLoginToken,
  getLoginTokenFromPath,
  getLanguageFromPath,
} from '../../utils/common';
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from '../../utils/browser-storage';
import ClearCartMutation from '../../mutations/ClearCartMutation';

const UserContext = React.createContext();

const UserConsumer = UserContext.Consumer;

const GA_USER_ID = 'ga-user-id';
const GA_TRACKING_DISABLE = 'ga-disable-' + GA_TRACKING_ID;
class UserProvider extends Component {
  constructor(props) {
    super(props);

    this.gaIsInitialized = false;
    this.state = {
      componentDidMount: false,
      isLoggedIn: getFromLocalStorage('accessToken') ? true : false,
      statsCookies:
        getFromLocalStorage('userAcceptsStatsCookies') === 'true' ? true : false,
      socialCookies:
        getFromLocalStorage('userAcceptsSocialCookies') === 'true' ? true : false,
      sportografWebCookies:
        getFromLocalStorage('userAcceptsSportografWebCookies') === 'true' ? true : false,
      isMarathonFoto: false,
    };

    props.history.listen((location) => {
      if (this.gaIsInitialized) {
        if (isLanguageSupported(getLanguageFromPath(location.pathname))) {
          setPage(location.pathname);
          trackPageview(location.pathname);
        }
      }
    });

    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handleCookiesChange = this.handleCookiesChange.bind(this);
    this.handleMarathonFotoChange = this.handleMarathonFotoChange.bind(this);
  }

  componentDidMount() {
    // Check if the path has a login token
    this.setState({ componentDidMount: true });
    const { location, onRequestStart } = this.props;
    if (pathHasLoginToken(location.search)) {
      onRequestStart();
      const loginToken = getLoginTokenFromPath(location.search);
      if (this.state.isLoggedIn) {
        this._handleLogout(() => {
          this._handleLogin('email', loginToken, location.pathname);
        });
      } else {
        this._handleLogin('email', loginToken, location.pathname);
      }
    }

    // Init Google Analytics (GA)
    if (this.state.statsCookies && !this.gaIsInitialized) {
      initGA(GA_TRACKING_ID);
      trackPageview(this.props.location.pathname);
      this.gaIsInitialized = true;
    }
  }

  handleLoginChange(newLoginStatus, callback, cartShouldBeCleared, cartId) {
    if (!newLoginStatus) {
      this._handleLogout(callback);
    }
    if (cartShouldBeCleared && newLoginStatus === false) {
      ClearCartMutation.commit(environment, cartId);
    }
  }

  handleMarathonFotoChange(newValue) {
    this.setState({ isMarathonFoto: newValue });
  }

  handleCookiesChange(newValue) {
    this.setState({
      statsCookies: newValue.stats,
      socialCookies: newValue.social,
      sportografWebCookies: newValue.sportografWeb,
    });
    if (this.state.statsCookies || newValue.stats) {
      setToLocalStorage('userAcceptsStatsCookies', newValue.stats);
    }
    if (this.state.socialCookies || newValue.social) {
      setToLocalStorage('userAcceptsSocialCookies', newValue.social);
    }
    if (this.state.sportografWebCookies || newValue.sportografWeb) {
      setToLocalStorage('userAcceptsSportografWebCookies', newValue.sportografWeb);
    }

    // Update cookie settings of user
    this._setCookieSettings(newValue.stats);

    // If the cookies settings changes, GA can be switched on
    if (newValue.stats && !this.gaIsInitialized) {
      initGA(GA_TRACKING_ID);
    }

    // If the cookies settings changes with stats OFF
    if (!newValue.stats) {
      disableGA(GA_TRACKING_DISABLE);
      this._delete_cookie('_ga');
      this._delete_cookie('_gat');
      this._delete_cookie('_gid');
    }
  }

  _handleLogout(callback) {
    this.setState({ isLoggedIn: false }, () => {
      removeFromLocalStorage('refreshToken');
      removeFromLocalStorage('accessToken');
      removeFromLocalStorage('email');
      callback && callback();
    });
  }

  _delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  _handleLogin(provider, code, redirectPath) {
    const { isLoading, history, onRequestStart, onRequestStop, onRequestError } =
      this.props;
    // Since location.pathname can differ from the path (URL) that was originally before the
    // login token, it is also necessary to get the path (URL) where the customer should
    // be redirected after successful login.
    !isLoading && onRequestStart();
    LoginMutation.commit(environment, provider, code, (error, auth) => {
      if (!error) {
        setToLocalStorage('email', auth.email);
        setToLocalStorage('refreshToken', auth.token.refresh);
        setToLocalStorage('accessToken', auth.token.access);
        this.setState({ isLoggedIn: true }, () => {
          if (!redirectPath.includes('checkout')) {
            // If the login link is not for the checkout: replace the current path (URL) with
            // the 'location.pathname'.
            // If the login link is for the checkout: do not replace the current path (URL)
            // with the 'location.pathname' because this information is necessary when the
            // CheckoutPage is rendered.
            history.replace(redirectPath);
          }
        });
        onRequestStop();
      } else {
        onRequestError(error);
      }
    });
  }

  _setCookieSettings(optIn) {
    const userId = getFromLocalStorage(GA_USER_ID);
    if (userId) {
      SetCookieSettingsMutation.commit(environment, optIn, userId);
    }
  }

  render() {
    // Since the 'componentDidMount' method starts some processes which influence
    // the return values of the component, the 'LoadingView' is returned as long this
    // method has not been called.
    if (this.props.isLoading || !this.state.componentDidMount) {
      return <LoadingView />;
    }
    return (
      <UserContext.Provider
        value={{
          isLoggedIn: this.state.isLoggedIn,
          isMarathonFoto: this.state.isMarathonFoto,
          onLoginChange: this.handleLoginChange,
          onCookiesChange: this.handleCookiesChange,
          onMarathonFotoChange: this.handleMarathonFotoChange,
          statsCookies: this.state.statsCookies,
          socialCookies: this.state.socialCookies,
          sportografWebCookies: this.state.sportografWebCookies,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
};

const WrappedComponent = withRouter(withRequestHandler(UserProvider));

export { WrappedComponent as UserProvider };
export { UserContext };
export { UserConsumer };
