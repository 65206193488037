import React, { useRef, useEffect } from 'react';

function usePrevStateValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; 
  }, [value]);
  return ref.current; 
}
export default usePrevStateValue;
