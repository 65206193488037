// Checking if the current browser is supported
function isSupportedBrowser(wndw) {
  var Browsers, Versions, browser_name, browser_version;

  Versions = {
    Firefox: /firefox\/([\d\w\.\-]+)/i,
    IE: /msie\s([\d\.]+[\d])/i,
    Chrome: /chrome\/([\d\w\.\-]+)/i,
    Safari: /version\/([\d\w\.\-]+)/i,
  };
  Browsers = {
    Konqueror: /konqueror/i,
    Chrome: /chrome/i,
    Safari: /safari/i,
    IE: /msie/i,
    Opera: /opera/i,
    Firefox: /firefox/i,
  };

  function UserAgent(source) {
    if (source == null) {
      source = navigator.userAgent;
    }
    this.source = source.replace(/^\s*/, '').replace(/\s*$/, '');
    this.browser_name = browser_name(this.source);
    this.browser_version = browser_version(this.source);
  }

  browser_name = function(string) {
    if (Browsers.Konqueror.test(string)) {
      return 'konqueror';
    } else if (Browsers.Chrome.test(string)) {
      return 'chrome';
    } else if (Browsers.Safari.test(string)) {
      return 'safari';
    } else if (Browsers.IE.test(string)) {
      return 'ie';
    } else if (Browsers.Opera.test(string)) {
      return 'opera';
    } else if (Browsers.Firefox.test(string)) {
      return 'firefox';
    } else {
      return 'unknown';
    }
  };

  browser_version = function(string) {
    var regex;
    switch (browser_name(string)) {
      case 'chrome':
        if (Versions.Chrome.test(string)) {
          return RegExp.$1;
        }
        break;
      case 'safari':
        if (Versions.Safari.test(string)) {
          return RegExp.$1;
        }
        break;
      case 'firefox':
        if (Versions.Firefox.test(string)) {
          return RegExp.$1;
        }
        break;
      case 'ie':
        if (Versions.IE.test(string)) {
          return RegExp.$1;
        }
        break;
      default:
        regex = /#\{name\}[\/ ]([\d\w\.\-]+)/i;
        if (regex.test(string)) {
          return RegExp.$1;
        }
    }
  };

  // Assume supported, and just return false for browser versions
  // that we definitely have no interest in supporting.
  var userAgent = new UserAgent();
  if (userAgent.browser_name === 'ie' && userAgent.browser_version <= 11) {
    return false;
  }
  if (userAgent.browser_name === 'unknown') {
    return false;
  }
  return true;
}

export { isSupportedBrowser };
