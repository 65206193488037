import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';

import { THUMBNAIL, SINGLE_MEDIA_ACCOUNT_PAGE } from '../../../utils/variables';
import { getPhotoVariant } from '../../../utils/common';
import EventListItem from './EventListItem';
import { useTransition, animated } from 'react-spring';

const EventTwoStepsListItem = (props) => {
  const { viewer, event, eventId, itemHeight, setIsLoading } = props;
  const [showAllBibs, setShowAllBibs] = useState(false);

  let boughtBibNumbers = viewer.account.eventPurchases.participants;
  let hasOnlyOneBibBought = boughtBibNumbers.length === 1;
  const hasSingleMediaBought =
    viewer.account.eventPurchases.singleMedia.mediaInfo.count > 0;
  const hasOnlySingleMediaBought = boughtBibNumbers.length === 0 && hasSingleMediaBought;

  if (hasSingleMediaBought) {
    const containsSingleMediaAccountPage = boughtBibNumbers.some(
      (participant) => participant.startnumber === SINGLE_MEDIA_ACCOUNT_PAGE
    );
    if (!containsSingleMediaAccountPage) {
      let boughtBibNumbersCopy = boughtBibNumbers.slice();
      boughtBibNumbersCopy.push({ startnumber: SINGLE_MEDIA_ACCOUNT_PAGE });
      boughtBibNumbers = boughtBibNumbersCopy;
      hasOnlyOneBibBought = boughtBibNumbersCopy.length === 1;
    }
  }

  const getRandomPhotoForBib = (bibNumber) => {
    if (bibNumber === SINGLE_MEDIA_ACCOUNT_PAGE) {
      if (viewer.account.eventPurchases.singleMedia.mediaList.edges.length < 1) {
        return null;
      }
      return getPhotoVariant(
        viewer.account.eventPurchases.singleMedia.mediaList.edges[0].node.variants,
        THUMBNAIL
      );
    } else {
      const dataForBibNumber = boughtBibNumbers.filter(
        (eventPurchaseInfo) => eventPurchaseInfo.startnumber === bibNumber
      );
      if (dataForBibNumber.length > 0) {
        const { edges } = dataForBibNumber[0].media.mediaList;
        if (edges.length < 1) {
          return null;
        }
        const randomIndex = Math.floor(Math.random() * edges.length);
        return getPhotoVariant(edges[randomIndex].node.variants, THUMBNAIL);
      }
    }
  };

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(false);
    }
  }, []);

  const transitions = useTransition(showAllBibs ? boughtBibNumbers : [], {
    from: {
      opacity: 0,
      transform: 'translateX(-50px)',
      width: '0%',
      flexBasis: '0%',
      paddingLeft: '0',
      paddingRight: '0',
    },
    enter: [
      {
        width: '25%',
        flexBasis: '25%',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',

        config: { duration: 300 },
      },
      {
        opacity: 1,
        transform: 'translateX(0)',
        config: { duration: 300 },
      },
    ],

    leave: [
      {
        opacity: 0,
        transform: 'translateX(-50px)',
        config: { duration: 300 },
      },
      {
        width: '0%',
        flexBasis: '0%',
        paddingLeft: '0',
        paddingRight: '0',
        config: { duration: 300 },
      },
    ],
    trail: 100,
  });

  if (hasOnlyOneBibBought) {
    return (
      <EventListItem
        event={event}
        hasFooter={false}
        key={event.id}
        linkTo={`/account/event/${eventId}/${boughtBibNumbers[0].startnumber}`}
        showLinkIcon={true}
        itemHeight={itemHeight}
        fotoFlatId={boughtBibNumbers[0].startnumber}
        isSinglePhotosItem={boughtBibNumbers[0].startnumber === SINGLE_MEDIA_ACCOUNT_PAGE}
      />
    );
  } else if (hasOnlySingleMediaBought) {
    return (
      <EventListItem
        event={event}
        hasFooter={false}
        key={event.id}
        linkTo={`/account/event/${eventId}/${SINGLE_MEDIA_ACCOUNT_PAGE}`}
        showLinkIcon={true}
        itemHeight={itemHeight}
        isSinglePhotosItem={true}
      />
    );
  } else {
    return (
      <>
        <EventListItem
          event={event}
          hasFooter={true}
          key={event.id}
          onClick={() => {
            //wait for height correction to avoid buggy animation
            if (itemHeight) {
              setShowAllBibs((prevState) => !prevState);
            }
          }}
          itemHeight={itemHeight}
          hasManyBibsToShow={true}
          isExpanded={showAllBibs}
          numberOfBibs={boughtBibNumbers.length}
        />
        {transitions((style, participant, t) => (
          <animated.div style={style} key={participant.startnumber}>
            <EventListItem
              event={event}
              hasFooter={false}
              key={event.id}
              isParticipantFotoFlatPreview={true}
              participantPreviewPhoto={getRandomPhotoForBib(participant.startnumber)}
              fotoFlatId={participant.startnumber}
              linkTo={`/account/event/${eventId}/${participant.startnumber}`}
              showLinkIcon={true}
              isSinglePhotosItem={participant.startnumber === SINGLE_MEDIA_ACCOUNT_PAGE}
            />
          </animated.div>
        ))}
      </>
    );
  }
};

EventTwoStepsListItem.propTypes = {
  eventId: PropTypes.string,
  viewer: PropTypes.object,
  event: PropTypes.object,
  itemHeight: PropTypes.number,
  setIsLoading: PropTypes.func,
};

export default createFragmentContainer(EventTwoStepsListItem, {
  viewer: graphql`
    fragment EventTwoStepsListItem_viewer on Viewer {
      account {
        eventPurchases(byStartnumber: true, eventId: $eventId) {
          singleMedia {
            mediaInfo {
              count
            }

            mediaList(first: 1) {
              edges {
                node {
                  id
                  variants {
                    name
                    url
                  }
                }
              }
            }
          }
          participants {
            startnumber
            media {
              mediaList(first: 50) {
                edges {
                  node {
                    id
                    variants {
                      name
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
