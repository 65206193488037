import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessageWrappedInSpan } from '../../misc';

import AddPhotoAlternateRoundedIcon from '@material-ui/icons/AddPhotoAlternateRounded';

const MediaListAdditionalPhotoTile = ({ onBuyFotoFlat }) => {
  return (
    <div className="medialist-item-container">
      <div className="medialist-additional-photo">
        <div className="medialist-additional-photo_icon">
          <AddPhotoAlternateRoundedIcon />
        </div>
        <div className="medialist-additional-photo_text">
          <FormattedMessageWrappedInSpan
            id="mediaListAdditionalPhotoTile.moreMightBeAdded"
            defaultMessage="More photos will be added automatically to your package if found"
          />
        </div>
      </div>
    </div>
  );
};

MediaListAdditionalPhotoTile.propTypes = {
  onBuyFotoFlat: PropTypes.func,
};

export default MediaListAdditionalPhotoTile;
