import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import FormattedMessageWrappedInSpan from '../../misc/FormattedMessageWrappedInSpan';
import CloseIcon from '@material-ui/icons/Close';

const JobHint = () => {
  const [isJobHintClosed, setIsJobHintClosed] = useState(false);

  const handleHideJobHint = (e) => {
    e.preventDefault();
    setIsJobHintClosed(true);
    };

  return (
    <div className={isJobHintClosed ? "job-hint exit" : "job-hint"}>
      <Link to="/jobs">
        <div className="job-hint-redirect-btn">
        <FormattedMessageWrappedInSpan
          id="homePage.weAreHiringHint"
          defaultMessage="We are hiring!"
        />
        </div>
      </Link>
      <button  onClick={handleHideJobHint}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default JobHint;
