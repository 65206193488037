import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation ClearCartMutation {
    clearCart {
      cleared
    }
  }
`;

function commit(environment, cartId, callback) {
  return commitMutation(environment, {
    mutation,
    updater: store => {
      store.delete(cartId);
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.clearCart.cleared);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
