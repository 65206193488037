import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function UnsupportedBrowserBanner(props) {
  const { show, onRequestClose } = props;

  if (show) {
    return (
      <div className="cookie-banner-container">
        <div>
          <p>
            <FormattedMessageWrappedInSpan
              id="unsupportedBrowserBanner.note"
              defaultMessage="Please note that Sportograf does not yet fully support Internet Explorer."
            />
            <br />
            <FormattedMessageWrappedInSpan
              id="unsupportedBrowserBanner.recommondation"
              defaultMessage="We recommend upgrading to the latest Microsoft Edge, Google Chrome, Safari or Firefox."
            />
          </p>
        </div>
        <div className="button-container">
          <button className="secondary" onClick={() => onRequestClose(true)}>
            <FormattedMessageWrappedInSpan id="unsupportedBrowserBanner.acceptButton" defaultMessage="Ok" />
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

UnsupportedBrowserBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
};

export default UnsupportedBrowserBanner;
