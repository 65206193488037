import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../components/misc';
import { Redirect } from 'react-router-dom';

import GDPRHint from '../components/content/GDPRHint';
import LoadingPlaceholder from '../components/content/loading/LoadingPlaceholder';
import LoginForm from '../components/content/LoginForm';
import withRequestHandler from '../components/hoc/withRequestHandler';
import withUserConsumer from '../components/hoc/withUserConsumer';
import withNotificationDialogConsumer from '../components/hoc/withNotificationDialogConsumer';

import RequestLoginMutation from '../mutations/RequestLoginMutation';

import environment from '../environment';

import { MAGIC_LINK_SENT_MSG } from '../utils/variables';
import { setToLocalStorage } from '../utils/browser-storage';

const LoginPage = (props) => {
  const [linkSentSuccess, setLinkSentSuccess] = useState(false);
  const [isLinkSending, setIsLinkSending] = useState(false);

  const handleRequestLogin = email => {
    setIsLinkSending(true);
    setToLocalStorage('email', email);
    const { match, history, onRequestStart, onRequestStop, onRequestError } = props;
    onRequestStart();
    RequestLoginMutation.commit(
      environment,
      email,
      false,
      match.params.language,
      (error, res) => {
        if (error) {
          onRequestError(error);
          // Here it's necessary to replace the path with '/:language/login'. Otherwise
          // the component will be unmounted and remounted. This causes the state to be
          // reset and the ErrorDialog not to be displayed.
          setIsLinkSending(false);
          history.replace(`/${match.params.language}/login`);
        } else {
          props.onOpenNotificationDialog(MAGIC_LINK_SENT_MSG);
          onRequestStop();
          setLinkSentSuccess(true);
          setIsLinkSending(false);
        }
      },
    );
  };

  const {
    isLoggedIn,
    isLoading,
    onOpenNotificationDialog,
  } = props;
  if (isLoggedIn) {
    return <Redirect to="/account/events" />;
  }

  return (
    <div className="container-page container-960 login-page">
        <div className="row flex-center">
          <div className="col-xs-16 col-sm-6 login-container">
              <h2>
                <FormattedMessageWrappedInSpan id="loginPage.title" defaultMessage="Signup / Login" />
              </h2>
              <LoadingPlaceholder isLoading={isLoading}>
                <LoginForm
                  onRequestLogin={handleRequestLogin}
                  onOpenNotificationDialog={onOpenNotificationDialog}
                  isLinkSending={isLinkSending || isLoading}
                  linkSentSuccess={linkSentSuccess}
                />
                <GDPRHint className="mt-30 center-text" />
              </LoadingPlaceholder>
            </div>
        </div>
    </div>
  );
}

LoginPage.propTypes = {
  isLoggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  onOpenNotificationDialog: PropTypes.func,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
};

const WithNotificationDialogHoc = withNotificationDialogConsumer(LoginPage);
const WithUserConsumerHoc = withUserConsumer(WithNotificationDialogHoc);
const WithRequestHandlerHoc = withRequestHandler(WithUserConsumerHoc);

export { LoginPage };
export default WithRequestHandlerHoc;
