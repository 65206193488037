import React from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import isMobile from '../../../utils/isMobile'

import aboutEditingBeforeImage from '../../../static/images/aboutPage/about-editing-before.jpg'
import aboutEditingAfterImage from '../../../static/images/aboutPage/about-editing-after.jpg'
import aboutEditingAfterImageMobile from '../../../static/images/aboutPage/about-editing-after-mobile.jpg'
import aboutEditingBeforeImageMobile from '../../../static/images/aboutPage/about-editing-before-mobile.jpg'

const AboutEditing = props => (
  <StickyContainer className="about-editing-animation-container">
    <Sticky bottomOffset={isMobile() ? 0 : 50}>
      {({
        style,
        isSticky,
        wasSticky,
        distanceFromTop,
        distanceFromBottom,
        calculatedHeight
      }) => {
        const percentage = getViewPercentage(isSticky, distanceFromTop, distanceFromBottom)

        return (
          <div className="about-editing-animation-outer" style={style}>
            <div className="about-editing-animation">
              
              <div className="about-editing-animation-after">
                <img src={isMobile() ? aboutEditingAfterImageMobile : aboutEditingAfterImage} alt=""/>
                <div className="about-editing-animation-text about-editing-animation-text-after">
                  After
                </div>
              </div>
              <div className="about-editing-animation-before" style={{
                maxHeight: isSticky && `${percentage*100}%`
              }}>
                <img src={isMobile() ? aboutEditingBeforeImageMobile : aboutEditingBeforeImage} alt=""/>
                <div className="about-editing-animation-text about-editing-animation-text-before">
                  Before
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Sticky>
  </StickyContainer>
)

function getViewPercentage(isSticky, top, bottom) {
  if (!isSticky) {
    return
  }

  return 1 - Math.min((top * -1)/ (bottom - top - 200), 1);
}

export default AboutEditing
