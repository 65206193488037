/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CheckoutBillTable_bill$ref = any;
type CheckoutOrderingPaymentStepAddress_address$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutOrderingPaymentStep_cart$ref: FragmentReference;
declare export opaque type CheckoutOrderingPaymentStep_cart$fragmentType: CheckoutOrderingPaymentStep_cart$ref;
export type CheckoutOrderingPaymentStep_cart = {|
  +id: string,
  +availablePaymentMethods: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +defaultPaymentType: ?string,
  |}>,
  +order: ?{|
    +id: string,
    +address: ?{|
      +firstName: ?string,
      +lastName: ?string,
      +line1: ?string,
      +line2: ?string,
      +postalCode: ?string,
      +city: ?string,
      +country: ?{|
        +alpha2: ?string
      |},
      +state: ?string,
      +$fragmentRefs: CheckoutOrderingPaymentStepAddress_address$ref,
    |},
    +bill: ?{|
      +totalBruttoPrice: ?any,
      +totalBruttoPriceUnpayed: ?any,
      +$fragmentRefs: CheckoutBillTable_bill$ref,
    |},
    +payment: ?{|
      +paymentMethod: ?{|
        +id: string,
        +name: ?string,
        +defaultPaymentType: ?string,
      |}
    |},
    +state: ?string,
  |},
  +additionalProducts: ?$ReadOnlyArray<?{|
    +id: string,
    +type: ?string,
  |}>,
  +paymentRequired: ?boolean,
  +totalPrice: ?any,
  +$refType: CheckoutOrderingPaymentStep_cart$ref,
|};
export type CheckoutOrderingPaymentStep_cart$data = CheckoutOrderingPaymentStep_cart;
export type CheckoutOrderingPaymentStep_cart$key = {
  +$data?: CheckoutOrderingPaymentStep_cart$data,
  +$fragmentRefs: CheckoutOrderingPaymentStep_cart$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "defaultPaymentType",
    "args": null,
    "storageKey": null
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CheckoutOrderingPaymentStep_cart",
  "type": "Cart",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "availablePaymentMethods",
      "storageKey": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "address",
          "storageKey": null,
          "args": null,
          "concreteType": "Address",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "line1",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "line2",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "postalCode",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "city",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "country",
              "storageKey": null,
              "args": null,
              "concreteType": "Country",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "alpha2",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            (v2/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "CheckoutOrderingPaymentStepAddress_address",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "bill",
          "storageKey": null,
          "args": null,
          "concreteType": "Bill",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalBruttoPrice",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalBruttoPriceUnpayed",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "CheckoutBillTable_bill",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "payment",
          "storageKey": null,
          "args": null,
          "concreteType": "Payment",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "paymentMethod",
              "storageKey": null,
              "args": null,
              "concreteType": "PaymentMethod",
              "plural": false,
              "selections": (v1/*: any*/)
            }
          ]
        },
        (v2/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "additionalProducts",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentRequired",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalPrice",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1246c20b23b07b9e18461a08d64c0cc1';

module.exports = node;
