import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CookieSettingsDialog from '../components/content/dialogs/CookieSettingsDialog';
import { FormattedMessageWrappedInSpan } from '../components/misc';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const MFFooter = (props) => {
  const { statsCookies, socialCookies, onCookiesChange } = props;

  const [cookieSettingsDialogOpen, setCookieSettingsDialogOpen] = useState(false);

  return (
    <Fragment>
      <div className="mf-header marathon-foto bootstrap-styles">
        <footer className="l-site-footer footer">
          <div className="footer__navigation-bar">
            <div className="container">
              <div className="row-bootstrap">
                <div className="col-md-12 col-lg-8">
                  <ul className="footer__site-links">
                    <li className="footer__site-link">
                      <a href="https://marathonfoto.com/Home/AboutUs">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.about"
                          defaultMessage="About"
                        />
                      </a>
                    </li>
                    <li className="footer__site-link">
                      <a href="https://marathonfoto.com/Home/UpcomingEvents">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.upcomingEvents"
                          defaultMessage="Upcoming Events"
                        />
                      </a>
                    </li>
                    <li className="footer__site-link">
                      <a href="https://marathonfoto.com/Race/Directors">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.raceDirectors"
                          defaultMessage="Race Directors"
                        />
                      </a>
                    </li>
                    <li className="footer__site-link">
                      <a href="https://marathonfoto.com/Home/EmailUs">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.feedback"
                          defaultMessage="Feedback"
                        />
                      </a>
                    </li>
                    <li className="footer__site-link">
                      <a href="https://marathonfoto.com/Home/ContactUs">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.contactUs"
                          defaultMessage="contactUs"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-4 col-lg-4 col-lg-offset-0">
                  <div className="row-bootstrap">
                    <div className="col-sm-8"></div>
                    <div className="col-sm-4">
                      <ul className="footer__social-links">
                        <a
                          href="https://twitter.com/MarathonFotoEPG"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li className="footer__social-link footer__social-icon footer__social-icon--twitter"></li>
                        </a>
                        <a
                          href="https://www.facebook.com/marathonfoto/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li className="footer__social-link footer__social-icon footer__social-icon--facebook"></li>
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__info-bar">
            <div className="container">
              <div className="row-bootstrap">
                <div className="col-sm-6">
                  <ul className="footer__privacy-terms-links">
                    <li className="footer__privacy-terms-link">
                      <a href="https://marathonfoto.com/Home/Privacy">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.privacyPolicy"
                          defaultMessage="Privacy Policy"
                        />
                      </a>
                    </li>
                    <li className="footer__privacy-terms-link">
                      <a href="https://marathonfoto.com/Home/TermsOfUse">
                        <FormattedMessageWrappedInSpan
                          id="MFFooter.terms"
                          defaultMessage="Terms Of Use"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <p className="footer__permission">
                    <FormattedMessageWrappedInSpan
                      id="MFFooter.copyright"
                      defaultMessage="© {currentYear} MarathonFoto Digital Solutions"
                      values={{
                        currentYear: new Date().getFullYear,
                      }}
                    />

                    <br />
                    <FormattedMessageWrappedInSpan
                      id="MFFooter.permission"
                      defaultMessage="Images may not be reproduced without written permission."
                    />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <CookieSettingsDialog
        statsCookies={statsCookies}
        socialCookies={socialCookies}
        onCookiesChange={onCookiesChange}
        onRequestClose={(needsRefresh) => {
          setCookieSettingsDialogOpen(false);
          if (needsRefresh) {
            refreshPage();
          }
        }}
        open={cookieSettingsDialogOpen}
      />
    </Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

MFFooter.propTypes = {
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
  onCookiesChange: PropTypes.func,
};

export default injectIntl(MFFooter);
