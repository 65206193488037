/* global braintree: true */
/* global paypal: true */
/* global process: true */

import { sendError } from '../appsignal';

function renderPayPalBtn(elementId, braintreeToken, config) {
  const buttonStyle =
    config.checkoutStep === 2
      ? {
          color: config.paymentMethod.name === 'PayPal' ? 'blue' : 'black',
          shape: 'rect',
          size: 'small',
          label: 'buynow',
        }
      : {
          color: 'gold',
          shape: 'pill',
          size: 'small',
          label: 'checkout',
        };

  braintree.client.create(
    {
      authorization: braintreeToken,
    },
    function(clientErr, clientInstance) {
      // Stop if there was a problem creating the client.
      // This could happen if there is a network error or if the authorization
      // is invalid.
      if (clientErr) {
        console.error('Error creating client:', clientErr);
        return;
      }

      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create(
        {
          client: clientInstance,
        },
        function(paypalCheckoutErr, paypalCheckoutInstance) {
          // Stop if there was a problem creating PayPal Checkout.
          // This could happen if there was a network error or if it's incorrectly
          // configured.
          if (paypalCheckoutErr) {
            console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
            return;
          }

          paypalCheckoutInstance.loadPayPalSDK({
            currency: config.variables.currency,
            locale: config.locale.replace("-","_"),
          }, function (loadPayPalSDKErr) {
            if (loadPayPalSDKErr) {
              console.error('Error loading PayPal SDK:', loadPayPalSDKErr);
              return;
            }

            // Adds https://www.paypal.com/sdk/js script to the page and
            // adds the paypal object to the window

            const fundingSource = config.paymentMethod ?
              (config.paymentMethod.name == 'PayPal' ? paypal.FUNDING.PAYPAL : paypal.FUNDING.CARD) :
              paypal.FUNDING.PAYPAL;

            // Set up PayPal JS SDK (see next section)
            // Set up PayPal with the checkout.js library
            paypal.Buttons(
              {
                fundingSource: fundingSource,
                createOrder: function() {
                  return paypalCheckoutInstance.createPayment({
                    flow: 'checkout', // Required
                    enableShippingAddress: config.paymentMethod ? false : true,
                    ...config.variables,
                  });
                },

                style: buttonStyle,

                onClick: function(data) {
                  config.onClick && config.onClick();
                },

                onApprove: function(data, actions) {
                  return paypalCheckoutInstance
                    .tokenizePayment(data)
                    .then(function(payload) {
                      config.onAuthorize(payload.nonce);
                    })
                    .catch(error => {
                      error["name"] = "PayPal Tokenization "+error["name"];
                      sendError(error);
                    });
                },

                onCancel: function(data) {
                  config.onCancel && config.onCancel();
                },

                onError: function(err) {
                  err["name"] = "PayPal onError "+err["name"];
                  sendError(err);
                  return;
                },

                onShippingChange: function(data, actions) {
                  // Workaround to prevent the embedded credit card form
                  // from showing.
                  return actions.resolve();
                },
              }
            ).render(
              `#${elementId}`,
            ).then(function() {
              // The PayPal button will be rendered in an html element with the id
              // `paypal-button`. This function will be called when the PayPal button
              // is set up and ready to be used.
              config.onReady();
            }).catch(error => {
              const knownErrorMsg = `Document is ready and element #${elementId} does not exist`;
              if (!error.message.includes(knownErrorMsg)) {
                error["name"] = "PayPal General "+error["name"];
                sendError(error);
              }
              // else: do nothing
            });
          });

        },
      );
    },
  );
}

export function initPaypalButton(elementId, braintreeToken, config) {
  renderPayPalBtn(elementId, braintreeToken, config);
}
