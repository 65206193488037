import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import AccountPage from './AccountPage';
import QueryHandler from '../components/misc/QueryHandler';
import withUserConsumer from '../components/hoc/withUserConsumer';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const AccountPageQueryRenderer = (props) => {
  const { location, isLoggedIn, onCookiesChange, statsCookies, socialCookies } = props;
  if (!isLoggedIn) {
    return <Redirect to={`/login${location.search}`} />;
  }
  return (
    <QueryHandler
      query={graphql`
        query AccountPageQueryRendererQuery($count: Int!) {
          viewer {
            ...AccountPage_viewer
          }
        }
      `}
      variables={{ count: 100 }}
      render={(props) => {
        return (
          <AccountPage
            viewer={props.viewer}
            onCookiesChange={onCookiesChange}
            socialCookies={socialCookies}
            statsCookies={statsCookies}
          />
        );
      }}
    />
  );
};

AccountPageQueryRenderer.propTypes = {
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  viewer: PropTypes.object,
  onCookiesChange: PropTypes.func,
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
};

export default withUserConsumer(AccountPageQueryRenderer);
