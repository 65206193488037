import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessageWrappedInSpan } from '../../misc';
import classNames from 'classnames';

// material ui
import Chip from '@material-ui/core/Chip';

const ALL_PHOTOS_ID = 'allphotos';

const ExtendedSearchCategorySearchTab = (props) => {
  const { categories, selectedCategoryId, eventId, startnumber, onNavigateTo } = props;

  const handleChipChanged = (categoryId) => {
    onNavigateTo(`/event/${eventId}/category/${categoryId}/${startnumber}`);
  };

  return (
    <div className="extended-search-category-container">
      <div className="row center-xs">
        {categories.searchAllPhotos && (
          <Chip
            classes={{
              root: classNames('extended-search-category-chip', {
                selected: selectedCategoryId === ALL_PHOTOS_ID,
              }),
            }}
            key={ALL_PHOTOS_ID}
            label={
              <FormattedMessageWrappedInSpan
                id="extendedSearchCategorySearchTab.allPhotosChip"
                defaultMessage="All photos of the event"
              />
            }
            onClick={() => handleChipChanged(ALL_PHOTOS_ID)}
          />
        )}
        {categories.mediaCollections.map((mc) => {
          if (mc.name !== 'ALL pictures of the event') {
            return (
              <Chip
                classes={{
                  root: classNames('extended-search-category-chip', {
                    selected: selectedCategoryId === mc.id,
                  }),
                }}
                key={mc.id}
                label={mc.name}
                onClick={() => handleChipChanged(mc.id)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

ExtendedSearchCategorySearchTab.propTypes = {
  eventId: PropTypes.number,
  selectedCategoryId: PropTypes.string,
  startnumber: PropTypes.string,
  categories: PropTypes.object,
  onNavigateTo: PropTypes.func,
};

export { ExtendedSearchCategorySearchTab };

export default createFragmentContainer(ExtendedSearchCategorySearchTab, {
  categories: graphql`
    fragment ExtendedSearchCategorySearchTab_categories on Event {
      mediaCollections {
        id
        name
      }
      searchAllPhotos
    }
  `,
});
