import React, { useState, useEffect } from 'react';
import JobsPageItem from './JobsItem';
import { useParams } from 'react-router-dom';
import jobsEn from '../../../intl/en/jobs-en.json';
import jobsDe from '../../../intl/de/jobs-de.json';
import CircularProgress from '@material-ui/core/CircularProgress';

const JobsPage = () => {
  const [showJob, setShowJob] = useState(false);
  const [job, setJob] = useState('nix');
  const language = useParams().language;
  const [jobsData, setJobsData] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    if (language === 'de') {
      setJobsData(jobsDe.jobsPage);
    } else {
      setJobsData(jobsEn.jobsPage);
    }
  }, []);

  useEffect(() => {
    if (jobsData) {
      setIsDataFetched(true);
    }
  }, [jobsData]);

  const determineImage = (workType) => {
    switch (workType) {
      case 'developerWebsite':
        return 'https://www.sportograf.com/images/elixir.png';
      case 'developerCSharp':
        return 'https://www.sportograf.com/images/csharp.png';
      case 'photographer':
        return 'https://www.sportograf.com/images/photographer.png';
      default:
        return 'https://www.sportograf.com/images/job-em.png';
    }
  };

  return (
    <>
      <div className="social-buttons-container"/>
      <div className="container-960 container-full-width">
        <div className="row mt-40">
          {!isDataFetched && (
            <span>
              <CircularProgress />
            </span>
          )}
          {isDataFetched &&
            jobsData &&
            Object.entries(jobsData).map((jobAd) => (
              <JobsPageItem
                image={determineImage(jobAd[0])}
                jobType={jobAd[0]}
                showJob={showJob}
                setShowJob={setShowJob}
                setJob={setJob}
                job={job}
                title={jobAd[1].title && jobAd[1].title}
                titleEn={jobAd[1].titleEn && jobAd[1].titleEn}
                intro={jobAd[1].intro && jobAd[1].intro}
                introEn={jobAd[1].introEn && jobAd[1].introEn}
                language={language}
                jobData={jobAd[1]}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default JobsPage;
export const JOBS_AMOUNT = 4;
