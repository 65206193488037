import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import isEmpty from 'validator/lib/isEmpty';
import isEmailProper from '../../../utils/isEmailProper';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import GDPRHint from '../GDPRHint';
import { FormattedMessageWrappedInSpan } from '../../misc';

import { getFromLocalStorage } from '../../../utils/browser-storage';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class FeedbackForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      email: getFromLocalStorage('email') || '',
      showInputError: false,
      rating: 0,
    };

    this.handleSendFeedback = this.handleSendFeedback.bind(this);
    this.handleInputValueChange = this.handleInputValueChange.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
  }

  handleInputValueChange(e) {
    this.setState({ [e.target.id]: e.target.value });

    if (this.state.showInputError) {
      this.setState({ showInputError: false });
    }
  }

  handleSendFeedback() {
    if (this._inputIsValid()) {
      const satisfaction = `\nUser satisfaction: ${this.state.rating}`;
      const feedback = this.state.message + satisfaction;
      this.props.onSendClick(this.state.email.trim(), feedback);
    } else {
      this.setState({ showInputError: true });
    }
  }

  handleRatingChange(index) {
    // Possibility to unselect the smallest rating symbol (here: first star) again
    if (index === 1 && this.state.rating === 1) {
      this.setState({ rating: 0 });
    } else {
      this.setState({ rating: index });
    }
  }

  getErrorText(inputValue, isEmailField) {
    const { intl } = this.props;
    if (this._isEmtpy(inputValue)) {
      return intl.formatMessage(translations.inputDefaultError);
    }
    if (isEmailField) {
      if (!isEmailProper(inputValue)) {
        return intl.formatMessage(translations.invalidEmailError);
      }
    }
  }

  _inputIsValid() {
    if (!isEmailProper(this.state.email)) {
      return false;
    }

    return true;
  }

  _isEmtpy(s) {
    return isEmpty(s, { ignore_whitespace: true });
  }

  _renderRating() {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={i <= this.state.rating ? 'filled-star' : 'empty-star'}
          onClick={() => this.handleRatingChange(i)}
        />
      );
    }
    return stars;
  }

  render() {
    const { intl, onCancelClick, showRating } = this.props;
    const { message, email, showInputError } = this.state;

    return (
      <div className="notification-container">
        <DialogContent>
          <div className="feedback-body">
            <h1>
              <FormattedMessageWrappedInSpan
                id="feedbackForm.title"
                defaultMessage="Your feedback counts!"
              />
            </h1>
            <h3>
              <FormattedMessageWrappedInSpan
                id="feedbackForm.subtitle"
                defaultMessage="Tell us your feedback."
              />
            </h3>
            {showRating && (
              <Fragment>
                <Divider classes={{ root: 'feedback-divider' }} />
                <h2 className="mb-30">
                  <FormattedMessageWrappedInSpan
                    id="feedbackForm.question"
                    defaultMessage="How do you like our new website?"
                  />
                </h2>
                <div className="feedback-rating">
                  <div className="feedback-stars-container">{this._renderRating()}</div>
                </div>
                <Divider classes={{ root: 'feedback-divider' }} />
              </Fragment>
            )}
            <TextField
              autoFocus={!email}
              classes={{ root: 'feedback-input' }}
              error={showInputError}
              fullWidth
              helperText={showInputError && this.getErrorText(email, true)}
              id="email"
              label={intl.formatMessage(translations.emailFloatingLabel)}
              onChange={this.handleInputValueChange}
              value={email}
            />
            <div className="feedback-text-area-wrapper">
              <TextField
                autoFocus={email !== ''}
                classes={{ root: 'feedback-input' }}
                fullWidth
                id="message"
                label={intl.formatMessage(translations.feedbackFloatingLabel)}
                multiline={true}
                onChange={this.handleInputValueChange}
                placeholder={intl.formatMessage(translations.feedbackInputHintText)}
                rows={2}
                rowsMax={5}
                value={message}
              />
            </div>
            <div>
              <GDPRHint />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="notification-action">
            <button onClick={onCancelClick}>
              <FormattedMessageWrappedInSpan
                id="feedbackForm.cancelButton"
                defaultMessage="Cancel"
              />
            </button>
            <button className="accept" onClick={this.handleSendFeedback}>
              <FormattedMessageWrappedInSpan
                id="feedbackForm.sendButton"
                defaultMessage="Send Feedback"
              />
            </button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

const translations = defineMessages({
  emailFloatingLabel: {
    id: 'feedbackForm.emailFloatingLabel',
    defaultMessage: 'Your email address',
  },
  inputDefaultError: {
    id: 'feedbackForm.inputDefaultError',
    defaultMessage: 'This field is required',
  },
  invalidEmailError: {
    id: 'feedbackForm.invalidEmailError',
    defaultMessage: 'Please enter a correct email address',
  },
  feedbackFloatingLabel: {
    id: 'feedbackForm.feedbackFloatingLabel',
    defaultMessage: 'Your feedback',
  },
  feedbackInputHintText: {
    id: 'feedbackForm.feedbackInputHintText',
    defaultMessage:
      'Write us something about our photos or the new website. Every suggestion counts.',
  },
});

FeedbackForm.propTypes = {
  showRating: PropTypes.bool,
  intl: PropTypes.object,
  onCancelClick: PropTypes.func,
  onSendClick: PropTypes.func,
};

export { FeedbackForm };
export default injectIntl(FeedbackForm);
