import React from 'react';
import PropTypes from 'prop-types';

import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';

import { FormattedMessageWrappedInSpan } from '../../misc';
import {
  RECHECK_APPROVED,
  RECHECK_REJECTED,
  RECHECK_REQUESTED,
} from '../../../utils/variables';

const ThumbnailPreviewRecheckButtons = (props) => {
  const { recheckStatus, onGoToAccountEventPage, recheckHasOrder } = props;

  return (
    <>
      {recheckStatus === RECHECK_REQUESTED && (
        <button className="btn-default primary-new full-width" disabled={true}>
          <FormattedMessageWrappedInSpan
            id="thumbnailPreviewAccountDetails.actions.recheckInProgress"
            defaultMessage="In progress"
          />
        </button>
      )}
      {recheckStatus === RECHECK_REJECTED && (
        <button className="btn-default secondary-new full-width" disabled={true}>
          <FormattedMessageWrappedInSpan
            id="thumbnailPreviewAccountDetails.actions.recheckRejected"
            defaultMessage="Rejected"
          />
        </button>
      )}
      {recheckStatus === RECHECK_APPROVED && recheckHasOrder && (
        <>
          <button
            className="accont-download-btn"
            onClick={() => onGoToAccountEventPage()}
          >
            <ExitToAppRoundedIcon className="account-tile-icon"/>
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.actions.goToOrder"
              defaultMessage="Go to order"
            />
          </button>
        </>
      )}
    </>
  );
};

ThumbnailPreviewRecheckButtons.propTypes = {
  recheckStatus: PropTypes.string,
  onGoToAccountEventPage: PropTypes.func,
  recheckHasOrder: PropTypes.bool,
};

export default ThumbnailPreviewRecheckButtons;
