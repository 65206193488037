import React, { useState, useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { animated, useTransition } from 'react-spring';

import Image from '../../misc/Image';
import ImageInfo from '../../misc/ImageInfo';
import { IconRoundedButton } from '../../misc/Buttons';

import { getPhotoVariant } from '../../../utils/common';
import {
  THUMBNAIL,
  RECHECK_APPROVED,
  RECHECK_REJECTED,
  RECHECK_REQUESTED,
  RECHECK,
} from '../../../utils/variables';
import { FormattedMessageWrappedInSpan } from '../../misc';

import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

const MediaListItem = (props) => {
  const {
    item,
    intl,
    onShowPreview,
    listType,
    status,
    isMultiselectModeActive,
    onClickForMultipleSelect,
    isSelectedInMultiselectMode,
  } = props;

  const [isItemSelected, setIsItemSelected] = useState(Boolean(item.recheckStatus));

  useEffect(() => {
    if (isSelectedInMultiselectMode) {
      setIsItemSelected(true);
    } else {
      if (!item.recheckStatus) {
        setIsItemSelected(false);
      }
    }
  }, [isSelectedInMultiselectMode]);

  // According to the backend team, we can't assume that the object which describes a
  // specific variant will always be found at the same index in the array.

  // if (isAccountEventPage) {
  //   const socialMedia = getPhotoVariant(item.variants, SOCIAL_MEDIA);
  //   if (socialMedia) {
  //     return socialMedia;
  //   }
  // }

  // If none of the previous variants were found return the 'thumbnail' variant
  const photoVariant = getPhotoVariant(item.variants, THUMBNAIL);

  const defineBottomLeftElement = () => {
    if (listType != RECHECK && !isMultiselectModeActive) {
      return (
        <ImageInfo
          label={intl.formatTime(new Date(item.shotDate), {
            timeZone: 'UTC',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        />
      );
    } else if (listType === RECHECK) {
      if (status === RECHECK_REQUESTED) {
        return (
          <>
            <RefreshRoundedIcon />
            <FormattedMessageWrappedInSpan
              id="accountRecheckPage.state.progress"
              defaultMessage="In progress"
            />
          </>
        );
      }

      if (status === RECHECK_APPROVED) {
        return (
          <>
            <DoneRoundedIcon />
            <FormattedMessageWrappedInSpan
              id="accountRecheckPage.state.approved"
              defaultMessage="Approved"
            />
          </>
        );
      }
      if (status === RECHECK_REJECTED) {
        return (
          <>
            <CloseRoundedIcon />
            <FormattedMessageWrappedInSpan
              id="accountRecheckPage.state.rejected"
              defaultMessage="Rejected"
            />
          </>
        );
      }
    } else return null;
  };

  const transitions = useTransition(isItemSelected, {
    from: { transform: 'scale3d(0.9, 0.9, 0.9)', opacity: 0 },
    enter: {
      transform: 'scale3d(1.1, 1.1, 1.1)',
      opacity: 1,
      config: { velocity: 0.02, tension: 180, friction: 20 },
    },
    leave: { transform: 'scale3d(0.9, 0.9, 0.9)', opacity: 0 },
    config: { duration: 200 },
  });

  return (
    <div
      className={classNames('medialist-item-container')}
      onClick={() => {
        if (isMultiselectModeActive && !item.recheckStatus) {
          onClickForMultipleSelect();
          setIsItemSelected((prevState) => !prevState);
        } else if (!isMultiselectModeActive) {
          onShowPreview(item.id);
        }
      }}
      // to prevent mouse selection on little drag movements
      onMouseDown={(event) => event.preventDefault()}
      onMouseUp={(event) => event.preventDefault()}
    >
      <Image
        listType={listType}
        recheckStatus={listType === RECHECK && status}
        src={photoVariant?.url || item.url}
        imgHasFullWidth={true}
        bottomLeftElement={defineBottomLeftElement()}
        isSelected={isItemSelected}
      />
      {transitions((style, element) =>
        element ? (
          <animated.div style={style} className="medialist-item-check-icon">
            {isMultiselectModeActive &&
              (item.recheckStatus ? (
                <div className="img-info-tag">
                  <FormattedMessageWrappedInSpan
                    id="image.alreadyRequested"
                    defaultMessage="Requested"
                  />
                </div>
              ) : (
                <IconRoundedButton icon={<DoneRoundedIcon />} noHoverEffects={true} />
              ))}
          </animated.div>
        ) : null
      )}
    </div>
  );
};

MediaListItem.propTypes = {
  status: PropTypes.string,
  item: PropTypes.object,
  intl: PropTypes.object,
  onShowPreview: PropTypes.func,
  listType: PropTypes.string,
  isMultiselectModeActive: PropTypes.bool,
  onClickForMultipleSelect: PropTypes.func,
  isSelectedInMultiselectMode: PropTypes.bool,
};

export { MediaListItem };

export default createFragmentContainer(injectIntl(MediaListItem), {
  item: graphql`
    fragment MediaListItem_item on Media {
      id
      sgId
      name
      shotDate
      recheckStatus
      variants {
        name
        url
      }
    }
  `,
});
