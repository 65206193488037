import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation BraintreeClientTokenMutation {
    braintreeClientToken {
      token
    }
  }
`;

function commit(environment, callback) {
  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.braintreeClientToken.token);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };
