import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';

import { FormattedMessageWrappedInSpan } from '../../misc';

////////////////////////////////////////////
// TODO MISSING TEST for onRest transition effect
////////////////////////////////////////////

const MultiselectRecheckModeActiveInformation = (props) => {
  const {
    onAccept,
    onCancel,
    isRecheckRequestSent,
    isMultiselectModeActive,
    isAcceptButtonDisabled,
    onResetAfterSuccess,
  } = props;

  const [showContent, setShowContent] = useState(false);

  const transitions = useTransition(showContent, {
    from: { opacity: 0, transform: 'translate3d(0, 100px, 0)' },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: { opacity: 0, transform: 'translate3d(0, 100px, 0)' },
    config: {
      opacity: { duration: 900 },
      transform: { duration: 500 },
      delay: 300,
      ease: 'ease-in-out',
    },
  });

  useEffect(() => {
    if (isRecheckRequestSent) {
      setShowContent(false);
      onCancel();
      onResetAfterSuccess();
    }
  }, [isRecheckRequestSent]);

  useEffect(() => {
    if (!isMultiselectModeActive) {
      setShowContent(false);
    }
  }, [isMultiselectModeActive]);

  return (
    <>
      {transitions((style) => (
        <animated.div style={style} className="row multiselect-recheck-info">
          {!isRecheckRequestSent && (
            <>
              <div className="col-sm-offset-2 col-xs-16 col-sm-7 mb-20">
                <h1 className="mb-10">
                  <FormattedMessageWrappedInSpan
                    id="MultiselectRecheckModeActiveInformation.heading"
                    defaultMessage="Multiselect review mode"
                  />
                </h1>
                <FormattedMessageWrappedInSpan
                  id="MultiselectRecheckModeActiveInformation.instructionHowToSelect"
                  defaultMessage="Click on the thumbnails of the photos you want request a review for."
                />
              </div>
              <div className="col-xs-16 col-sm-5 multiselect-btns">
                <button
                  className="btn-default primary-new full-width"
                  style={{ marginBottom: '10px' }}
                  onClick={onAccept}
                  disabled={isAcceptButtonDisabled}
                >
                  <FormattedMessageWrappedInSpan
                    className="multiselect-accept-btn_desktop"
                    id="MultiselectRecheckModeActiveInformation.acceptButton.desktop"
                    defaultMessage="Confirm choices"
                  />
                  <FormattedMessageWrappedInSpan
                    className="multiselect-accept-btn_mobile"
                    id="MultiselectRecheckModeActiveInformation.acceptButton.mobile"
                    defaultMessage="Confirm"
                  />
                </button>
                <button
                  className="btn-default secondary-new full-width"
                  onClick={onCancel}
                >
                  <FormattedMessageWrappedInSpan
                    id="MultiselectRecheckModeActiveInformation.cancelButton"
                    defaultMessage="Cancel"
                  />
                </button>
              </div>
            </>
          )}
        </animated.div>
      ))}
    </>
  );
};

MultiselectRecheckModeActiveInformation.propTypes = {
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  isRecheckRequestSent: PropTypes.bool,
  isMultiselectModeActive: PropTypes.bool,
  isAcceptButtonDisabled: PropTypes.bool,
  onResetAfterSuccess: PropTypes.func,
};

export default MultiselectRecheckModeActiveInformation;
