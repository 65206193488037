/**
 * @flow
 * @relayHash d1fb7a7365edf30468f077e8ae248305
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MFNavigationBarQueryVariables = {||};
export type MFNavigationBarQueryResponse = {|
  +viewer: ?{|
    +cart: ?{|
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string
      |}>
    |}
  |}
|};
export type MFNavigationBarQuery = {|
  variables: MFNavigationBarQueryVariables,
  response: MFNavigationBarQueryResponse,
|};
*/


/*
query MFNavigationBarQuery {
  viewer {
    cart {
      lineItems {
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lineItems",
  "storageKey": null,
  "args": null,
  "concreteType": "LineItem",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MFNavigationBarQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MFNavigationBarQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MFNavigationBarQuery",
    "id": null,
    "text": "query MFNavigationBarQuery {\n  viewer {\n    cart {\n      lineItems {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b91c3e01ceaac2dbd037327de0b26353';

module.exports = node;
