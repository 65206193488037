import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation RequestAccessToEventMutation($input: RequestAccessToEventInput!) {
    requestAccessToEvent(input: $input) {
      email
      status
    }
  }
`;

function commit(environment, email, language, eventID, startnumber, callback) {
  return commitMutation(environment, {
    mutation,
    variables: {
      input: {
        email,
        language,
        eventID,
        startnumber
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        callback && callback(errors[0]);
      } else {
        callback && callback(null, response.requestAccessToEvent.email);
      }
    },
    onError: err => {
      callback && callback(err);
    },
  });
}

export default { commit };