import { useReducer } from 'react';
import filter from 'lodash.filter';
import find from 'lodash.find';

// This hook should help you handle multiple loading states in your component

// 'state' is an array which contains multiple objects
function useLoadingReducer(...initialIds) {
  const [state, dispatch] = useReducer(reducer, initialIds, init);

  function isObjectLoading(id) {
    const foundObject = find(state, o => o.id === id);
    if (foundObject) {
      return foundObject.pending;
    }
    return false;
  }

  return [state, dispatch, isObjectLoading];

  // ['foto-flat', 'single photo 1', 'single photo 2']
}

function init(initialIds) {
  if (initialIds === undefined) {
    return [];
  } else {
    return initialIds.map(id => ({
      id,
      pending: true,
      error: null,
    }));
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'add':
      // TODO: if the same id should be added to the list, remove first the corresponding
      // object

      // 'action.payload' is the id of the object to be added
      return [
        ...state,
        {
          id: action.payload,
          pending: true,
          error: null,
        },
      ];

    case 'remove':
      // 'action.payload' is the id of the object to be removed
      return filter(state, o => o.id !== action.payload);

    case 'error':
      // 'action.payload' is an object which contains the id and the error
      return [
        ...filter(state, o => o.id !== action.payload.id),
        {
          id: action.payload.id,
          pending: false,
          eror: {
            msg: action.payload.error,
          },
        },
      ];
  }
}

export default useLoadingReducer;
