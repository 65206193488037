/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CartLineItem_lineItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartOverviewItem_lineItem$ref: FragmentReference;
declare export opaque type CartOverviewItem_lineItem$fragmentType: CartOverviewItem_lineItem$ref;
export type CartOverviewItem_lineItem = {|
  +price: ?any,
  +$fragmentRefs: CartLineItem_lineItem$ref,
  +$refType: CartOverviewItem_lineItem$ref,
|};
export type CartOverviewItem_lineItem$data = CartOverviewItem_lineItem;
export type CartOverviewItem_lineItem$key = {
  +$data?: CartOverviewItem_lineItem$data,
  +$fragmentRefs: CartOverviewItem_lineItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CartOverviewItem_lineItem",
  "type": "LineItem",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CartLineItem_lineItem",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4a9ef99a928e2ca983681df821c992df';

module.exports = node;
