/**
 * @flow
 * @relayHash 9e5e4d8c14185e94ba853fd19ed20789
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BraintreeClientTokenMutationVariables = {||};
export type BraintreeClientTokenMutationResponse = {|
  +braintreeClientToken: ?{|
    +token: string
  |}
|};
export type BraintreeClientTokenMutation = {|
  variables: BraintreeClientTokenMutationVariables,
  response: BraintreeClientTokenMutationResponse,
|};
*/


/*
mutation BraintreeClientTokenMutation {
  braintreeClientToken {
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "braintreeClientToken",
    "storageKey": null,
    "args": null,
    "concreteType": "BraintreeClientTokenPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BraintreeClientTokenMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BraintreeClientTokenMutation",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BraintreeClientTokenMutation",
    "id": null,
    "text": "mutation BraintreeClientTokenMutation {\n  braintreeClientToken {\n    token\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82c1b3ad9985b9bfbdd9979c6ff6c834';

module.exports = node;
