import React from 'react';
import PropTypes from 'prop-types';

import {FormattedMessageWrappedInSpan} from '../../misc';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const EventReminderResult = (props) => {
  const {
    showReminderResult,
    reminderResultSuccessful,
    showNewsletterResult,
    newsletterResultStatus,
    closeDialog
  } = props;

  if (!showReminderResult && !showNewsletterResult) {
    return null;
  }

  return (
    <div className="notification-container">
      <DialogContent>
        <div className="notification-body">
          <h1>
            {reminderResultSuccessful &&
              <FormattedMessageWrappedInSpan
                id="eventReminderResult.success.title"
                defaultMessage="Thank you!"
              />
            }
            {!reminderResultSuccessful &&
              <FormattedMessageWrappedInSpan
                id="eventReminderResult.fail.title"
                defaultMessage="We're sorry"
              />
            }
          </h1>
          {reminderResultSuccessful &&
            <FormattedMessageWrappedInSpan
              id="eventReminderResult.success.message"
              defaultMessage="To complete the process, please click on the link in the email we just sent you."
            />
          }
          {!reminderResultSuccessful &&
            <FormattedMessageWrappedInSpan
              id="eventReminderResult.fail.message"
              defaultMessage="Requesting the reminder failed"
            />
          }
          {showReminderResult && showNewsletterResult && !newsletterResultStatus && (
            <CircularProgress />
          )}
          {newsletterResultStatus === 'pending' &&
            <FormattedMessageWrappedInSpan
              id='eventReminderResult.newsletter.pendingStateDescription'
              defaultMessage="Thank you for subscribing to our Newsletter. We send you an email, where you can confirm your email address."
            />
          }
          {newsletterResultStatus === 'subscribed' &&
            <FormattedMessageWrappedInSpan
              id='eventReminderResult.newsletter.subscribedStateDescription'
              defaultMessage="You are already registered for our newsletter."
            />
          }
        </div>
      </DialogContent>
      <DialogActions>
        <div className="notification-action">
          <button onClick={closeDialog}>
            <FormattedMessageWrappedInSpan
              id='eventReminderResult.closeButton'
              defaultMessage="Close"
            />
          </button>
        </div>
      </DialogActions>
    </div>
  )
}

EventReminderResult.propTypes = {
  showReminderResult: PropTypes.bool,
  reminderResultSuccessful: PropTypes.bool,
  showNewsletterResult: PropTypes.bool,
  newsletterResultStatus: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default EventReminderResult;
