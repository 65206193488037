/**
 * @flow
 * @relayHash fa070729988b8e75c4d769351c39df2f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplyOrderCouponInput = {|
  code: string,
  orderId: string,
|};
export type ApplyOrderCouponMutationVariables = {|
  input: ApplyOrderCouponInput
|};
export type ApplyOrderCouponMutationResponse = {|
  +applyOrderCoupon: ?{|
    +cart: ?{|
      +paymentRequired: ?boolean,
      +order: ?{|
        +bill: ?{|
          +coupon: ?{|
            +vat: ?any,
            +brutto: ?any,
          |},
          +totalBruttoPriceUnpayed: ?any,
        |}
      |},
    |}
  |}
|};
export type ApplyOrderCouponMutation = {|
  variables: ApplyOrderCouponMutationVariables,
  response: ApplyOrderCouponMutationResponse,
|};
*/


/*
mutation ApplyOrderCouponMutation(
  $input: ApplyOrderCouponInput!
) {
  applyOrderCoupon(input: $input) {
    cart {
      paymentRequired
      order {
        bill {
          coupon {
            vat
            brutto
          }
          totalBruttoPriceUnpayed
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ApplyOrderCouponInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paymentRequired",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bill",
  "storageKey": null,
  "args": null,
  "concreteType": "Bill",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "coupon",
      "storageKey": null,
      "args": null,
      "concreteType": "BillCoupon",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vat",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "brutto",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBruttoPriceUnpayed",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ApplyOrderCouponMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "applyOrderCoupon",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyOrderCouponPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApplyOrderCouponMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "applyOrderCoupon",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyOrderCouponPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cart",
            "storageKey": null,
            "args": null,
            "concreteType": "Cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ApplyOrderCouponMutation",
    "id": null,
    "text": "mutation ApplyOrderCouponMutation(\n  $input: ApplyOrderCouponInput!\n) {\n  applyOrderCoupon(input: $input) {\n    cart {\n      paymentRequired\n      order {\n        bill {\n          coupon {\n            vat\n            brutto\n          }\n          totalBruttoPriceUnpayed\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cce2785ad9ded500c38fd64c7ea21c74';

module.exports = node;
