/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RecheckMediaList_account$ref = any;
type RecheckMediaList_impressionMediaData$ref = any;
type RecheckMediaList_offer$ref = any;
type RecheckMediaList_recheckMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountRecheckPage_viewer$ref: FragmentReference;
declare export opaque type AccountRecheckPage_viewer$fragmentType: AccountRecheckPage_viewer$ref;
export type AccountRecheckPage_viewer = {|
  +event: ?{|
    +mainSearch: ?string,
    +$fragmentRefs: RecheckMediaList_impressionMediaData$ref & RecheckMediaList_offer$ref,
  |},
  +account: ?{|
    +rechecks: ?$ReadOnlyArray<?{|
      +startnumber: ?string,
      +requestedAt: ?any,
      +media: ?{|
        +$fragmentRefs: RecheckMediaList_recheckMedia$ref
      |},
    |}>,
    +$fragmentRefs: RecheckMediaList_account$ref,
  |},
  +$refType: AccountRecheckPage_viewer$ref,
|};
export type AccountRecheckPage_viewer$data = AccountRecheckPage_viewer;
export type AccountRecheckPage_viewer$key = {
  +$data?: AccountRecheckPage_viewer$data,
  +$fragmentRefs: AccountRecheckPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AccountRecheckPage_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "eventId"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mainSearch",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "RecheckMediaList_impressionMediaData",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "RecheckMediaList_offer",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "rechecks",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "eventId",
              "variableName": "eventId"
            }
          ],
          "concreteType": "Recheck",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startnumber",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "requestedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "media",
              "storageKey": null,
              "args": null,
              "concreteType": "ExtendedMediaConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "RecheckMediaList_recheckMedia",
                  "args": null
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "RecheckMediaList_account",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e099d29db4828303f6fe437911ade812';

module.exports = node;
