import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { Link } from 'react-router-dom';
import { customScrollToComponent } from '../../../utils/common';

import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import { Tooltip } from '@material-ui/core';

const CheckoutCompletedView = (props) => {
  const { email, onOpenFeedbackClick, orderId, isLoggedIn, sgIdForOneEvent } = props;
  const [isCodeCopiedToClipboad, setIsCodeCopiedToClipboad] = useState(false);
  const checkoutSuccessRef = useRef(null);

  const copyOrderIdToClipboard = () => {
    navigator.clipboard.writeText(orderId);
    setIsCodeCopiedToClipboad(true);
  };

  useEffect(() => {
    if (isCodeCopiedToClipboad) {
      const timer = setTimeout(() => {
        setIsCodeCopiedToClipboad(false);
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [isCodeCopiedToClipboad]);

  useEffect(() => {
    if (checkoutSuccessRef.current) {
      customScrollToComponent(checkoutSuccessRef.current);
    }
  }, [])

  return (
    <div className="checkout-success row" ref={checkoutSuccessRef}>
      <div className="col-sm-10 col-xs-16 checkout-success-texts ">
        <h1>
          <FormattedMessageWrappedInSpan
            id="checkoutCompletedView.thankyou"
            defaultMessage="Thank You!"
          />
        </h1>
        <FormattedMessageWrappedInSpan
          id="checkoutCompletedView.message.nextSteps"
          defaultMessage="Thank you for your order. We have just sent you a confirmation email to <span>{email}</span>."
          values={{
            span: (chunks) => <span className="checkout-success-email">{chunks}</span>,
            email: email,
          }}
        />
        <FormattedMessageWrappedInSpan
          id="checkoutCompletedView.message.spamHint"
          defaultMessage="Please check your spam as well."
        />
        <h3>
          <FormattedMessageWrappedInSpan
            id="checkoutCompletedView.message.wish"
            defaultMessage="Enjoy your photos!"
          />
        </h3>
        <div className="checkout-success-actions">
          <Tooltip
            title={
              isCodeCopiedToClipboad ? (
                <FormattedMessageWrappedInSpan
                  id="checkoutCompletedView.copiedToClipboard"
                  defaultMessage="Copied to clipboard"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="checkoutCompletedView.copyToClipboard"
                  defaultMessage="Copy to clipboard"
                />
              )
            }
          >
            <div className="text-with-icon black" onClick={copyOrderIdToClipboard}>
              <span className="text-with-icon icon">
                <ReceiptRoundedIcon />
              </span>
              <FormattedMessageWrappedInSpan
                id="checkoutCompletedView.orderIdBolded"
                defaultMessage="Order ID <span>{orderId}</span>"
                values={{
                  orderId: orderId,
                  span: (chunks) => (
                    <span className="text-with-icon-text-line2">{chunks}</span>
                  ),
                }}
              />
            </div>
          </Tooltip>
          {/* <div className="text-with-icon blue" onClick={onOpenFeedbackClick}>
            <span className="text-with-icon icon">
              <ThumbsUpDownRoundedIcon />
            </span>
            <FormattedMessageWrappedInSpan
              id="checkoutCompletedView.openFeedbackButton"
              defaultMessage="Give us feedback"
            />
          </div> */}
        </div>
        <div className="checkout-success-redirectBtns">
          <Link to="/">
            <button className="secondary fullwidth">
              <FormattedMessageWrappedInSpan
                id="checkoutCompletedView.linkToHomePage"
                defaultMessage="Continue browsing"
              />
            </button>
          </Link>
          <Link to={`${isLoggedIn ? sgIdForOneEvent ? `/account/event/${sgIdForOneEvent}` : '/account/events' : '/login'}`}>
            <button className="btn-default primary-new full-width">
              {isLoggedIn ? (
                <FormattedMessageWrappedInSpan
                  id="checkoutCompletedView.linkToAccountToDownload"
                  defaultMessage="Go to your account to see photos"
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="checkoutCompletedView.linkToLoginPageToDownload"
                  defaultMessage="Log in to see photos"
                />
              )}
            </button>
          </Link>
        </div>
      </div>
      <div className="col-sm-6 col-xs-16 checkout-success-illustration">
        <div className="checked-flag">
          <div className="star star-1"></div>
          <div className="star star-2"></div>
          <div className="star star-3"></div>
        </div>
      </div>
    </div>
  );
};

export { CheckoutCompletedView };

CheckoutCompletedView.propTypes = {
  email: PropTypes.string,
  onOpenFeedbackClick: PropTypes.func,
  orderId: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  sgIdForOneEvent: PropTypes.string
};

export default CheckoutCompletedView;