// eslint ignore
/* global EVENT_FETCH_COUNT: true */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { createPaginationContainer, graphql } from 'react-relay';
import orderBy from 'lodash.orderby';

import EventListItem from './EventListItem';
import LoadMoreButton from './LoadMoreButton';
import NoEventsFoundInformation from '../informations/NoEventsFoundInformation';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class EventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
    };

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ isFetching: true });
    this.props.relay.loadMore(EVENT_FETCH_COUNT, () =>
      this.setState({ isFetching: false })
    );
  }

  _getRouteForEventListItem(event) {
    if (event.subEvents.length > 0) {
      return `/event/${event.sgId}/subevents`;
    }
    return `/event/${event.sgId}`;
  }

  // This isn't used anymore since we don't need the count for each EventCard
  _getPhotoCount(event) {
    let photosCount = 0;
    if (event.subEvents.length > 0) {
      for (let i = 0; i < event.subEvents.length; i++) {
        photosCount += event.subEvents[i].media.mediaInfo.count;
      }
    } else {
      photosCount = event.media.mediaInfo.count;
    }
    return photosCount;
  }

  render() {
    const { viewer, relay, hasFilter } = this.props;
    const { isFetching } = this.state;

    if (viewer.events.edges.length === 0) {
      return <NoEventsFoundInformation />;
    }

    let eventsToDisplay = viewer.events.edges;
    if (hasFilter) {
      eventsToDisplay = orderBy(eventsToDisplay, ['node.beginsAt'], ['desc']);
    }

    return (
      <Fragment>
        <div className="container-960 container-full-width">
          <div className="row">
            {eventsToDisplay.map(({ node: eventNode }) => {
              return (
                <EventListItem
                  event={eventNode}
                  hasPhotoCount={false}
                  key={eventNode.id}
                  linkTo={this._getRouteForEventListItem(eventNode)}
                />
              );
            })}
          </div>
        </div>
        {relay.hasMore() && (
          <LoadMoreButton showSpinner={isFetching} onClick={this.loadMore} />
        )}
      </Fragment>
    );
  }
}

EventList.propTypes = {
  hasFilter: PropTypes.bool,
  viewer: PropTypes.object,
  relay: PropTypes.object,
};

export { EventList };

export default createPaginationContainer(
  EventList,
  {
    viewer: graphql`
      fragment EventList_viewer on Viewer {
        events(first: $count, after: $cursor, query: $query, frontpage: true)
          @connection(key: "EventList_events") {
          edges {
            node {
              id
              beginsAt
              sgId
              state
              subEvents {
                id
                sgId
              }
              ...EventListItem_event
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    // Function that should indicate which connection to paginate over, given the fragment
    // props.
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.events;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        count: paginationInfo.count,
        cursor: paginationInfo.cursor,
        query: fragmentVariables.query,
        landingpage: props.landingpage,
      };
    },
    query: graphql`
      query EventListQuery($count: Int!, $cursor: String, $query: String, $landingpage: String) {
        viewer(landingpage: $landingpage) {
          ...EventList_viewer
        }
      }
    `,
  }
);
