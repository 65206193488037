import React from 'react';
import PropTypes from 'prop-types';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const NavigationMenuMobile = (props) => {
  return (
    <a onClick={props.handleMobileExpand} className="navigation-bar-mobile-menu">
      <svg viewBox="0 0 90 80" xmlns="http://www.w3.org/2000/svg" width="29" height="24">
        <g>
          <rect className="bar1" x="10" y="10" width="70" height="12" rx="7" ry="7" fill="#fff" />
          <rect className="bar2" x="10" y="35" width="70" height="12" rx="7" ry="7" fill="#fff" />
          <rect className="bar3" x="10" y="60" width="70" height="12" rx="7" ry="7" fill="#fff" />
        </g>
      </svg>
    </a>
  )
}

NavigationMenuMobile.propTypes = {
  handleMobileExpand: PropTypes.func,
}

export default NavigationMenuMobile;
