/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PastEventInformation_successorEvent$ref: FragmentReference;
declare export opaque type PastEventInformation_successorEvent$fragmentType: PastEventInformation_successorEvent$ref;
export type PastEventInformation_successorEvent = {|
  +sgId: ?number,
  +beginsAt: ?any,
  +$refType: PastEventInformation_successorEvent$ref,
|};
export type PastEventInformation_successorEvent$data = PastEventInformation_successorEvent;
export type PastEventInformation_successorEvent$key = {
  +$data?: PastEventInformation_successorEvent$data,
  +$fragmentRefs: PastEventInformation_successorEvent$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PastEventInformation_successorEvent",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sgId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beginsAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '627d58c7e9537d0337a1aa346ebd147e';

module.exports = node;
