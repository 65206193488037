import React from 'react';
import { FormattedMessageWrappedInSpan } from '../../misc';

const NoEventsFoundInformation = () => {
  return (
    <div className="container-960 container-full-width">
      <h2>
        <FormattedMessageWrappedInSpan
          id="noEventsFoundView.message"
          defaultMessage="We could not find any events for the name you entered."
        />
      </h2>
    </div>
  );
};

export default NoEventsFoundInformation;
