import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomDialog from './CustomDialog';
import FeedbackForm from '../feedback/FeedbackForm';
import FeedbackResult from '../feedback/FeedbackResult';

import SendFeedbackMutation from '../../../mutations/SendFeedbackMutation';

import environment from '../../../environment';

////////////////////////////////////////////
// TODO TEST CASE - mutation handleSendFeedback
////////////////////////////////////////////

class FeedbackDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,
    };

    this.handleSendFeedback = this.handleSendFeedback.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ status: undefined });
    this.props.onRequestClose();
  }

  handleSendFeedback(from, content) {
    SendFeedbackMutation.commit(environment, from, content, (err, status) => {
      if (err) {
        this.handleClose();
      } else {
        this.setState({ status });  
      }
    });
  }

  render() {
    const { open, showRating } = this.props;
    const { status } = this.state;

    return (
      <CustomDialog open={open} onRequestClose={this.handleClose}>
        {status === undefined ? (
          <FeedbackForm
            showRating={showRating}
            onCancelClick={this.handleClose}
            onSendClick={this.handleSendFeedback}
          />
        ) : (
          <FeedbackResult onCloseClick={this.handleClose} status={status} />
        )}
      </CustomDialog>
    );
  }
}

FeedbackDialog.propTypes = {
  open: PropTypes.bool,
  showRating: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default FeedbackDialog;
