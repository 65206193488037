import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { getCountryNameByAlpha2 } from '../../../intl/languages';
import { FormattedMessageWrappedInSpan } from '../../misc';

const CheckoutOrderingPaymentStepAddress = props => {
  const { address, onShowAddress, onChangeAddress } = props;
  return (
    <div className="row">
      <div className="order-payment-form-address col-xs-8 col-xs-offset-8 col-sm-3 col-sm-offset-10">
        <div
          className="order-payment-form-address-expand"
          onClick={onShowAddress}
        >
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepAddress.hideAddressButton"
            defaultMessage="Hide Address"
          />
        </div>
        <span>{`${address.title || ''} ${address.firstName} ${address.lastName}`}</span>
        <span>{address.line1}</span>
        <span>{address.line2 || ''}</span>
        <span>{`${address.postalCode} ${address.city}`}</span>
        <span>{getCountryNameByAlpha2(address.country.alpha2)}</span>
        {address.country.alpha2 === 'US' && <span>{address.state}</span>}
        <span>{address.phone || ''}</span>
        <div
          className="order-payment-form-address-edit"
          onClick={onChangeAddress}
        >
          <FormattedMessageWrappedInSpan
            id="checkoutOrderingPaymentStepAddress.changeAddressButton"
            defaultMessage="Change Address"
          />
        </div>
      </div>
    </div>
  );
};

CheckoutOrderingPaymentStepAddress.propTypes = {
  address: PropTypes.object,
  onShowAddress: PropTypes.func,
  onChangeAddress: PropTypes.func,
};

export { CheckoutOrderingPaymentStepAddress };

export default createFragmentContainer(CheckoutOrderingPaymentStepAddress, {
  address: graphql`
    fragment CheckoutOrderingPaymentStepAddress_address on Address {
      firstName
      lastName
      phone
      city
      state
      postalCode
      line2
      line1
      country {
        alpha2
        name
      }
      state
    }
  `,
});
