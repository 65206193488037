import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { Link } from 'react-router-dom';

const ExtendedSearchSmartSearchTab = props => {
  const { eventId, startnumber, isSmartSearchPage, eventHasSearchByFace } = props;

  return (
    <div className="extended-search-deepsearch-container">
      {!isSmartSearchPage && (
        <div>
          <h2>
            <FormattedMessageWrappedInSpan
              id="extendedSearchSmartSearchTab.beforeSearch.title"
              defaultMessage="Show me similar photos"
            />
          </h2>
          <p>
            <FormattedMessageWrappedInSpan
              id="extendedSearchSmartSearchTab.beforeSearch.subtitle"
              defaultMessage="We will show you photos, where you might be on."
            />
          </p>
          <Link to={`/event/${eventId}/smartsearch/${startnumber}`} replace={true}>
            <button className="primary">
              <FormattedMessageWrappedInSpan
                id="extendedSearchSmartSearchTab.searchButton"
                defaultMessage="Go"
              />
            </button>
          </Link>
        </div>
      )}
      {isSmartSearchPage && (
        <div>
          <h2>
            <FormattedMessageWrappedInSpan
              id="extendedSearchSmartSearchTab.afterSearch.title"
              defaultMessage="Tag photos which show you"
            />
          </h2>
          <p>
            {eventHasSearchByFace && (
              <FormattedMessageWrappedInSpan
                id="extendedSearchSmartSearchTab.afterSearch.searchByFace.subtitle"
                defaultMessage="Click on a photo and leave your email address."
              />
            )}
            {!eventHasSearchByFace && (
              <FormattedMessageWrappedInSpan
                id="extendedSearchSmartSearchTab.afterSearch.subtitle"
                defaultMessage="Click on a photo, leave your bib number and email address."
              />
            )}
          </p>
        </div>
      )}
    </div>
  );
};

ExtendedSearchSmartSearchTab.propTypes = {
  eventId: PropTypes.number,
  startnumber: PropTypes.string,
  isSmartSearchPage: PropTypes.bool,
  eventHasSearchByFace: PropTypes.bool,
};

export default ExtendedSearchSmartSearchTab;
